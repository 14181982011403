import { Formatter } from '@shift/transform';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NameAndCompanyFormatter: Formatter<any[], { children: string | null }> = ({
    transform: ([names, companies]) => {
        const namesLen = names ? names.length : 0;
        const companiesLen = companies ? companies.length : 0;
        const values = [...Array(namesLen).keys()].map((i) => [names[i], i < companiesLen ? companies[i] : null]);
        const formattedValues = values.map(([name, company]) => (!company ? name : `${name} (${company})`));
        return ({
            children: !formattedValues || formattedValues.length === 0 ? null : formattedValues[0],
        });
    },
});
