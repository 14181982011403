import React from 'react';
import classNames from 'classnames';
import { StyledText } from '@shift/design-system';
import { ContextTranslate as CT } from 'src/utils/translation';
import { SingleFieldInformation } from 'src/types';

export interface SingleLabelProps {
    fieldInfo: SingleFieldInformation;
}

export const SingleLabel = ({ fieldInfo }: SingleLabelProps) => (
    <StyledText weight="bold" size="medium">
        <span className={classNames({ 'mandatory-field': fieldInfo.isRequired })}>
            <CT k={fieldInfo.label ?? ''} />
        </span>
    </StyledText>
);
