import React from 'react';
import { NumberInput, NumberInputProps } from '@shift/design-system';


export const IntegerInput = ({ onChange, ...props }: NumberInputProps) => (
    <NumberInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onChange={(val) => onChange && onChange(val?.replace(/[^0-9]/g, ''))}
    />
);
