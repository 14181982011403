/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState, useEffect, useCallback, useContext,
} from 'react';
import {
    Grid, GridItem, ThemeContext, useKeyboardNavigation,
} from '@shift/design-system';
import { PlusSvg } from '@shift/icons';
import { FieldArray } from 'react-final-form-arrays';
import { AnyType, ArrayFieldInformation, SingleFieldInformation } from 'src/types';
import {
    formTranslate,
} from 'src/utils/forms';
import { SubHeaderForm } from 'src/components/SubHeader/SubHeaderForm';
import { SecondaryIconButton } from 'src/components/button/SecondaryTextButton';

export type ArrayItemProps = {
    setItemPath: <T = SingleFieldInformation | ArrayFieldInformation>(item: T) => T;
    itemIndex: number;
}

export interface ArrayFieldWrapperProps {
    itemTitle?: string;
    Item: React.ComponentType<ArrayItemProps>;
    emptyElement?: (() => AnyType) | AnyType;
    addItemLabel?: string;
    initialItemsCount?: number;
    minCount?: number;
    enableAdd?: boolean;
    disabled?: boolean;
    onChange?: (value: unknown) => void;
}

interface ArrayFieldValues {
    push: (value: any) => void;
    remove: (index: number) => any;
    map: <R>(iterator: (name: string, index: number) => R) => R[];
    pop: () => any;
    length?: number;
}
interface InternalArrayFieldProps extends ArrayFieldWrapperProps {
    fields: ArrayFieldValues;
}

export interface ExpandableFieldProps extends ArrayFieldWrapperProps {
    fieldInfo: ArrayFieldInformation;
}

export const MaxAutoCreatedItems = 5;

interface SubHeader {
    name: string;
    text: string;
    withIcon: boolean;
    onClose: () => void;
    children: JSX.Element;
}

const SubHeader = ({
    name, withIcon, onClose, text, children,
}: SubHeader) => {
    const { colors } = useContext(ThemeContext);

    const keyboardListener = useKeyboardNavigation({
        onEnter: onClose,
    });

    return (
        <div key={name}>
            <SubHeaderForm
                icon={withIcon ? (
                    <div
                        style={{
                            color: colors.brand.secondary.base,
                            padding: '5px',
                        }}
                        onKeyDown={keyboardListener}
                        role="button"
                        tabIndex={0}
                        aria-label="delete"
                        className="fa fa-trash"
                        onClick={onClose}
                    />
                ) : (
                    <div style={{ color: colors.brand.secondary.base }} />
                )}
                text={text}
            />
            {children}
        </div>
    );
};

export const ArrayFieldWrapper = ({
    itemTitle = '',
    Item,
    emptyElement = {},
    addItemLabel = 'Add',
    initialItemsCount = 0,
    minCount = 0,
    enableAdd = true,
    disabled = false,
    fields,
}: InternalArrayFieldProps) => {
    const [count, setCount] = useState(0);
    const [scroll, setScroll] = useState(0);
    const getEmptyElement = useCallback(() => (typeof emptyElement === 'function' ? emptyElement() : emptyElement), [emptyElement]);
    useEffect(() => {
        const newCount = Math.min(initialItemsCount, MaxAutoCreatedItems);
        if (newCount) {
            if (count < newCount) {
                setCount(newCount);
                if (!fields.length || fields.length < newCount) {
                    const length = newCount - (!fields.length ? 0 : fields.length);
                    for (let i = 0; i < length; i += 1) {
                        // TODO: Remove the setTimeout as soon as the issue https://github.com/final-form/react-final-form-arrays/issues/123
                        // will be fixed
                        setTimeout(() => fields.push(getEmptyElement()), 0);
                    }
                }
            }
        }
        if (!enableAdd && (!newCount || (fields.length && fields.length > newCount))) {
            setCount(newCount);
            const length = (!fields.length ? 0 : fields.length) - newCount;
            for (let i = 0; i < length; i += 1) {
                fields.pop();
            }
        }
    }, [count, fields, getEmptyElement, enableAdd, initialItemsCount]);

    useEffect(() => {
        if (scroll) {
            window.scrollTo(0, scroll);
        }
        setScroll(0);
    }, [scroll]);

    return (
        <div>
            {fields.map((fieldName: string, index: number) => (
                <SubHeader
                    name={fieldName}
                    withIcon={enableAdd && minCount <= index}
                    text={`${formTranslate(itemTitle)} ${index + 1}`}
                    onClose={() => fields.remove(index)}
                >
                    <Item
                        setItemPath={
                            (item: any) => ({ ...item, path: `${fieldName}.${(item).path}` })
                        }
                        itemIndex={index}
                    />
                </SubHeader>
            ))}
            {
                enableAdd && (
                    <Grid>
                        <GridItem style={{
                            width: '50%',
                            marginBottom: '1rem',
                        }}
                        >
                            <SecondaryIconButton
                                type="button"
                                disabled={disabled}
                                icon={<PlusSvg />}
                                onClick={() => {
                                    setScroll(window.scrollY);
                                    fields.push(getEmptyElement());
                                }}
                            >
                                {formTranslate(addItemLabel)}
                            </SecondaryIconButton>
                        </GridItem>
                    </Grid>
                )
            }
        </div>
    );
};

export const ExpandableField = (props: ExpandableFieldProps) => {
    const { fieldInfo } = props;
    return (
        <FieldArray
            name={fieldInfo.path}
            validate={fieldInfo.validate}
        >
            {({ fields }) => (
                <ArrayFieldWrapper fields={fields} {...props} />
            )}
        </FieldArray>
    );
};
