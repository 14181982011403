import React, { useState, useEffect } from 'react';

import { FilterableHeader, TextSearchFilter, FilterableHeaderSide } from '@shift/design-system';

import {
    useFieldSort, useFieldFilter, Operation, mapOrder, unmapOrder, Field,
} from '../../../libs/filters';
import { translate } from '../../../utils/forms';

export interface TextFilterProps {
    field: Field;
    operation: Operation;
    children: React.ReactNode;
    side?: FilterableHeaderSide;
}

export const TextFilter = ({
    children, field, operation, side,
}: TextFilterProps) => {
    const [sort, setSort] = useFieldSort(field);
    const [filter, setFilter] = useFieldFilter(field);

    const [pendingFilter, setPendingFilter] = useState(filter);

    useEffect(() => {
        setPendingFilter(filter);
    }, [filter]);

    return (
        <FilterableHeader
            side={side}
            activeFilter={filter?.parameters?.[0]?.value ? filter.parameters[0].value !== '' : false}
            filter={pendingFilter?.parameters?.[0]?.value}
            onFilterChange={(s: string) => setPendingFilter({
                ...operation,
                parameters: [
                    {
                        ...operation.parameters[0],
                        value: s,
                    },
                ],
            })}
            onSubmit={() => setFilter(pendingFilter)}
            onClear={() => { setFilter(null); setSort(null); setPendingFilter(null); }}
            FilterComponent={TextSearchFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            sortAscText={translate('common:sort-string-asc')}
            sortDescText={translate('common:sort-string-desc')}
            order={mapOrder(sort)}
            onOrderChange={(o) => setSort(unmapOrder(o))}
        >
            {children}
        </FilterableHeader>
    );
};
