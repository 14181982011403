import { IMyInfoState } from 'src/types/myInfo';

const key = 'myInfoState';

export function loadState(initialState: IMyInfoState): IMyInfoState {
    try {
        /* global sessionStorage */
        const serializedState = sessionStorage.getItem(key);

        if (!serializedState) return initialState;

        return JSON.parse(serializedState);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Failed to load state: ${e}`);
        return initialState;
    }
}

export function saveState(state: IMyInfoState) {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem(key, serializedState);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Failed to save state: ${e}`);
    }
}

export function clearState() {
    try {
        sessionStorage.removeItem(key);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Failed to save state: ${e}`);
    }
}
