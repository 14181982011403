import {
    RadioGroupInput, RadioGroupInputProps, StyledText,
} from '@shift/design-system';
import React from 'react';
import styled from 'styled-components';
import { MyInfo } from 'src/config/myinfo';
import { useMyInfoState } from 'src/state/MyInfoStateProvider';
import { Link } from 'react-router-dom';
import { arcForm } from 'src/config/routes';
import logo from '../../resources/myinfo_retrival.png';
import logoDisabled from '../../resources/myinfo_retrival_disabled.png';

export type DriverOwnerProps = Omit<RadioGroupInputProps, 'disabled' | 'error' | 'onFocus' | 'onBlur'>;

export const DriverOwner = ({
    value,
}: DriverOwnerProps) => {
    const [isSelected, setIsSelected] = React.useState(false);
    const {
        updateAndStore,
    } = useMyInfoState();
    const title = 'Accident Report prefilled with Myinfo';

    const options = {
        both: {
            labelName: 'The person is the driver and owner of the vehicle',
        },
        driver: {
            labelName: 'The person is only the driver',
        },
        owner: {
            labelName: 'The person is only the owner',
        },
    };

    const handleClick = () => {
        if (isSelected) {
            const authState = Math.floor(Math.random() * 100000);

            const authoriseUrl = `${MyInfo.authUrl
            }?client_id=${
                MyInfo.clientId
            }&attributes=${
                MyInfo.attributes
            }&purpose=${
                MyInfo.purpose
            }&state=${
                encodeURIComponent(authState)
            }&redirect_uri=${
                MyInfo.redirectUrl}`;

            window.location.href = authoriseUrl;
        }
    };

    const onSelect = (selected: string) => {
        setIsSelected(true);
        if (selected === 'both') {
            updateAndStore({ isVehicleDriver: true, isVehicleOwner: true });
        } else if (selected === 'driver') {
            updateAndStore({ isVehicleDriver: true, isVehicleOwner: false });
        } else if (selected === 'owner') {
            updateAndStore({ isVehicleDriver: false, isVehicleOwner: true });
        }
    };

    return (
        <Container>
            <Content>
                <Title>{title}</Title>
                <Space>
                    <RadioGroupInput
                        name={title}
                        options={options}
                        value={value}
                        onChange={onSelect}
                    />
                </Space>
                <ImageButton src={isSelected ? logo : logoDisabled} alt="singpass login" onClick={handleClick} isSelected={isSelected} />
                <StyledText type="div">
                    If the person doesn’t have singpass you can
                    {' '}
                    <StyledLink to={arcForm.path}>fill the report manually</StyledLink>
                </StyledText>
                <br />
                <StyledText type="div">
                    After retrieving Myinfo data please complete the AR by saving it as a Draft or an Open accident report within 2 hours.
                    The AR will become inaccessible after this period.
                </StyledText>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
`;

const Content = styled.div`
  display: flex; /* Use flexbox layout */
  justify-content: flex-start; /* Center content horizontally */
  flex-direction: column;
  height: 100vh; /* Make the container take up the full viewport height */
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    padding-top: 11px;
`;

const Space = styled.div`
    padding-top: 32px;
    padding-bottom: 32px;
`;

const ImageButton = styled.img<{ isSelected: boolean }>`
    width: 272px;
    padding-bottom: 32px;

    &:hover {
        cursor: ${(props) => (props.isSelected ? 'pointer' : 'auto')};
        opacity: 0.9;
    }
`;
const StyledLink = styled(Link)`
    color: #D86018;
    &:hover {
        color: #D86018;
    }
`;
