import { Formatter } from '@shift/transform';
import { EnumValue } from 'src/types';
import { EnumFormatters } from './EnumValuesFormatter';

export const DrivingLicenseValidityFormatter: Formatter<(string | EnumValue)[], { children: string }> = ({
    transform: (params) => {
        const [[validityEnum], [validitySourceName]] = params as [ [EnumValue], [string], ];

        return { children: !validityEnum ? validitySourceName : EnumFormatters.EnumValuesFormatter.transform([[validityEnum]]).children };
    },
});
