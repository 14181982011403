import React, { useContext } from 'react';

import { IMAGE_FORMATS } from 'src/constants';
import { FileFormats } from '@shift/design-system';

type FetchType = (input: RequestInfo, init?: RequestInit) => Promise<Response>;
export type DownloadType = (url: string, name?: string | undefined) => Promise<void>;

const context = React.createContext<FetchType | null>(null);

export interface FetchProviderProps {
    token: () => Promise<string|null>;
}
// eslint-disable-next-line no-console
const errorLog = process.env.NODE_ENV === 'production' ? console.warn : console.error;

export const FetchProvider: React.FC<FetchProviderProps> = ({ token, children }) => {
    const fetch: FetchType = React.useCallback(async (input, init) => {
        const inp = new window.Request(input);
        const t = await token();
        if (t) {
            inp.headers.append('Authorization', `Bearer ${t}`);
        } else {
            errorLog('Unauthorized to fetch data');
        }
        return window.fetch(inp, init);
    }, [token]);
    return (
        <context.Provider value={fetch}>
            {children}
        </context.Provider>
    );
};

export const useFetch = () => {
    const fetch = useContext(context);
    if (!fetch) {
        throw new Error('missing provider');
    }
    const download = React.useCallback<DownloadType>(async (url: string, name?: string) => {
        const res = await fetch(url);
        const blob = new window.Blob([await res.blob()]);
        const fileName = name ?? res.headers.get('content-disposition')?.split('filename=')[1]; // Name provided by backend
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const blobUrl = window.URL.createObjectURL(blob);
            const el = document.createElement('a');
            el.href = blobUrl;
            if (fileName) {
                el.download = fileName; // Override with frontend name
            }
            el.click();
        }
    }, [fetch]);
    const imgPreview = React.useCallback(async (url: string, fileFormat?: FileFormats) => {
        if (fileFormat && !(IMAGE_FORMATS.includes(fileFormat))) {
            return '';
        }
        const res = await fetch(url);
        return window.URL.createObjectURL(new window.Blob([await res.blob()]));
    }, [fetch]);
    const goodDataEncryptedClaims = React.useCallback(async (url: string) => {
        const res = await fetch(url);
        return res;
    }, [fetch]);
    return {
        fetch,
        download,
        imgPreview,
        goodDataEncryptedClaims,
    };
};
