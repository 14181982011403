import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { StyledText, withCustomStyles } from '@shift/design-system';

import { MyInfoStateProvider } from 'src/state/MyInfoStateProvider';
import { Providers } from './Providers';
import { Application } from './Application';

const ApplicationContainer = styled(withCustomStyles(StyledText))`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const ApplicationWrapper = () => (
    <Providers>
        <Helmet>
            <title>GIA GEARS</title>
        </Helmet>
        <ApplicationContainer type="div">
            <MyInfoStateProvider>
                <Application />
            </MyInfoStateProvider>
        </ApplicationContainer>
    </Providers>
);
