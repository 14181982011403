import { ProcessedGearsConfig } from '../../config/configuration/types/GearsConfig';
import { buildQuery } from '..';
import { accidentReportingsMock } from './accidentReportingsMock';
import { accidentReportsMock } from './accidentReportsMock';
import { explorationMock } from './explorationMock';
import { arcsMock } from './arcsMock';
import { enumsMock } from './enumsMock';
import {
    officersMock, policeStationsMock, bolaMock,
    vehicleModelsMock, insurersMock,
} from './entitiesMock';
import { recoveryMock } from './recoveryMock';

export const mocks = (config: ProcessedGearsConfig) => [
    ...accidentReportingsMock,
    ...accidentReportsMock(config.accidentReport),
    ...recoveryMock(config.recovery),
    ...arcsMock(config.arc),
    ...enumsMock,
    ...insurersMock,
    ...officersMock,
    ...policeStationsMock,
    ...bolaMock,
    ...vehicleModelsMock,
    ...explorationMock(config.exploration),
    {
        request: {
            query: buildQuery(['claim'], [['id'], ['referenceCode']]),
            variables: {
                id: 2,
            },
        },
        result: {
            data: {
                claim: { id: 2, referenceCode: 'CLAIM N1' },
            },
        },
    },
    {
        request: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            query: buildQuery(config.tests.kvCard!.context, config.tests.kvCard!.fields),
            variables: {
                id: 5,
            },
        },
        result: {
            data: {
                claim: { id: 5, persons: [{ id: 5, name: 'John Doe', recordOwner: 'SHIFT' }] },
            },
        },
    },
];
