import React, { useCallback } from 'react';
import { translate } from 'src/utils/forms';
import { RadioGroupInput, RadioGroupInputProps } from '@shift/design-system';

export interface YesNoRadioProps extends Omit<RadioGroupInputProps, 'onChange' | 'value' | 'options'> {
    onChange: (value: boolean) => void;
    value: boolean;
}

export const YesNoRadio = ({
    onChange, value, onFocus, onBlur, ...others
}: YesNoRadioProps) => {
    const options = {
        true: {
            labelName: translate('common:yes'),
        },
        false: {
            labelName: translate('common:no'),
        },
    };

    const onChangeCb = useCallback((v: string) => {
        onChange(v === 'true');
    }, [onChange]);

    return (
        <RadioGroupInput
            options={options}
            value={`${value}`}
            onChange={onChangeCb}
            onFocus={onFocus}
            onBlur={onBlur}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...others}
        />
    );
};
