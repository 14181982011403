import React, { useContext, ReactElement } from 'react';
import styled from 'styled-components';
import { StyledLink, CircularLoader, ThemeContext } from '@shift/design-system';
import { Widgetter } from '@shift/transform';
import { useFetch } from 'src/libs/fetch';
import { download } from 'src/utils/documents/download';

export const LoaderWrapper = styled.span`
    display: inline-block;
    margin: auto 0.5rem;
    vertical-align: top;
`;

const DownloadWidget = ({
    children, filename, url,
}: DownloadWidgetterProps) => {
    const { download: dl } = useFetch();
    const theme = useContext(ThemeContext);
    const [loading, setLoading] = React.useState(false);
    const onClick = React.useCallback(() => {
        if (!loading) download(dl, url, setLoading, filename);
    }, [dl, filename, url, setLoading, loading]);
    return (
        <StyledLink onClick={onClick}>
            {loading
                ? (
                    <>
                        <LoaderWrapper>
                            <CircularLoader width="0.2rem" size="1.3rem" color={theme.colors.brand.primary.base} />
                        </LoaderWrapper>
                        {children}
                    </>
                )
                : children}
        </StyledLink>
    );
};

export interface DownloadWidgetterProps {
    children: string | ReactElement;
    filename?: string;
    url: string;
}

export const DownloadWidgetter: Widgetter<DownloadWidgetterProps> = {
    transform: DownloadWidget,
};
