import React from 'react';
import { StyledText } from '@shift/design-system';
import { Widgetter } from '@shift/transform';

const MaxWidthTextWidget = ({ children }: { children: string[] }) => (
    <div style={{
        maxWidth: '110px',
    }}
    >
        <StyledText type="div" wrap="nowrap">
            {children}
        </StyledText>
    </div>
);
export const MaxWidthTextWidgetter: Widgetter<{ children: string[] }> = {
    transform: MaxWidthTextWidget,
};
