import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { formSections } from 'src/config/form';
import { formTranslate } from 'src/utils/forms';
import {
    FormField, FormSection,
    YesNoRadio, ExpandableField, ConditionalFormField,
} from 'src/components/form';
import { InjuredItem } from '../array-items';

export const InjuredSection = () => {
    const { name, title, fields } = formSections.injuredSection;
    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.anyInjuredPersons}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <ConditionalFormField
                sourceField={fields.anyInjuredPersons}
                targetFields={[fields.anyInjuredTookAmbulance]}
            >
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <FormField
                            fieldInfo={fields.anyInjuredTookAmbulance}
                            component={YesNoRadio}
                            className="radio-option"
                        />
                    </div>
                </div>
            </ConditionalFormField>
            <Field name={fields.anyInjuredPersons.path} subscription={{ value: true }}>
                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                    <ExpandableField
                        fieldInfo={fields.injuredPersons}
                        itemTitle="injured-person"
                        addItemLabel="add-injured"
                        Item={InjuredItem}
                        initialItemsCount={input.value ? 1 : 0}
                        enableAdd={!!input.value}
                    />
                )}
            </Field>
        </FormSection>
    );
};
