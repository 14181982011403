
export type ExplorationSearchType = 'person' | 'vehicle';
export type ExplorationSearchHistoryType = 'policies' | 'claims' | 'accidentReports';

export const VEHICLE_CONTEXT = 'vehicle';
export const PERSON_CONTEXT = 'person';
export const NCD_CONTEXT = 'ncd';
export const INSURER_ENQUIRY_CONTEXT = 'insurerEnquiry';
export const OWNED_VEHICLE_CONTEXT = 'ownedVehicle';
export const PERSON_CHROOTER_CONTEXT = 'explorePerson';
export const VEHICLE_CHROOTER_CONTEXT = 'exploreVehicle';
export const NCD_CHROOTER_CONTEXT = 'exploreOwnedVehicle';

export const ExplorationRoutes = {
    vehicle: '/exploration/vehicle',
    person: '/exploration/person',
};

export interface SearchHistoryRowsConfigPaths {
    row: ['exploration', 'policyHistories' | 'claimHistories' | 'accidentReportHistories'];
    expendableRows: {
        context: 'exploration';
        configs: {
            subContext:
            'claimExpandableSection1' |
            'claimExpandableSection2' |
            'claimExpandableSection3' |
            'accidentReportExpandableSection1' |
            'accidentReportExpandableSection2' |
            'accidentReportExpandableSection3' |
            'policyExpandableSection1' |
            'policyExpandableSection2'
            ;
            type?: 'keysValues' | 'TitleContent';
        }[];
    }[];
}

export interface SearchHistoryTableConfigContexts {
    customContext: string[];
    customChrooterContext: string[];
}
