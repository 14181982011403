import {
    EnumFormatter, FileWithTypeFormatter,
} from 'src/utils/forms/formatters';
import { isValidFileWithType } from 'src/utils/forms';

export const drmOffer = {
    termsConditionsStep: {
        name: 'termsConditionsStep',
        title: 'terms-agreement',
        fields: {},
    },
    approveStep: {
        name: 'approveStep',
        title: '',
        fields: {
            comment: {
                path: 'bolaCase.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
            commentType: {
                path: 'comment.commentType',
            },
        },
    },
    cancelStep: {
        name: 'cancelStep',
        title: '',
        fields: {
            comment: {
                path: 'bolaCase.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
            commentType: {
                path: 'comment.commentType',
            },
        },
    },
    bolaScenarioStep: {
        name: 'bolaCase',
        title: 'bola-scenario',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'bolaCase.actionType',
                target: 'nextAction.step.action',
            },
            bolaScenario: {
                path: 'bolaCase.bolaScenario',
                target: 'nextAction.bolaScenario.id',
                label: 'bola-scenario',
                isRequired: true,
                formatter: EnumFormatter,
            },
            comment: {
                path: 'bolaCase.comment',
                target: 'comment.text.content',
                label: 'our-position',
                isRequired: true,
            },
            commentType: {
                path: 'comment.commentType',
            },
            accidentCircumstances: {
                path: 'bolaCase.accidentCircumstances',
                target: 'accidentCircumstancesForDrmEscalation.text.content',
                label: 'our-version',
            },
            accidentCircumstancesCommentType: {
                path: 'accidentCircumstancesForDrmEscalation.commentType',
            },
        },
    },
    attachmentsStep: {
        name: 'attachmentStep',
        title: 'attachments',
        fields: {
            attachments: {
                path: 'attachmentStep.attachments',
                label: 'new-attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        document: 'document',
                        category: 'category',
                    },
                },
                formatter: FileWithTypeFormatter,
                validate: isValidFileWithType,
            },
        },
    },
};
