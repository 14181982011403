/* eslint-disable no-shadow */
import { Formatter } from '@shift/transform';
import { LinksExplorationSearchWidgetterProps } from '../widgets/LinksExplorationSearchWidgetter';
import { ExplorationSearchType } from '../../pages/exploration/constants';
import { permissions } from '../permissions';


const addVrnParam = (vrn: any) => (vrn ? `?vrn=${vrn}` : '');
const addIdentParam = (ident: any) => (ident ? `?ident=${ident}` : '');

export const LinksExplorationSearchFormatter = (
    searchType: ExplorationSearchType,
): Formatter<any[], LinksExplorationSearchWidgetterProps> => ({
    transform: ([variables]) => ({
        children: variables
            .filter((t, i) => variables.indexOf(t) === i)
            .map((variable: string) => ({
                content: `${variable}`,
                action: 'EXPLORATION_SEARCH',
                searchType,
                permission: permissions.canSeeExploration,
                // eslint-disable-next-line no-nested-ternary
                link: `/exploration/${searchType}${searchType === 'person' ? addIdentParam(variable) : addVrnParam(variable)}`,
            })),
    }),
});
