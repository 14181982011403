import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from 'src/libs/filters';
import { DaysLeftFilter } from 'src/components/Table/Filters';

export const DaysLeftFilterWidgetter: Widgetter<FilterProps, FilterProps, Filter> = {
    transform: ({ children }, options) => (options ? (
        <DaysLeftFilter
            field={options.field}
            operation={options.operation}
        >
            {children}
        </DaysLeftFilter>
    ) : children as ReactElement),
};
