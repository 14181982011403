import {
    useRef, useEffect, EffectCallback, DependencyList,
} from 'react';

// https://stackoverflow.com/a/57632587/7945461
export function useUpdateEffect(effect: EffectCallback, dependencies: DependencyList = []) {
    const isInitialMount = useRef(true);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effect();
        }
    }, dependencies);
}
