/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { I18nModalBillableAction, ModalBillableAction } from './ModalBillableAction';
import { formatGQLErrors } from '../../utils/forms/utils';
import { MessageModalContext } from '../../MessageModal';
import { AnyType } from '../../types';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

export const BILLABLE_ACTIONS = {
    UN_MATCH_AR: 'EXT-022',
    NCD_SEARCH: 'NCT-002',
    INSURER_ENQUIRY_TAB: 'IET-002',
    THIRD_PARTY_REPORTS_TAB: 'TRT-002',
    EXPLORATION_SEARCH: 'EXT-001',
    EXPLORATION_SEARCH_PERSON: 'EXT-002',
    EXPLORATION_SEARCH_VEHICLE: 'EXT-004',
};

export type BillableActionProps = {
    action: keyof typeof BILLABLE_ACTIONS;
    i18nModal?: I18nModalBillableAction;
    request?: {
        variables?: AnyType;
        mutationQuery?: DocumentNode;
        onFetchStarted?: () => void;
        onFetchCompleted?: () => void;
        OnFetchSucceeded?: (billableActionID: number) => void;
        cleanCache?: boolean;
    };
    /*
     paidAccess = true => will be billed for the action
     paidAccess = false => Already paid, or is the owner of the AR
    */
    paidAccess: boolean;
    children: React.ReactElement<{ onClick: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void }>;

};


export const BillableAction = (
    {
        children,
        action,
        request = {
            mutationQuery: undefined,
            variables: {},
            cleanCache: false,
            onFetchCompleted: undefined,
            onFetchStarted: undefined,
            OnFetchSucceeded: undefined,
        },
        paidAccess,
        i18nModal,
    }: BillableActionProps,
) => {
    const storage = useLocalStorage();
    const accessIsFree = paidAccess === false;
    const skipConfirmationModal = (storage
        && storage.getItem(BILLABLE_ACTIONS[action]));

    const client = useApolloClient();
    const [displayModal, setDisplayModal] = React.useState(false);
    const { onClick } = children.props;
    const {
        variables, mutationQuery, cleanCache, onFetchCompleted, onFetchStarted, OnFetchSucceeded,
    } = request;
    const { displayMessage } = useContext(MessageModalContext);

    const executeMutationThenClick = () => {
        onFetchStarted && onFetchStarted();
        const mutation = mutationQuery
            ? () => client.mutate({ mutation: mutationQuery, variables })
            : () => Promise.resolve({});

        setDisplayModal(false);
        mutation()
            .then((response: any) => {
                if (cleanCache) {
                    // we want to clean the cache
                    // to be sure to have updated billable icons
                    client.clearStore();
                }
                OnFetchSucceeded && OnFetchSucceeded(response.data.billExplorationSearch);
                // perform the originally action
                onClick();
            }).catch((error) => {
                displayMessage(formatGQLErrors(error.graphQLErrors));
            })
            .finally(() => onFetchCompleted && onFetchCompleted());
    };

    const actionableChildren = React.cloneElement(children, {
        onClick: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (event) {
                event.preventDefault();
            }
            if (accessIsFree) {
                onClick();
            } else if (skipConfirmationModal) {
                executeMutationThenClick();
            } else {
                setDisplayModal(!displayModal);
            }
        },
    });
    return (
        <>
            {actionableChildren}
            <ModalBillableAction
                i18n={i18nModal}
                isVisible={displayModal}
                actionType={action === 'EXPLORATION_SEARCH_PERSON' ? 'person' : 'vehicle'}
                onCancel={() => setDisplayModal(!displayModal)}
                onConfirm={(alwaysSkipModal) => {
                    if (alwaysSkipModal) {
                        // eslint-disable-next-line no-unused-expressions
                        storage && storage.setItem(BILLABLE_ACTIONS[action], 'true');
                    }
                    executeMutationThenClick();
                }}
            />
        </>

    );
};
