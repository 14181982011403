import { Formatter } from '@shift/transform';
import { EnumFormatters } from './EnumValuesFormatter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PurchasedByFormatter: Formatter<any[], { children: string[] }> = {
    transform: ([insurers]) => ({
        children: insurers
            // distinct
            .filter((t, i, arr) => arr.findIndex((tt) => tt.Name === t.Name) === i)
            // take the last five purchased
            .filter((_, index) => index < 5)
            .map((insurer) => EnumFormatters.EnumValuesFormatter.transform([insurer]).children)
        ,
    }),
};
