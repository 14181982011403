import React from 'react';

import { Widgetter } from '@shift/transform';
import { SelectInput, SelectInputOption } from '@shift/design-system';

const defaultOptions = {
    currentIndex: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSelect: (_idx: number) => {},
};

export const SelectWidgetter: Widgetter<{ children: string[] }, {}, { currentIndex: number; onSelect: (idx: number) => void }> = {
    transform: ({ children }, opts = defaultOptions) => {
        const { currentIndex, onSelect } = opts;
        const options = children.map<SelectInputOption>((choice, idx) => ({ idx, label: choice, value: choice }));
        return (
            <SelectInput
                isClearable={false}
                isSearchable={false}
                options={options}
                value={options[currentIndex] as any}
                onChange={(values) => onSelect((values as any).idx)}
            />
        );
    },
};
