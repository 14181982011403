import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from 'src/libs/filters';
import { IntegerFilter } from 'src/components/Table/Filters';

export const IntegerFilterWidgetter: Widgetter<FilterProps, FilterProps, Filter> = {
    transform: ({ children }, options) => (options ? (
        <IntegerFilter
            field={options.field}
            operation={options.operation}
        >
            {children}
        </IntegerFilter>
    ) : children as ReactElement),
};
