import React, { createContext } from 'react';
import { translate } from '@gears/translations';
import { AnyType } from 'src/types';

const { Provider, Consumer } = createContext('');

export interface ContextTranslateProviderProps {
    workspace: string;
    children: React.ReactNode;
}

export const ContextTranslateProvider = ({ workspace, children }: ContextTranslateProviderProps) => (
    <Provider value={workspace}>
        {children}
    </Provider>
);

export interface ContextTranslateProps {
    k: string;
    options?: AnyType;
}

export const ContextTranslate = ({ k, options }: ContextTranslateProps) => (
    <Consumer>
        {(value) => (value ? translate(`${value}:${k}`, options) : translate(k, options))}
    </Consumer>
);
