import { isValidFile, isValidLossAmount } from 'src/utils/forms';
import { getFileFormatter } from 'src/utils/forms/formatters';

export const reinitiate = {
    singleSection: {
        name: 'reinitiate',
        title: 'reinitiate',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'actionType',
                target: 'nextAction.step.action',
            },
            lossAmount: {
                path: 'reinitiate.lossAmount',
                target: 'amount',
                label: 'loss-amount',
                isRequired: true,
                validate: isValidLossAmount,
            },
            thirdPartyLegalCost: {
                path: 'reinitiate.tpLegalCost',
                target: 'legalCost',
                label: 'legal-cost',
                isRequired: true,
            },
            attachments: {
                path: 'reinitiate.attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        id: 'document.id',
                        name: 'document.documentName',
                    },
                },
                label: 'attachments',
                formatter: getFileFormatter(true),
                validate: isValidFile,
            },
            comment: {
                path: 'reinitiate.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
            commentType: {
                path: 'reinitiate.commentType',
                target: 'comment.commentType',
                label: '',
            },
        },
    },
};
