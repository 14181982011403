import { useMemo, useContext } from 'react';
import { translate } from '@gears/translations';
import { SelectInputOption } from '@shift/design-system';
import { phoneType, faxType, otherEnum } from 'src/constants';
import {
    AnyType, BolaCaseSelectInputOption, EnumItem,
    VehicleModelSelectInputOption, PoliceStationSelectInputOption, DrmReasonSelectInputOption,
} from 'src/types';
import { MessageModalContext } from 'src/MessageModal';
import {
    getOfficers, getArcInsurers, getPoliceStations,
    getBolaCases, getVehicleModels, useQuery, GqlQueryName, getArcNames, getDrmAdjudicationReasonItemPrices,
} from 'src/graphql';
import { useEnum } from './useEnums';
import { isNoneEnum } from '../forms';

type AsyncListResult = {
    loading: boolean;
    options: SelectInputOption[];
    error: Error | undefined;
    refetch: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useListFromBackend = (gqlQuery: any, buildFn: (data: AnyType) => SelectInputOption[], querySettings?: AnyType): AsyncListResult => {
    const { displayMessage } = useContext(MessageModalContext);
    const {
        loading, data, error, refetch,
    } = useQuery(gqlQuery, { displayName: GqlQueryName.backendList, ...querySettings });

    return useMemo(() => {
        if (!loading && !data && !querySettings?.skip) {
            displayMessage([`${translate('common:load-options-fail')}: ${error?.message} `]);
        }
        return {
            loading,
            options: data ? buildFn(data) : [],
            error,
            refetch: () => { refetch().catch(() => { /* silent error */ }); },
        };
    }, [data, loading, error, buildFn, querySettings, displayMessage, refetch]);
};

const buildArcInsurers = (data: AnyType) => {
    const list = data?.me?.arcEntity?.arc?.insurers ?? [];
    return list.map((entity: AnyType) => ({
        value: `${entity.insurerCode.Type}.${entity.insurerCode.Name}`,
        label: translate(`enums:${entity.insurerCode.Type}.${entity.insurerCode.Name}`, { defaultValue: entity.insurerCode.Name }),
    }));
};
const buildArcNames = (data: AnyType) => {
    const list = data?.arcList?.items ?? [];
    return list.map((item: AnyType) => ({
        value: `${item?.person?.specializations.mapping_Person_Gears.id}`,
        label: `${item?.person?.specializations.mapping_Person_Gears.name}`,
    }));
};

export const useArcInsurersList = () => useListFromBackend(getArcInsurers, buildArcInsurers);

export const useArcNameList = () => useListFromBackend(getArcNames, buildArcNames, {
    variables: {
        parameters: {
            start: 0,
            length: 1000,
            filters: [],
            sort: null,
        },
    },
});

const buildRecoveryOfficers = (data: AnyType) => {
    const list = data?.me?.insurerEntity?.insurer?.insurerEntities ?? [];
    return list.map((entity: AnyType) => ({
        value: entity?.identity?.identityId,
        label: entity?.person?.specializations?.mapping_Person_Gears?.name,
    }));
};

export const useOfficersList = () => useListFromBackend(getOfficers, buildRecoveryOfficers);

const buildPoliceStations = (data: AnyType): PoliceStationSelectInputOption[] => {
    const list = data?.policeStations ?? [];
    const policeStations = list.map((entity: AnyType) => {
        const address = entity.addresses?.length ? entity.addresses[0].fullAddress : undefined;
        const phones: Array<AnyType> = entity.phoneNumbers?.length
            ? entity.phoneNumbers.filter(((p: AnyType) => p.phoneNumberType?.Name === phoneType)) : [];
        const faxes: Array<AnyType> = entity.phoneNumbers?.length
            ? entity.phoneNumbers.filter(((p: AnyType) => p.phoneNumberType?.Name === faxType)) : [];
        const contactInformation = (address || phones || faxes) ? {
            address,
            phone: phones?.length ? phones[0].number : undefined,
            fax: faxes?.length ? faxes[0].number : undefined,
        } : undefined;
        return {
            value: entity.id,
            label: entity.specializations.mapping_Person_Gears.name,
            contactInformation,
            recordOwner: `${entity.recordOwner.Type}.${entity.recordOwner.Name}`,
        };
    });
    policeStations.push({ value: 0, label: translate(`enums:${otherEnum}`) });
    return policeStations;
};

export const usePoliceStationsList = () => useListFromBackend(getPoliceStations, buildPoliceStations);


const buildBolaCases = (data: AnyType, bolaScenarioList: EnumItem[] | null, filter: BolaScenarioEnumValuesFilter): BolaCaseSelectInputOption[] => {
    if (!data?.bolaCases || !bolaScenarioList) {
        return [];
    }

    const withUndefined = data.bolaCases.map((entity: AnyType) => {
        const enumItem = bolaScenarioList.find((item: EnumItem) => item.Name === entity.caseEnumIdentifier
            && item.ValueId === entity.caseEnumValueId);

        if (!enumItem) {
            throw new Error(`Bola scenario ${entity.caseEnumIdentifier}.${entity.caseEnumValueId} not found`);
        }

        if (filter?.find((x) => x === enumItem.Value)) {
            return undefined;
        }

        // eslint-disable-next-line max-len
        const liabilities = `${entity.specializations?.mapping_AccidentCase_LiabilityScenario_Gia?.xLiablity}/${entity.specializations?.mapping_AccidentCase_LiabilityScenario_Gia?.yLiablity}`;
        return {
            value: entity.id,
            // eslint-disable-next-line max-len
            label: `BOLA ${translate(`enums:BolaScenarioNumber.${enumItem.Value}`)}: ${translate(`enums:${enumItem.Name}.${enumItem.Value}`, { defaultValue: enumItem.Value })} - ${liabilities}`,
            title: `BOLA ${translate(`enums:BolaScenarioNumber.${enumItem.Value}`)}`,
            subTitle: translate(`enums:${enumItem.Name}.${enumItem.Value}`, { defaultValue: enumItem.Value }),
            enumValueId: entity.caseEnumValueId,
            description: entity.description,
            document: entity.document,
            xLiablity: entity.specializations?.mapping_AccidentCase_LiabilityScenario_Gia?.xLiablity,
            yLiablity: entity.specializations?.mapping_AccidentCase_LiabilityScenario_Gia?.yLiablity,
        };
    });

    return withUndefined.filter((x: AnyType) => x !== undefined);
};

type BolaScenarioEnumValuesFilter = string[] | undefined
export const BolaScenarioInsurerFilter: BolaScenarioEnumValuesFilter = ['BOLANA0'];

export const useBolaCaseList = (filter: BolaScenarioEnumValuesFilter = undefined): { loading: boolean; options: SelectInputOption[] } => {
    const { displayMessage } = useContext(MessageModalContext);
    const { loading, data, error } = useQuery(getBolaCases, { displayName: GqlQueryName.getBolaCases });
    const { loading: loadingEnum, selectedEnum: bolaEnum } = useEnum('BolaScenario');

    try {
        return useMemo(() => {
            if ((!loading && !data) || (!loadingEnum && !bolaEnum)) {
                displayMessage([`${translate('common:load-options-fail')}: ${error?.message}`]);
            }
            return { loading: loading && loadingEnum, options: (data && bolaEnum) ? buildBolaCases(data, bolaEnum, filter) : [] };
        }, [data, loading, error, loadingEnum, bolaEnum, displayMessage, filter]);
    } catch (e) {
        displayMessage(e.stack);
        return { loading: false, options: [] };
    }
};

const buildVehicleModels = (data: AnyType, make: string): VehicleModelSelectInputOption[] => {
    const list = data?.vehicleModels ?? [];
    return !make ? [] : list.map((entity: AnyType) => ({
        value: entity.model,
        label: translate(`vehicle-models:${entity.make.Name}_${entity.model}`, { defaultValue: entity.model }),
        make,
    })).concat({ value: otherEnum, make, label: translate(`enums:${otherEnum}`) });
};

export const useVehicleModelsList = (make: string) => {
    const list = useListFromBackend(
        getVehicleModels, (data) => buildVehicleModels(data, make), { variables: { make }, skip: !make || isNoneEnum(make) },
    );

    const otherList = useMemo(() => ({
        loading: false,
        options: buildVehicleModels([], otherEnum),
        error: [],
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        refetch: () => { },
    }), []);

    return isNoneEnum(make) ? otherList : list;
};

const buildLosingReasons = (data: AnyType, recoveryDrmAdjudicationLosingParty: EnumItem[] | null): DrmReasonSelectInputOption[] => {
    const list = recoveryDrmAdjudicationLosingParty ?? [];
    return list.map((entity: EnumItem) => {
        const itemPrice = data.recoveryDrmAdjudicationLosingPartyItemPrices[entity.Value];
        let mappedPrice;
        switch (entity.Value) {
        case 'LosingPartyIp':
        case 'ShouldNotDrmIp':
        case 'LosingPartyMultipleIp':
        case 'ShouldNotDrmMultipleIp':
            mappedPrice = itemPrice ? `(${itemPrice}-0)` : '';
            break;
        case 'LosingPartyRp':
        case 'ShouldNotDrmRp':
        case 'LosingPartyMultipleRp':
        case 'ShouldNotDrmMultipleRp':
            mappedPrice = itemPrice ? `(0-${itemPrice})` : '';
            break;
        case 'LosingPartyBoth':
        case 'ShouldNotDrmBoth':
        case 'AdjudicatedNoFault':
            mappedPrice = itemPrice ? `(${itemPrice}-${itemPrice})` : '';
            break;
        default:
            mappedPrice = '';
            break;
        }
        return {
            value: `${entity.Name}.${entity.ValueId}`,
            label: `${translate(`enums:RecoveryDrmAdjudicationLosingReason:${entity.Value}`, { defaultValue: entity.Value })} ${mappedPrice}`,
            valueId: entity.ValueId,
        };
    });
};


export const useLosingReasons = (): { loading: boolean; options: SelectInputOption[] } => {
    const { displayMessage } = useContext(MessageModalContext);
    const {
        loading, data, error,
    } = useQuery(getDrmAdjudicationReasonItemPrices, { displayName: GqlQueryName.getRecoveryDrmAdjudicationLosingPartyItemPrices });
    const { loading: loadingEnum, selectedEnum: recoveryDrmAdjudicationLosingPartyEnum } = useEnum('RecoveryDrmAdjudicationLosingParty');

    try {
        return useMemo(() => {
            if ((!loading && !data) || (!loadingEnum && !recoveryDrmAdjudicationLosingPartyEnum)) {
                displayMessage([`${translate('common:load-options-fail')}: ${error?.message}`]);
            }
            return {
                loading: loading && loadingEnum,
                options: (data && recoveryDrmAdjudicationLosingPartyEnum)
                    ? buildLosingReasons(data, recoveryDrmAdjudicationLosingPartyEnum) : [],
            };
        }, [data, loading, error, loadingEnum, recoveryDrmAdjudicationLosingPartyEnum, displayMessage]);
    } catch (e) {
        displayMessage(e.stack);
        return { loading: false, options: [] };
    }
};
