import { capitalize } from 'src/utils';
import gql from 'graphql-tag';
import { SearchTypes } from 'src/libs/filters';

type ContextFactory = (ctx: string) => string;

const memoize = <B, C>(fct: (s: string, additionalParam?: C) => B) => {
    const cache: { [key: string]: B } = {};
    return (s: string, additionalParam?: C): B => {
        if (!(s in cache)) {
            cache[s] = fct(s, additionalParam);
        }
        return cache[s];
    };
};

export const contextFactory = memoize((category: string): ContextFactory => (ctx: string) => `
query get${capitalize(category)}($id: Long) {
    ${category}(id: $id) {
${ctx}
    }
}
`);

export const searchContextFactory = memoize((category: string, searchType?: SearchTypes): ContextFactory => (ctx: string) => `
query search${capitalize(category)}($parameters: ${searchType}GraphQLSearchParametersInput) {
    ${category}List(parameters: $parameters) {
        itemsCount
        totalCount
        items{
    ${ctx}
        }
    }
}
`);

// TODO only handles person exploration
export const explorationContextFactory = memoize((category: string): ContextFactory => (ctx: string) => {
    // TODO ARF! this is getting worse
    let param: string;
    if (category === 'person') {
        param = 'ident';
    } else if (category === 'vehicle') {
        param = 'vrn';
    } else {
        throw new Error(`unknown category: ${category}`);
    }
    return `
query exploration${capitalize(category)}($${param}: String, $billableActionID: Long) {
    explore${capitalize(category)}(${param}: $${param}, billableActionID: $billableActionID) {
    ${ctx}
    }
}
`;
});

export const ncdEmailContextFactory = memoize((_category: string): ContextFactory => (ctx: string) => `
query ncdEmail($personId: Long, $objectId: Long) {
    ownedVehicle(personId: $personId, objectId: $objectId) {
        ${ctx}
    }
}`);

export const ncdContextFactory = memoize((_category: string): ContextFactory => (ctx: string) => `
    query ncd($nric: String, $vrn: String) {
        exploreOwnedVehicle(nric: $nric, vrn: $vrn) {
            ${ctx}
        }
    }
`);

export const insurerEnquiryContextFactory = memoize((_category: string): ContextFactory => (ctx: string) => `
    query insurerEnquiry($vrn: String, $dateTimeOffset: ShiftDateTimeOffset) {
        insurerEnquiry(vrn: $vrn, dateTimeOffset: $dateTimeOffset) {
            ${ctx}
        }
    }
`);

type Node = {
    name: string;
    children: Node[];
};

const buildLinePrint = (tree: Node[], indent: string): string => tree.reduce((acc, node) => {
    let res = acc;
    if (res.length !== 0) {
        res += '\n';
    }
    res += indent;
    res += node.name;
    if (node.children.length !== 0) {
        res += ' {\n';
        res += buildLinePrint(node.children, `${indent}    `);
        res += '\n';
        res += indent;
        res += '}';
    }
    return res;
}, '');

export const buildFieldTree = (fields: string[][]): Node[] => {
    if (fields.length === 0) {
        return [];
    }
    const f = fields.filter((field) => field.length !== 0);
    const data: { [key: string]: string[][] } = {};
    f.forEach((field) => {
        const [prefix, ...path] = field;
        if (!data[prefix]) {
            data[prefix] = [];
        }
        if (path.length !== 0) {
            data[prefix].push(path);
        }
    });
    if (!data.id) {
        data.id = [];
    }

    return Object.entries(data).map(([key, value]) => ({
        name: key,
        children: buildFieldTree(value),
    }));
};

const isString = (context: string | string[]): context is string => typeof context === 'string';

const splitContext = (context: string | string[]): [string, string[]] => {
    if (isString(context)) {
        if (context.length === 0) {
            throw new Error('context can\'t be empty');
        }
        return [context, []];
    }
    const [initial, ...rest] = context;
    if (initial.length === 0) {
        throw new Error('context can\'t be empty');
    }

    return [initial, rest];
};

export const buildQueryString = (context: string | string[], fields: string[][], factory: (s: string) => ContextFactory = contextFactory): string => {
    const [mainContext, path] = splitContext(context);
    if (fields.length === 0) {
        throw new Error('no field provided');
    }
    const tree = buildFieldTree(fields.map((field) => [...path, ...field]));
    return factory(mainContext)(buildLinePrint(tree, '        '));
};

export const buildQuery = (context: string | string[], fields: string[][]) => gql(buildQueryString(context, fields));
export const buildListQuery = (context: string | string[], fields: string[][], searchType: SearchTypes) => gql(
    buildQueryString(context, fields, (s: string) => searchContextFactory(s, searchType)),
);
export const buildExplorationQuery = (context: string | string[], fields: string[][]) => gql(
    buildQueryString(context, fields, explorationContextFactory),
);
export const buildNcdEmailQuery = (context: string | string[], fields: string[][]) => gql(
    buildQueryString(context, fields, ncdEmailContextFactory),
);
export const buildNcdQuery = (context: string | string[], fields: string[][]) => gql(
    buildQueryString(context, fields, ncdContextFactory),
);
export const buildInsurerEnquiryQuery = (context: string | string[], fields: string[][]) => gql(
    buildQueryString(context, fields, insurerEnquiryContextFactory),
);

export const getEnums = gql('query { enums }');

export const getDrmAdjudicationReasonItemPrices = gql('query { recoveryDrmAdjudicationLosingPartyItemPrices }');

export const getArcInsurers = gql(`
    query Insurers {
        me {
            arcEntity {
                arc {
                    insurers {
                        insurerCode
                    }
                }
            }
        }
    }
`);

export const getArcNames = gql(`
    query searchArc($parameters: ProviderFieldsGraphQLSearchParametersInput) {
        arcList(parameters: $parameters) {
        itemsCount
        totalCount
        items {
            person {
            specializations {
                mapping_Person_Gears {
                name
                id          
                }         
            } 
        }
        }
        }
    }  
`);

export const getOfficers = gql(`
    query Officers {
        me {
            insurerEntity {
                insurer {
                    insurerEntities {
                        person {
                            specializations {
                                mapping_Person_Gears {
                                    name
                                }
                            }
                        }
                        identity {
                            identityId
                        }
                    }
                }
        }
        }
    }
`);

export const getPoliceStations = gql(`
    query GetPoliceStations {
        policeStations {
            id
            recordOwner
            specializations {
                id
                mapping_Person_Gears {
                    id
                    name
                }
            }
            name
            addresses {
                id
                fullAddress
            }
            phoneNumbers {
                id
                phoneNumberType
                number
            }
        }
    }
`);

export const getArcList = gql(`
    query {
        arcList {
            id,
            person {
                id,
                specializations {
                    mapping_Person_Gears {
                        name
                    }
                }
                addresses {fullAddress}
                phoneNumbers {number}
            }
        }
    }
`);

export const getBolaCases = gql(`
    query {
        bolaCases {
            id,
            caseEnumIdentifier,
            caseEnumValueId,
            description,
            specializations {
                mapping_AccidentCase_LiabilityScenario_Gia {
                    id
                    xLiablity
                    yLiablity
                }
            }
            document {
                id
            }
        }
    }
`);

export const getVehicleModels = gql(`
    query GetVehicleModels($make: ShiftEnum_VehicleMake) {
        vehicleModels(make: $make) {
            id
            make
            model
        }
    }
`);

export const getInsurerEmail = gql(`
    query GetInsurerContactEmail($insurer: ShiftEnum_InsurerCode) {
        insurerContactEmail(insurer: $insurer)
    }
`);

export const getUserCompany = gql`
    query userCompany {
        me {
            company {
                id
                name
            }
        }
    }
`;

export const getBillableItem = gql(`
query GetBillableItem($billableItemName: String){
    getBillableItem(billableItemName: $billableItemName) {
        price
        gst
        gstInclusivePrice
    }
}`);
