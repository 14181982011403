/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */
import { getOfficers, getPoliceStations, getBolaCases } from '..';
import { getVehicleModels, getArcInsurers } from '../query-builder';

export const insurersMock = [
    {
        request: {
            query: getArcInsurers,
        },
        newData: () => ({
            data: {
                me: {
                    identityId: '46d84dc9-a95e-420e-b6d4-a1c9402caf9f',
                    arcEntity: {
                        arc: {
                            insurers: [
                                {
                                    insurerCode: {
                                        Type: 'InsurerCode',
                                        Name: 'Aig',
                                    },
                                },
                                {
                                    insurerCode: {
                                        Type: 'InsurerCode',
                                        Name: 'Axa',
                                    },
                                },
                                {
                                    insurerCode: {
                                        Type: 'InsurerCode',
                                        Name: 'InsurerA',
                                    },
                                },
                                {
                                    insurerCode: {
                                        Type: 'InsurerCode',
                                        Name: 'InsurerB',
                                    },
                                },
                                {
                                    insurerCode: {
                                        Type: 'InsurerCode',
                                        Name: 'Ntuc',
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        }),
    }];

export const officersMock = [
    {
        request: {
            query: getOfficers,
        },
        newData: () => ({
            data: {
                me: {
                    identityId: '46d84dc9-a95e-420e-b6d4-a1c9402caf9f',
                    insurerEntity: [
                        {
                            insurer: {
                                insurerEntities: [
                                    {
                                        person: {
                                            specializations: {
                                                id: 1,
                                                mapping_Person_Gears: {
                                                    id: 1,
                                                    name: 'Thibault H.',
                                                },
                                            },
                                        },
                                        identity: {
                                            identityId: '46d84dc9-a95e-420e-b6d4-a1c9402caf9f',
                                        },
                                    },
                                    {
                                        person: {
                                            specializations: {
                                                id: 2,
                                                mapping_Person_Gears: {
                                                    id: 2,
                                                    name: 'Siying Z.',
                                                },
                                            },
                                        },
                                        identity: {
                                            identityId: '8c10c1d4-7b06-4aa8-9c05-5531d7ec3eb9',
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        }),
    }];

export const policeStationsMock = [
    {
        request: {
            query: getPoliceStations,
        },
        newData: () => ({
            data: {
                policeStations: [
                    {
                        id: 60,
                        recordOwner: {
                            Type: 'InsurerCode',
                            Name: 'Gia',
                        },
                        specializations: {
                            id: 60,
                            mapping_Person_Gears: {
                                id: 60,
                                name: 'Rochor Neighbourhood Police Centre',
                            },
                        },
                        addresses: [
                            {
                                fullAddress: '11 Kampong Kapor Rd, Singapore 208678',
                            },
                        ],
                        phoneNumbers: [
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Phone',
                                },
                                number: '12345678',
                            },
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Fax',
                                },
                                number: '123456780987',
                            },
                        ],
                    },
                    {
                        id: 61,
                        recordOwner: {
                            Type: 'InsurerCode',
                            Name: 'Gia',
                        },
                        specializations: {
                            id: 61,
                            mapping_Person_Gears: {
                                id: 61,
                                name: 'Central Police Division',
                            },
                        },
                        addresses: [
                            {
                                fullAddress: 'Block A, 391 New Bridge Road, #03-112, Police Cantonment Complex, 088762',
                            },
                        ],
                        phoneNumbers: [
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Phone',
                                },
                                number: '87654321',
                            },
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Fax',
                                },
                                number: '876543217654',
                            },
                        ],
                    },
                    {
                        id: 62,
                        recordOwner: {
                            Type: 'InsurerCode',
                            Name: 'Gia',
                        },
                        specializations: {
                            id: 62,
                            mapping_Person_Gears: {
                                id: 62,
                                name: 'Traffic Police',
                            },
                        },
                        addresses: [
                            {
                                fullAddress: '10 Ubi Ave 3, Singapore 408865',
                            },
                        ],
                        phoneNumbers: [
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Phone',
                                },
                                number: '12894376',
                            },
                            {
                                phoneNumberType: {
                                    Type: 'PhoneNumberType',
                                    Name: 'Fax',
                                },
                                number: '128943761231',
                            },
                        ],
                    },
                ],
            },
        }),
    }];

export const bolaMock = [
    {
        request: {
            query: getBolaCases,
        },
        newData: () => ({
            data: {
                bolaCases: [
                    {
                        caseEnumIdentifier: 'BolaScenario',
                        caseEnumValueId: 1,
                        description: 'Driver of the vehicle behind will be 100% liable for the losses occured to the vehicle in front.',
                        document: {
                            id: 1,
                        },
                    },
                ],
            },
        }),
    },
];

export const vehicleModelsMock = [
    // BMW
    {
        request: {
            query: getVehicleModels,
            variables: {
                make: 'VehicleMake.Bmw',
            },
        },
        newData: () => ({
            data: {
                vehicleModels: [
                    { id: 1, make: { Type: 'VehicleMake', Name: 'Bmw' }, model: 'Serie 1' },
                    { id: 2, make: { Type: 'VehicleMake', Name: 'Bmw' }, model: 'Serie 3' },
                    { id: 3, make: { Type: 'VehicleMake', Name: 'Bmw' }, model: 'Serie 5' },
                ],
            },
        }),
    },
    // Nissan
    {
        request: {
            query: getVehicleModels,
            variables: {
                make: 'VehicleMake.Nissan',
            },
        },
        newData: () => ({
            data: {
                vehicleModels: [
                    { id: 4, make: { Type: 'VehicleMake', Name: 'Nissan' }, model: 'Qashqai' },
                    { id: 5, make: { Type: 'VehicleMake', Name: 'Nissan' }, model: 'X-Trail' },
                    { id: 6, make: { Type: 'VehicleMake', Name: 'Nissan' }, model: 'Note' },
                ],
            },
        }),
    },
    // Honda
    {
        request: {
            query: getVehicleModels,
            variables: {
                make: 'VehicleMake.Honda',
            },
        },
        newData: () => ({
            data: {
                vehicleModels: [
                    { id: 7, make: { Type: 'VehicleMake', Name: 'Honda' }, model: 'Civic' },
                    { id: 8, make: { Type: 'VehicleMake', Name: 'Honda' }, model: 'Jazz' },
                    { id: 9, make: { Type: 'VehicleMake', Name: 'Honda' }, model: 'CR-V' },
                ],
            },
        }),
    },
];
