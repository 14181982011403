import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { State } from './State';
import { Authenticator, AuthState } from './Authenticator';
import { unawait } from '../await';

interface SecurityState {
    loading: boolean;
    error?: Error | null;
}

export type Security = SecurityState & State & { token: () => Promise<string | null> };

const objectKeys = <T extends {}>(object: T): (keyof T)[] => Object.keys(object) as any;

export const SecurityContext = React.createContext<Security>({
    loading: false,
    error: null,
    status: 'UNAUTHENTICATED',
    login: () => { /* */ },
    forgotPassword: () => { /* */ },
    resumeRecovery: () => { /* */ },
    token: async () => null,
});

const useOnLocationChange = (f: () => Promise<void>, deps: React.DependencyList) => {
    const location = useLocation();

    useEffect(unawait(async () => {
        await f();
    }), [...deps, location]);
};

type FlatState<S> = {
    [P in keyof S]: S[P] extends (...params: infer U) => Promise<void>
        ? (...params: U) => void
        : S[P] extends ((...params: infer U) => Promise<void>) | undefined
            ? ((...params: U) => void) | undefined
            : S[P] extends (...params: any[]) => any
                ? never
                : S[P] extends object
                    ? FlatState<S[P]>
                    : S[P];
};

type ContextState = FlatState<AuthState>;

const mapState = (s: AuthState, dispatch: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    error?: Error | null | undefined;
}>>): ContextState => {
    const unpromise = (p: Promise<void>) => {
        dispatch((st) => ({ ...st, loading: true }));
        p
            .then(() => {
                dispatch((st) => ({ ...st, loading: false, error: null }));
            }, (e) => {
                dispatch((st) => ({ ...st, loading: false, error: e }));
            });
    };
    switch (s.status) {
    case 'UNAUTHENTICATED':
        return {
            status: 'UNAUTHENTICATED',
            login: (username: string, password: string) => unpromise(s.login(username, password)),
            forgotPassword: (username: string) => unpromise(s.forgotPassword(username)),
            resumeRecovery: (token: string) => unpromise(s.resumeRecovery(token)),
        };
    case 'AUTHENTICATED':
        return {
            status: 'AUTHENTICATED',
            user: s.user,
            logout: () => unpromise(s.logout()),
            token: undefined as never, // weird...
        };
    case 'FACTOR_ACTIVATE': {
        const { resend } = s;
        return {
            status: 'FACTOR_ACTIVATE',
            activate: (params) => unpromise(s.activate(params)),
            cancel: () => unpromise(s.cancel()),
            previous: () => unpromise(s.previous()),
            resend: resend ? () => unpromise(resend()) : undefined,
            factor: s.factor,
        };
    }
    case 'FACTOR_CHALLENGE':
        return {
            status: 'FACTOR_CHALLENGE',
            verify: (params) => unpromise(s.verify(params)),
            cancel: () => unpromise(s.cancel()),
            previous: () => unpromise(s.previous()),
            factorType: s.factorType,
        };
    case 'FACTOR_ENROLL':
        return {
            status: 'FACTOR_ENROLL',
            cancel: () => unpromise(s.cancel()),
            factors: objectKeys(s.factors).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...s.factors[k],
                    enroll: () => unpromise(s.factors[k]!.enroll()),
                },
            }), {}),
        };
    case 'FACTOR_REQUIRED':
        return {
            status: 'FACTOR_REQUIRED',
            cancel: () => unpromise(s.cancel()),
            factors: objectKeys(s.factors).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...s.factors[k],
                    verify: () => unpromise(s.factors[k]!.verify()),
                },
            }), {}),
        };
    case 'LOCKED_OUT':
        return {
            status: 'LOCKED_OUT',
        };
    case 'PASSWORD_EXPIRATION': {
        const { skip } = s;
        return {
            status: 'PASSWORD_EXPIRATION',
            changePassword: (username: string) => unpromise(s.changePassword(username)),
            skip: skip ? () => unpromise(skip()) : undefined,
            cancel: () => unpromise(s.cancel()),
        };
    }
    case 'RECOVERY_CHALLENGE': {
        return {
            status: 'RECOVERY_CHALLENGE',
            resumeRecovery: (token: string) => unpromise(s.resumeRecovery(token)),
            cancel: () => unpromise(s.cancel()),
        };
    }
    default:
        throw new Error('unreachable');
    }
};

export const SecurityProvider = ({ auth, children }: { auth: Authenticator; children: React.ReactNode }) => {
    const [state, setState] = useState<{ loading: boolean; error?: Error | null }>({ loading: false });

    const { checkState, state: tx } = auth;

    const token = React.useCallback(async () => {
        const s = auth.state();
        if (s.status !== 'AUTHENTICATED') {
            return null;
        }
        return (await s.token()).accessToken;
    }, [auth]);

    useOnLocationChange(async () => {
        try {
            setState((s) => ({ ...s, loading: true }));
            await checkState();
            setState((s) => ({ ...s, loading: false }));
        } catch (e) {
            setState((s) => ({ ...s, loading: false, error: e }));
        }
    }, [auth]);

    const value = {
        ...mapState(tx(), setState),
        ...state,
        token,
    };

    return (
        <SecurityContext.Provider value={value}>
            {children}
        </SecurityContext.Provider>
    );
};

const useSecurityInternal = () => {
    const sec = React.useContext(SecurityContext);

    const authenticated = sec.status === 'AUTHENTICATED';
    const user = sec.status === 'AUTHENTICATED' ? sec.user : null;
    const authenticating = sec.loading; // for backward compatibility

    return {
        ...sec,
        authenticated,
        user,
        authenticating,
    };
};

type UseSecurity = ReturnType<typeof useSecurityInternal>
type SecurityWithStatus<S extends Security['status'] | unknown, Sec extends UseSecurity = UseSecurity> =
    S extends Security['status']
        ? Sec & { status: S }
        : Sec;

export const useSecurity = <Status extends Security['status'], >(status?: Status): SecurityWithStatus<Status> => {
    const sec = useSecurityInternal();

    if (!status) {
        return sec as never;
    }
    if (sec.status !== status) {
        throw new Error('invalid security status');
    }

    return sec as never;
};
