/* eslint-disable react/require-default-props */
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { FormSpy } from 'react-final-form';
import { isSectionValid } from 'src/utils/forms';
import propTypes from 'prop-types';

import { useKeyboardNavigation } from '@shift/design-system';

import { useKeyboardFocusableToggle } from 'src/libs/hooks/useKeyboardFocusableToggle';

const FormSection = ({
    title,
    name,
    children,
    useDifferentSectionIcon = false,
    collapsible = true,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const keyboardListener = useKeyboardNavigation({
        onEnter: () => setCollapsed(!collapsed),
    });

    const bodyPanelRef = useRef(null);
    const toggleFocusable = useKeyboardFocusableToggle(bodyPanelRef);

    useEffect(() => toggleFocusable(!collapsed), [collapsed, toggleFocusable]);

    return (
        <FormSpy
            subscription={{ errors: true }}
            render={({ errors }) => {
                const valid = isSectionValid(errors, name);
                return (
                    <div className={classNames('panel', 'panel-default', 'expandable-panel')} id={name}>
                        <div className="panel-heading">
                            <span className={classNames({
                                'fas fa-check-circle': !useDifferentSectionIcon && valid,
                                'far fa-circle': !useDifferentSectionIcon && !valid,
                                'fas fa-exclamation-triangle': useDifferentSectionIcon,
                            })}
                            />
                            {title}
                            {collapsible && (
                                <div
                                    onKeyDown={keyboardListener}
                                    role="button"
                                    tabIndex="0"
                                    aria-label="Expand/Collapse"
                                    className={classNames('pull-right', 'expand-button',
                                        {
                                            'fas fa-angle-up': !collapsed,
                                            'fas fa-angle-down': collapsed,
                                        })}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                            )}
                        </div>
                        <div ref={bodyPanelRef} className={classNames('panel-body', { collapsed })}>
                            {children}
                        </div>
                    </div>
                );
            }}
        />
    );
};

FormSection.propTypes = {
    name: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    children: propTypes.any.isRequired,
    useDifferentSectionIcon: propTypes.bool,
    collapsible: propTypes.bool,
};

export default FormSection;
