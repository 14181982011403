import { getFileFormatter } from '../../../utils/forms/formatters';
import { isValidFile } from '../../../utils/forms/validations';

export const acceptCounterOffer = {
    singleSection: {
        name: 'acceptCounterOffer',
        title: 'accept-counter-offer',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'nextAction.step.action',
            },
            commentType: {
                path: 'comment.commentType',
            },
            attachments: {
                path: 'acceptCounterOffer.attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        id: 'document.id',
                        name: 'document.documentName',
                    },
                },
                label: 'attachments',
                formatter: getFileFormatter(true),
                validate: isValidFile,
            },
            comment: {
                path: 'acceptCounterOffer.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
        },
    },
};
