/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formatter } from '@shift/transform';
import { translate } from '@gears/translations';
import { RecoveryWorkflowStepDeadlineState } from 'src/constants';
import { DateTimeFormatters } from './DateTimeFormatters';

export const RecoveryDeadlineFormatter: Formatter<any[], { children: string }> = {
    transform: (params) => {
        const [[expirationDateUtc], [recoverWorkflowDeadlineState]] = params && params[0] && params[1] && params;
        const value = (recoverWorkflowDeadlineState?.Name === RecoveryWorkflowStepDeadlineState.WaitingAction
            && expirationDateUtc
            && DateTimeFormatters.DaysLeftFormatter.transform([[expirationDateUtc]]).children) || null;

        return {
            children: value ? `< ${value}` : translate('common:NA'),
        };
    },
};
