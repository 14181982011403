import { DefaultSortsConfig } from './types';
import { RecoveriesFields, ClaimFields, TpReportFields } from './filters';
import { OrderType } from './sort';

export const defaultSortConfig: DefaultSortsConfig = {
    AccidentReports: {
        field: {
            id: ClaimFields.SubmissionDate,
            i18NId: 'accident-reports:claim-submissionDate',
        },
        order: { name: '', order: OrderType.Desc },
    },
    Recoveries: {
        field: {
            id: RecoveriesFields.Deadline,
            i18NId: 'recoveries:recovery-workflowStep-deadline',
        },
        order: { name: '', order: OrderType.Asc },
    },
    GiaRecoveries: {
        field: {
            id: RecoveriesFields.InitiationDate,
            i18NId: 'recoveries:recovery-initiationDate',
        },
        order: { name: '', order: OrderType.Desc },
    },
    ThirdPartyReports: {
        field: {
            id: TpReportFields.CreateDate,
            i18NId: 'third-party-report:tpReport-createDate',
        },
        order: { name: '', order: OrderType.Desc },
    },
};
