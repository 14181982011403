export const vehicleFuels: Record<string, string> = {
    //"MyInfo value": "Gears value"
    'Compressed Natural Gas': 'NaturalGas',
    'Diesel': 'Diesel',
    'Diesel-CNG': 'Lpg',
    'Diesel-Electric': 'HybridDiesel',
    'Diesel-Electric (Plug-In)': 'HybridDieselPlugIn',
    'Electric': 'Electric',
    'Petrol': 'Gasoline',
    'Petrol-CNG': 'Lpg',
    'Petrol-Electric': 'HybridGasoline',
    'Petrol-Electric (Plug-In)': 'HybridGasolinePlugIn',
};

// GEARS values:
// Gasoline = 0,
// Diesel = 1,
// Lpg = 2,
// Electric = 3,
// NaturalGas = 4,
// HybridGasoline = 5,
// HybridDiesel = 6,
// Hydrogen = 7,
// Hybrid  = 8
