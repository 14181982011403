import { Formatter } from '@shift/transform';
import { translate } from '../../utils/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TranslateValueFormatter: Formatter<any, { children: string }> = {
    transform: (params) => {
        const value = params && params.length > 0 && params[0];
        const i18Value = translate(value);
        return {
            children: i18Value,
        };
    },
};

export const TranslateWithNamespaceValueFormatter: (namespace: string) => Formatter<any, { children: string }> = (namespace: string) => ({
    transform: (params) => {
        const value = params && params.length > 0 && params[0];
        const i18Value = translate(`${namespace}:${value}`);

        return {
            children: i18Value,
        };
    },
});

// eslint-disable-next-line max-len
export const TranslateAndJoinWithNamespaceValueFormatter: (namespace: string, format: (val: any) => string) => Formatter<any, { children: string }> = (namespace: string, format: (val: any) => string) => ({
    transform: (params) => {
        const [[key], [val]] = params;
        const i18Value = translate(`${namespace}:${key}-${format(val)}`);

        return {
            children: i18Value,
        };
    },
});
