import { Formatter } from '@shift/transform';
import { translate } from '@gears/translations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DefaultValueFormatterFactory: (...defaultValue: any[]) => Formatter<any[], { children: any }> = (defaultValue) => ({
    transform: (param) => {
        if (param.flat().flat().some((a) => a)) {
            return { children: param.flat() };
        }

        return { children: defaultValue };
    },
});

export const DefaultTranslationValueFormatterFactory: (defaultValue: string) => Formatter<any[], { children: any }> = (defaultValue) => ({
    transform: (param) => DefaultValueFormatterFactory(translate(defaultValue)).transform(param),
});
