/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { Grid, GridItem } from '@shift/design-system';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { AnyType, ArrayFieldInformation, SingleFieldInformation } from 'src/types';
import {

} from 'src/utils/forms';
import { SingleLabel } from './fields/single-label';

export type ArrayItemProps = {
    setItemPath: <T = SingleFieldInformation | ArrayFieldInformation>(item: T) => T;
    itemIndex: number;
}

export interface ArrayFieldWrapperProps {
    itemTitle?: string;
    Item: React.ComponentType<ArrayItemProps>;
    emptyElement?: (() => AnyType) | AnyType;
    addItemLabel?: string;
    initialItemsCount?: number;
}

interface ArrayFieldValues {
    push: (value: any) => void;
    remove: (index: number) => any;
    map: <R>(iterator: (name: string, index: number) => R) => R[];
    pop: () => any;
    length?: number;
}
interface InternalArrayFieldProps extends ArrayFieldWrapperProps {
    fields: ArrayFieldValues;
}

export interface ExpandableFieldProps extends ArrayFieldWrapperProps {
    fieldInfo: ArrayFieldInformation;
    label: string;
}

export const ArrayFieldWrapper = ({
    Item,
    fields,
}: InternalArrayFieldProps) => (
    <div>
        {fields.map((fieldName: string, index: number) => (
            <Item
                setItemPath={
                    (item: any) => ({ ...item, path: `${fieldName}.${(item).path}` })
                }
                itemIndex={index}
            />
        ))}
    </div>
);

export const TableInfo = (props: ExpandableFieldProps) => {
    const { fieldInfo, label } = props;
    return (
        <FieldArray
            name={fieldInfo.path}
            validate={fieldInfo.validate}
        >

            {({ fields }) => (
                <>
                    {fields.length !== 0
                    && (
                        <Grid direction="column">
                            <GridItem style={{
                                paddingBottom: '10px',
                            }}
                            >
                                <SingleLabel fieldInfo={{ path: fieldInfo.path, label, target: fieldInfo.target }} />
                            </GridItem>
                            <GridItem>
                                <ArrayFieldWrapper fields={fields} {...props} />
                            </GridItem>
                        </Grid>
                    )}
                </>
            )}
        </FieldArray>
    );
};
