import { Formatter } from '@shift/transform';

export function ElementAtFormatterFactory<T>(
    index: number | 'last', formatter: Formatter<T[], { children: string }>,
): Formatter<T[], { children: string }> {
    return ({
        transform: (values) => {
            const value = values.flat()[index === 'last' ? values.flat().length - 1 : index];
            return formatter.transform([[value]]);
        },
    });
}
