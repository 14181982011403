import { CheckboxesToEnumFormatter } from 'src/utils/forms/formatters';

export const waiveRecovery = {
    singleSection: {
        name: 'waive',
        title: 'waive',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'nextAction.step.action',
            },
            commentType: {
                path: 'comment.commentType',
            },
            waiveReason: {
                path: 'waive.waiveReason',
                target: 'nextAction.step.specializations.ui_WorkflowStep_Reason',
                label: 'waive-reason',
                isRequired: true,
                formatter: CheckboxesToEnumFormatter,
            },
            comment: {
                path: 'waive.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
        },
    },
};
