export const additionalCommentSection = {
    name: 'additionalComment',
    title: 'additional-comment',
    useDifferentSectionIcon: true,
    collapsible: false,
    fields: {
        additionalComment: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_FreeText.eventDescription',
            label: 'additional-comment',
        },
    },
};
