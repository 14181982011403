import React, { ReactElement } from 'react';
import { MockedProvider as BaseMockedProvider, MockedResponse } from '@apollo/react-testing';

import { GraphQLQueryOptionsProvider } from 'src/config/query-options';
import { mocks } from './mocks';
import { GearsConfigProvider, GearsConfigConsumer, ProcessedGearsConfig } from '../../config/configuration';
import { gearsConfig } from '../../config/configuration/clients/tests';

interface MockedProviderProps {
    children: ReactElement;
    providedMock?: (config: ProcessedGearsConfig) => readonly MockedResponse[];
}

export const MockedProvider = ({ children, providedMock }: MockedProviderProps) => (
    <GearsConfigProvider configuration={gearsConfig}>
        <GearsConfigConsumer>
            {(config) => (
                config
                 && (
                     <BaseMockedProvider
                         mocks={(providedMock && providedMock(config)) || mocks(config)}
                         addTypename={false}
                         defaultOptions={{
                             watchQuery: {
                                 fetchPolicy: 'no-cache',
                             },
                             query: {
                                 fetchPolicy: 'no-cache',
                             },
                         }}
                     >
                         <GraphQLQueryOptionsProvider>
                             {children}
                         </GraphQLQueryOptionsProvider>

                     </BaseMockedProvider>
                 )
            )}
        </GearsConfigConsumer>
    </GearsConfigProvider>
);
