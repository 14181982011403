import React, { useEffect, useRef, useState } from 'react';
import { useFetch } from 'src/libs/fetch';
import { apiConfig, dashboardLink } from 'src/config';
import { permissions } from 'src/config/permissions';
import { useSecurity } from 'src/libs/auth';

export const Reporting = () => {
    const { goodDataEncryptedClaims } = useFetch();

    const [formUrl, setFormUrl] = useState('');
    const [targetUrl, setTargetUrl] = useState();
    const [SSOProvider, setSSOProvider] = useState();
    const [encryptedClaims, setEncryptedClaims] = useState();
    const [dashboard, setDashboard] = useState('');
    const { user } = useSecurity();
    const formRef = useRef<HTMLFormElement>(null);
    useEffect(() => {
        if (user != null && (permissions.isGIASecretariat(user) || permissions.isAdmin(user))) {
            setDashboard(dashboardLink.gia);
        } else {
            setDashboard(dashboardLink.insurer);
        }

        if (dashboard) {
            goodDataEncryptedClaims(`${apiConfig.reportingSecuredClaimsUri}/${dashboard}`)
                .then((res) => res.json())
                .then((data) => {
                    setEncryptedClaims(data.encryptedClaims);
                    setSSOProvider(data.ssoProvider);
                    setTargetUrl(data.targetUrl);
                    setFormUrl(data.formUrl);
                    // eslint-disable-next-line no-unused-expressions
            formRef?.current?.submit();
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef, dashboard]);
    return (
        <>
            <form ref={formRef} target="GoodDataIframe" method="post" action={formUrl}>
                <input
                    type="hidden"
                    name="targetUrl"
                    value={targetUrl}
                />
                <input
                    type="hidden"
                    name="ssoProvider"
                    value={SSOProvider}
                />
                <input
                    type="hidden"
                    name="encryptedClaims"
                    value={encryptedClaims}
                />
            </form>
            <iframe
                className="gooddata-iframe"
                name="GoodDataIframe"
                title="GoodDataIframe"
            />
        </>


    );
};
