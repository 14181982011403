import { unknownObjectEnum } from 'src/constants';
import { KeyValueCardConfig, SelectKeyValueCardConfig } from '../../../../../libs/config/QueryConfigTypes';
import { FormatterFactories, Formatters } from '../../../../formatters';
import { Widgetters } from '../../../../widgets';

const personDetails: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['person'],
    i18Namespace: 'person-exploration',
    transforms: [
        {
            chrooters: [
                ['name'],
            ],
        },
        {
            i18NId: 'last-update',
            chrooters: [
                [
                    'modifyDateUtc',
                ],
            ],
            formatter: Formatters.UtcDateFormatter,
        },
    ],
};

const vehiclesByPerson: SelectKeyValueCardConfig = {
    configType: 'SelectKeyValueCard',
    context: ['person', 'ownedVehicles'],
    i18Namespace: 'person-exploration',
    header: {
        chrooters: [
            ['vehicle'],
        ],
        formatter: Formatters.MultipleFormatters([
            Formatters.VehicleNumberSelectFormatter({
                getter: (vehicle) => vehicle?.vrn,
                prefix: 'person-exploration:vehicle-title',
            }),
            Formatters.ListFormatterWithOptions({
                isDistinct: true,
            }),
        ]),

    },
    transforms: [
        {
            chrooters: [
                ['vehicle', 'vrn'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        {
            chrooters: [
                ['vehicle', 'type'],
            ],
            formatter: Formatters.MultipleFormatters([
                FormatterFactories.DefaultValueFormatterFactory(unknownObjectEnum),
                Formatters.EnumValuesFormatter,
            ]),
        },
        {
            i18NId: 'vehicle-make',
            chrooters: [
                ['vehicle', 'makeNotInEnum'],
                ['vehicle', 'make'],
                ['vehicle', 'makeDescription'],
            ],
            formatter: Formatters.VehicleMakeFormatter,
        },
        {
            chrooters: [
                ['vehicle', 'model'],
            ],
            formatter: Formatters.VehicleModelFormatter,
        },
        {
            i18NId: 'current-ncd',
            chrooters: [
                [
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'coefficient',
                ],
                // Do not remove used to get the insurer code to get the email
                [
                    'policy',
                    'specializations',
                    'mapping_Policy_Insurer',
                    'insurer',
                ],
            ],
            widgetter: Widgetters.PercentageWidget,
        },
        {
            i18NId: 'renewal-ncd',
            chrooters: [
                [
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    {
                        name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' }] },
                    },
                    'coefficient',
                ],
                [
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'coefficient'],
            ],
            formatter: Formatters.NcdValuesFormatter,
            widgetter: Widgetters.TrajectoryIconWidget,
        },
        {
            i18NId: 'ncd-protection',
            chrooters: [
                [
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'protection'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            i18NId: 'last-update',
            chrooters: [
                [
                    'vehicle', 'modifyDateUtc',
                ],
            ],
            formatter: Formatters.UtcDateFormatter,
        },
    ],
};

const personNumberAccident: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['person'],
    i18Namespace: 'statistic-exploration',
    transforms: [
        {
            i18NId: 'driver',
            chrooters: [
                [
                    'adhocStatistics',
                    'accidents',
                    'asDriver',
                ],
                [
                    'adhocStatistics',
                    'accidents',
                    'startDate',
                ],
                [
                    'adhocStatistics',
                    'accidents',
                    'endDate',
                ],
            ],
        },
        {
            i18NId: 'insured',
            chrooters: [
                [
                    'adhocStatistics',
                    'accidents',
                    'asOwner',
                ],
            ],
        },
        {
            i18NId: 'driver-insured',
            chrooters: [
                [
                    'adhocStatistics',
                    'accidents',
                    'asBoth',
                ],
            ],
        },
    ],
};

const personTotalClaimAmount: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['person'],
    i18Namespace: 'statistic-exploration',
    transforms: [
        {
            i18NId: 'total',
            chrooters: [
                [
                    'statistics',
                    'totalClaimedAmountsWhereThePersonWasInvolvedAsFirstPartyVehicleOwner',
                ],
                [
                    'statistics',
                    'startDate',
                ],
                [
                    'statistics',
                    'endDate',
                ],
            ],
        },
    ],
};

export const personInformation = {
    personDetails,
    vehiclesByPerson,
    personTotalClaimAmount,
    personNumberAccident,
};
