import { permissions, TokenPredicate } from './permissions';

type RouteSetup = {
    path: string;
    permissions: TokenPredicate | undefined;
};

export const accidentReportDetail: RouteSetup = {
    path: '/accident-reports/details/:id',
    permissions: permissions.canReadReport,
};
export const accidentReportList: RouteSetup = {
    path: '/accident-reports',
    permissions: permissions.canListReport,
};
export const arcList: RouteSetup = {
    path: '/arcs',
    permissions: permissions.canListArc,
};
export const arcForm: RouteSetup = {
    path: '/accident-report-form/:id?',
    permissions: permissions.canCreateOrUpdateReport,
};
export const arcPreForm: RouteSetup = {
    path: '/accident-report-form/create',
    permissions: permissions.canCreateOrUpdateReport,
};
export const arcSubmitted: RouteSetup = {
    path: '/accident-report-submitted/:id?',
    permissions: undefined,
};
export const recoveries: RouteSetup = {
    path: '/recoveries',
    permissions: permissions.canListRecovery,
};
export const recovery: RouteSetup = {
    path: '/recoveries/details/:id',
    permissions: permissions.canReadRecovery,
};
export const recoveryTests: RouteSetup = {
    path: '/recovery/tests',
    permissions: undefined,
};
export const exploration: RouteSetup = {
    path: '/exploration',
    permissions: permissions.canSeeExploration,
};
export const ncd: RouteSetup = {
    path: '/ncd',
    permissions: permissions.canSeeNcd,
};
export const insurerEnquiry: RouteSetup = {
    path: '/insurer-enquiry',
    permissions: permissions.canSeeInsurerEnquiry,
};
export const thirdPartyReports: RouteSetup = {
    path: '/third-party-reports',
    permissions: permissions.canSeeThirdPartyReports,
};
export const folderList: RouteSetup = {
    path: '/folders',
    permissions: undefined,
};
export const folderDetail: RouteSetup = {
    path: '/folders/details/:id',
    permissions: undefined,
};

export const account: RouteSetup = {
    path: '/account',
    permissions: undefined,
};
export const accountChangePassword: RouteSetup = {
    path: '/account/change-password',
    permissions: undefined,
};
export const accountCreateAccount: RouteSetup = {
    path: '/account/create-account',
    permissions: permissions.isAdmin,
};
export const accountChangeLanguage: RouteSetup = {
    path: '/account/change-language',
    permissions: undefined,
};

export const settings: RouteSetup = {
    path: '/settings',
    permissions: undefined,
};

export const reporting: RouteSetup = {
    path: '/reporting',
    permissions: permissions.canSeeDashboard,
};

export const callback: RouteSetup = {
    path: '/callback',
    permissions: undefined,
};
