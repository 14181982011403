import { Formatter } from '@shift/transform';
import { EnumValue } from 'src/types';
import { useEnum } from 'src/utils/hooks';
import { translate } from '../../utils/forms';

const EnumValuesFormatter: Formatter<EnumValue[], { children: string }> = {
    transform: (enums) => {
        const value = enums.flat()?.[0];
        const key = value?.Type && value?.Name ? `enums:${value?.Type}.${value?.Name}` : '';
        return {
            children: translate(key, { defaultValue: value?.Name ?? '' }),
        };
    },
};

export interface CustomHookEnumFomatter extends Formatter<any[], { children: string }>{
    Transform: any;
}

const EnumValuesIdFormatter: CustomHookEnumFomatter = {
    transform: (enums) => EnumValuesIdFormatter.Transform(enums),
    Transform: (enums: any[]) => {
        const [name, value] = enums.flat();
        const enumValues = useEnum(name);
        const currentValue = enumValues?.selectedEnum?.filter((en) => en.ValueId === value)[0];
        const key = currentValue ? `enums:${currentValue?.Name}.${currentValue?.Value}` : '';
        return {
            children: translate(key, { defaultValue: value?.Name ?? '' }),
        };
    },
};

const BindedNameEnumValueFormatterFactory = (enumName: string): Formatter<string[], { children: string }> => ({
    transform: (enums) => {
        const value = enums.flat()?.[0];
        const key = value ? `enums:${enumName}.${value}` : '';
        return {
            children: translate(key, { defaultValue: value ?? '' }),
        };
    },
});

export const EnumFormatters = {
    EnumValuesFormatter,
    EnumValuesIdFormatter,
    BindedNameEnumValueFormatterFactory,
};
