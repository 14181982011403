import { parseUtcDate } from 'src/utils';
import { Formatter } from '@shift/transform';
import { DateTimeFileNameFormat } from 'src/constants';
import { DownloadWidgetterProps } from '../widgets/DownloadWidget';
import { apiConfig } from '..';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PDFLinkFormatter: Formatter<any, DownloadWidgetterProps> = {
    transform: (params) => {
        const values = params && params.flat();
        const [id, name, submissionDate, version] = values;
        const formattedVersion = parseUtcDate(submissionDate).format(DateTimeFileNameFormat);
        return {
            url: id && (version != null) ? `${apiConfig.pdfUri}/${id}?version=${version}&type=Full&masked=false` : '',
            filename: `${name}_${formattedVersion}.pdf`,
            children: 'Download PDF',
        };
    },
};
