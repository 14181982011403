import moment from 'moment-timezone';
import { SelectInputOption } from '@shift/design-system';
import { translate } from '@gears/translations';
import {
    isValidDate, isValidLocation,
    validateListIfCondition,
    isValidFile,
    mergeDateTime,
    getStateTimezone,
    isValidAlphanumeric,
    isRequiredIfCondition,
    isValidNationalIdentifier,
    phoneNumberRequired,
    isValidPhoneNumber,
    validateIfCondition,
    isValidEmail,
} from 'src/utils/forms';
import {
    getFileFormatter, PhoneNumberFormatter,
    LocationFormatter, EnumFormatter, EnumIdentifierValuePairFormatter, PoliceStationFormatter, AccidentDateFormatter, AccidentTimeFormatter,
    UpperCaseFormatter,
} from 'src/utils/forms/formatters';
import { EnumValue, AnyType } from 'src/types';
import { DefaultTimezone } from 'src/constants';
import { get } from 'object-path';

const isTranslatedReport = (formValues: AnyType) => (get(formValues, 'generalInformation.translation.isTranslatedReport'));

export const accidentSection = {
    name: 'generalInformation',
    title: 'accident-details',
    fields: {
        claimant: {
            path: 'ownVehicle.claimant',
            label: 'who-claimant',
            formatter: {
                format: (value: EnumValue) => (value.Name === 'None' ? null
                    : {
                        value: `${value.Type}.${value.Name}`,
                        label: translate(`enums:${value.Type}.${value.Name}`,
                            { defaultValue: value.Name }),
                    }),
                transform: ({ value: option }: { value: SelectInputOption }) => (option?.value ? option?.value : null),
            },
            isRequired: true,
        },
        reportPurpose: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_PurposeIndication.purpose',
            label: 'report-type-claim-question',
            isRequired: true,
            formatter: EnumFormatter,
        },
        id: {
            path: 'generalInformation.accidentInformation.id',
            label: '',
        },
        referenceCode: {
            path: 'generalInformation.accidentInformation.referenceCode',
            label: '',
        },
        status: {
            path: 'generalInformation.accidentInformation.status',
            label: '',
            formatter: {
                format: (value: EnumValue) => value,
            },
        },
        areaOrState: {
            path: 'generalInformation.accidentAddress.areaOrState',
            label: 'country-state',
            formatter: {
                format: (value: string) => ({ value, label: value }),
                transform: ({ value: option }: { value: SelectInputOption }) => option?.label,
            },
            isRequired: true,
        },
        date: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Event.eventDateOffset',
            label: 'accident-date',
            formatter: AccidentDateFormatter,
            isRequired: true,
            validate: isValidDate,
        },
        time: {
            path: 'generalInformation.time',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Event.eventDateOffset',
            label: 'accident-time',
            isRequired: true,
            formatter: AccidentTimeFormatter,
            validate: (value: any, formValues: AnyType) => {
                const accidentTimezone = getStateTimezone(
                    get(formValues, 'generalInformation.accidentAddress.areaOrState')?.value,
                );
                const date = mergeDateTime(
                    get(formValues, 'generalInformation.accidentInformation.specializations.mapping_Claim_Event.eventDateOffset'),
                    value,
                    accidentTimezone ?? DefaultTimezone,
                );

                return date && date > moment.tz(accidentTimezone ?? DefaultTimezone)
                    ? translate('accident-report-form:no-future-date-time')
                    : undefined;
            },
        },
        location: {
            path: 'generalInformation.location',
            label: 'exact-location',
            target: {
                fullAddress: 'generalInformation.accidentAddress.fullAddress',
                country: 'generalInformation.accidentAddress.country',
                postCode: 'generalInformation.accidentAddress.postCode',
                latitude: 'generalInformation.accidentAddress.latitude',
                longitude: 'generalInformation.accidentAddress.longitude',
            },
            formatter: LocationFormatter,
            isRequired: true,
            validate: isValidLocation,
        },
        locationAdditionalInformation: {
            path: 'generalInformation.accidentAddress.complement',
            label: 'location-additional-information',
        },
        type: {
            path: 'generalInformation.type',
            label: 'accident-type',
            target: {
                identifier: 'generalInformation.accidentInformation.specializations.mapping_Claim_Cause.enumIdentifier',
                valueId: 'generalInformation.accidentInformation.specializations.mapping_Claim_Cause.enumValueId',
            },
            formatter: EnumIdentifierValuePairFormatter,
            isRequired: true,
        },
        weatherCondition: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_RoadAccident.weatherCondition',
            label: 'weather-condition',
            formatter: EnumFormatter,
            isRequired: true,
        },
        weatherConditionDescription: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_RoadAccident.weatherConditionDescription',
            label: 'weather-condition-description',
            isRequired: true,
        },
        roadSurface: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_RoadAccident.roadSurface',
            label: 'road-surface',
            formatter: EnumFormatter,
            isRequired: true,
        },
        roadSurfaceDescription: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_RoadAccident.roadSurfaceDescription',
            label: 'road-surface-description',
            isRequired: true,
        },
        description: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Cause.detailedCircumstances',
            label: 'accident-description',
            isRequired: true,
        },
        foreignVehicleInvolved: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyForeignVehicle',
            label: 'foreign-vehicle-involved',
            isRequired: true,
        },
        foreignVehicles: {
            path: 'generalInformation.foreignVehicles',
            target: 'foreignVehicles',
            label: 'other-vehicles',
            item: {
                number: {
                    path: 'specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                    label: 'foreign-vehicle-number',
                    isRequired: true,
                    validate: isValidAlphanumeric,
                    formatter: UpperCaseFormatter,
                },
                category: {
                    path: 'specializations.mapping_Object_Motor_Vehicle.type',
                    label: 'foreign-vehicle-category',
                    formatter: EnumFormatter,
                    isRequired: true,
                },
            },
            validate: validateListIfCondition(
                'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyForeignVehicle',
            ),
        },
        vehicleCount: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.numberOfVehicles',
            label: 'vehicle-count',
            isRequired: true,
        },
        approachedByAssistance: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyUnknownPersonAssistance',
            label: 'approached-by-assistance',
            isRequired: true,
        },
        reportedToPolice: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyPoliceReport',
            label: 'reported-to-police',
            isRequired: true,
        },
        policeStation: {
            path: 'generalInformation.policeStation',
            target: {
                id: 'generalInformation.policeStationInformation.person.id',
                recordOwner: 'generalInformation.policeStationInformation.person.recordOwner',
            },
            label: 'police-station-name',
            formatter: PoliceStationFormatter,
            isRequired: true,
        },
        policeStationName: {
            path: 'generalInformation.policeStationInformation.person.specializations.mapping_Person_Gears.name',
            label: 'police-station-name',
            isRequired: true,
        },
        policeReport: {
            path: 'generalInformation.policeReport',
            target: {
                name: 'attachments.policeReport',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'police-report',
            formatter: getFileFormatter(true),
            validate: isValidFile,
        },
        isTranslatedReport: {
            path: 'generalInformation.translation.isTranslatedReport',
            label: 'is-translated',
            isRequired: true,
        },
        translatorName: {
            path: 'generalInformation.translation.person.specializations.mapping_Person_Gears.name',
            label: 'translator-name',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isTranslatedReport),
        },
        translatorTypeOfId: {
            path: 'generalInformation.translation.person.specializations.mapping_Person_NationalIdentifier.0.identifier',
            label: 'translator-id-type',
            formatter: EnumFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isTranslatedReport),
        },
        translatorId: {
            path: 'generalInformation.translation.person.specializations.mapping_Person_NationalIdentifier.0.nationalIdentifier',
            label: 'translator-id',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isTranslatedReport),
            validate: isValidNationalIdentifier(
                'generalInformation.translation.person.specializations.mapping_Person_NationalIdentifier.0.identifier',
            ),
        },
        translatorMobilePhone: {
            path: 'generalInformation.translation.phoneNumber',
            label: 'translator-phone',
            target: {
                number: 'generalInformation.translation.phoneNumber.number',
                countryCode: 'generalInformation.translation.phoneNumber.countryCode',
                phoneNumberType: 'generalInformation.translation.phoneNumber.phoneNumberType',
            },
            formatter: PhoneNumberFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isTranslatedReport, phoneNumberRequired),
            validate: validateIfCondition(isTranslatedReport, isValidPhoneNumber),
        },
        translatorEmail: {
            path: 'generalInformation.translation.email.address',
            label: 'translator-email',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isTranslatedReport),
            validate: validateIfCondition(isTranslatedReport, isValidEmail),
        },
        translatedReport: {
            path: 'generalInformation.translation.translatedReport',
            target: {
                id: 'attachments.translatedReport.id',
                name: 'attachments.translatedReport.documentName',
                size: 'attachments.translatedReport.specializations.document_Document_DestinationDiskStorageInformation.fileSize',
            },
            label: 'translated-report',
            formatter: getFileFormatter(),
            validate: isValidFile,
        },
        originalReport: {
            path: 'generalInformation.translation.originalReport',
            target: {
                id: 'attachments.originalReport.id',
                name: 'attachments.originalReport.documentName',
                size: 'attachments.originalReport.specializations.document_Document_DestinationDiskStorageInformation.fileSize',
            },
            label: 'original-report',
            formatter: getFileFormatter(),
            validate: isValidFile,
        },
        originalLanguage: {
            path: 'attachments.originalLanguage',
            label: 'original-language',
            formatter: {
                format: (value: string) => (value === 'Others' ? { value: 0, label: value } : { value, label: value }),
                transform: ({ value: option }: { value: SelectInputOption }) => option?.label,
            },
            isRequired: true,
        },
        originalLanguageOthers: {
            path: 'attachments.originalLanguageOthers',
            label: 'original-language-others',
        },
        noticeOfProsecutionGiven: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyProsecutionGiven',
            label: 'notice-of-prosecution-given',
            isRequired: true,
        },
        prosecutedName: {
            path: 'generalInformation.prosecutionInformation.specializations.mapping_Assessment_FreeText.0.text',
            label: 'prosecuted-name',
            isRequired: true,
        },
        startNewReportDate: {
            path: 'generalInformation.accidentInformation.specializations.mapping_Claim_Statement.0.entryReportDateOffset',
            label: 'new-report-click-date',
        },
    },
};
