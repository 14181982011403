import React, { useMemo, useState, useEffect } from 'react';

import {
    SelectInputOption, FilterableHeaderSide, FilterableHeader, SelectSearchFilter, CheckboxOptions,
} from '@shift/design-system';
import { translate } from '@gears/translations';

import {
    useFieldFilter, Field, ParameterType, Operation, SearchOperationType,
} from '../../../libs/filters';

const TRUE = 'true';
const FALSE = 'false';
const StringBoolean = [TRUE, FALSE] as const;
type StringBoolean = typeof StringBoolean[number]

const optionOperationMap = {
    [TRUE]: SearchOperationType.Equal,
    [FALSE]: SearchOperationType.NotEqual,
};

const filterToOptions = (filter: Operation | null): StringBoolean | null => {
    switch (filter?.type) {
    case SearchOperationType.Equal:
        return TRUE;
    case SearchOperationType.NotEqual:
        return FALSE;
    default:
        return null;
    }
};

function optionsToFilter(
    value: string,
    checkedOption: StringBoolean | null,
    parameterType: ParameterType,
): Operation | null {
    return value && checkedOption && parameterType
        ? {
            name: '',
            parameters: [{ name: '', type: parameterType, value }],
            type: optionOperationMap[checkedOption],
        }
        : null;
}

export interface EqualValueBooleanFilterProps {
    field: Field;
    children: React.ReactNode;
    parameterType: ParameterType;
    value: string;
    side?: FilterableHeaderSide;
}

export const EqualValueBooleanFilter = ({
    field, children, side, parameterType, value,
}: EqualValueBooleanFilterProps) => {
    const options: SelectInputOption[] = useMemo(() => [
        { label: translate('common:yes'), value: TRUE },
        { label: translate('common:no'), value: FALSE },
    ], []);

    const [filter, setFilter] = useFieldFilter(field);
    const [pendingFilter, setPendingFilter] = useState<StringBoolean | null>(null);

    const checkboxOptions: CheckboxOptions = useMemo(
        () => (options ? options.reduce((p, c) => ({ ...p, [c.value]: { labelName: c.label } }), {}) : {}), [options],
    );

    useEffect(() => {
        if (checkboxOptions) {
            const newValue = filterToOptions(filter);
            setPendingFilter(newValue);
        }
    }, [filter, checkboxOptions]);

    return (
        <FilterableHeader
            activeFilter={Boolean(filter)}
            side={side}
            filter={pendingFilter ? [pendingFilter] : []}
            checkboxes={{
                options: checkboxOptions,
            }}
            onFilterChange={(o: string[]) => setPendingFilter((o[1] ?? o[0]) as StringBoolean)}
            onSubmit={() => setFilter(optionsToFilter(value, pendingFilter, parameterType))}
            onClear={() => { setFilter(null); setPendingFilter(null); }}
            FilterComponent={SelectSearchFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            deselectAllText={translate('common:none')}
            sortable={false}
        >
            {children}
        </FilterableHeader>
    );
};
