/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */
import { DateFormat, DateOffsetFormat } from 'src/constants';
import { parseOffsetDate } from 'src/utils';
import { RecoveryConfig } from '../../config/configuration';
import { ProcessedSubConfiguration } from '../../libs/config';
import { buildQuery, buildListQuery } from '../query-builder';
import { recoveryData } from './recoveryMockObjects';

export const recoveryMock = (config: ProcessedSubConfiguration<RecoveryConfig>) => [
    // Recoveries list
    {
        request: {
            query: buildListQuery(
                config.list.context,
                config.list.fields,
                'RecoveriesFields',
            ),
            variables: {
                parameters: { start: 0, length: 10 },
            },
        },
        newData: () => ({
            data: {
                recoveryList: {
                    totalCount: 2,
                    itemsCount: 2,
                    items: [
                        {
                            id: 1,
                            referenceCode: 'RCV7104',
                            accidentReport: {
                                id: 12,
                                objects: [
                                    {
                                        id: 2,
                                        specializations: {
                                            id: 38393,
                                            mapping_Object_Motor_VehicleRegistration: [{ id: 2, number: 'SAK1915C' }],
                                        },
                                    },
                                ],
                                specializations: {
                                    id: 12,
                                    mapping_Claim_Event: {
                                        id: 12,
                                        eventDateOffset: parseOffsetDate('15/02/2019', DateFormat).format(DateOffsetFormat),
                                    },
                                },
                            },
                            workflow: {
                                id: 54,
                                lastWorkflowStep: {
                                    id: 42,
                                    state: 'Initial offer',
                                    type: 'OD recovery',
                                },
                            },
                        },
                        {
                            id: 1,
                            referenceCode: 'RCV9992',
                            accidentReport: {
                                id: 2,
                                objects: [
                                    {
                                        id: 6,
                                        specializations: {
                                            id: 394720,
                                            mapping_Object_Motor_VehicleRegistration: [{ id: 6, number: 'SAK1110C' }],
                                        },
                                    },
                                ],
                                specializations: {
                                    id: 2,
                                    mapping_Claim_Event: {
                                        id: 2,
                                        eventDateOffset: parseOffsetDate('13/12/2017', DateFormat).format(DateOffsetFormat),
                                    },
                                },
                            },
                            workflow: {
                                id: 12,
                                lastWorkflowStep: {
                                    id: 22,
                                    state: 'Counter-offer',
                                    type: 'OD recovery',
                                },
                            },
                        },
                    ],
                },
            },
        }),
    },
    // description
    {
        request: {
            query: buildQuery(
                config.recovery.context,
                config.recovery.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                recovery: recoveryData,
            },
        }),
    },
    // IP information
    {
        request: {
            query: buildQuery(
                config.ipInformation.context,
                config.ipInformation.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    specializations: {
                        mapping_Claim_Insurer: {
                            insurer: {
                                Type: 'RecordOwner',
                                Name: 'Insurer A',
                            },
                        },
                    },
                    referenceCode: 'A099714',
                    persons: [
                        {
                            id: 1,
                            name: 'Soh Cheng Tiong',
                            specializations: {
                                mapping_Person_NationalIdentifier: [
                                    {
                                        nationalIdentifier: 'S1191122A',
                                    },
                                ],
                            },
                        },
                    ],
                    objects: [
                        {
                            id: 1,
                            specializations: {
                                mapping_Object_Motor_VehicleRegistration: [
                                    {
                                        number: 'SAK1110C',
                                    },
                                ],
                            },
                        },
                    ],
                    officerInCharge: 'Thibault H',
                },
            },
        }),
    },
    // RP information
    {
        request: {
            query: buildQuery(
                config.rpInformation.context,
                config.rpInformation.fields,
            ),
            variables: {
                id: 2,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    specializations: {
                        mapping_Claim_Insurer: {
                            insurer: {
                                Type: 'RecordOwner',
                                Name: 'Insurer B',
                            },
                        },
                    },
                    referenceCode: 'B052798',
                    persons: [
                        {
                            id: 1,
                            name: 'Soh Tang',
                            specializations: {
                                mapping_Person_NationalIdentifier: [
                                    {
                                        nationalIdentifier: 'S1191122A',
                                    },
                                ],
                            },
                        },
                    ],
                    objects: [
                        {
                            id: 1,
                            specializations: {
                                mapping_Object_Motor_VehicleRegistration: [
                                    {
                                        number: 'ABC3210C',
                                    },
                                ],
                            },
                        },
                    ],
                    officerInCharge: 'Thibault H',
                },
            },
        }),
    },
    // Description 1
    {
        request: {
            query: buildQuery(
                config.descriptions.context,
                config.descriptions.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    specializations: {
                        id: 1,
                        mapping_Claim_Cause: {
                            detailedCircumstances: `
                                On 15/7/2018 at around 1950 hrs, I stopped my vehicle at the
                                traffic light along Geylang Road, near to Lorong 16a Geylang
                                as the traffic was red, I was rear ended by a black BMW, plate
                                number: XXXXX. I am lodging this report for insurance claim.
                            `,
                        },
                    },
                },
            },
        }),
    },
    // Description 2
    {
        request: {
            query: buildQuery(
                config.descriptions.context,
                config.descriptions.fields,
            ),
            variables: {
                id: 2,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 2,
                    specializations: {
                        id: 2,
                        mapping_Claim_Cause: {
                            detailedCircumstances: `
                                After traffic junction, the accident took place. I did not
                                realised that car in front had stopped. I manage to brake but
                                could not stop on time and collided onto the rear of the car.
                                Damages to my car were on the front portion. No injuries
                                were involved.
                            `,
                        },
                    },
                },
            },
        }),
    },
    // Address 1
    {
        request: {
            query: buildQuery(
                config.addresses.context,
                config.addresses.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    addresses: [{
                        id: 1,
                        fullAddress: '1 Raffles Blvd, Singapore 039593',
                    }],
                },
            },
        }),
    },
    // Address 2
    {
        request: {
            query: buildQuery(
                config.addresses.context,
                config.addresses.fields,
            ),
            variables: {
                id: 2,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    addresses: [{
                        id: 2,
                        fullAddress: '3 Raffles Blvd, Singapore 039593',
                    }],
                },
            },
        }),
    },
    // Location 1
    {
        request: {
            query: buildQuery(
                config.accidentLocation.context,
                config.accidentLocation.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    addresses: [
                        {
                            id: 1,
                            latitude: 1.292626,
                            longitude: 103.856797,
                            fullAddress: 'Outram Road',
                        },
                    ],
                },
            },
        }),
    },
    // Location 2
    {
        request: {
            query: buildQuery(
                config.accidentLocation.context,
                config.accidentLocation.fields,
            ),
            variables: {
                id: 2,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 2,
                    addresses: [
                        {
                            id: 2,
                            latitude: 1.290626,
                            longitude: 103.856797,
                            fullAddress: 'Beach Road',
                        },
                    ],
                },
            },
        }),
    },
    // Accident reports
    {
        request: {
            query: buildQuery(
                config.accidentReports.context,
                config.accidentReports.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                recoveryCase: {
                    id: 1,
                    accidentReports: [
                        {
                            id: 1,
                            referenceCode: 'G1677325',
                            plateNumber: 'SAK1110C',
                            owner: 'IP',
                        },
                        {
                            id: 2,
                            referenceCode: 'KL865179',
                            plateNumber: 'SBZ2228E',
                            owner: 'RP',
                        },
                        {
                            id: 3,
                            referenceCode: 'G0242312',
                            plateNumber: 'SL8434328E',
                            owner: 'TP match',
                        },
                    ],
                },
            },
        }),
    },
    {
        request: {
            query: buildQuery(
                config.comments.context,
                config.comments.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                recovery: {
                    comments: [
                        { content: 'First message' },
                        { content: 'Second message' },
                    ],
                },
            },
        }),
    },
];
