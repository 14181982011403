import React from 'react';

import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
    Tooltip, TooltipSection, TooltipProps, StyledText, Icon,
} from '@shift/design-system';
import { CogSvg, SignOutSvg } from '@shift/icons';

import { useSecurity } from 'src/libs/auth';
import { translate } from 'src/utils/forms';
import { colors } from 'src/constants';

export type UserTooltipProps = Pick<TooltipProps, 'anchorRef' | 'onClickOutside'>

const UserTooltipWrapper = styled(Tooltip)`
    width: 250px; 
`;

const UserOption = styled(StyledText)<{noMarginBottom?: boolean}>`
    display: flex;
    align-items: center;
    cursor: pointer;

    & > span:last-child {
        border-bottom: 1px dashed transparent;
        transition-property: border-bottom;
        transition-duration: .1s;
        transition-timing-function: ease-in-out;
    }

    &:hover {
        & > span:last-child {
            border-bottom: 1px dashed ${colors.border?.default};
        }
    }

    & > span:first-child {
        margin-right: 15px;
    }

    ${({ noMarginBottom }) => !noMarginBottom && css`
        margin-bottom: 15px;
    `}
`;

export const UserTooltip = ({
    anchorRef,
    onClickOutside,
}: UserTooltipProps) => {
    const { user, logout } = useSecurity('AUTHENTICATED');
    const history = useHistory();

    return (
        <UserTooltipWrapper
            moveCrossAxisWhenCropped
            reflectMainAxisWhenCropped
            orientation="bottom"
            anchorRef={anchorRef}
            onClickOutside={onClickOutside}
        >
            <TooltipSection>
                <StyledText weight="semiBold" wrap="nowrap">{user?.name}</StyledText>
                <StyledText color={colors.text?.secondary} wrap="nowrap">{user?.email}</StyledText>
            </TooltipSection>
            <TooltipSection noBottomBorder>
                <UserOption weight="semiBold" type="div" onClick={() => history.push('/account')}>
                    <Icon width={1.5} height={1.5}><CogSvg /></Icon>
                    <span>{translate('pages:account')}</span>
                </UserOption>
                <UserOption weight="semiBold" noMarginBottom type="div" onClick={logout}>
                    <Icon width={1.5} height={1.5}><SignOutSvg /></Icon>
                    <span>{translate('common:logout')}</span>
                </UserOption>
            </TooltipSection>
        </UserTooltipWrapper>
    );
};
