import { ExplorationConfig } from '../../types/ExplorationConfig';
import { mergedHistories } from './exploration/historiesTable';
import { requestStatusFromInsurer } from './exploration/requestStatusFromInsurer';
import { personInformation } from './exploration/person';
import { vehicleInformation } from './exploration/vehicle';
import { expandableRowSections } from './exploration/expandableRowSections';


export const explorationConfig: ExplorationConfig = {
    // Cards
    ...personInformation,
    ...vehicleInformation,
    // Email info
    requestStatusFromInsurer,
    // Exploration Histories Table
    mergedHistories,
    ...expandableRowSections,
};
