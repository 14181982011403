import React from 'react';
import { PhoneInput } from '@shift/design-system';
import { formSections } from 'src/config/form';
import {
    FormField, ArrayItemProps,
} from 'src/components/form';
import { sgCountryCode } from 'src/constants';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const WitnessItem = ({ setItemPath }: ArrayItemProps) => {
    const { item: itemInformation } = formSections.witnessSection.fields.witnesses;
    return (
        <>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.name)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.phone)}
                        component={PhoneInput}
                        defaultValue={sgCountryCode}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.email)}
                        component={TextInput}
                    />
                </div>
            </div>
        </>
    );
};
