import { useLayoutEffect, useState } from 'react';
import { runtimeConfig, RuntimeConfig } from 'src/config/runtime-config';

export interface OctopusConfig {
    deploymentDate: RuntimeConfig['deploymentDate'];
    projectName: RuntimeConfig['projectName'];
    releaseVersion: RuntimeConfig['releaseVersion'];
}

export const useOctopusConfig = () => {
    const [octopusConfig, setOctopusConfig] = useState<OctopusConfig>();

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            const { releaseVersion, projectName, deploymentDate } = runtimeConfig();
            setOctopusConfig({ releaseVersion, projectName, deploymentDate });
        }
    }, []);

    return octopusConfig;
};
