/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
    FileInfo, SelectInputOption, FilesUploadWithPreview, FilesUploadWithType as BaseFilesUploadWithType,

    FileFormats, VariantProvider, StyledSubHeader, withCustomStyles,
} from '@shift/design-system';

import { useDebouncedCallback } from 'use-debounce';

import { uploadFile } from 'src/utils/documents/upload';
import { useTranslations, translate } from '@gears/translations';

import { DocumentCategory } from 'src/types';
import { useFetch } from 'src/libs/fetch';
import { noop, getFileExtension } from 'src/utils';
import { useSecurity } from 'src/libs/auth';
import { SecondaryTextButton } from '../../button';

export interface OnChangeFileValue {
    id: number;
    name: string;
    progress?: number;
    size: number;
    src: string;
    errorMessage?: string;
}
const CustomSecondaryTextButton = withCustomStyles(SecondaryTextButton);

export interface DownloadButtonProps {
    visible?: boolean;
    onClick?: () => void;
}
export const DownloadButton = ({
    onClick,
    visible,
}: DownloadButtonProps) => (visible
    ? (
        <CustomSecondaryTextButton
            style={{
                width: '100%',
                height: '100%',
                marginLeft: '8px',
            }}
            onClick={onClick || noop}
        >
            {translate('document:download-all')}
        </CustomSecondaryTextButton>
    )
    : null);

// TODO: Move to DS
const FilesUploadWrapper = ({
    acceptedFormats,
    maxFileSize,
    children,
}: FilesUploadWrapper) => (
    <>
        {children}
        {acceptedFormats && (
            <StyledSubHeader type="s3">
                {translate('document:supported-formats')}
                {': '}
                {acceptedFormats.join(', ')}
            </StyledSubHeader>
        )}
        {maxFileSize && (
            <StyledSubHeader type="s3">
                {translate('document:document-max-size')}
                {': '}
                {maxFileSize}
                {translate('units:MB')}
            </StyledSubHeader>
        )}
    </>
);


const getImage = (dataUrl: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const image = new window.Image();
    image.src = dataUrl;
    image.onload = () => {
        resolve(image);
    };
    image.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        reject(error);
    };
});

// https://stackoverflow.com/a/51550052
const downscaleImage = (
    dataUrl: string,
    imageType: string, // e.g. 'image/jpeg'
    resolution: number, // max width/height in pixels
    quality: number, // e.g. 0.9 = 90% quality
    cb: (dowscaledImage: string) => void,
) => {
    // Create a temporary image so that we can compute the height of the image.
    getImage(dataUrl).then((image) => {
        const oldWidth = image.naturalWidth;
        const oldHeight = image.naturalHeight;

        const longestDimension = oldWidth > oldHeight ? 'width' : 'height';
        const currentRes = longestDimension === 'width' ? oldWidth : oldHeight;
        if (currentRes > resolution) {
            // Calculate new dimensions
            const newSize = longestDimension === 'width'
                ? Math.floor((oldHeight / oldWidth) * resolution)
                : Math.floor((oldWidth / oldHeight) * resolution);
            const newWidth = longestDimension === 'width' ? resolution : newSize;
            const newHeight = longestDimension === 'height' ? resolution : newSize;

            // Create a temporary canvas to draw the downscaled image on.
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the downscaled image on the canvas and return the new data URL.
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(image, 0, 0, newWidth, newHeight);
            const newDataUrl = canvas.toDataURL(imageType, quality);
            cb(newDataUrl);
            return;
        }

        cb(dataUrl);
    });
};

export interface FilesUploadProps {
    value: FileInfo[];
    onChange: (values: OnChangeFileValue[]) => any;
    isMulti: boolean;
    addText: string;
    direction?: 'column' | 'row';
    hasIcon?: boolean;
    types?: SelectInputOption[];
    onFocus: () => void;
    onBlur: () => void;
    width?: string;
    /** maxFileSize in MB */
    maxFileSize?: number;
    acceptedFormats?: FileFormats[];
    documentCategory?: DocumentCategory;
    documentType?: DocumentType;
    hasDownloadAllButton?: boolean;
    onDownloadAll?: () => void;
}

const handleAdd = (
    newFiles: FileList,
    files: FileInfo[],
    isMulti: boolean,
    maxFileSize: number,
    cbs: {
        onChange: (list: any) => any;
        updateLabel: (value: React.SetStateAction<boolean>) => void;
    },
    token: () => Promise<string | null>,
    documentCategory?: DocumentCategory,
) => {
    const { onChange, updateLabel } = cbs;

    // Do not remove spread as we don't want to change initial reference
    let newList = isMulti && files ? [...files] : [];
    const updateItem = (id: number, field: string, val: any) => {
        newList = newList.map((item) => (item.id === id ? { ...item, [field]: val } : item));
        onChange(newList);
    };

    const onFinishUpload = (file: File, tempId: number, idString: string) => {
        updateItem(tempId, 'progress', undefined);
        if (idString) {
            const id = parseInt(idString, 10);
            updateItem(tempId, 'id', id);
        }
        onChange(newList);
    };

    const onFail = (tempId: number, errorMessage: string) => {
        updateItem(tempId, 'progress', undefined);
        updateItem(tempId, 'errorMessage', errorMessage);
        onChange(newList);
    };

    // Get the smaller id (used to generate a temporary unique id for each new file)
    let currentId = Math.min(...newList.map((file) => file.id));
    if (currentId > 0) currentId = 0;

    for (let i = 0; i < (isMulti ? newFiles.length : 1); i += 1) {
        const file = newFiles[i];
        currentId -= 1;
        const tempId = currentId;
        newList.push({
            id: tempId,
            name: file.name,
            size: file.size,
        });

        if (file.type.startsWith('image/')) {
            const fr = new window.FileReader();
            fr.onload = () => {
                updateItem(tempId, 'src', fr.result);
                downscaleImage(`${fr.result}`, file.type, 500, 0.7, (downscaledImage) => { updateItem(tempId, 'thumbnailSrc', downscaledImage); });
            };
            fr.readAsDataURL(file);
        }

        if (file.size / 1024 ** 2 < maxFileSize) {
            uploadFile(
                file,
                (progress) => updateItem(tempId, 'progress', progress),
                (idString: string) => onFinishUpload(file, tempId, idString),
                (errorMessage: string) => onFail(tempId, errorMessage),
                documentCategory,
                token,
            );
        } else {
            updateItem(tempId, 'errorMessage', `${translate('document:file-exceeds')} ${maxFileSize}${translate('units:MB')}`);
            newList.filter(({ id }) => id !== tempId);
            onChange(newList);
        }
    }
    updateLabel(!!newFiles.length && !isMulti);
};

const isInProgress = (list: FileInfo[]) => list.every(({ progress }) => progress !== undefined);

// This is not passed inline in order to avoid re-renders
const BUTTON_VARIANTS = ['secondary', 'large'];

export const FilesUpload = ({
    addText,
    value,
    isMulti = false,
    onChange,
    direction = 'column',
    hasIcon = true,
    maxFileSize = 15,
    acceptedFormats,
    onFocus,
    onBlur,
    width = '50%',
    documentCategory,
    hasDownloadAllButton,
    onDownloadAll,
    ...props
}: FilesUploadProps) => {
    const commonTranslate = useTranslations('common');
    const [useReplaceLabel, setUseReplaceLabel] = useState(false);
    const { token } = useSecurity();
    // Do not set thumbnails intially to avoid having the ds load them (unauthenticated)
    const [previews, updatePreviews] = useState<FileInfo[]>((value?.map ? value?.map((v) => ({ ...v, thumbnailSrc: undefined })) : null) ?? []);
    const [updateErrorsDisplay] = useDebouncedCallback((list: FileInfo[]) => {
        if (!isInProgress(list)) {
            onFocus();
            onBlur();
        }
    }, 200);

    const onChangeCallback = useCallback((list: any) => {
        onChange && onChange(list);
        updateErrorsDisplay(list);
    }, [onChange, updateErrorsDisplay]);

    const handleRemove = (id: number) => {
        const newValue = value.filter((item) => item.id !== id);
        onChangeCallback(newValue);
        setUseReplaceLabel(false);
    };

    const onAddCb = useCallback((newFiles: FileList) => handleAdd(
        newFiles,
        value,
        isMulti,
        maxFileSize,
        {
            onChange: onChangeCallback,
            updateLabel: setUseReplaceLabel,
        },
        token,
        documentCategory,
    ), [onChangeCallback, setUseReplaceLabel, value, isMulti, maxFileSize, token, documentCategory]);

    const { imgPreview, download: dl } = useFetch();
    useEffect(() => {
        let array = value ?? [];
        array = Array.isArray(array) ? array : [array];
        Promise.all(array.map(async (preview): Promise<FileInfo | null> => {
            if (!preview) {
                return null;
            }

            if (preview?.src && preview.src.startsWith('data:')) {
                return preview;
            }

            const result = preview.thumbnailSrc
                && await imgPreview(preview.thumbnailSrc, getFileExtension(preview.name));
            // check type img
            return { ...preview, thumbnailSrc: result };
        }))
            .then((prevs) => prevs.filter((preview): preview is FileInfo => !!preview))
            .then((prevs) => updatePreviews(prevs));
    }, [value, imgPreview]);

    return (
        <div
            style={{ width }}
            // TODO figure out why these two causes the delete button (little trash can icons) to be clickable on the second/third try
            // The first (maybe even the first two) click are ignore like it allow the button the gain focus and then the click works
            // and performs as intended
            // onFocus={onFocus}
            // onBlur={onBlur}
        >
            <FilesUploadWrapper
                acceptedFormats={acceptedFormats}
                maxFileSize={maxFileSize}
            >
                <FilesUploadWithPreview
                    uploadButtonHasIcon={hasIcon}
                    direction={direction}
                    uploadButtonText={`${useReplaceLabel ? commonTranslate('Replace') : commonTranslate('Upload')} ${addText}`}
                    files={previews}
                    onAdd={onAddCb}
                    onRemove={handleRemove}
                    onClickOnFile={(url, filename) => url && filename && dl(url, filename)}
                    acceptedFormats={acceptedFormats}
                    uploadButtonVariants={BUTTON_VARIANTS}
                    secondaryButton={hasDownloadAllButton
                        ? (
                            <DownloadButton
                                visible={value && value.length > 0}
                                onClick={onDownloadAll}
                            />
                        ) : null}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            </FilesUploadWrapper>

        </div>
    );
};

export const FilesUploadWithType = ({
    addText,
    value,
    types,
    isMulti = false,
    onChange,
    hasIcon = true,
    maxFileSize = 15,
    acceptedFormats,
    onFocus,
    onBlur,
    documentCategory,
    hasDownloadAllButton,
    onDownloadAll,
    ...props
}: FilesUploadProps) => {
    const [updateErrorsDisplay] = useDebouncedCallback((list: FileInfo[]) => {
        if (!isInProgress(list)) {
            onFocus();
            onBlur();
        }
    }, 200);

    const commonTranslate = useTranslations('common');
    const onChangeCallback = useCallback((list) => {
        onChange && onChange(list);
        updateErrorsDisplay(list);
    }, [onChange, updateErrorsDisplay]);

    const [useReplaceLabel, setUseReplaceLabel] = useState(false);
    const { download: dl } = useFetch();
    const { token } = useSecurity();

    const handleRemove = (id: number) => {
        onChangeCallback(value.filter((item) => item.id !== id));
        setUseReplaceLabel(false);
    };

    const onAddCb = useCallback((newFiles: FileList) => handleAdd(
        newFiles,
        value,
        isMulti,
        maxFileSize,
        {
            onChange: onChangeCallback,
            updateLabel: setUseReplaceLabel,
        },
        token,
        documentCategory,
    ), [onChangeCallback, setUseReplaceLabel, value, isMulti, maxFileSize, token, documentCategory]);

    return (
        <FilesUploadWrapper
            acceptedFormats={acceptedFormats}
            maxFileSize={maxFileSize}
        >
            <VariantProvider component="button" name="secondary">
                <BaseFilesUploadWithType
                    uploadButtonHasIcon={hasIcon}
                    uploadButtonText={`${useReplaceLabel ? commonTranslate('Replace') : commonTranslate('Upload')} ${addText}`}
                    files={value ?? []}
                    onAdd={onAddCb}
                    types={types || []}
                    onTypeChanged={(id, type) => {
                        const newList = value.map((item) => (item.id === id ? { ...item, type } : item));
                        onChangeCallback(newList);
                    }}
                    i18n={
                        {
                            documentType: commonTranslate('document-type'),
                            filename: commonTranslate('filename'),
                        }
                    }
                    onRemove={handleRemove}
                    onClickOnFile={(url, filename) => url && filename && dl(url, filename)}
                    acceptedFormats={acceptedFormats}
                    secondaryButton={hasDownloadAllButton
                        ? (
                            <DownloadButton
                                visible={value && value.length > 0}
                                onClick={onDownloadAll}
                            />
                        ) : null}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            </VariantProvider>
        </FilesUploadWrapper>
    );
};
interface FilesUploadWrapper {
    children: React.ReactNode;
    acceptedFormats?: FileFormats[];
    maxFileSize: number;
}
