import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from '../../../libs/filters';
import { EnumFilter, EnumFilterProps } from '../../../components/Table/Filters';

export const EnumFilterWidgetterFactory:
    (enumName: string, side?: EnumFilterProps['side']) => Widgetter<FilterProps, FilterProps, Filter> = (enumName, side) => ({
        transform: ({ children }, options) => (options ? (
            <EnumFilter
                side={side}
                field={options.field}
                operation={options.operation}
                enumName={enumName}
            >
                {children}
            </EnumFilter>
        ) : children as ReactElement),
    });
