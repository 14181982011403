import React from 'react';
import { Link } from 'react-router-dom';
import { StyledText } from '@shift/design-system';
import { Widgetter } from '@shift/transform';

const LinksWidget = ({ children }: LinksWidgetterProps) => (
    <>
        {children.map(({ content, link }, idx) => (
            <StyledText
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
            >
                {link ? <Link to={link}>{content}</Link> : content}
            </StyledText>
        ))}
    </>
);

export interface LinksWidgetterProps {
    children: { content: string; link: string | null }[];
}
export const LinksWidgetter: Widgetter<LinksWidgetterProps> = {
    // eslint-disable-next-line react/jsx-props-no-spreading
    transform: (props) => <LinksWidget {...props} />,
};
