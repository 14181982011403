import React, { useEffect, useState } from 'react';
import { Moment, utc } from 'moment-timezone';

import { FilterableHeader } from '@shift/design-system';
import {
    useFieldSort, useFieldFilter, Operation,
    mapOrder, unmapOrder, Field, revertOrder,
} from 'src/libs/filters';
import { translate } from 'src/utils/forms';
import { DateUtcFormat, DefaultTZNow } from 'src/constants';
import { parseUtcDate } from 'src/utils';
import { IntegerInputFilter } from './IntegerInputFilter';


export const DaysSinceFilter = ({
    children, field, operation,
}: { field: Field; operation: Operation; children: React.ReactNode }) => {
    const [sort, setSort] = useFieldSort(field);
    const [filter, setFilter] = useFieldFilter(field);
    const [now, setNow] = useState(DefaultTZNow);

    const [pendingFilter, setPendingFilter] = useState(filter);

    const format = (date: Moment) => {
        if (!date) {
            return '';
        }
        return utc(date).format(DateUtcFormat);
    };

    useEffect(() => {
        setPendingFilter(filter);
    }, [filter]);


    const pendingMinValue = pendingFilter?.parameters[1].value
        ? now.diff(parseUtcDate(pendingFilter?.parameters[1].value), 'days') + 1 : null;
    const pendingMaxValue = pendingFilter?.parameters[0].value
        ? now.diff(parseUtcDate(pendingFilter?.parameters[0].value), 'days') : null;
    const minValue = filter?.parameters[1].value
        ? now.diff(parseUtcDate(filter?.parameters[1].value), 'days') + 1 : null;
    const maxValue = filter?.parameters[0].value
        ? now.diff(parseUtcDate(filter?.parameters[0].value), 'days') : null;

    return (
        <FilterableHeader
            activeFilter={Boolean(minValue) || Boolean(maxValue)}
            filter={{ minValue: pendingMinValue, maxValue: pendingMaxValue }}
            onFilterChange={({ minValue: min, maxValue: max }) => {
                const instant = DefaultTZNow;
                setNow(instant);
                setPendingFilter({
                    ...operation,
                    parameters: [
                        {
                            ...operation.parameters[0],
                            value: max
                                ? format(instant.clone().subtract(max, 'days').subtract(1, 'seconds'))
                                : operation?.parameters[0].value,
                        },
                        {
                            ...operation.parameters[1],
                            value: min
                                ? format(instant.clone().subtract(min - 1, 'days').subtract(1, 'seconds'))
                                : operation?.parameters[1].value,
                        },
                    ],
                });
            }}
            onSubmit={() => setFilter(pendingFilter)}
            onClear={() => { setFilter(null); setPendingFilter(null); setSort(null); }}
            FilterComponent={IntegerInputFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            sortAscText={translate('common:sort-number-asc')}
            sortDescText={translate('common:sort-number-desc')}
            order={revertOrder(mapOrder(sort))}
            onOrderChange={(o) => setSort(unmapOrder(revertOrder(o)))}
        >
            {children}
        </FilterableHeader>
    );
};
