import React from 'react';
import {
    StyledLinkProps, GridItem, Grid, Icon,
} from '@shift/design-system';
import { FileInvoiceDollarSvg } from '@shift/icons';
import { useHistory, Link } from 'react-router-dom';
import { BillableActionProps, BillableAction } from './BillableAction';

export type BillableSubmitLinkActionProps = Omit<BillableActionProps, 'children'>
    & Omit<StyledLinkProps, 'onClick'>
    & {
        href: string;
        style?: React.CSSProperties;
    };


export const BillableLinkAction = (
    {
        href,
        action,
        children,
        request,
        paidAccess,
        i18nModal,
        ...otherProps
    }: BillableSubmitLinkActionProps,
) => {
    const history = useHistory();
    return (
        <BillableAction
            action={action}
            paidAccess={paidAccess}
            request={request}
            i18nModal={i18nModal}
        >
            <Link
                onClick={() => {
                    history.push(href);
                }}
                // ability to open in a new tab
                to={href}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...otherProps}
            >
                <Grid
                    alignPlacement="center"
                >
                    {paidAccess && (
                        <GridItem
                            style={{
                                paddingRight: '2px',
                            }}
                        >
                            <Icon
                                width={1}
                                height={1}
                            >
                                <FileInvoiceDollarSvg />
                            </Icon>
                        </GridItem>
                    )}
                    <GridItem>
                        {children}
                    </GridItem>
                </Grid>
            </Link>

        </BillableAction>
    );
};
