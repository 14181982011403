import { Formatter } from '@shift/transform';

const filterPassengers = (passengers: any[]) => {
    const filtered = passengers.filter((r) => r.links.mapping_ClaimPerson.find((cp: any) => cp.specializations.mapping_ClaimPerson_Function.find(
        (rr: any) => rr.enumIdentifier === 'MotorClaimPersonFunction' && rr.enumValueId === 4,
    )));

    return filtered;
};

export const PassengerFormatter: Formatter<any[], { children: string[] } | undefined> = {
    transform([raw]) {
        const passengers = filterPassengers(raw);
        const passengerNames = passengers.map((passenger) => passenger.specializations.mapping_Person_Gears.name);
        let result;
        if (passengerNames.length !== 0) {
            result = {
                children: passengerNames,
            };
        } else {
            result = undefined;
        }
        return result;
    },
};

export const GenderFormatter: Formatter<any[], { children: string[] } | undefined> = {
    transform([raw]) {
        const passengers = filterPassengers(raw);
        const passengerGenders = passengers.map((passenger) => passenger.specializations.mapping_Person_Individual.gender?.Name);
        let result;
        if (passengerGenders.length !== 0) {
            result = {
                children: passengerGenders,
            };
        } else {
            result = undefined;
        }
        return result;
    },
};
