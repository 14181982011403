import { Formatter } from '@shift/transform';
import { EnumValue } from 'src/types';
import { translate } from '../../utils/forms';

export const EnumListValuesCommaFormatter: Formatter<EnumValue[], { children: any }> = {
    transform: (enums) => ({
        children: enums.flat().map((e) => translate(e?.Type && e?.Name ? `enums:${e?.Type}.${e?.Name}` : '', { defaultValue: e?.Name ?? '' }))
            .join(',\n'),
    }),
};
