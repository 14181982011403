export const vehicleModels: Record<string, string> = {
    // "Gears value": "MyInfo value"
    Adiva_Ad1: 'Ad1',
    Adiva_Ad200: 'Ad200',
    Adiva_Ad3: 'Ad3',
    Adiva_Ar3: 'Ar3',
    AlfaRomeo_147: '147',
    AlfaRomeo_156: '156',
    AlfaRomeo_159: '159',
    AlfaRomeo_166: '166',
    AlfaRomeo_170: '170',
    AlfaRomeo_1750: '1750',
    AlfaRomeo_4c: '4c',
    AlfaRomeo_A147: 'A147',
    AlfaRomeo_A156: 'A156',
    AlfaRomeo_A159: 'A159',
    AlfaRomeo_Brera: 'Brera',
    AlfaRomeo_Giulietta: 'Giulietta',
    AlfaRomeo_Gt: 'Gt',
    AlfaRomeo_Gtv: 'Gtv',
    AlfaRomeo_Mito: 'Mito',
    AlfaRomeo_Spider: 'Spider',
    Aprilia_Atlantic: 'Atlantic',
    Aprilia_Caponord: 'Caponord',
    Aprilia_Dorsoduro: 'Dorsoduro',
    Aprilia_Mojito: 'Mojito',
    Aprilia_Tuono: 'Tuono',
    AstonMartin_Db7: 'Db7',
    AstonMartin_Db9: 'Db9',
    AstonMartin_Dbs: 'Dbs',
    AstonMartin_Rapide: 'Rapide',
    AstonMartin_V8: 'V8',
    AstonMartin_Valkyrie: 'Valkyrie',
    AstonMartin_Vanquish: 'Vanquish',
    Audi_A1: 'A1',
    Audi_A3: 'A3',
    Audi_A4: 'A4',
    Audi_A5: 'A5',
    Audi_A6: 'A6',
    Audi_A7: 'A7',
    Audi_A8: 'A8',
    Audi_A8l: 'A8l',
    Audi_Allroad: 'Allroad',
    Audi_Q2: 'Q2',
    Audi_Q3: 'Q3',
    Audi_Q5: 'Q5',
    Audi_Q7: 'Q7',
    Audi_Q8: 'Q8',
    Audi_Quat: 'Quat',
    Audi_R8: 'R8',
    Audi_Rs3: 'RS 3',
    Audi_Rs4: 'RS 4',
    Audi_Rs5: 'RS 5',
    Audi_Rs6: 'RS 6',
    Audi_S3: 'S3',
    Audi_S4: 'S4',
    Audi_S5: 'S5',
    Audi_S6: 'S6',
    Audi_S7: 'S7',
    Audi_S8: 'S8',
    Audi_Tt: 'TT',
    Audi_Ttc: 'Ttc',
    Audi_Tts: 'TTS',
    Austin_A40: 'A40',
    Bajaj_Avenger: 'Avenger',
    Bajaj_Ct100: 'Ct100',
    Bajaj_Pulsar: 'Pulsar',
    Benelli_Adiva: 'Adiva',
    Bentley_Arnage: 'Arnage',
    Bentley_Azure: 'Azure',
    Bentley_Continental: 'Continental',
    'Bentley_Flying spur': 'Flying spur',
    Bentley_Mulsanne: 'Mulsanne',
    Bmw_116d: '116d',
    Bmw_116i: '116i',
    Bmw_118: '118',
    Bmw_118i: '118i',
    Bmw_120i: '120i',
    Bmw_125i: '125i',
    Bmw_130i: '130i',
    Bmw_135i: '135i',
    Bmw_216d: '216d',
    Bmw_216i: '216i',
    Bmw_218d: '218d',
    Bmw_218i: '218i',
    Bmw_220i: '220i',
    Bmw_225xe: '225xe',
    Bmw_228i: '228i',
    Bmw_230i: '230i',
    'Bmw_315cdi/4325': '315cdi/4325',
    'Bmw_316cdi/3665': '316cdi/3665',
    Bmw_316i: '316i',
    Bmw_318: '318',
    Bmw_318ci: '318ci',
    Bmw_318i: '318i',
    Bmw_320ci: '320ci',
    Bmw_320d: '320d',
    Bmw_320i: '320i',
    Bmw_320im: '320im',
    Bmw_323i: '323i',
    Bmw_323ia: '323ia',
    Bmw_325ci: '325ci',
    Bmw_325i: '325i',
    Bmw_328i: '328i',
    Bmw_330ci: '330ci',
    Bmw_330i: '330i',
    Bmw_335i: '335i',
    Bmw_340i: '340i',
    Bmw_420i: '420i',
    Bmw_428i: '428i',
    Bmw_430i: '430i',
    Bmw_435i: '435i',
    Bmw_440i: '440i',
    Bmw_520d: '520d',
    Bmw_520i: '520i',
    Bmw_523i: '523i',
    Bmw_525i: '525i',
    Bmw_528i: '528i',
    Bmw_530i: '530i',
    Bmw_535d: '535d',
    Bmw_535i: '535i',
    Bmw_540i: '540i',
    Bmw_550i: '550i',
    Bmw_630ci: '630ci',
    Bmw_630i: '630i',
    Bmw_640i: '640i',
    Bmw_645: '645',
    Bmw_645ci: '645ci',
    Bmw_650ci: '650ci',
    Bmw_650i: '650i',
    Bmw_725d: '725d',
    Bmw_728i: '728i',
    Bmw_730: '730',
    Bmw_730i: '730i',
    Bmw_730li: '730li',
    Bmw_735i: '735i',
    Bmw_735li: '735li',
    Bmw_740: '740',
    Bmw_740i: '740i',
    Bmw_740le: '740le',
    Bmw_740li: '740li',
    Bmw_745i: '745i',
    Bmw_745li: '745li',
    Bmw_750i: '750i',
    Bmw_750li: '750li',
    Bmw_760li: '760li',
    Bmw_840i: '840i',
    Bmw_Activehybrid: 'Activehybrid',
    'Bmw_Alpina b3': 'Alpina b3',
    'Bmw_Alpina b4': 'Alpina b4',
    'Bmw_Alpina d5': 'Alpina d5',
    Bmw_C650gt: 'C650gt',
    Bmw_F650gs: 'F650gs',
    Bmw_F800gs: 'F800gs',
    Bmw_G310: 'G310',
    Bmw_I3: 'I3',
    Bmw_I8: 'I8',
    Bmw_M135i: 'M135i',
    Bmw_M140i: 'M140i',
    Bmw_M2: 'M2',
    Bmw_M235i: 'M235i',
    Bmw_M240i: 'M240i',
    Bmw_M3: 'M3',
    Bmw_M4: 'M4',
    Bmw_M5: 'M5',
    Bmw_M6: 'M6',
    Bmw_X1: 'X1',
    Bmw_X2: 'X2',
    Bmw_X3: 'X3',
    Bmw_X4: 'X4',
    Bmw_X5: 'X5',
    Bmw_X6: 'X6',
    Bmw_X7: 'X7',
    Bmw_Z3: 'Z3',
    Bmw_Z4: 'Z4',
    Brixton_Bx125: 'Bx125',
    'Bugati_Eb 110': 'Eb 110',
    Bugati_Veyron: 'Veyron',
    Byd_E6h: 'E6h',
    Byd_E6y: 'E6y',
    CanAm_Spyder: 'Spyder',
    Chana_Benni: 'Benni',
    Chery_A168: 'A168',
    Chery_Oriental: 'Oriental',
    Chery_Qq: 'Qq',
    Chery_T11: 'T11',
    Chery_V5: 'V5',
    Chevrolet_Aveo: 'Aveo',
    Chevrolet_Aveo5: 'Aveo5',
    Chevrolet_Captiva: 'Captiva',
    Chevrolet_Cruze: 'Cruze',
    Chevrolet_Epica: 'Epica',
    Chevrolet_Lumina: 'Lumina',
    Chevrolet_Malibu: 'Malibu',
    Chevrolet_Mw: 'Mw',
    Chevrolet_Optra: 'Optra',
    Chevrolet_Optra5: 'Optra5',
    Chevrolet_Orlando: 'Orlando',
    Chevrolet_Sonic: 'Sonic',
    Chevrolet_Spark: 'Spark',
    Chevrolet_Vivant: 'Vivant',
    Chrysler_300b: '300b',
    Chrysler_300c: '300c',
    Chrysler_Crossfire: 'Crossfire',
    'Chrysler_Grand voyager': 'Grand voyager',
    Chrysler_Pt: 'Pt',
    Chrysler_Sebring: 'Sebring',
    Citroen_Berlingo: 'Berlingo',
    Citroen_C1: 'C1',
    Citroen_C3: 'C3',
    Citroen_C4: 'C4',
    'Citroen_C4 picasso': 'C4 picasso',
    'Citroen_C4 spacetourer': 'C4 spacetourer',
    Citroen_C5: 'C5',
    Citroen_C6: 'C6',
    Citroen_Dispatch: 'Dispatch',
    Citroen_Ds3: 'Ds3',
    Citroen_Ds4: 'Ds4',
    Citroen_Ds5: 'Ds5',
    Citroen_Jumpy: 'Jumpy',
    Citroen_Nemo: 'Nemo',
    'Citroen_Traction avant': 'Traction avant',
    Citroen_Xsara: 'Xsara',
    'Daelim_B-bone': 'B-bone',
    Daelim_Citi: 'Citi',
    Daelim_Daystar: 'Daystar',
    Daelim_Delfino: 'Delfino',
    Daelim_Roadwin: 'Roadwin',
    'Daelim_Roadwin r125': 'Roadwin r125',
    'Daelim_Roadwin r250': 'Roadwin r250',
    'Daelim_Roadwin x5': 'Roadwin x5',
    Daewoo_Kalos: 'Kalos',
    Daewoo_Lanos: 'Lanos',
    Daf_Cf75: 'Cf75',
    Daf_Cf85: 'Cf85',
    Daf_Sb220: 'Sb220',
    Daihatsu_Copen: 'Copen',
    Daihatsu_Extol: 'Extol',
    'Daihatsu_Gran move': 'Gran move',
    Daihatsu_Hijet: 'Hijet',
    Daihatsu_Materia: 'Materia',
    'Daihatsu_Move canbus': 'Move canbus',
    'Daihatsu_S200rv-smlk': 'S200rv-smlk',
    'Daihatsu_S221rv-smlf': 'S221rv-smlf',
    'Daihatsu_S221rv-zmlf': 'S221rv-zmlf',
    Daihatsu_Sirion: 'Sirion',
    Daihatsu_Terios: 'Terios',
    'Daihatsu_V99luc(p': 'V99luc(p',
    Daihatsu_Yrv: 'Yrv',
    Daihatsu_Zebra: 'Zebra',
    Derbi_Senda: 'Senda',
    Dodge_Atitude: 'Atitude',
    Dodge_Caliber: 'Caliber',
    Dodge_Dart: 'Dart',
    Dodge_Journey: 'Journey',
    Dongfeng_Eq5021: 'Eq5021',
    Dongfeng_Eq6380: 'Eq6380',
    Dongfeng_Star: 'Star',
    Ducati_1199: '1199',
    Ducati_848: '848',
    Ducati_916: '916',
    Ducati_996sps: '996sps',
    Ducati_Diavel: 'Diavel',
    Ducati_Monster: 'Monster',
    Ducati_Multistrada: 'Multistrada',
    Dulevo_5000: '5000',
    Ferrari_360: '360',
    Ferrari_430: '430',
    Ferrari_456: '456',
    Ferrari_458: '458',
    Ferrari_575m: '575m',
    Ferrari_599: '599',
    Ferrari_612: '612',
    Ferrari_California: 'California',
    Ferrari_Challenge: 'Challenge',
    'Ferrari_Challenge stradale': 'Challenge stradale',
    Ferrari_Enzo: 'Enzo',
    Ferrari_F12berlinetta: 'F12berlinetta',
    Ferrari_F430: 'F430',
    Ferrari_Ff: 'Ff',
    Fiat_124: '124',
    Fiat_500: '500',
    Fiat_Bravo: 'Bravo',
    Fiat_Cinquecento: 'Cinquecento',
    Fiat_Croma: 'Croma',
    Fiat_Doblo: 'Doblo',
    Fiat_Ducato: 'Ducato',
    Fiat_Fiorino: 'Fiorino',
    Fiat_Idea: 'Idea',
    Fiat_Multipla: 'Multipla',
    Fiat_Panda: 'Panda',
    Fiat_Panorama: 'Panorama',
    Fiat_Punto: 'Punto',
    Fiat_Scudo: 'Scudo',
    Fiat_Stilo: 'Stilo',
    Fiat_Ulysse: 'Ulysse',
    Ford_Anglia: 'Anglia',
    Ford_Econ: 'Econ',
    Ford_Escape: 'Escape',
    Ford_Everest: 'Everest',
    Ford_Fiesta: 'Fiesta',
    Ford_Focus: 'Focus',
    Ford_Galaxie: 'Galaxie',
    Ford_Galaxy: 'Galaxy',
    Ford_Kuga: 'Kuga',
    Ford_Laser: 'Laser',
    Ford_Mondeo: 'Mondeo',
    Ford_Mustang: 'Mustang',
    Ford_Ranger: 'Ranger',
    'Ford_S-max': 'S-max',
    Ford_Spectron: 'Spectron',
    Ford_Transit: 'Transit',
    Foton_Bj1041: 'Bj1041',
    Foton_Bj6103: 'Bj6103',
    Foton_Bj6940: 'Bj6940',
    Geely_Ck: 'Ck',
    Geely_Mk: 'Mk',
    Gilera_Fucco: 'Fucco',
    'Gilera_Nexus 300': 'Nexus 300',
    'Gilera_Nexus 500': 'Nexus 500',
    Gilera_Runner: 'Runner',
    Hafei_Minz: 'Minz',
    HarleyDavidson_Flhr: 'Flhr',
    HarleyDavidson_Flhrc: 'Flhrc',
    HarleyDavidson_Flht: 'Flht',
    HarleyDavidson_Flhtcu: 'Flhtcu',
    HarleyDavidson_Flhx: 'Flhx',
    HarleyDavidson_Flstc: 'Flstc',
    HarleyDavidson_Flstf: 'Flstf',
    HarleyDavidson_Flstn: 'Flstn',
    HarleyDavidson_Flsts: 'Flsts',
    HarleyDavidson_Flstsb: 'Flstsb',
    HarleyDavidson_Fltrx: 'Fltrx',
    HarleyDavidson_Fxcw: 'Fxcw',
    HarleyDavidson_Fxcwc: 'Fxcwc',
    HarleyDavidson_Fxd: 'Fxd',
    HarleyDavidson_Fxdb: 'Fxdb',
    HarleyDavidson_Fxdc: 'Fxdc',
    HarleyDavidson_Fxdf: 'Fxdf',
    HarleyDavidson_Fxdl: 'Fxdl',
    HarleyDavidson_Fxstb: 'Fxstb',
    HarleyDavidson_Fxstc: 'Fxstc',
    HarleyDavidson_Fxstd: 'Fxstd',
    HarleyDavidson_Fxstf: 'Fxstf',
    HarleyDavidson_Street: 'Street',
    'HarleyDavidson_Xl 1000': 'Xl 1000',
    'HarleyDavidson_Xl 1200c': 'Xl 1200c',
    'HarleyDavidson_Xl 1200l': 'Xl 1200l',
    'HarleyDavidson_Xl 1200n': 'Xl 1200n',
    'HarleyDavidson_Xl 1200r': 'Xl 1200r',
    'HarleyDavidson_Xl 125': 'Xl 125',
    'HarleyDavidson_Xl 883': 'Xl 883',
    'HarleyDavidson_Xl 883c': 'Xl 883c',
    'HarleyDavidson_Xl 883l': 'Xl 883l',
    'HarleyDavidson_Xl 883r': 'Xl 883r',
    Hillman_Minx: 'Minx',
    'Hino_700 series': '700 series',
    Hino_Fc3jlka: 'Fc3jlka',
    Hino_Fc4jgpa: 'Fc4jgpa',
    Hino_Fd1jpka: 'Fd1jpka',
    'Hino_Fd7jpma-has': 'Fd7jpma-has',
    Hino_Fd8jlka: 'Fd8jlka',
    Hino_Fd8jpka: 'Fd8jpka',
    Hino_Fd9jpna: 'Fd9jpna',
    Hino_Fg8jr1a: 'Fg8jr1a',
    Hino_Fr1k: 'Fr1k',
    Hino_Fs1elkd: 'Fs1elkd',
    Hino_Fs1etka: 'Fs1etka',
    Hino_Fs1etla: 'Fs1etla',
    Hino_Fs1etma: 'Fs1etma',
    Hino_Fs275ka: 'Fs275ka',
    Hino_Fs3frka: 'Fs3frka',
    Hino_Fy1euka: 'Fy1euka',
    Hino_Gd1jppa: 'Gd1jppa',
    Hino_Gh1jpla: 'Gh1jpla',
    Hino_Gh1jrka: 'Gh1jrka',
    Hino_Gh1jrpa: 'Gh1jrpa',
    Hino_Gh8jrka: 'Gh8jrka',
    'Hino_Gh8jrma-qgs': 'Gh8jrma-qgs',
    Hino_Sh1eeka: 'Sh1eeka',
    'Hino_Sh1eema-kas': 'Sh1eema-kas',
    Hino_Sh1eera: 'Sh1eera',
    Hino_Sh273ka: 'Sh273ka',
    Holden_Cascada: 'Cascada',
    Holden_Colorado: 'Colorado',
    Honda_400x: '400x',
    Honda_Accord: 'Accord',
    Honda_Acty: 'Acty',
    'Honda_Adv 750': 'Adv 750',
    'Honda_Afx-110': 'Afx-110',
    Honda_Airwave: 'Airwave',
    Honda_Ambition: 'Ambition',
    'Honda_Anf 125': 'Anf 125',
    Honda_C100: 'C100',
    Honda_Ca125t: 'Ca125t',
    Honda_Ca125x: 'Ca125x',
    Honda_Cb1000r: 'Cb1000r',
    Honda_Cb125: 'Cb125',
    Honda_Cb1300: 'Cb1300',
    Honda_Cb150r: 'Cb150r',
    Honda_Cb190r: 'Cb190r',
    Honda_Cb190x: 'Cb190x',
    Honda_Cb250p: 'Cb250p',
    Honda_Cb400: 'Cb400',
    Honda_Cb4008j: 'Cb4008j',
    Honda_Cb400a8j: 'Cb400a8j',
    Honda_Cb400asfyj: 'Cb400asfyj',
    Honda_Cb400f: 'Cb400f',
    Honda_Cb400sf: 'Cb400sf',
    Honda_Cb400sf2j: 'Cb400sf2j',
    Honda_Cb400sf4j: 'Cb400sf4j',
    Honda_Cb400sfhv: 'Cb400sfhv',
    Honda_Cb400sfvt: 'Cb400sfvt',
    Honda_Cb400sfxj: 'Cb400sfxj',
    Honda_Cb400sfy: 'Cb400sfy',
    Honda_Cb400sfyj: 'Cb400sfyj',
    Honda_Cb400x: 'Cb400x',
    'Honda_Cb600fa-pc41': 'Cb600fa-pc41',
    Honda_Cb750f21: 'Cb750f21',
    Honda_Cb900f: 'Cb900f',
    Honda_Cbf: 'Cbf',
    Honda_Cbf1000: 'Cbf1000',
    Honda_Cbf125r: 'Cbf125r',
    Honda_Cbf150: 'Cbf150',
    Honda_Cbf190wh: 'Cbf190wh',
    Honda_Cbf600m: 'Cbf600m',
    Honda_Cbf87lhsa: 'Cbf87lhsa',
    Honda_Cbr: 'Cbr',
    Honda_Cbr1000rr: 'Cbr1000rr',
    Honda_Cbr1100xx: 'Cbr1100xx',
    Honda_Cbr125r: 'Cbr125r',
    Honda_Cbr150r: 'Cbr150r',
    Honda_Cbr250r: 'Cbr250r',
    Honda_Cbr400rr: 'Cbr400rr',
    Honda_Cbr400rrr: 'Cbr400rrr',
    Honda_Cbr600f: 'Cbr600f',
    Honda_Cbr600rr: 'Cbr600rr',
    Honda_Cbr900: 'Cbr900',
    Honda_Cbx200: 'Cbx200',
    Honda_Cbx250: 'Cbx250',
    Honda_Cbx400x: 'Cbx400x',
    Honda_Cg125: 'Cg125',
    Honda_City: 'City',
    Honda_Civic: 'Civic',
    'Honda_Civic mugen': 'Civic mugen',
    Honda_Click: 'Click',
    'Honda_Cm 125': 'Cm 125',
    Honda_Cn250: 'Cn250',
    'Honda_Cr-v': 'Cr-v',
    'Honda_Cr-z': 'Cr-z',
    Honda_Crf1000a: 'Crf1000a',
    Honda_Crf250r: 'Crf250r',
    Honda_Crossroad: 'Crossroad',
    Honda_Cs1: 'Cs1',
    Honda_Deauville: 'Deauville',
    'Honda_Dn-01': 'Dn-01',
    Honda_Dream: 'Dream',
    Honda_Edix: 'Edix',
    Honda_Elysion: 'Elysion',
    Honda_Ex5: 'Ex5',
    Honda_Fes250: 'Fes250',
    Honda_Fireblade: 'Fireblade',
    Honda_Fit: 'Fit',
    Honda_Fjs400: 'Fjs400',
    Honda_Fjs600: 'Fjs600',
    Honda_Fmx650: 'Fmx650',
    'Honda_Forza 300': 'Forza 300',
    Honda_Freed: 'Freed',
    Honda_Fs150f: 'Fs150f',
    Honda_Fs150fh3: 'Fs150fh3',
    Honda_Fs150gh3: 'Fs150gh3',
    Honda_Future: 'Future',
    Honda_Gl: 'Gl',
    Honda_Gl156s: 'Gl156s',
    Honda_Gl1800: 'Gl1800',
    Honda_Glh125: 'Glh125',
    Honda_Gls200: 'Gls200',
    Honda_Goldwing: 'Goldwing',
    Honda_Grace: 'Grace',
    'Honda_Hr-v': 'Hr-v',
    Honda_Insight: 'Insight',
    Honda_Inspire: 'Inspire',
    Honda_Integra: 'Integra',
    Honda_Jade: 'Jade',
    Honda_Jazz: 'Jazz',
    Honda_Mobilio: 'Mobilio',
    Honda_Msx125: 'Msx125',
    'Honda_N-box': 'N-box',
    Honda_Nc700sa: 'Nc700sa',
    Honda_Nc700x: 'Nc700x',
    Honda_Nc750d: 'Nc750d',
    Honda_Nc750s: 'Nc750s',
    Honda_Nc750sa: 'Nc750sa',
    Honda_Nc750xa: 'Nc750xa',
    Honda_Nf100: 'Nf100',
    Honda_Nf110m: 'Nf110m',
    Honda_Nf125: 'Nf125',
    Honda_Nf125md: 'Nf125md',
    Honda_Nice: 'Nice',
    Honda_Nighthawk250: 'Nighthawk250',
    Honda_Nsx: 'Nsx',
    Honda_Nv400c: 'Nv400c',
    Honda_Odyssey: 'Odyssey',
    Honda_Pcx125: 'Pcx125',
    Honda_Pcx150a: 'Pcx150a',
    Honda_Prelude: 'Prelude',
    Honda_Ps150i: 'Ps150i',
    Honda_S2000: 'S2000',
    Honda_S660: 'S660',
    Honda_Shadow: 'Shadow',
    Honda_Shuttle: 'Shuttle',
    Honda_Silverwing: 'Silverwing',
    Honda_Sonic: 'Sonic',
    Honda_Steed: 'Steed',
    Honda_Stepwagon: 'Stepwagon',
    Honda_Stream: 'Stream',
    Honda_Tiger: 'Tiger',
    Honda_Varadero: 'Varadero',
    Honda_Vario: 'Vario',
    Honda_Vezel: 'Vezel',
    Honda_Wave: 'Wave',
    'Honda_Xl 400': 'Xl 400',
    Husqvarna_701: '701',
    Hyosung_Comet: 'Comet',
    'Hyosung_Fx-110': 'Fx-110',
    Hyundai_Accent: 'Accent',
    'Hyundai_Ae ioniq': 'Ae ioniq',
    Hyundai_Atos: 'Atos',
    Hyundai_Avante: 'Avante',
    Hyundai_Azera: 'Azera',
    Hyundai_Coupe: 'Coupe',
    Hyundai_Elantra: 'Elantra',
    Hyundai_Eon: 'Eon',
    Hyundai_Genesis: 'Genesis',
    Hyundai_Getz: 'Getz',
    Hyundai_Grandeur: 'Grandeur',
    Hyundai_I30: 'I30',
    Hyundai_I40: 'I40',
    Hyundai_I45: 'I45',
    Hyundai_Ioniq: 'Ioniq',
    Hyundai_Matrix: 'Matrix',
    'Hyundai_Santa fe': 'Santa fe',
    Hyundai_Scoupe: 'Scoupe',
    Hyundai_Sonata: 'Sonata',
    Hyundai_Starex: 'Starex',
    Hyundai_Terracan: 'Terracan',
    Hyundai_Tg: 'Tg',
    Hyundai_Trajet: 'Trajet',
    Hyundai_Tucson: 'Tucson',
    Hyundai_Tuscani: 'Tuscani',
    Hyundai_Veloster: 'Veloster',
    Hyundai_Verna: 'Verna',
    'Indian_Chief darkhorse': 'Chief darkhorse',
    Infiniti_Fx37: 'Fx37',
    Infiniti_Fx50: 'Fx50',
    Infiniti_G37: 'G37',
    Infiniti_M25: 'M25',
    Infiniti_M35h: 'M35h',
    Infiniti_M37: 'M37',
    Infiniti_Q30: 'Q30',
    Infiniti_Q50: 'Q50',
    Infiniti_Q60: 'Q60',
    Infiniti_Q70: 'Q70',
    Isuzu_Cxh51s: 'Cxh51s',
    Isuzu_Cxz50k: 'Cxz50k',
    Isuzu_Cxz50q: 'Cxz50q',
    Isuzu_Cxz71q: 'Cxz71q',
    Isuzu_Cxz81k: 'Cxz81k',
    Isuzu_Cyh52s: 'Cyh52s',
    Isuzu_Cyh52t: 'Cyh52t',
    Isuzu_Cyz52k: 'Cyz52k',
    Isuzu_Cyz52l: 'Cyz52l',
    Isuzu_Cyz52r: 'Cyz52r',
    'Isuzu_D-max': 'D-max',
    Isuzu_Exr50d: 'Exr50d',
    Isuzu_Exr52e: 'Exr52e',
    Isuzu_Exz52k: 'Exz52k',
    Isuzu_Frr33p: 'Frr33p',
    'Isuzu_Frr90suqa-c': 'Frr90suqa-c',
    Isuzu_Fsr: 'Fsr',
    Isuzu_Ftr33f: 'Ftr33f',
    Isuzu_Ftr33p: 'Ftr33p',
    Isuzu_Ftr34p: 'Ftr34p',
    Isuzu_Fvr34p: 'Fvr34p',
    Isuzu_Fvr34suqdc: 'Fvr34suqdc',
    Isuzu_Fxz77m: 'Fxz77m',
    Isuzu_Mt112l: 'Mt112l',
    Isuzu_Nhr55e: 'Nhr55e',
    Isuzu_Nhr69e: 'Nhr69e',
    Isuzu_Nhr69eu3es: 'Nhr69eu3es',
    Isuzu_Nhr85aue4a: 'Nhr85aue4a',
    Isuzu_Nhr85aue4aa: 'Nhr85aue4aa',
    Isuzu_Nhr85eu3es: 'Nhr85eu3es',
    Isuzu_Nhr85uh4aa: 'Nhr85uh4aa',
    Isuzu_Nhr87aue4aa: 'Nhr87aue4aa',
    Isuzu_Reward: 'Reward',
    'Iveco_Ml 190 el': 'Ml 190 el',
    Iveco_Mll190el25: 'Mll190el25',
    Iveco_Stralis: 'Stralis',
    Iveco_Trakker: 'Trakker',
    'Jaguar_E-pace': 'E-pace',
    'Jaguar_F-pace': 'F-pace',
    'Jaguar_F-type': 'F-type',
    'Jaguar_S-type': 'S-type',
    'Jaguar_X-type': 'X-type',
    Jaguar_Xe: 'Xe',
    Jaguar_Xf: 'Xf',
    Jaguar_Xfr: 'Xfr',
    Jaguar_Xj: 'Xj',
    Jaguar_Xj6: 'Xj6',
    Jaguar_Xj8: 'Xj8',
    Jaguar_Xjl: 'Xjl',
    Jaguar_Xjr: 'Xjr',
    Jaguar_Xk8: 'Xk8',
    Jaguar_Xkr: 'Xkr',
    Jeep_Cherokee: 'Cherokee',
    Jeep_Commander: 'Commander',
    Jeep_Compass: 'Compass',
    'Jeep_Grand cherokee': 'Grand cherokee',
    Jeep_Patriot: 'Patriot',
    Jeep_Renegade: 'Renegade',
    Jeep_Wrangler: 'Wrangler',
    Kawasaki_1400gtr: '1400gtr',
    Kawasaki_300i: '300i',
    Kawasaki_Eliminator: 'Eliminator',
    'Kawasaki_Er-6n': 'Er-6n',
    Kawasaki_Er250: 'Er250',
    'Kawasaki_Er250-c': 'Er250-c',
    'Kawasaki_Er650-e': 'Er650-e',
    Kawasaki_Estrella: 'Estrella',
    'Kawasaki_Ex250-l': 'Ex250-l',
    Kawasaki_Ex250k: 'Ex250k',
    Kawasaki_Ex300b: 'Ex300b',
    Kawasaki_F4: 'F4',
    Kawasaki_Ninja: 'Ninja',
    Kawasaki_Z800: 'Z800',
    'Kawasaki_Zg1000-a9': 'Zg1000-a9',
    Kawasaki_Zg1400a: 'Zg1400a',
    Kawasaki_Zg1400e: 'Zg1400e',
    Kawasaki_Zr1000: 'Zr1000',
    Kawasaki_Zr1000a2h: 'Zr1000a2h',
    Kawasaki_Zr800b: 'Zr800b',
    Kawasaki_Zx1000e: 'Zx1000e',
    Kawasaki_Zx1000f: 'Zx1000f',
    Kawasaki_Zx10r: 'Zx10r',
    Kawasaki_Zx1100d6: 'Zx1100d6',
    Kawasaki_Zx130: 'Zx130',
    Kawasaki_Zx1400d: 'Zx1400d',
    Kawasaki_Zx150: 'Zx150',
    Kawasaki_Zx6r: 'Zx6r',
    Kawasaki_Zxr: 'Zxr',
    Kawasaki_Zzr1200: 'Zzr1200',
    Kawasaki_Zzr1400: 'Zzr1400',
    'Keeway_F-act150': 'F-act150',
    Kia_Carens: 'Carens',
    Kia_Carnival: 'Carnival',
    Kia_Cerato: 'Cerato',
    Kia_Combi: 'Combi',
    Kia_Forte: 'Forte',
    Kia_Magentis: 'Magentis',
    Kia_Niro: 'Niro',
    Kia_Optima: 'Optima',
    Kia_Picanto: 'Picanto',
    Kia_Pregio: 'Pregio',
    Kia_Rio: 'Rio',
    Kia_Sephia: 'Sephia',
    Kia_Sorento: 'Sorento',
    Kia_Spectra: 'Spectra',
    Kia_Sportage: 'Sportage',
    Kia_Stinger: 'Stinger',
    Kia_Stonic: 'Stonic',
    'Komatsu_Fd30t-14': 'Fd30t-14',
    Ktm_1190: '1190',
    Ktm_125: '125',
    Ktm_1290: '1290',
    Ktm_200: '200',
    Ktm_350: '350',
    Ktm_390: '390',
    'Ktm_400 exc': '400 exc',
    Ktm_525: '525',
    Ktm_690: '690',
    Ktm_690smc: '690smc',
    Ktm_950: '950',
    Ktm_990: '990',
    'Ktm_Rc 200': 'Rc 200',
    'Ktm_Rc 390': 'Rc 390',
    Kymco_Dink: 'Dink',
    Kymco_Downtown: 'Downtown',
    Kymco_Myroad: 'Myroad',
    Kymco_People: 'People',
    Kymco_Sk60ae: 'Sk60ae',
    Kymco_Xciting: 'Xciting',
    Lamborghini_Aventador: 'Aventador',
    Lamborghini_Gallardo: 'Gallardo',
    Lamborghini_Huracan: 'Huracan',
    Lamborghini_Murcielago: 'Murcielago',
    Lamborghini_Urus: 'Urus',
    LandRover_88: '88',
    LandRover_Defender: 'Defender',
    LandRover_Discovery: 'Discovery',
    LandRover_Evoque: 'Evoque',
    LandRover_Freelander: 'Freelander',
    'LandRover_Land rover': 'Land rover',
    'LandRover_Range rover': 'Range rover',
    Lexus_Ct200h: 'Ct200h',
    Lexus_Es250: 'Es250',
    Lexus_Es300: 'Es300',
    Lexus_Es300f: 'Es300f',
    Lexus_Es300h: 'Es300h',
    Lexus_Gs200t: 'Gs200t',
    Lexus_Gs250: 'Gs250',
    Lexus_Gs300: 'Gs300',
    Lexus_Gs350: 'Gs350',
    Lexus_Gs450h: 'Gs450h',
    'Lexus_Is 250': 'Is 250',
    'Lexus_Is 5.0': 'Is 5.0',
    Lexus_Is200: 'Is200',
    Lexus_Is250: 'Is250',
    Lexus_Is300: 'Is300',
    Lexus_Is350: 'Is350',
    Lexus_Ls: 'Ls',
    Lexus_Ls460l: 'Ls460l',
    Lexus_Nx200t: 'Nx200t',
    Lexus_Nx300: 'Nx300',
    Lexus_Nx300h: 'Nx300h',
    'Lexus_Rc 350': 'Rc 350',
    Lexus_Rx200t: 'Rx200t',
    Lexus_Rx270: 'Rx270',
    Lexus_Rx300: 'Rx300',
    Lexus_Rx350: 'Rx350',
    Lexus_Rx400h: 'Rx400h',
    Lexus_Rx450: 'Rx450',
    Lexus_Rx450h: 'Rx450h',
    Lexus_Sc: 'Sc',
    Leyland_Mgb: 'Mgb',
    Lifan_520: '520',
    Lml_Star: 'Star',
    Lotus_Elise: 'Elise',
    Lotus_Europa: 'Europa',
    Lotus_Evora: 'Evora',
    Lotus_Exige: 'Exige',
    Man_Ng363f: 'Ng363f',
    Man_Tga: 'Tga',
    Man_Tgl: 'Tgl',
    Man_Tgm: 'Tgm',
    Man_Tgs: 'Tgs',
    Maserati_3200: '3200',
    Maserati_Coupe: 'Coupe',
    Maserati_Ghibli: 'Ghibli',
    Maserati_Grancabrio: 'Grancabrio',
    Maserati_Quattroporte: 'Quattroporte',
    Maserati_Spyder: 'Spyder',
    Mazda_2: '2',
    Mazda_5: '5',
    Mazda_3: '3',
    Mazda_323: '323',
    Mazda_6: '6',
    Mazda_8: '8',
    Mazda_Atenza: 'Atenza',
    Mazda_Axela: 'Axela',
    Mazda_Biante: 'Biante',
    'Mazda_Cx-3': 'Cx-3',
    'Mazda_Cx-5': 'Cx-5',
    'Mazda_Cx-7': 'Cx-7',
    'Mazda_Cx-9': 'Cx-9',
    Mazda_Demio: 'Demio',
    Mazda_E2500: 'E2500',
    Mazda_Mpv: 'Mpv',
    'Mazda_Mx-5': 'Mx-5',
    Mazda_Premacy: 'Premacy',
    'Mazda_Rx-8': 'Rx-8',
    'McLaren_Mp4-12c': 'Mp4-12c',
    Mercedes_190e: '190e',
    Mercedes_200e: '200e',
    Mercedes_220: '220',
    Mercedes_220ce: '220ce',
    Mercedes_220e: '220e',
    Mercedes_220s: '220s',
    Mercedes_230ce: '230ce',
    Mercedes_230e: '230e',
    Mercedes_230s: '230s',
    Mercedes_230te: '230te',
    Mercedes_250: '250',
    Mercedes_250s: '250s',
    Mercedes_260e: '260e',
    Mercedes_280: '280',
    Mercedes_280e: '280e',
    Mercedes_280s: '280s',
    Mercedes_280sel: '280sel',
    Mercedes_280sl: '280sl',
    Mercedes_300ce: '300ce',
    Mercedes_300e: '300e',
    Mercedes_300se: '300se',
    Mercedes_300sel: '300sel',
    Mercedes_300sl: '300sl',
    Mercedes_320ce: '320ce',
    Mercedes_320sel: '320sel',
    'Mercedes_3336/6x4': '3336/6x4',
    'Mercedes_3336k/6x4': '3336k/6x4',
    'Mercedes_3341s/6x4': '3341s/6x4',
    Mercedes_350sl: '350sl',
    Mercedes_380sec: '380sec',
    Mercedes_380sel: '380sel',
    Mercedes_380slc: '380slc',
    Mercedes_400sel: '400sel',
    Mercedes_420sec: '420sec',
    Mercedes_420sel: '420sel',
    Mercedes_500se: '500se',
    Mercedes_500sec: '500sec',
    Mercedes_500sel: '500sel',
    Mercedes_500sl: '500sl',
    Mercedes_515: '515',
    Mercedes_600: '600',
    Mercedes_A150: 'A150',
    Mercedes_A160l: 'A160l',
    Mercedes_A170: 'A170',
    Mercedes_A180: 'A180',
    Mercedes_A180d: 'A180d',
    Mercedes_A190: 'A190',
    Mercedes_A190l: 'A190l',
    Mercedes_A200: 'A200',
    Mercedes_A220: 'A220',
    Mercedes_A250: 'A250',
    Mercedes_A35: 'A35',
    Mercedes_A45: 'A45',
    Mercedes_Actros: 'Actros',
    Mercedes_Amg: 'Amg',
    Mercedes_Atego: 'Atego',
    Mercedes_B160: 'B160',
    Mercedes_B170: 'B170',
    Mercedes_B180: 'B180',
    Mercedes_B200: 'B200',
    Mercedes_C180: 'C180',
    Mercedes_C180k: 'C180k',
    Mercedes_C200: 'C200',
    Mercedes_C200k: 'C200k',
    Mercedes_C220: 'C220',
    Mercedes_C230: 'C230',
    Mercedes_C240: 'C240',
    Mercedes_C250: 'C250',
    Mercedes_C280: 'C280',
    Mercedes_C300: 'C300',
    Mercedes_C32: 'C32',
    Mercedes_C320: 'C320',
    Mercedes_C350: 'C350',
    Mercedes_C350e: 'C350e',
    Mercedes_C43: 'C43',
    Mercedes_C55: 'C55',
    Mercedes_C63: 'C63',
    Mercedes_Citan: 'Citan',
    Mercedes_Citaro: 'Citaro',
    Mercedes_Cl500: 'Cl500',
    Mercedes_Cl500a: 'Cl500a',
    Mercedes_Cl55: 'Cl55',
    Mercedes_Cl550: 'Cl550',
    Mercedes_Cl600: 'Cl600',
    Mercedes_Cl63: 'Cl63',
    Mercedes_Cla180: 'Cla180',
    Mercedes_Cla200: 'Cla200',
    Mercedes_Cla220: 'Cla220',
    Mercedes_Cla250: 'Cla250',
    Mercedes_Cla45: 'Cla45',
    Mercedes_Clc180: 'Clc180',
    Mercedes_Clc230: 'Clc230',
    Mercedes_Clk: 'Clk',
    Mercedes_Clk200: 'Clk200',
    Mercedes_Clk200ml: 'Clk200ml',
    Mercedes_Clk230: 'Clk230',
    Mercedes_Clk240: 'Clk240',
    Mercedes_Clk280: 'Clk280',
    Mercedes_Clk320: 'Clk320',
    Mercedes_Clk350: 'Clk350',
    Mercedes_Clk500: 'Clk500',
    Mercedes_Clk55: 'Clk55',
    Mercedes_Cls250: 'Cls250',
    Mercedes_Cls280: 'Cls280',
    Mercedes_Cls300: 'Cls300',
    Mercedes_Cls350: 'Cls350',
    Mercedes_Cls400: 'Cls400',
    Mercedes_Cls450: 'Cls450',
    Mercedes_Cls500: 'Cls500',
    Mercedes_Cls55: 'Cls55',
    Mercedes_Cls63: 'Cls63',
    Mercedes_E200: 'E200',
    Mercedes_E200k: 'E200k',
    Mercedes_E200ml: 'E200ml',
    Mercedes_E220: 'E220',
    Mercedes_E220d: 'E220d',
    Mercedes_E230: 'E230',
    Mercedes_E240: 'E240',
    Mercedes_E250: 'E250',
    Mercedes_E280: 'E280',
    Mercedes_E300: 'E300',
    Mercedes_E320: 'E320',
    Mercedes_E350: 'E350',
    Mercedes_E350d: 'E350d',
    Mercedes_E400: 'E400',
    Mercedes_E43: 'E43',
    Mercedes_E500: 'E500',
    Mercedes_E53: 'E53',
    Mercedes_E55: 'E55',
    Mercedes_E63: 'E63',
    Mercedes_E63s: 'E63s',
    Mercedes_G350: 'G350',
    Mercedes_G55: 'G55',
    Mercedes_G63: 'G63',
    Mercedes_Gl350: 'Gl350',
    Mercedes_Gl400: 'Gl400',
    Mercedes_Gl450: 'Gl450',
    Mercedes_Gla180: 'Gla180',
    Mercedes_Gla200: 'Gla200',
    Mercedes_Gla250: 'Gla250',
    Mercedes_Gla45: 'Gla45',
    Mercedes_Glc200: 'Glc200',
    Mercedes_Glc220: 'Glc220',
    Mercedes_Glc250: 'Glc250',
    Mercedes_Glc43: 'Glc43',
    Mercedes_Gle250: 'Gle250',
    Mercedes_Gle400: 'Gle400',
    Mercedes_Gle450: 'Gle450',
    Mercedes_Gls400: 'Gls400',
    'Mercedes_Mb actros': 'Mb actros',
    Mercedes_Mb100: 'Mb100',
    Mercedes_Mb100d: 'Mb100d',
    Mercedes_Mb140d: 'Mb140d',
    Mercedes_Ml250: 'Ml250',
    Mercedes_Ml300: 'Ml300',
    Mercedes_Ml320: 'Ml320',
    Mercedes_Ml350: 'Ml350',
    Mercedes_Ml400: 'Ml400',
    Mercedes_Ml430: 'Ml430',
    Mercedes_Ml500: 'Ml500',
    Mercedes_Ml55: 'Ml55',
    Mercedes_Ml63: 'Ml63',
    Mercedes_O405g: 'O405g',
    Mercedes_R350l: 'R350l',
    Mercedes_R500l: 'R500l',
    Mercedes_S280: 'S280',
    Mercedes_S280l: 'S280l',
    Mercedes_S300: 'S300',
    Mercedes_S300l: 'S300l',
    Mercedes_S320: 'S320',
    Mercedes_S320l: 'S320l',
    Mercedes_S350: 'S350',
    Mercedes_S350l: 'S350l',
    Mercedes_S400: 'S400',
    Mercedes_S400l: 'S400l',
    Mercedes_S420l: 'S420l',
    Mercedes_S430: 'S430',
    Mercedes_S430l: 'S430l',
    Mercedes_S450l: 'S450l',
    Mercedes_S500: 'S500',
    Mercedes_S500l: 'S500l',
    Mercedes_S55l: 'S55l',
    Mercedes_S560l: 'S560l',
    Mercedes_S600: 'S600',
    Mercedes_S600l: 'S600l',
    Mercedes_S65: 'S65',
    Mercedes_Sl280: 'Sl280',
    Mercedes_Sl300: 'Sl300',
    Mercedes_Sl320: 'Sl320',
    Mercedes_Sl350: 'Sl350',
    Mercedes_Sl500: 'Sl500',
    Mercedes_Sl55: 'Sl55',
    Mercedes_Sl600: 'Sl600',
    Mercedes_Sl63: 'Sl63',
    Mercedes_Sl65: 'Sl65',
    Mercedes_Slc180: 'Slc180',
    Mercedes_Slc280: 'Slc280',
    Mercedes_Slk200: 'Slk200',
    Mercedes_Slk230: 'Slk230',
    Mercedes_Slk250: 'Slk250',
    Mercedes_Slk280: 'Slk280',
    Mercedes_Slk300: 'Slk300',
    Mercedes_Slk320: 'Slk320',
    Mercedes_Slk350: 'Slk350',
    Mercedes_Slk55: 'Slk55',
    Mercedes_Slr: 'Slr',
    Mercedes_Slr722: 'Slr722',
    Mercedes_Sls: 'Sls',
    Mercedes_Sprinter: 'Sprinter',
    Mercedes_Viano: 'Viano',
    Mercedes_Vito: 'Vito',
    Mg_Midget: 'Midget',
    Mini_7: '7',
    Mini_850: '850',
    Mini_Brick: 'Brick',
    Mini_Checkmate: 'Checkmate',
    Mini_Clubman: 'Clubman',
    Mini_Convertible: 'Convertible',
    Mini_Cooper: 'Cooper',
    'Mini_John cooper works': 'John cooper works',
    Mini_Mini: 'Mini',
    Mini_Minor: 'Minor',
    Mini_One: 'One',
    Mini_Park: 'Park',
    Mini_Seven: 'Seven',
    Mitsubishi_Airtrek: 'Airtrek',
    Mitsubishi_Asx: 'Asx',
    Mitsubishi_Attrage: 'Attrage',
    Mitsubishi_Canter: 'Canter',
    Mitsubishi_Colt: 'Colt',
    Mitsubishi_Delica: 'Delica',
    Mitsubishi_Dion: 'Dion',
    'Mitsubishi_Eclipse cross': 'Eclipse cross',
    'Mitsubishi_Ek wagon': 'Ek wagon',
    Mitsubishi_Evo: 'Evo',
    'Mitsubishi_Evo-10': 'Evo-10',
    Mitsubishi_Fb511b0jrdeb: 'Fb511b0jrdeb',
    Mitsubishi_Fb511b0srdea: 'Fb511b0srdea',
    Mitsubishi_Fb511ba46391: 'Fb511ba46391',
    Mitsubishi_Fb511bojrdec: 'Fb511bojrdec',
    Mitsubishi_Fb511bojsrde: 'Fb511bojsrde',
    Mitsubishi_Fb511bosrdea: 'Fb511bosrdea',
    Mitsubishi_Fb70abosrdea: 'Fb70abosrdea',
    Mitsubishi_Fb70abosrdeb: 'Fb70abosrdeb',
    Mitsubishi_Fb70bb1srdea: 'Fb70bb1srdea',
    Mitsubishi_Fe424: 'Fe424',
    Mitsubishi_Fe639c6srdea: 'Fe639c6srdea',
    Mitsubishi_Fe639e6rde: 'Fe639e6rde',
    Mitsubishi_Fe639et0rde: 'Fe639et0rde',
    Mitsubishi_Fe639et0srde: 'Fe639et0srde',
    Mitsubishi_Fe639etorde: 'Fe639etorde',
    Mitsubishi_Fe639etosrde: 'Fe639etosrde',
    Mitsubishi_Fe659f6rde: 'Fe659f6rde',
    Mitsubishi_Fe659f6srde: 'Fe659f6srde',
    Mitsubishi_Fe83be6srdea: 'Fe83be6srdea',
    Mitsubishi_Fe83beosrdea: 'Fe83beosrdea',
    Mitsubishi_Fe83pe6srdeb: 'Fe83pe6srdeb',
    Mitsubishi_Fe83peosrdea: 'Fe83peosrdea',
    Mitsubishi_Fe84be6srdea: 'Fe84be6srdea',
    Mitsubishi_Fe85djzsrdea: 'Fe85djzsrdea',
    Mitsubishi_Fe85pg6srde: 'Fe85pg6srde',
    Mitsubishi_Feb21cr4sdeb: 'Feb21cr4sdeb',
    Mitsubishi_Feb21er3sden: 'Feb21er3sden',
    Mitsubishi_Fk617msjrdec: 'Fk617msjrdec',
    Mitsubishi_Fk61fm: 'Fk61fm',
    Mitsubishi_Fk61fmj1rdea: 'Fk61fmj1rdea',
    Mitsubishi_Fm657msrdec: 'Fm657msrdec',
    Mitsubishi_Fm65fm1rdea: 'Fm65fm1rdea',
    Mitsubishi_Fp517dr2rdeb: 'Fp517dr2rdeb',
    Mitsubishi_Fp51jdr4rdea: 'Fp51jdr4rdea',
    Mitsubishi_Fp51sdr3vdea: 'Fp51sdr3vdea',
    Mitsubishi_Fq62fs2rdec: 'Fq62fs2rdec',
    Mitsubishi_Fs527s: 'Fs527s',
    Mitsubishi_Fto: 'Fto',
    Mitsubishi_Fuso: 'Fuso',
    'Mitsubishi_Fuso rosa': 'Fuso rosa',
    Mitsubishi_Fv515hr: 'Fv515hr',
    Mitsubishi_Fv517: 'Fv517',
    Mitsubishi_Fv51j: 'Fv51j',
    Mitsubishi_Fv51jp: 'Fv51jp',
    Mitsubishi_Galant: 'Galant',
    Mitsubishi_Grandis: 'Grandis',
    Mitsubishi_i: 'i',
    Mitsubishi_L200: 'L200',
    Mitsubishi_L300: 'L300',
    Mitsubishi_Lancer: 'Lancer',
    Mitsubishi_Minicab: 'Minicab',
    Mitsubishi_Montero: 'Montero',
    Mitsubishi_Outlander: 'Outlander',
    Mitsubishi_Pajero: 'Pajero',
    Mitsubishi_Rosa: 'Rosa',
    Mitsubishi_Shogun: 'Shogun',
    Mitsubishi_Triton: 'Triton',
    Morris_Eight: 'Eight',
    Morris_Minor: 'Minor',
    Mvagusta_Brutale: 'Brutale',
    Mvagusta_Stradale: 'Stradale',
    Nissan_350z: '350z',
    Nissan_370z: '370z',
    Nissan_Ad: 'Ad',
    Nissan_Almera: 'Almera',
    Nissan_Atlas: 'Atlas',
    Nissan_Cabstar: 'Cabstar',
    Nissan_Caravan: 'Caravan',
    Nissan_Cedric: 'Cedric',
    Nissan_Cefiro: 'Cefiro',
    Nissan_Cgb45clsmnb: 'Cgb45clsmnb',
    Nissan_Cgb45lsmnb: 'Cgb45lsmnb',
    Nissan_Ck20: 'Ck20',
    Nissan_Ckb45abtn2: 'Ckb45abtn2',
    Nissan_Ckb536: 'Ckb536',
    Nissan_Cmf87h05441: 'Cmf87h05441',
    Nissan_Cmf87lhsa: 'Cmf87lhsa',
    Nissan_Cpb14nhr: 'Cpb14nhr',
    Nissan_Cube: 'Cube',
    Nissan_Cwb45ahtn2: 'Cwb45ahtn2',
    Nissan_Cwb45aphn2: 'Cwb45aphn2',
    Nissan_Cwb45clldn: 'Cwb45clldn',
    Nissan_Cwb45clphnb: 'Cwb45clphnb',
    Nissan_Cwb4cll00058: 'Cwb4cll00058',
    Nissan_Cwb520: 'Cwb520',
    Nissan_Dualis: 'Dualis',
    Nissan_Elgrand: 'Elgrand',
    Nissan_Fairlady: 'Fairlady',
    Nissan_Frontier: 'Frontier',
    Nissan_Fuga: 'Fuga',
    Nissan_Gd21: 'Gd21',
    Nissan_Gkb45clbhnb: 'Gkb45clbhnb',
    'Nissan_Grand livina': 'Grand livina',
    Nissan_Infinity: 'Infinity',
    Nissan_Juke: 'Juke',
    Nissan_Lafesta: 'Lafesta',
    Nissan_Latio: 'Latio',
    Nissan_March: 'March',
    Nissan_Mkb210: 'Mkb210',
    Nissan_Mkb212: 'Mkb212',
    Nissan_Mkb37: 'Mkb37',
    Nissan_Mkb8: 'Mkb8',
    Nissan_Murano: 'Murano',
    Nissan_Navara: 'Navara',
    Nissan_Note: 'Note',
    Nissan_Nv150: 'Nv150',
    Nissan_Nv200: 'Nv200',
    Nissan_Nv350: 'Nv350',
    Nissan_Prairie: 'Prairie',
    Nissan_Presage: 'Presage',
    Nissan_Presea: 'Presea',
    Nissan_Primera: 'Primera',
    Nissan_Pu41h4: 'Pu41h4',
    Nissan_Pu41t4: 'Pu41t4',
    Nissan_Pulsar: 'Pulsar',
    Nissan_Qashqai: 'Qashqai',
    Nissan_Sentra: 'Sentra',
    Nissan_Serena: 'Serena',
    Nissan_Silvia: 'Silvia',
    Nissan_Skyline: 'Skyline',
    Nissan_Sunny: 'Sunny',
    Nissan_Sylphy: 'Sylphy',
    Nissan_Teana: 'Teana',
    Nissan_Urvan: 'Urvan',
    Nissan_Vanette: 'Vanette',
    'Nissan_X-trail': 'X-trail',
    Noble_Gto: 'Gto',
    Noble_M14: 'M14',
    Noble_M400: 'M400',
    Opel_Adam: 'Adam',
    Opel_Astra: 'Astra',
    Opel_Combo: 'Combo',
    'Opel_Combo-c': 'Combo-c',
    Opel_Corsa: 'Corsa',
    'Opel_Corsa-d': 'Corsa-d',
    Opel_Crossland: 'Crossland',
    Opel_Grandland: 'Grandland',
    Opel_Insignia: 'Insignia',
    Opel_Meriva: 'Meriva',
    Opel_Mokka: 'Mokka',
    Opel_Movano: 'Movano',
    Opel_Omega: 'Omega',
    Opel_Tigra: 'Tigra',
    Opel_Vectra: 'Vectra',
    Opel_Vivaro: 'Vivaro',
    Opel_Zafira: 'Zafira',
    Pagani_Zonda: 'Zonda',
    Perodua_Alza: 'Alza',
    Perodua_Axia: 'Axia',
    Perodua_Bezza: 'Bezza',
    Perodua_Kancil: 'Kancil',
    Perodua_Kelisa: 'Kelisa',
    Perodua_Kembara: 'Kembara',
    Perodua_Kenari: 'Kenari',
    Perodua_Myvi: 'Myvi',
    Perodua_Viva: 'Viva',
    Peugeot_1007: '1007',
    Peugeot_107: '107',
    Peugeot_2008: '2008',
    Peugeot_206: '206',
    Peugeot_207: '207',
    Peugeot_207cc: '207cc',
    Peugeot_208: '208',
    Peugeot_3008: '3008',
    Peugeot_307: '307',
    Peugeot_308: '308',
    Peugeot_308cc: '308cc',
    Peugeot_406: '406',
    Peugeot_407: '407',
    Peugeot_408: '408',
    Peugeot_5008: '5008',
    Peugeot_508: '508',
    Peugeot_508sw: '508sw',
    Peugeot_607: '607',
    Peugeot_Expert: 'Expert',
    Peugeot_Partner: 'Partner',
    Peugeot_Rcz: 'Rcz',
    Peugeot_Vivacity: 'Vivacity',
    'Pgo_G-max': 'G-max',
    'Pgo_I me 150': 'I me 150',
    Piaggio_Beverly: 'Beverly',
    Piaggio_Fly: 'Fly',
    Piaggio_Mp3: 'Mp3',
    Piaggio_Sfera: 'Sfera',
    'Piaggio_Skipper 125': 'Skipper 125',
    'Piaggio_Skipper 150': 'Skipper 150',
    Piaggio_X10: 'X10',
    Piaggio_X8: 'X8',
    Piaggio_X9: 'X9',
    Porsche_911: '911',
    Porsche_928: '928',
    Porsche_356b: '356b',
    Porsche_550: '550',
    Porsche_718: '718',
    Porsche_Boxster: 'Boxster',
    Porsche_C4: 'C4',
    Porsche_C4s: 'C4s',
    Porsche_Cayenne: 'Cayenne',
    Porsche_Cayman: 'Cayman',
    Porsche_Gt3: 'Gt3',
    Porsche_Macan: 'Macan',
    Porsche_Panamera: 'Panamera',
    Proton_Exora: 'Exora',
    Proton_Gen2: 'Gen2',
    Proton_Inspira: 'Inspira',
    Proton_Iriz: 'Iriz',
    Proton_Iswara: 'Iswara',
    Proton_Perdana: 'Perdana',
    Proton_Persona: 'Persona',
    Proton_Preve: 'Preve',
    Proton_Satria: 'Satria',
    Proton_Savvy: 'Savvy',
    Proton_Waja: 'Waja',
    Proton_Wira: 'Wira',
    Renault_Alpine: 'Alpine',
    Renault_Captur: 'Captur',
    Renault_Clio: 'Clio',
    Renault_Espace: 'Espace',
    Renault_Fluence: 'Fluence',
    Renault_Kadjar: 'Kadjar',
    Renault_Kangoo: 'Kangoo',
    Renault_Kng: 'Kng',
    Renault_Koleos: 'Koleos',
    Renault_Laguna: 'Laguna',
    Renault_Latitude: 'Latitude',
    Renault_Master: 'Master',
    Renault_Megane: 'Megane',
    Renault_Megcla: 'Megcla',
    Renault_Modus: 'Modus',
    Renault_Scenic: 'Scenic',
    Renault_Trafic: 'Trafic',
    Rieju_Marathon: 'Marathon',
    RollsRoyce_Dawn: 'Dawn',
    RollsRoyce_Ghost: 'Ghost',
    RollsRoyce_Phantom: 'Phantom',
    RollsRoyce_Silvershadow: 'Silvershadow',
    RollsRoyce_Wraith: 'Wraith',
    Rover_216: '216',
    Saab_44077: '44077',
    Saab_44079: '44079',
    Saab_940: '940',
    Scania_G410la4x2msz: 'G410la4x2msz',
    Scania_G420cb: 'G420cb',
    Seat_Arona: 'Arona',
    Seat_Ateca: 'Ateca',
    Seat_Ibiza: 'Ibiza',
    Seat_Leon: 'Leon',
    Seat_Toledo: 'Toledo',
    Skoda_Fabia: 'Fabia',
    Skoda_Karoq: 'Karoq',
    Skoda_Kodiaq: 'Kodiaq',
    Skoda_Octavia: 'Octavia',
    Skoda_Rapid: 'Rapid',
    Skoda_Superb: 'Superb',
    Skoda_Yeti: 'Yeti',
    Smart_Fortwo: 'Fortwo',
    Ssangyong_Actyon: 'Actyon',
    Ssangyong_Chairman: 'Chairman',
    Ssangyong_Korando: 'Korando',
    Ssangyong_Musso: 'Musso',
    Ssangyong_Rexton: 'Rexton',
    Ssangyong_Rodius: 'Rodius',
    Ssangyong_Stavic: 'Stavic',
    Ssangyong_Tivoli: 'Tivoli',
    Subaru_B9: 'B9',
    Subaru_Brz: 'Brz',
    Subaru_Exiga: 'Exiga',
    Subaru_Forester: 'Forester',
    Subaru_Impreza: 'Impreza',
    Subaru_Legacy: 'Legacy',
    Subaru_Outback: 'Outback',
    Subaru_R1: 'R1',
    Subaru_R2: 'R2',
    Subaru_Tribeca: 'Tribeca',
    Subaru_Vivio: 'Vivio',
    Subaru_Wrx: 'Wrx',
    Subaru_Xv: 'Xv',
    Sunlong_Slk6110: 'Slk6110',
    Sunlong_Slk6905: 'Slk6905',
    Suzuki_Alto: 'Alto',
    'Suzuki_An 125 burgman': 'An 125 burgman',
    'Suzuki_An 400 burgman': 'An 400 burgman',
    'Suzuki_An 650 burgman': 'An 650 burgman',
    Suzuki_Apv: 'Apv',
    Suzuki_Arena: 'Arena',
    Suzuki_Baleno: 'Baleno',
    Suzuki_Bandit: 'Bandit',
    Suzuki_Belang: 'Belang',
    Suzuki_Carry: 'Carry',
    Suzuki_Cervo: 'Cervo',
    Suzuki_Ciaz: 'Ciaz',
    Suzuki_Delta: 'Delta',
    Suzuki_Dl1000k2: 'Dl1000k2',
    Suzuki_Dl650al1: 'Dl650al1',
    Suzuki_Dr: 'Dr',
    Suzuki_Dr100: 'Dr100',
    Suzuki_Drz400sm: 'Drz400sm',
    Suzuki_Drz400smk5: 'Drz400smk5',
    Suzuki_Drz400smk7: 'Drz400smk7',
    Suzuki_Drz400smk8: 'Drz400smk8',
    Suzuki_Drz400smk9: 'Drz400smk9',
    Suzuki_Drz400sml0: 'Drz400sml0',
    Suzuki_Escudo: 'Escudo',
    Suzuki_Every: 'Every',
    Suzuki_Fd110ks: 'Fd110ks',
    Suzuki_Fd110xcsdu: 'Fd110xcsdu',
    Suzuki_Fd125xsu: 'Fd125xsu',
    Suzuki_Fh125m: 'Fh125m',
    Suzuki_Fl125fsd: 'Fl125fsd',
    Suzuki_Fu150scd: 'Fu150scd',
    Suzuki_Gn125: 'Gn125',
    Suzuki_Gn125e: 'Gn125e',
    Suzuki_Gn125h: 'Gn125h',
    Suzuki_Gn250e: 'Gn250e',
    'Suzuki_Grand vitara': 'Grand vitara',
    Suzuki_Gs150: 'Gs150',
    Suzuki_Gsf1250: 'Gsf1250',
    Suzuki_Gsf400: 'Gsf400',
    Suzuki_Gsr400: 'Gsr400',
    Suzuki_Gsr600: 'Gsr600',
    'Suzuki_Gsx-r1000': 'Gsx-r1000',
    'Suzuki_Gsx-r1100': 'Gsx-r1100',
    'Suzuki_Gsx-r150': 'Gsx-r150',
    'Suzuki_Gsx-r400': 'Gsx-r400',
    'Suzuki_Gsx-r600': 'Gsx-r600',
    'Suzuki_Gsx-r750': 'Gsx-r750',
    'Suzuki_Gsx-s1000': 'Gsx-s1000',
    Suzuki_Gsx1300: 'Gsx1300',
    Suzuki_Gsx400: 'Gsx400',
    Suzuki_Gsx750: 'Gsx750',
    Suzuki_Ignis: 'Ignis',
    Suzuki_Intruder: 'Intruder',
    Suzuki_Jimny: 'Jimny',
    Suzuki_Katana: 'Katana',
    Suzuki_Kizashi: 'Kizashi',
    Suzuki_Lapin: 'Lapin',
    Suzuki_Liana: 'Liana',
    'Suzuki_S-cross': 'S-cross',
    Suzuki_Sfv650: 'Sfv650',
    Suzuki_Skydrive: 'Skydrive',
    Suzuki_Smash: 'Smash',
    Suzuki_Solio: 'Solio',
    Suzuki_Splash: 'Splash',
    Suzuki_Stingray: 'Stingray',
    Suzuki_Swift: 'Swift',
    Suzuki_Sx4: 'Sx4',
    Suzuki_Vitara: 'Vitara',
    'Sym_Bonus 110': 'Bonus 110',
    Sym_Combiz: 'Combiz',
    Sym_Cruisym: 'Cruisym',
    Sym_En: 'En',
    Sym_Evo: 'Evo',
    'Sym_Joymax 300': 'Joymax 300',
    'Sym_Joymax 300i': 'Joymax 300i',
    'Sym_Joyride 200': 'Joyride 200',
    'Sym_Joyride 200i': 'Joyride 200i',
    Sym_Maxsym: 'Maxsym',
    Tennant_Atlv: 'Atlv',
    Tgb_Express: 'Express',
    Toyota_86: '86',
    Toyota_Allion: 'Allion',
    Toyota_Alphard: 'Alphard',
    Toyota_Altezza: 'Altezza',
    Toyota_Aqua: 'Aqua',
    Toyota_Auris: 'Auris',
    Toyota_Avanza: 'Avanza',
    Toyota_Axio: 'Axio',
    Toyota_Aygo: 'Aygo',
    Toyota_Bb: 'Bb',
    Toyota_Belta: 'Belta',
    'Toyota_C-hr': 'C-hr',
    Toyota_Caldina: 'Caldina',
    Toyota_Camry: 'Camry',
    Toyota_Celica: 'Celica',
    Toyota_Coaster: 'Coaster',
    Toyota_Corolla: 'Corolla',
    Toyota_Corona: 'Corona',
    Toyota_Crown: 'Crown',
    Toyota_Dyna: 'Dyna',
    Toyota_Echo: 'Echo',
    Toyota_Esquire: 'Esquire',
    Toyota_Estima: 'Estima',
    'Toyota_Fj cruiser': 'Fj cruiser',
    Toyota_Fortuner: 'Fortuner',
    Toyota_Harrier: 'Harrier',
    Toyota_Hiace: 'Hiace',
    Toyota_Hilux: 'Hilux',
    Toyota_Innova: 'Innova',
    Toyota_Iq: 'Iq',
    Toyota_Isis: 'Isis',
    Toyota_Ist: 'Ist',
    'Toyota_Land cruiser': 'Land cruiser',
    Toyota_Liteace: 'Liteace',
    Toyota_Mark: 'Mark',
    'Toyota_Mr-2': 'Mr-2',
    'Toyota_Mr-s': 'Mr-s',
    Toyota_Noah: 'Noah',
    Toyota_Passo: 'Passo',
    Toyota_Picnic: 'Picnic',
    Toyota_Porte: 'Porte',
    Toyota_Prado: 'Prado',
    Toyota_Premio: 'Premio',
    Toyota_Previa: 'Previa',
    Toyota_Prius: 'Prius',
    Toyota_Proace: 'Proace',
    Toyota_Probox: 'Probox',
    Toyota_Ractics: 'Ractics',
    Toyota_Rav4: 'Rav4',
    Toyota_Regius: 'Regius',
    Toyota_Regiusace: 'Regiusace',
    Toyota_Royal: 'Royal',
    Toyota_Rush: 'Rush',
    Toyota_Sera: 'Sera',
    Toyota_Sienta: 'Sienta',
    Toyota_Soluna: 'Soluna',
    'Toyota_Spade y': 'Spade y',
    Toyota_Starlet: 'Starlet',
    Toyota_Townace: 'Townace',
    Toyota_Toyoace: 'Toyoace',
    Toyota_Unser: 'Unser',
    Toyota_Vanguard: 'Vanguard',
    Toyota_Vellfire: 'Vellfire',
    Toyota_Verossa: 'Verossa',
    Toyota_Vios: 'Vios',
    Toyota_Vitz: 'Vitz',
    Toyota_Voltz: 'Voltz',
    Toyota_Voxy: 'Voxy',
    Toyota_Will: 'Will',
    Toyota_Wish: 'Wish',
    Toyota_Yaris: 'Yaris',
    Triumph_Bonneville: 'Bonneville',
    Triumph_Daytona: 'Daytona',
    Triumph_Scrambler: 'Scrambler',
    'Triumph_Speed triple': 'Speed triple',
    Triumph_Spitfire: 'Spitfire',
    Triumph_Sprint: 'Sprint',
    Triumph_Street: 'Street',
    Triumph_Tiger: 'Tiger',
    Triumph_Tr3: 'Tr3',
    Tvs_Apache: 'Apache',
    UDTrucks_Condor: 'Condor',
    UDTrucks_Cwb5elldnt: 'Cwb5elldnt',
    UDTrucks_Cwb5elphnt: 'Cwb5elphnt',
    UDTrucks_Escot: 'Escot',
    Vespa_Et8: 'Et8',
    Vespa_Excel: 'Excel',
    Vespa_Gt200a: 'Gt200a',
    Vespa_Gts: 'Gts',
    Vespa_Lx: 'Lx',
    Vespa_Primavera: 'Primavera',
    Vespa_Px: 'Px',
    Vespa_Px15: 'Px15',
    Vespa_Px150: 'Px150',
    Vespa_Px20: 'Px20',
    Vespa_Px200: 'Px200',
    Vespa_Px200e: 'Px200e',
    Vespa_Sprint: 'Sprint',
    Volkswagen_Arteon: 'Arteon',
    Volkswagen_Beetle: 'Beetle',
    Volkswagen_Bora: 'Bora',
    Volkswagen_Caddy: 'Caddy',
    Volkswagen_Caravelle: 'Caravelle',
    Volkswagen_Cc: 'Cc',
    Volkswagen_Crosspolo: 'Crosspolo',
    Volkswagen_Eos: 'Eos',
    Volkswagen_Golf: 'Golf',
    Volkswagen_Jetta: 'Jetta',
    Volkswagen_Kombi: 'Kombi',
    Volkswagen_Multivan: 'Multivan',
    Volkswagen_Passat: 'Passat',
    Volkswagen_Phaeton: 'Phaeton',
    Volkswagen_Polo: 'Polo',
    Volkswagen_Scirocco: 'Scirocco',
    Volkswagen_Sharan: 'Sharan',
    Volkswagen_Tiguan: 'Tiguan',
    Volkswagen_Touareg: 'Touareg',
    Volkswagen_Touran: 'Touran',
    Volkswagen_Vento: 'Vento',
    Volvo_240: '240',
    'Volvo_B10m-60': 'B10m-60',
    Volvo_B10tl: 'B10tl',
    Volvo_B11r: 'B11r',
    Volvo_B5lh: 'B5lh',
    Volvo_B7r: 'B7r',
    Volvo_B7rle: 'B7rle',
    Volvo_B8r: 'B8r',
    Volvo_B9l: 'B9l',
    Volvo_B9r: 'B9r',
    Volvo_B9tl: 'B9tl',
    Volvo_C30: 'C30',
    Volvo_C70: 'C70',
    'Volvo_Cross country': 'Cross country',
    Volvo_Fee300: 'Fee300',
    Volvo_Flh290: 'Flh290',
    Volvo_Fm370: 'Fm370',
    Volvo_Fm420: 'Fm420',
    Volvo_Fmx370: 'Fmx370',
    Volvo_Fmx410: 'Fmx410',
    Volvo_Fmx420: 'Fmx420',
    Volvo_S40: 'S40',
    Volvo_S60: 'S60',
    Volvo_S80: 'S80',
    Volvo_S90: 'S90',
    Volvo_V40: 'V40',
    Volvo_V50: 'V50',
    Volvo_V60: 'V60',
    Volvo_V70: 'V70',
    Volvo_V90: 'V90',
    Volvo_X60: 'X60',
    Volvo_Xc40: 'Xc40',
    Volvo_Xc60: 'Xc60',
    Volvo_Xc70: 'Xc70',
    Volvo_Xc90: 'Xc90',
    Yamaha_135lc: '135lc',
    Yamaha_Aerox: 'Aerox',
    Yamaha_Czd300a: 'Czd300a',
    Yamaha_Dt125r: 'Dt125r',
    Yamaha_Dt200wr: 'Dt200wr',
    Yamaha_Ego: 'Ego',
    Yamaha_Fino: 'Fino',
    Yamaha_Fjr1300: 'Fjr1300',
    Yamaha_Fjr1300abs: 'Fjr1300abs',
    'Yamaha_Fz1-n': 'Fz1-n',
    'Yamaha_Fz1-s': 'Fz1-s',
    Yamaha_Fz150: 'Fz150',
    Yamaha_Fz150i: 'Fz150i',
    Yamaha_Fz16: 'Fz16',
    Yamaha_Fz16st: 'Fz16st',
    'Yamaha_Fz6-n': 'Fz6-n',
    'Yamaha_Fz6-s': 'Fz6-s',
    'Yamaha_Fz8-n': 'Fz8-n',
    'Yamaha_Fz8-s': 'Fz8-s',
    Yamaha_Fzn150: 'Fzn150',
    Yamaha_Fzs1000: 'Fzs1000',
    Yamaha_Gdr155a: 'Gdr155a',
    'Yamaha_Jupiter lc135': 'Jupiter lc135',
    'Yamaha_Jupiter mx 135': 'Jupiter mx 135',
    'Yamaha_Jupiter t150': 'Jupiter t150',
    'Yamaha_Jupiter z1': 'Jupiter z1',
    'Yamaha_Majesty 400': 'Majesty 400',
    Yamaha_Mio: 'Mio',
    'Yamaha_Mt-01': 'Mt-01',
    'Yamaha_Mt-03': 'Mt-03',
    'Yamaha_Mt-07': 'Mt-07',
    'Yamaha_Mt-09': 'Mt-09',
    'Yamaha_Mt-10': 'Mt-10',
    'Yamaha_Mt-15': 'Mt-15',
    Yamaha_Mtm850a: 'Mtm850a',
    Yamaha_Mtn155: 'Mtn155',
    'Yamaha_Mw 125': 'Mw 125',
    'Yamaha_Mx king t150': 'Mx king t150',
    Yamaha_Nouvo: 'Nouvo',
    Yamaha_Pw50: 'Pw50',
    Yamaha_Pw80: 'Pw80',
    Yamaha_Rx115: 'Rx115',
    Yamaha_Ttx: 'Ttx',
    'Yamaha_V-max': 'V-max',
    'Yamaha_X-1r': 'X-1r',
    'Yamaha_X-1t': 'X-1t',
    Yamaha_Xabre: 'Xabre',
    Yamaha_Xj6: 'Xj6',
    Yamaha_Xj6s: 'Xj6s',
    Yamaha_Xj900s: 'Xj900s',
    Yamaha_Xjr1300: 'Xjr1300',
    Yamaha_Xjr400r: 'Xjr400r',
    Yamaha_Xmax: 'Xmax',
    Yamaha_Xtz: 'Xtz',
    Yamaha_Xvs: 'Xvs',
    Yutong_Zk6100h: 'Zk6100h',
    Yutong_Zk6107h: 'Zk6107h',
    Yutong_Zk6107he: 'Zk6107he',
    Yutong_Zk6118hga: 'Zk6118hga',
    Yutong_Zk6119h: 'Zk6119h',
    Yutong_Zk6122he9: 'Zk6122he9',
    Yutong_Zk6127h: 'Zk6127h',
    Yutong_Zk6898he: 'Zk6898he',
    Yutong_Zk6930h: 'Zk6930h',
    Yutong_Zk6938h: 'Zk6938h',
    Zongshen_Evo: 'Evo',
    'Zongshen_Zs125-47': 'Zs125-47',
    'Zongshen_Zs125-50': 'Zs125-50',
    'Zongshen_Zs125-58': 'Zs125-58',
    AlexanderDennis_ENVIRO500: 'ENVIRO500',
    Bollore_BLUECAR: 'BLUECAR',
    'Crown_FIGHTER 150': 'FIGHTER 150',
    'Demag_15.9': '15.9',
    Maserati_GRANSPORT: 'GRANSPORT',
    Maserati_GRANTURISMO: 'GRANTURISMO',
    Grove_GMK6300L: 'GMK6300L',
    Hdk_DEL3042CX2: 'DEL3042CX2',
    Kasinski_MIRAGE: 'MIRAGE',
    Lexbuild_FTBCI: 'FTBCI',
    'Manitou_MHT 860 LT': 'MHT 860 LT',
    Maxus_G10: 'G10',
    Maxus_V80: 'V80',
    MgRover_75: '75',
    MgRover_TF: 'TF',
    Mitsuoka_GALUE: 'GALUE',
    Mitsuoka_NOUERA: 'NOUERA',
    Modenas_ELEGAN: 'ELEGAN',
    Naza_BLADE: 'BLADE',
    Naza_CITRA: 'CITRA',
    Naza_NBF250RI: 'NBF250RI',
    RoyalEnfield_BULLET: 'BULLET',
    RoyalEnfield_CLIPPER: 'CLIPPER',
    Shacman_F3000: 'F3000',
    'ShenyangBrillianceJinbei_ERA JINBEI': 'ERA JINBEI',
    Sunlong_SLK6972B25R143: 'SLK6972B25R143',
    Tadano_FAUN: 'FAUN',
    'Terex_AC 160': 'AC 160',
    Terex_AC140KA: 'AC140KA',
};
