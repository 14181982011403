/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Locale } from '@gears/translations';
import { RootReducerType } from 'src/store/reducers';
import { setLocale } from 'src/store/application/application-actions';
import { Button, Grid, GridItem } from '@shift/design-system';
import { ToggleMockConsumer } from 'src/graphql/GraphQLProvider';
import { useSecurity } from 'src/libs/auth';
import { useHistory } from 'react-router-dom';

const gridItemStyle = { marginTop: '1rem' };

export const Settings = () => {
    const history = useHistory();
    const locale = useSelector((state: RootReducerType) => state.get('application').get('locale'));
    const dispatch = useDispatch();
    const { user } = useSecurity();

    const gridStyle = {
        border: 'black 1px solid',
        width: '100%',
    };

    const style = {
        width: '600px',
        padding: '0 0 0 10px',
        border: 'black 1px solid',
    };

    return (
        <Grid
            direction="column"
            style={{
                margin: '0px 28px',
            }}
        >
            <GridItem style={gridItemStyle}>
                <Button onClick={() => history.push('/recovery/tests')}>Recovery test page</Button>
            </GridItem>
            <GridItem style={gridItemStyle}>
                <p>
                    Current Language:
                    { locale}
                </p>
            </GridItem>
            <GridItem style={gridItemStyle}>
                <p>Change language:</p>
                <Grid>
                    <GridItem style={{ marginRight: '1rem' }}>
                        <Button onClick={() => dispatch(setLocale(Locale.en))}>English</Button>
                    </GridItem>
                    <GridItem style={{ marginRight: '1rem' }}>
                        <Button onClick={() => dispatch(setLocale(Locale.enSG))}>English SG</Button>
                    </GridItem>
                </Grid>
            </GridItem>
            {user && (
                <GridItem style={gridItemStyle}>
                    <p>
                        Below is the information from your ID token which was obtained during the&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://developer.okta.com/docs/guides/implement-auth-code-pkce">
                            PKCE Flow
                        </a>
                        &nbsp;and is now stored in local storage.
                    </p>
                    <Grid direction="column" style={gridStyle}>
                        <GridItem>
                            <Grid alignPlacement="center">
                                <GridItem style={style}>
                                    <p style={{ fontSize: 'x-large', textAlign: 'center' }}>Claim</p>
                                </GridItem>
                                <GridItem style={style}>
                                    <p style={{ fontSize: 'x-large', textAlign: 'center' }}>Value</p>
                                </GridItem>
                            </Grid>
                        </GridItem>
                        {Object.entries(user).map(([claimName, claimValue], index) => (
                            <GridItem key={index}>
                                <Grid alignPlacement="center">
                                    <GridItem style={style}>
                                        <p>{claimName}</p>
                                    </GridItem>
                                    <GridItem style={style}>
                                        <p>{JSON.stringify(claimValue, null, 4)}</p>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        ))}
                    </Grid>
                </GridItem>
            )}
            <GridItem style={gridItemStyle}>
                <ToggleMockConsumer />
            </GridItem>
        </Grid>
    );
};
