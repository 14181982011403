/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import merge from 'deepmerge';
import { get, set } from 'object-path';
import { formSections } from 'src/config/form';
import {
    SingleFieldInformation, FormSectionInformation, AnyType, ArrayFieldInformation, ArrayFieldTargets, FieldInformation,
} from 'src/types';
import { combineMerge } from 'src/utils/forms';
import { buildQuery } from './query-builder';

const formContext = 'accidentReport';

const pathToQueryArray = (path: string) => path.split('.').filter((e: string) => isNaN(Number(e)));

const buildFieldPath = (field: SingleFieldInformation | ArrayFieldInformation, context: string[] = []): string[][] => {
    const arrayField = field as ArrayFieldInformation;
    if (arrayField?.item) {
        return Object.values(arrayField.item).flatMap((subField) => buildFieldPath(
            subField,
            context.concat(pathToQueryArray(arrayField.target ?? arrayField.path)),
        ));
    }

    const singleField = field as SingleFieldInformation;
    if (singleField) {
        /**
         * Check to see if this is really necessary
         * Can't have a single field with a transform function only
         */
        if (singleField.formatter && !singleField.formatter.format) {
            return [];
        }

        if (singleField.target) {
            if (typeof singleField.target === 'string') {
                return [context.concat(pathToQueryArray(singleField.target as string))];
            }
            const arrayTarget = singleField.target as ArrayFieldTargets;
            if (arrayTarget?.item) {
                return Object.values(arrayTarget.item).map((target) => context.concat(pathToQueryArray(`${arrayTarget.name}.${target}`)));
            }
            return Object.values(singleField.target).map((target) => context.concat(pathToQueryArray(target)));
        }

        return [context.concat(pathToQueryArray(singleField.path))];
    }

    return [];
};

export const buildFormAccidentReportQuery = () => {
    const fieldsArrays: string[][] = Object.values(formSections)
        .flatMap((section) => Object.values(section.fields).flatMap((field) => buildFieldPath(field)).filter((e) => e.length > 0));
    return buildQuery(formContext, fieldsArrays);
};

// a small wrapper for set function that returns the object's value
const setValue = (object: AnyType, path: string, value: any): AnyType => {
    set(object, path, value);
    return object;
};

const getFieldValue = (accidentReport: AnyType, field: FieldInformation): AnyType => {
    const target = (field as SingleFieldInformation)?.target;
    if (target) {
        if (typeof target === 'string') {
            return get(accidentReport, target as string);
        }

        let paths: [string, string][];
        const arrayTarget = target as ArrayFieldTargets;
        let value: any;
        if (arrayTarget?.item) {
            const valueArray = get(accidentReport, arrayTarget.name) as any[];
            paths = Object.entries(arrayTarget.item);
            value = valueArray?.map((item: AnyType) => {
                let resultItem: any;
                paths.forEach(([key, val]) => {
                    const targetValue = get(item, val);
                    if (targetValue) {
                        resultItem = resultItem ?? {};
                        set(resultItem, key, targetValue);
                    }
                });
                return resultItem;
            }).filter((e) => !!e);
        } else {
            paths = Object.entries(target);
            paths.forEach(([key, val]) => {
                const targetValue = get(accidentReport, val);
                if (targetValue) {
                    value = value ?? {};
                    set(value, key, targetValue);
                }
            });
        }
        return value;
    }
    return get(accidentReport, field.path);
};

const loadFieldData = (field: FieldInformation, accidentReport: AnyType): AnyType => {
    let value = getFieldValue(accidentReport, field);
    if (value !== undefined && value !== null) {
        const arrayField = field as ArrayFieldInformation;
        if (arrayField?.item) {
            const arrayResult: AnyType[] = [];
            value.forEach((item: AnyType) => {
                let result: AnyType = {};
                Object.values(arrayField.item).forEach((itemField) => {
                    result = merge(result, loadFieldData(itemField, item), { arrayMerge: combineMerge });
                });
                arrayResult.push(result);
            });
            return setValue({}, arrayField.path, arrayResult);
        }

        if (field.formatter?.format) {
            value = field.formatter.format(value, accidentReport);
        }
    }
    return setValue({}, field.path, value);
};

export const loadForm = (accidentReport: AnyType): AnyType => {
    let result: AnyType = {};

    Object.values(formSections).forEach((formSection) => {
        const section = formSection as FormSectionInformation;
        Object.values(section.fields).forEach((field) => {
            result = merge(result, loadFieldData(field, accidentReport), { arrayMerge: combineMerge });
        });
    });
    return result;
};
