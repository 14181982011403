import { Formatter } from '@shift/transform';
import { AnyType } from 'src/types';

export type ListFormatterOptions = {
    isDistinct: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InnerListFormatter = (
    option?: ListFormatterOptions,
): Formatter<any[], { children: AnyType[] | null }> => ({
    transform: (params) => {
        let list = params && params.length > 0 ? params[0] : null;

        if (Array.isArray(list) && option?.isDistinct) {
            list = list.filter((t, i) => list && list.indexOf(t) === i);
        }

        return {
            children: list,
        };
    },
});


export const ListFormatter = InnerListFormatter();
export const ListFormatterWithOptions = (option: ListFormatterOptions) => InnerListFormatter(option);
