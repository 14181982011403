import React, { useEffect, useState } from 'react';

import { FilterableHeader } from '@shift/design-system';
import {
    useFieldSort, useFieldFilter, Operation, mapOrder, unmapOrder, Field,
} from '../../../libs/filters';
import { translate } from '../../../utils/forms';
import { IntegerInputFilter } from './IntegerInputFilter';


export const IntegerFilter = ({
    children, field, operation,
}: { field: Field; operation: Operation; children: React.ReactNode }) => {
    const [sort, setSort] = useFieldSort(field);
    const [filter, setFilter] = useFieldFilter(field);

    const [pendingFilter, setPendingFilter] = useState(filter);

    useEffect(() => {
        setPendingFilter(filter);
    }, [filter]);

    const pendingMinValue = pendingFilter?.parameters[0].value ? parseInt(pendingFilter?.parameters[0].value, 10) : null;
    const pendingMaxValue = pendingFilter?.parameters[1].value ? parseInt(pendingFilter?.parameters[1].value, 10) : null;
    const minValue = filter?.parameters[0].value ? parseInt(filter?.parameters[0].value, 10) : null;
    const maxValue = filter?.parameters[1].value ? parseInt(filter?.parameters[1].value, 10) : null;

    return (
        <FilterableHeader
            activeFilter={Number.isInteger(minValue) || Number.isInteger(maxValue)}
            filter={{ minValue: pendingMinValue, maxValue: pendingMaxValue }}
            onFilterChange={({ minValue: min, maxValue: max }) => {
                setPendingFilter({
                    ...operation,
                    parameters: [
                        { ...operation.parameters[0], value: min?.toString() ?? operation?.parameters[0].value },
                        { ...operation.parameters[1], value: max?.toString() ?? operation?.parameters[1].value },
                    ],
                });
            }}
            onSubmit={() => setFilter(pendingFilter)}
            onClear={() => { setFilter(null); setPendingFilter(null); setSort(null); }}
            FilterComponent={IntegerInputFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            sortAscText={translate('common:sort-number-asc')}
            sortDescText={translate('common:sort-number-desc')}
            order={mapOrder(sort)}
            onOrderChange={(o) => setSort(unmapOrder(o))}
        >
            {children}
        </FilterableHeader>
    );
};
