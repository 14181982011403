import { Formatter } from '@shift/transform';
import { EnumValue } from 'src/types';
import { EnumFormatters } from './EnumValuesFormatter';

export const VehicleModelFormatter: Formatter<string[], { children: string }> = ({
    transform: (params) => {
        const [model] = params[0][0]?.split('___') ?? [];
        return { children: model };
    },
});

export const VehicleVariantFormatter: Formatter<string[], { children: string }> = ({
    transform: (params) => {
        const [, variant] = params[0][0]?.split('___') ?? [];
        return { children: variant };
    },
});

export const VehicleMakeFormatter: Formatter<(string | EnumValue)[], { children: string }> = ({
    transform: (params) => {
        const [[isNotEnum], [makeEnum], [makeText]] = params as [[string], [EnumValue], [string], ];

        return { children: isNotEnum ? makeText : EnumFormatters.EnumValuesFormatter.transform([[makeEnum]]).children };
    },
});

export const VehicleFuelFormatter: Formatter<(string | EnumValue)[], { children: string }> = ({
    transform: (params) => {
        const [[fuelEnum], [fuelText]] = params as [ [EnumValue], [string], ];

        return { children: !fuelEnum ? fuelText : EnumFormatters.EnumValuesFormatter.transform([[fuelEnum]]).children };
    },
});
