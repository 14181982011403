/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
    FileInfo, SelectInputOption, FilesSelection as BaseFilesSelection, VariantProvider,
} from '@shift/design-system';

import { useTranslations } from '@gears/translations';

import { useFetch } from 'src/libs/fetch';
import { apiConfig } from 'src/config';
import { OnChangeFileValue } from './files-upload';

export interface FilesSelectionProps {
    value: FileInfo[];
    selectableFiles: FileInfo[];
    onChange: (values: OnChangeFileValue[]) => any;
    types?: SelectInputOption[];
    reportReference: string;
}

export const FilesSelection = ({
    value,
    selectableFiles,
    types,
    onChange,
    reportReference,
    ...props
}: FilesSelectionProps) => {
    const commonTranslate = useTranslations('common');
    const [files, setFiles] = useState<FileInfo[]>(selectableFiles || []);

    const { download } = useFetch();

    const selection = value ? value.map((f) => f.name) : [];

    const dl = ((name: string) => download(`${apiConfig.importedDocumentUri}/${reportReference}/${name}`, name));

    return (
        <VariantProvider component="button" name="secondary">
            <BaseFilesSelection
                files={files ?? []}
                types={types || []}
                onTypeChanged={(id, type) => {
                    const newFileList = files.map((item) => (item.id === id ? { ...item, type } : item));
                    setFiles(newFileList);

                    const newValue = value ? value.map((f) => (f.id === id ? { ...f, type } : f)) : [];
                    onChange(newValue as OnChangeFileValue[]);
                }}
                i18n={
                    {
                        documentType: commonTranslate('document-type'),
                        filename: commonTranslate('filename'),
                    }
                }
                onClickOnFile={(_url, filename) => filename && dl(filename)}
                selection={selection}
                onSelectionChange={(names) => {
                    const newList = files.filter((f) => names.indexOf(f.name) > -1);
                    onChange(newList as OnChangeFileValue[]);
                }}
                pageSize={8}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        </VariantProvider>
    );
};
