import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from '../../../libs/filters';
import { LiabilityFilter, LiabilityFilterProps } from '../../../components/Table/Filters';

export const LiabilityFilterWidgetterFactory:
    (side?: LiabilityFilterProps['side']) => Widgetter<FilterProps, FilterProps, Filter> = (side) => ({
        transform: ({ children }, options) => (options ? (
            <LiabilityFilter
                side={side}
                field={options.field}
                operation={options.operation}
            >
                {children}
            </LiabilityFilter>
        ) : children as ReactElement),
    });

export const LiabilityFilterWidgetter = LiabilityFilterWidgetterFactory();
