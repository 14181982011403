/* eslint-disable @typescript-eslint/camelcase */
import { ProcessedSubConfiguration } from 'src/libs/config';
import { ArcConfig } from 'src/config/configuration';
import { buildListQuery } from '..';

export const arcsMock = (config: ProcessedSubConfiguration<ArcConfig>) => [
    // Recoveries list
    {
        request: {
            query: buildListQuery(
                config.list.context,
                config.list.fields,
                'ProviderFields',
            ),
            variables: {
                parameters: { start: 0, length: 10 },
            },
        },
        newData: () => ({
            data: {
                arcList: {
                    totalCount: 2,
                    itemsCount: 2,
                    items: [
                        {
                            id: 1,
                            person: {
                                id: 1,
                                specializations: {
                                    id: 1,
                                    mapping_Person_Gears: {
                                        id: 1,
                                        name: 'ARC1',
                                    },
                                },
                                addresses: [{
                                    id: 3,
                                    fullAddress: '171A Edgedale plains',
                                    postcode: '821171',
                                }],
                                phoneNumbers: [{
                                    id: 3,
                                    number: '91158859',
                                    countryCode: '65',
                                }],
                            },
                            insurers: [
                                {
                                    Type: 'InsurerCode',
                                    Name: 'InsurerA',
                                },
                                {
                                    Type: 'InsurerCode',
                                    Name: 'InsurerB',
                                },
                            ],
                        },
                        {
                            id: 2,
                            person: {
                                id: 2,
                                specializations: {
                                    id: 2,
                                    mapping_Person_Gears: {
                                        id: 2,
                                        name: 'ARC2',
                                    },
                                },
                                addresses: [{
                                    id: 3,
                                    fullAddress: '171A Other plains',
                                    postcode: '820071',
                                }],
                                phoneNumbers: [{
                                    id: 3,
                                    number: '12345698',
                                    countryCode: '65',
                                }],
                            },
                            insurers: [
                                {
                                    Type: 'InsurerCode',
                                    Name: 'InsurerB',
                                },
                                {
                                    Type: 'InsurerCode',
                                    Name: 'InsurerC',
                                },
                            ],
                        },
                    ],
                },
            },
        }),
    },
];
