import React from 'react';
import {
    Switch, Redirect, Route,
} from 'react-router-dom';

import { Route as SupervisedRoute } from '@shift/telemetry';

import { Home } from 'src/pages/home';
import { SecureRoute, useSecurity } from 'src/libs/auth';
import { AccidentReportFormPage } from 'src/pages/accident-report-form/form';
import { AccidentReportSubmittedPage } from 'src/pages/accident-report-form/submitted';
import { Settings } from 'src/pages/settings';
import { Reporting } from 'src/pages/reports';
import { Claims } from 'src/libs/auth/Authenticator';
import { TokenPredicate, permissions } from 'src/config/permissions';
import { useArcInsurersList } from 'src/utils/hooks';
import { CallbackPage } from 'src/pages/myinfo-reporting/callback';
import { DriverOwner } from 'src/pages/myinfo-reporting/driver-owner';
import { GridCenteredCircularLoader } from '../../components/Loader/GridCenteredCircularLoader';
import {
    accidentReportDetail,
    accidentReportList,
    arcForm,
    arcList,
    ncd,
    insurerEnquiry,
    arcSubmitted,
    recoveries,
    recovery,
    recoveryTests,
    exploration,
    settings,
    thirdPartyReports,
    folderList,
    folderDetail,
    account,
    reporting,
    callback,
    arcPreForm,
} from '../../config/routes';

const AccidentReportDetails = React.lazy(() => import('src/pages/accident-reports/details'));
const AccidentReports = React.lazy(() => import('src/pages/accident-reports/list'));
const RecoveryPage = React.lazy(() => import('src/pages/recoveries/details'));
const RecoveriesPage = React.lazy(() => import('src/pages/recoveries/list'));
const RecoveryTestsPage = React.lazy(() => import('src/pages/recoveries/tests'));
const ExplorationPage = React.lazy(() => import('src/pages/exploration'));
const NcdPage = React.lazy(() => import('src/pages/ncd'));
const InsurerEnquiryPage = React.lazy(() => import('src/pages/insurer-enquiry'));
const AccountPage = React.lazy(() => import('src/pages/account'));
const ThirdPartyReportsPage = React.lazy(() => import('src/pages/third-party-reports'));
const ArcsPage = React.lazy(() => import('src/pages/arcs/list'));
const FolderListPage = React.lazy(() => import('src/pages/folders/list'));
const FolderDetailPage = React.lazy(() => import('src/pages/folders/details'));

const Page404 = () => <p>Oops, you got lost...</p>;

const hasPermission = (
    user: Claims | null,
    { permissions: perm }: { permissions: TokenPredicate | undefined },
) => {
    if (!user) {
        return false;
    }
    if (!perm) {
        return true;
    }
    return perm(user);
};

export const LoggedContent = () => {
    const { user } = useSecurity();

    const { options, loading } = useArcInsurersList();

    const hasAccreditationsIfArc = user && permissions.isArcOnly(user) ? !!options.length : true;

    return loading ? <GridCenteredCircularLoader /> : (
        <>
            { /* eslint-disable-next-line react/jsx-props-no-spreading */}
            <SecureRoute render={(props) => <Home {...props} hasAccreditationsIfArc={hasAccreditationsIfArc} />} />
            <React.Suspense
                fallback={(
                    <GridCenteredCircularLoader
                        size="50px"
                        width="5px"
                        height="30vh"
                    />
                )}
            >
                <Switch>
                    {hasPermission(user, accidentReportDetail) && hasAccreditationsIfArc && (
                        <Route
                            path={accidentReportDetail.path}
                            component={AccidentReportDetails}
                        />
                    )}
                    {hasPermission(user, accidentReportList) && hasAccreditationsIfArc && (
                        <Route
                            path={accidentReportList.path}
                            component={AccidentReports}
                        />
                    )}
                    {hasPermission(user, arcList) && (
                        <Route path={arcList.path} component={ArcsPage} />
                    )}
                    {hasPermission(user, arcPreForm) && hasAccreditationsIfArc && (
                        <Route
                            path={arcPreForm.path}
                            component={DriverOwner}
                        />
                    )}
                    {hasPermission(user, arcForm) && hasAccreditationsIfArc && (
                        <Route
                            path={arcForm.path}
                            component={AccidentReportFormPage}
                        />
                    )}
                    {hasPermission(user, arcSubmitted) && (
                        <Route
                            path={arcSubmitted.path}
                            component={AccidentReportSubmittedPage}
                        />
                    )}
                    {hasPermission(user, recovery) && (
                        <Route path={recovery.path} component={RecoveryPage} />
                    )}
                    {hasPermission(user, recoveries) && (
                        <Route
                            path={recoveries.path}
                            component={RecoveriesPage}
                        />
                    )}
                    {hasPermission(user, recoveryTests) && (
                        <Route
                            path={recoveryTests.path}
                            component={RecoveryTestsPage}
                        />
                    )}
                    {hasPermission(user, exploration) && (
                        <Route
                            path={exploration.path}
                            component={ExplorationPage}
                        />
                    )}
                    {hasPermission(user, ncd) && (
                        <Route path={ncd.path} component={NcdPage} />
                    )}
                    {hasPermission(user, insurerEnquiry) && (
                        <Route
                            path={insurerEnquiry.path}
                            component={InsurerEnquiryPage}
                        />
                    )}
                    {hasPermission(user, thirdPartyReports) && (
                        <Route
                            path={thirdPartyReports.path}
                            component={ThirdPartyReportsPage}
                        />
                    )}
                    {hasPermission(user, settings) && (
                        <SupervisedRoute path={settings.path} component={Settings} />
                    )}
                    {hasPermission(user, reporting) && (
                        <SupervisedRoute path={reporting.path} component={Reporting} />
                    )}
                    {hasPermission(user, account) && (
                        <Route path={account.path} component={AccountPage} />
                    )}
                    {hasPermission(user, accidentReportList) && hasAccreditationsIfArc && (
                        <Redirect exact from="/" to={accidentReportList.path} />
                    )}
                    {hasPermission(user, arcForm) && hasAccreditationsIfArc && (
                        <Redirect exact from="/" to={arcForm.path} />
                    )}
                    {hasPermission(user, ncd) && (
                        <Redirect exact from="/" to={ncd.path} />
                    )}
                    {hasPermission(user, insurerEnquiry) && (
                        <Redirect exact from="/" to={insurerEnquiry.path} />
                    )}
                    {hasPermission(user, folderDetail) && (
                        <Route
                            path={folderDetail.path}
                            component={FolderDetailPage}
                        />
                    )}
                    {hasPermission(user, folderList) && (
                        <Route
                            path={folderList.path}
                            component={FolderListPage}
                        />
                    )}
                    {hasPermission(user, folderList) && !hasAccreditationsIfArc && (
                        <Redirect exact from="/" to={folderList.path} />
                    )}
                    <Route
                        path={callback.path}
                        component={CallbackPage}
                    />
                    <SupervisedRoute component={Page404} />
                </Switch>
            </React.Suspense>
        </>
    );
};
