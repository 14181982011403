/* eslint-disable no-restricted-globals */
import React, {
    useMemo, useCallback,
} from 'react';
import {
    SplitDateInput as SplitDateInputBase,
    SplitDateInputProps,
} from '@shift/design-system';
import moment from 'moment-timezone';
import { DateFormat } from 'src/constants';
import { toDateTimeWithoutTz } from '../../../utils';

export interface SplitDateProps extends
    Omit<SplitDateInputProps, 'value' | 'placeholder' | 'onChange' > {
    value: string;
    onChange: (date: string) => void;
}

export const SplitDateInput = ({
    name,
    value,
    onChange,
    formatValue,
    onFocus,
    onBlur,
    error,
    disabled,
    valid,
}: SplitDateProps) => {
    const date = useMemo(() => {
        if (value) {
            const tz = moment.tz.guess(true);
            return toDateTimeWithoutTz(moment.tz(value, DateFormat, tz));
        }
        return undefined;
    }, [value]);

    const onChangeCb = useCallback((val: Date | undefined) => {
        if (onChange) {
            if (val) {
                onChange(moment({ year: val.getFullYear(), month: val.getMonth(), day: val.getDate() }).format(DateFormat));
            } else {
                onChange('');
            }
        }
    }, [onChange]);

    return (
        <SplitDateInputBase
            name={name}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            valid={valid}
            error={error}
            value={date}
            onChange={onChangeCb}
            placeholder={
                {
                    day: 'Day',
                    month: 'Month',
                    year: 'Year',
                }
            }
            // hard coded for now, I'll update it in a next PR
            formatValue={formatValue}
        />
    );
};
