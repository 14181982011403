import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageProps {
    title?: string;
    children: React.ReactNode;
}

export const Page = ({ title, children }: PageProps) => (
    <>
        <Helmet titleTemplate={title && '%s — GEARS'}>
            <title>{title || 'GEARS'}</title>
        </Helmet>
        {children}
    </>
);
