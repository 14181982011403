import { Path } from '@shift/transform';
import { ParameterType, SearchOperationType, DocumentFields } from 'src/libs/filters';
import { Formatters } from '../../../formatters';
import { Widgetters } from '../../../widgets';
import { FolderConfig } from '../../types';

const rootContext: Path = ['folder'];

export const folderConfig: FolderConfig = {
    list: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'folder-list',
        transforms: [
            {
                i18NId: 'folder-name',
                chrooters: [
                    ['id'],
                    ['documentName'],
                ],
                formatter: Formatters.GoToFolderPageFormatter,
                widgetter: Widgetters.LinksWidgetter,
                filter: {
                    field: { id: DocumentFields.Name },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'folder-category',
                chrooters: [
                    ['specializations', 'document_Document_StatisticReport_Category', 'category'],
                ],
                formatter: Formatters.EnumValuesFormatter,
                filter: {
                    field: { id: DocumentFields.Category },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('StatisticReportCategory'),
                },
            },
            {
                i18NId: 'folder-description',
                chrooters: [
                    ['specializations', 'mapping_Document_FreeText', 'freeTextDescription'],
                ],
                filter: {
                    field: { id: DocumentFields.Description },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
        ],
    },
    documentName: {
        configType: 'Processed',
        context: rootContext.map((segment) => segment.toString()),
        fields: [
            ['documentName'],
        ],
    },
    details: {
        configType: 'KeyValueCard',
        context: rootContext,
        i18Namespace: 'folder-details',
        transforms: [
            {
                chrooters: [['documentName']],
            },
        ],
    },
    description: {
        configType: 'SingleValueCard',
        context: rootContext,
        transforms: [
            {
                chrooters: [
                    ['specializations', 'mapping_Document_FreeText', 'freeTextDescription'],
                ],
                formatter: Formatters.SingleValueFormatter,
                widgetter: Widgetters.TextWidgetter,
            },
        ],
    },
    files: {
        configType: 'Table',
        context: rootContext.concat(['documentToDocumentsByFirstDocument']),
        i18Namespace: 'folder-details',
        transforms: [
            {
                i18NId: 'file-name',
                chrooters: [
                    ['secondDocument', 'documentName'],
                ],
            },
            {
                i18NId: 'file-description',
                chrooters: [
                    ['secondDocument', 'specializations', 'mapping_Document_FreeText', 'freeTextDescription'],
                ],
            },
            {
                i18NId: 'download',
                chrooters: [
                    ['secondDocument', 'id'],
                    ['secondDocument', 'documentName'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'scheme'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'userInfo'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'host'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'port'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'path'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'query'],
                    ['secondDocument', 'specializations', 'document_Document_ExternalStorageInformation', 'fragment'],
                ],
                formatter: Formatters.DocumentLinkFormatter,
                widgetter: Widgetters.DocumentLinkWidgetter,
            },
        ],
    },
};
