import { TextFilterWidgetter, TextFilterWidgetterFactory } from './TextFilterWidget';
import { DateFilterWidgetter } from './DateFilterWidget';
import { BooleanFilterWidgetter, BooleanFilterWidgetterFactory } from './BooleanFilterWidget';
import { EqualConditionBooleanFilterWidgetterFactory } from './EqualConditionBooleanFilterWidget';
import { EnumFilterWidgetterFactory } from './EnumFilterWidgettterFactory';
import { DaysLeftFilterWidgetter } from './DaysLeftFilterWidget';
import { DaysSinceFilterWidgetter } from './DaysSinceFilterWidget';
import { LiabilityFilterWidgetter } from './LiabilityFilterWidget';
import { IntegerFilterWidgetter } from './IntegerFilterWidget';

export const FilterFactories = {
    EnumFilterWidgetterFactory,
    BooleanFilterWidgetterFactory,
    TextFilterWidgetterFactory,
    EqualConditionBooleanFilterWidgetterFactory,
};

export const Filter = {
    ...FilterFactories,
    TextFilterWidgetter,
    DateFilterWidgetter,
    BooleanFilterWidgetter,
    DaysLeftFilterWidgetter,
    DaysSinceFilterWidgetter,
    LiabilityFilterWidgetter,
    IntegerFilterWidgetter,
};
