import { Formatter } from '@shift/transform';
import { sgCountryCode } from 'src/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneNumberFormatter: Formatter<string[], { children: string }> = {
    transform: (params) => {
        const [[countryCode], [number]] = params;
        return {
            children: number ? `+${countryCode ?? sgCountryCode.dialCode} ${number}` : '',
        };
    },
};
