import React, {
    createContext, ReactElement, useState, useCallback, useContext,
} from 'react';
import {
    ModalHeader, ModalContent, Modal, StyledText, ThemeContext, withCustomStyles, Card,
    TextButton,
} from '@shift/design-system';

import { noop } from './utils';

interface ModalContext {
    displayMessage: (message: string[]) => void;
}

export const MessageModalContext = createContext<ModalContext>({ displayMessage: noop });

export const MessageModalProvider = ({ children }: { children: ReactElement }) => {
    const [messages, setMessage] = useState<string[] | null>(null);
    const displayMessage = useCallback((msg: string[]) => setMessage(msg), []);
    const { colors } = useContext(ThemeContext);
    const CustomCard = withCustomStyles(Card);

    if (messages && (!Array.isArray(messages) || !messages.length)) {
        return null;
    }
    return (
        <>
            <Modal visible={!!messages} onHide={() => setMessage(null)} zIndex={50000}>
                <ModalHeader>Notification</ModalHeader>
                <ModalContent>
                    <CustomCard style={{ flex: 1, backgroundColor: colors.background.error }}>
                        {messages?.map((message) => (
                            <StyledText color={colors.text.header}>
                                {message.toString()}
                            </StyledText>
                        ))}
                    </CustomCard>
                </ModalContent>
                <div style={{ margin: '1rem' }}>
                    <TextButton onClick={() => setMessage(null)}>
                        Close
                    </TextButton>
                </div>
            </Modal>
            <MessageModalContext.Provider value={{ displayMessage }}>
                {children}
            </MessageModalContext.Provider>
        </>
    );
};
