/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment-timezone';
import { translate } from '@gears/translations';
import { get } from 'object-path';
import { PhoneNumber, FileInfo, Location } from '@shift/design-system';

import {
    AnyType, FormSectionInformation, SingleFieldInformation, ArrayFieldInformation, ValidationError, FieldInformation,
} from 'src/types';
import { DateFormat, DateFormatRegex } from 'src/constants';
import { formTranslate } from 'src/utils/forms/utils';
import { OnChangeFileValue } from '../../components/form/fields/files-upload';

export const isRequired = (value: any) => {
    if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
        return formTranslate('field-mandatory');
    }
    return undefined;
};

export const isRequiredIfCondition = (
    condition: (formValues: AnyType) => any,
    requiredFunc: (value: any) => any = isRequired,
) => (value: any, formValues: AnyType) => {
    if (formValues && condition(formValues)) {
        return requiredFunc(value);
    }
    return undefined;
};

export const phoneNumberRequired = (value: PhoneNumber) => {
    if (!value?.countryCode || !value?.number) {
        return formTranslate('field-mandatory');
    }
    return undefined;
};

export const isValidFile = (value: OnChangeFileValue[]) => {
    const hasUploadingFiles = value && value.some && value.some((file) => file.id <= 0);
    if (hasUploadingFiles) {
        return formTranslate('file-uploading-or-failed');
    }
    return undefined;
};

export const validateIfCondition = (
    condition: (formValues: AnyType) => any,
    validateFunc: (value: any, formValues: AnyType) => any,
) => (value: any, formValues: AnyType) => {
    if (formValues && condition(formValues)) {
        return validateFunc(value, formValues);
    }
    return undefined;
};

export const validateListIfCondition = (conditionFieldName: string) => (value: any, formValues: AnyType) => {
    if (formValues && get(formValues, conditionFieldName) && (!value || value.length === 0)) {
        return 'There should be at least one item';
    }
    return undefined;
};

export const validateListCount = (listFieldName: string, elementLabel: string, offset: number) => (value: any, formValues: AnyType) => {
    const list = formValues ? get(formValues, listFieldName) : null;
    const countValue = value ? parseInt(value, 10) : 0;
    if (list && list.length > countValue - offset) {
        return `This field should be greater than the number of ${formTranslate(elementLabel)}s (${list.length + offset})`;
    }
    return undefined;
};

export const validatePassengerListCount = (listFieldName: string) => (value: any, formValues: AnyType) => {
    const list = formValues ? get(formValues, listFieldName) : null;
    const countValue = value ? parseInt(value, 10) : 0;
    const maximumRequiredPassengers = 7;

    const expectedCount = countValue === 0 ? 0 : (countValue - 1); // minus 1 to exclude the driver
    const actualCount = list?.length ?? 0;

    if (actualCount > expectedCount) {
        return translate('accident-report-form:remove-passengers', { count: actualCount - expectedCount });
    }

    const upperBound = Math.min(expectedCount, maximumRequiredPassengers);
    if (actualCount < upperBound) {
        return translate('accident-report-form:add-passengers', { count: upperBound - actualCount });
    }

    return undefined;
};

export const isValidDate = (value: string) => (
    value && (!DateFormatRegex.test(value) || !moment(value, DateFormat).isValid())
        ? translate('common:invalid-date')
        : undefined
);

export const isValidPassDate = (dateOfBirth: string) => (value: any, formValues: AnyType) => {
    if (value && (!DateFormatRegex.test(value) || !moment(value, DateFormat).isValid())) {
        return translate('common:invalid-date');
    }
    const formBirthDate = formValues ? get(formValues, dateOfBirth) : null;
    const birthDate = moment(formBirthDate, DateFormat);
    const passDrivingDate = moment(value, DateFormat);
    if (birthDate > passDrivingDate) {
        return translate('common:invalid-pass-date');
    }
    return undefined;
};

export const isValidLocation = (value: Location) => (
    value && (!value.fullAddress || !value.coordinates || !value.coordinates.lat || !value.coordinates.lng)
        ? translate('common:invalid-location')
        : undefined
);

export const isValidPhoneNumber = (value: PhoneNumber) => (
    value?.number && !value.countryCode
        ? translate('common:invalid-phone-number')
        : undefined
);

export const isValidFileWithType = (value: FileInfo[]) => {
    if (!value) {
        return undefined;
    }
    const hasUploadingFiles = value.some((file) => file.id <= 0);
    if (hasUploadingFiles) {
        return formTranslate('file-uploading-or-failed');
    }
    const errors = value.some((f) => f?.name && !f.type?.value);
    return errors ? translate('document:invalid-document-type') : undefined;
};

export const isValidCCValue = (value: number) => {
    if (!value) {
        return undefined;
    }
    return value < 0 || value > 20000 ? translate('common:invalid-cc') : undefined;
};


const isValidRegexFactory = (regex: RegExp, invalidMessageTranslationKey: string) => (value: string) => {
    if (value && !regex.test(value)) {
        return translate(invalidMessageTranslationKey);
    }
    return undefined;
};

// copy-pasted from https://www.regular-expressions.info/email.html
// eslint-disable-next-line max-len
const emailRegex = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const isValidEmail = isValidRegexFactory(emailRegex, 'common:invalid-email');

const alphanumericRegex = /^[a-zA-Z0-9]*$/;
export const isValidAlphanumeric = isValidRegexFactory(alphanumericRegex, 'common:invalid-alpha-numeric');

export const isValidLossAmount = (value: number) => {
    if (value <= 999999999) {
        return undefined;
    }
    return translate('recovery:invalid-loss-amount');
};

// shamelessly copy-pasted and adapted from https://codepen.io/liakwee/pen/aGrVbG
export const isValidNRIC = (value: string) => {
    if (!value) {
        return undefined;
    }
    if (value.length !== 9) {
        return formTranslate('invalid-nric-length');
    }
    const icArray = new Array(9);
    for (let i = 0; i < 9; i += 1) {
        icArray[i] = value.charAt(i);
    }
    icArray[1] *= 2;
    icArray[2] *= 7;
    icArray[3] *= 6;
    icArray[4] *= 5;
    icArray[5] *= 4;
    icArray[6] *= 3;
    icArray[7] *= 2;

    let weight = 0;
    for (let i = 1; i < 8; i += 1) {
        weight += parseInt(icArray[i], 10);
    }

    let offset;
    if (icArray[0] === 'T' || icArray[0] === 'G') {
        offset = 4;
    } else if (icArray[0] === 'M') {
        offset = 3;
    } else {
        offset = 0;
    }
    const temp = (offset + weight) % 11;

    const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
    const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    const m = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'J', 'L', 'K'];

    let theAlpha;
    if (icArray[0] === 'S' || icArray[0] === 'T') {
        theAlpha = st[temp];
    } else if (icArray[0] === 'F' || icArray[0] === 'G') {
        theAlpha = fg[temp];
    } else if (icArray[0] === 'M') {
        theAlpha = m[temp];
    }
    if (icArray[8] !== theAlpha) {
        return formTranslate('invalid-nric');
    }
    return undefined;
};

export const isValidNationalIdentifier = (typeFieldName: string) => (value: any, formValues: AnyType) => {
    const typeValue = get(formValues, typeFieldName)?.value;
    if (typeValue !== 'NationalIdentifierType.NationalIdentifier') {
        return undefined;
    }
    return isValidNRIC(value);
};

export const composeValidators = (...validators: any[]) => (value: any) => validators.reduce(
    (error, validator) => error || validator(value),
    undefined,
);

export const isSectionValid = (errors: AnyType, name: string) => !get(errors, name);

const validateFieldData = (field: FieldInformation, accidentReport: AnyType,
    errors: AnyType, isDraft: boolean): ValidationError[] => {
    const value = get(accidentReport, field.path);
    let result: ValidationError[] = [];

    const error = get(errors, field.path);
    if (!error) {
        return result;
    }

    const arrayField = field as ArrayFieldInformation;
    if (arrayField?.item && value) {
        value.forEach((item: AnyType, index: number) => {
            Object.values(arrayField.item).forEach((itemField) => {
                result = result.concat(validateFieldData(itemField, item, error[index], isDraft));
            });
        });
        return result;
    }

    const singleField = field as SingleFieldInformation;
    if (!isDraft) {
        result.push({ field: formTranslate(singleField.label), error });
    }

    if (isDraft && singleField.validate && value) {
        const validationError = singleField.validate(value, accidentReport);
        if (validationError) {
            result.push({ field: formTranslate(singleField.label), error: validationError });
        }
    }
    return result;
};

export const validateForm = (formSections: AnyType, accidentReport: AnyType, errors: AnyType, isDraft: boolean): ValidationError[] => {
    let result: ValidationError[] = [];
    Object.values(formSections).forEach((formSection) => {
        const section = formSection as FormSectionInformation;
        Object.values(section.fields).forEach((field) => {
            result = result.concat(validateFieldData(field, accidentReport, errors, isDraft));
        });
    });
    return result;
};
