import { Formatter } from '@shift/transform';
import { TrajectoryIconWidgetProps } from '../widgets/TrajectoryIconWidget';
import { PercentageWidgetProps } from '../widgets/PercentageWidget';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NcdValuesFormatter: Formatter<any[], TrajectoryIconWidgetProps> = {
    transform: ([[renewalNcd], [currentNcd]]) => ({
        currentNcd,
        renewalNcd,
    }),
};

export const CurrentNcdValuesFormatter: Formatter<number[], PercentageWidgetProps> = {
    transform: ([[currentNcd]]) => ({
        currentNcd,
    }),
};
