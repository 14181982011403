import { toUtcDateFormat, toUtcDateTimeFormat } from 'src/utils';
import { vehicleFuels } from './constants/vehicleFuels';
import { vehicleMakes } from './constants/vehicleMakes';
import { vehicleModels } from './constants/vehicleModels';
import { vehicleTypes } from './constants/vehicleTypes';
import { DateUtcFormat } from 'src/constants';

export interface IVehicleInfo {
    vehicleno?: string;
    type?: string;
    make?: string;
    model?: string;
    propellant?: string;
    transmission?: string;
    enginecapacity?: number;
    chassisno?: string;
    firstregistrationdate? : string;
    effectiveownershipdate? : string;
  }

export const mapVehicle = (state: any) => {
    const { isVehicleOwner, vehicleInfo: vi } = state;
    if (!vi || !isVehicleOwner) return {};

    const mapping_Object_Motor_Vehicle: any = {};

    mapFuel(mapping_Object_Motor_Vehicle, vi);

    const make = mapMake(mapping_Object_Motor_Vehicle, vi);

    mapModel(mapping_Object_Motor_Vehicle, vi, make);

    mapType(mapping_Object_Motor_Vehicle, vi);

    mapEngineCapacity(mapping_Object_Motor_Vehicle, vi);
    mapEffectiveOwnershipDate(mapping_Object_Motor_Vehicle, vi);

    mapFuel(mapping_Object_Motor_Vehicle, vi);
    mapChassisNo(mapping_Object_Motor_Vehicle, vi);
  
    mapFirstRegistrationDate(mapping_Object_Motor_Vehicle, vi);

    return mapping_Object_Motor_Vehicle;
};

const mapType = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
    if (!vi.type) return;

    const mappedType = Object.entries(vehicleTypes).find(
        (x) => x[0].toLocaleLowerCase() === vi.type?.toLocaleLowerCase()
    );
    if (mappedType) {
        mapping_Object_Motor_Vehicle.type = `VehicleType.${mappedType}`;
    }
};

const mapMake = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
    if (!vi.make) return;

    const lowercaseMake = vi.make?.toLocaleLowerCase();
    const make = Object.entries(vehicleMakes).find(
        (x) => x[1].toLocaleLowerCase() === lowercaseMake
    )?.[0];

    if (make) {
        mapping_Object_Motor_Vehicle.make = `VehicleMake.${make}`;
    } else {
        mapping_Object_Motor_Vehicle.makeNotInEnum = true;
        mapping_Object_Motor_Vehicle.makeDescription = vi.make;
    }

    return make;
};

const mapModel = (
    mapping_Object_Motor_Vehicle: any,
    vi: IVehicleInfo,
    make: string | undefined
) => {
    if (!vi.model) return;

    if (!make) {
        mapping_Object_Motor_Vehicle.model = `${vi.model}___`;
        return;
    }

    const models = Object.entries(vehicleModels)
        .filter((x) => x[0].startsWith(`${make}_`))
        ?.map((x) => ({
            code: x[0].split('_')[1],
            desc: x[1].toLocaleLowerCase(),
        }));

    const lowercaseModel = vi.model?.toLocaleLowerCase();
    const model = models?.find((x) => x.desc === lowercaseModel)?.code;
    mapping_Object_Motor_Vehicle.model = `${model ?? vi.model}___`;
};

const mapFuel = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {

    const lowercaseFuel = vi.propellant?.toLocaleLowerCase();
    const vehicleFuel = Object.entries(vehicleFuels).find(
        (x) => x[0].toLocaleLowerCase() === lowercaseFuel
    )?.[1];
    if (vehicleFuel) {
      mapping_Object_Motor_Vehicle.vehicleFuel = `VehicleFuel.${vehicleFuel}`;
    } else {
      mapping_Object_Motor_Vehicle.vehicleFuelSourceName = vi.propellant;
    }
  };

const mapEngineCapacity = (
    mapping_Object_Motor_Vehicle: any,
    vi: IVehicleInfo
) => {
    if (!vi.enginecapacity) return;

    mapping_Object_Motor_Vehicle.engineCapacityValue = vi.enginecapacity;
};

const mapEffectiveOwnershipDate = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
    if (!vi.effectiveownershipdate) return;
    var result = toUtcDateTimeFormat(vi.effectiveownershipdate);
    mapping_Object_Motor_Vehicle.purchaseDateUtc = result !== undefined ?`${result?.utc().format(DateUtcFormat)}`: undefined;
  }

  const mapChassisNo = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
    if (!vi.chassisno) return;
    
    mapping_Object_Motor_Vehicle.vIN = vi.chassisno;
  }
  
  
  const mapFirstRegistrationDate = (mapping_Object_Motor_Vehicle: any, vi: IVehicleInfo) => {
    if (!vi.firstregistrationdate) return;
  
    const result = toUtcDateFormat(vi.firstregistrationdate);
   
    mapping_Object_Motor_Vehicle.firstRegistrationDateUtc = result;
  }