import { Formatter } from '@shift/transform';
import { translate } from '../../utils/forms';

export const BooleanValueFormatter: Formatter<boolean[], { children: string }> = {
    transform: (bool) => {
        const value = bool.flat()?.[0];
        const key = value ? 'common:yes' : 'common:no';
        return {
            children: (value === null || value === undefined) ? '' : translate(key),
        };
    },
};
export const BooleanValueFallbackNoFormatter: Formatter<boolean[], { children: string }> = {
    transform: (bool) => {
        const value = bool.flat()?.[0];
        const key = value ? 'common:yes' : 'common:no';
        return {
            children: (value === null || value === undefined) ? translate('common:no') : translate(key),
        };
    },
};
export function ConditionalBooleanValueFormatterFactory<T>(
    condition: (value: T) => boolean,
): Formatter<T[], { children: string }> {
    return {
        transform: (val) => {
            const value = val.flat()?.[0];
            const key = condition(value) ? 'common:yes' : 'common:no';
            return {
                children: (value === null || value === undefined) ? '' : translate(key),
            };
        },
    };
}
