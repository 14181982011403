import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from 'src/libs/filters';
import { DateFilter } from 'src/components/Table/Filters';

export const DateFilterWidgetter: Widgetter<FilterProps, FilterProps, Filter> = {
    transform: ({ children }, options) => (options ? (
        <DateFilter
            field={options.field}
            operation={options.operation}
        >
            {children}
        </DateFilter>
    ) : children as ReactElement),
};
