import { configProviderFactory } from '../../../libs/config/ConfigBuilder';
import { GearsConfig, GearsSubConfigKeys } from '../types';

export const {
    Provider: GearsConfigProvider,
    Consumer: GearsConfigConsumer,
    useConfig: useGearsConfig,
} = configProviderFactory<GearsConfig>();

export const useAccidentReportsConfig = <K2 extends GearsSubConfigKeys<'accidentReport'>>
    (subContext: K2) => useGearsConfig('accidentReport', subContext);

export const useRecoveryConfig = <K2 extends GearsSubConfigKeys<'recovery'>>
    (subContext: K2) => useGearsConfig('recovery', subContext);

export const useExplorationConfig = <K2 extends GearsSubConfigKeys<'exploration'>>
    (subContext: K2) => useGearsConfig('exploration', subContext);

export const useNcdConfig = <K2 extends GearsSubConfigKeys<'ncd'>>
    (subContext: K2) => useGearsConfig('ncd', subContext);

export const useArcConfig = <K2 extends GearsSubConfigKeys<'arc'>>
    (subContext: K2) => useGearsConfig('arc', subContext);

export const useFolderConfig = <K2 extends GearsSubConfigKeys<'folder'>>
    (subContext: K2) => useGearsConfig('folder', subContext);
