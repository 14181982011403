import { COUNTRIES_INFOS, PhoneNumber } from '@shift/design-system';
import { IMyInfoState, IVehicleInfo } from 'src/types/myInfo';

const getPhoneNumber = (
    number: string | undefined,
    dialCode: string | undefined,
) => {
    let localDialCode = dialCode;
    if (dialCode && !dialCode.startsWith('+')) {
        localDialCode = `+${dialCode.trim()}`;
    }
    const countryCode = COUNTRIES_INFOS.find(
        (x) => x.dialCode === localDialCode,
    )?.code;
    const phoneNumber: PhoneNumber = {
        countryCode,
        dialCode: localDialCode?.replace('+', ''),
        number,
    };
    return phoneNumber;
};

export const mapMyInfoToReportState = (myInfo: any): IMyInfoState => {
    const state: IMyInfoState = {
        personalInfo: {
            ufin: myInfo.uinfin?.value,
            name: myInfo.name?.value,
            sex: myInfo.sex?.desc,
            dob: myInfo.dob?.value,
        },
        contactInfo: {
            email: myInfo.email?.value,
            phone: getPhoneNumber(
                myInfo.mobileno?.nbr.value,
                myInfo.mobileno?.areacode.value,
            ),
        },
        regAdd: {
            countryCode: myInfo.regadd?.country?.code,
            country: myInfo.regadd?.country?.desc,
            unit: myInfo.regadd?.unit?.value,
            street: myInfo.regadd?.street?.value,
            block: myInfo.regadd?.block?.value,
            postal: myInfo.regadd?.postal?.value,
            floor: myInfo.regadd?.floor?.value,
            building: myInfo.regadd?.building?.value,
        },
        ownedVehicles: myInfo.vehicles?.map((item: any) => {
            const vehicleInfo: IVehicleInfo = {
                vehicleno: item.vehicleno?.value,
                type: item.type?.value,
                make: item.make?.value,
                model: item.model?.value,
                propellant: item.propellant?.value,
                enginecapacity: item.enginecapacity.value,
                chassisno: item.chassisno?.value,
                firstregistrationdate: item.firstregistrationdate?.value,
                effectiveownershipdate: item.effectiveownership?.value,
            };
            return vehicleInfo;
        }),
        licenceInfo: {
            classes: myInfo.drivinglicence?.qdl?.classes.map((item: any) => ({ class: item.class?.value, issueDate: item.issuedate?.value })),
            validity: myInfo.drivinglicence?.qdl?.validity?.code,
            validityDesc: myInfo.drivinglicence?.qdl?.validity?.desc,
        },
    };
    return state;
};
