import { get } from 'object-path-immutable';
import { AnyType } from 'src/types';

export const isValuePopulatedByMyinfo = (path: string, initialValue: AnyType) => {
    const fieldValue = get(initialValue, path);
    return fieldValue !== undefined && fieldValue !== null;
};


export const availableDrivingLicenseInformation = (path: string, initialValue: AnyType) => {
    const fieldValue = get(initialValue, path);
    let qdlValues = false;
    if (fieldValue !== undefined && fieldValue !== null) {
        fieldValue.forEach((element: { startDate: null | undefined; licenseTypeSourceName: null | undefined }) => {
            if ((element.startDate !== undefined && element.startDate !== null)
            || (element.licenseTypeSourceName !== undefined && element.licenseTypeSourceName !== null)) {
                qdlValues = true;
            } else {
                qdlValues = false;
            }
        });
    }
    return qdlValues;
};
