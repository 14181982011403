/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    ReactElement,
    createContext, useState, useLayoutEffect,
} from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';

import { MockedProvider } from 'src/graphql/mocks';
import { useSecurity } from 'src/libs/auth';

import { apiConfig } from '../config';

// TODO this is a temporary fix to partially enable caching.
// This reason is that caching is based on the id of the object which may not
// be unique at the moment. For instance, non unique spes have an id but what
// identify the data is actually a set of fields (which includes the id but
// other fields as well). The non-unicity causes the cache to be invalid or
// to have duplicates in the displayed data (which should not be duplicated to
// begin with). However, the cache needs to be enabled in order to have
// auto-updating data on mutation, which is why some types are hard-coded here
// until a unique id is provided by the backend.
/* eslint-disable @typescript-eslint/camelcase */
const cacheableTypesWithSchemaPrefix: object = {
    mapping_Claim: true,
    mapping_Person: true,
    mapping_Object: true,
    mapping_Policy: true,
    document_Document: true,
    ui_Comment: true,
    mapping_Address: true,
    mapping_Mail: true,
    mapping_PhoneNumber: true,
};

const excludedTypes: object = {
    // Don't cache Identity. In Recoveries, in can be reached from Workflow -> Identity, or WorkflowStep -> Identity,
    // however these two paths were requesting different data. In some situations, the result was that
    // useQuery returned undefined data even if the network request was successfull.
    Identity: true,
};
/* eslint-enable @typescript-eslint/camelcase */

const createCache = () => (
    new InMemoryCache({
        // This was done because an object can be fetched from different paths and links inside the object would be different
        // TODO: Check if there is a better (more efficient) alternative
        // eslint-disable-next-line consistent-return
        dataIdFromObject: (object) => {
            const { __typename, id, links } = object;
            // eslint-disable-next-line no-prototype-builtins
            if (__typename && !cacheableTypesWithSchemaPrefix.hasOwnProperty(__typename) && __typename.indexOf('_') !== -1) {
                return undefined;
            }
            // eslint-disable-next-line no-prototype-builtins
            if (__typename && excludedTypes.hasOwnProperty(__typename)) {
                return undefined;
            }
            // eslint-disable-next-line no-prototype-builtins
            if (links && typeof links === 'object' && links.hasOwnProperty('id')) {
                return `${__typename}:${id}:${(links as any).id}`;
            }

            if (id !== undefined) {
                return `${__typename}:${id}`;
            }
        },
    })
);

// eslint-disable-next-line no-console
const errorLog = process.env.NODE_ENV === 'production' ? console.warn.bind(console) : console.error.bind(console);

const createLink = (uri: string, token: () => Promise<string|null>) => (
    ApolloLink.from([
        setContext(async (_request, previousContext) => {
            const t = await token();
            return {
                ...previousContext,
                headers: {
                    ...previousContext.headers,
                    authorization: t ? `Bearer ${t}` : '',
                },
            };
        }),
        onError(({ graphQLErrors, networkError }) => {
            // eslint-disable-next-line no-console
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) => (
                    errorLog(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                ));
            }
            if (networkError) {
                errorLog(`[Network error]: ${networkError}`);
            }
        }),
        createHttpLink({ uri }),
    ])
);

export const newGraphQL = (token: () => Promise<string|null>) => new ApolloClient({
    link: createLink(apiConfig.resourceUri, token),
    cache: createCache(),
});

interface ToggleMockContext {
    useMock: boolean;
    toggleMocked: () => void;
}

const { Provider: ToggleMockProvider, Consumer } = createContext<ToggleMockContext>({
    useMock: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleMocked: () => {},
});

export const GraphQLProvider = ({ client, children }: { client: ApolloClient<unknown>; children: ReactElement }) => {
    const [mocked, setMocked] = useState(process.env.REACT_APP_USE_MOCK === 'true');
    const { user } = useSecurity();
    const userSub = user?.sub;
    useLayoutEffect(() => {
        if (!userSub) {
            console.log('[GQL] clear cache', client, userSub, mocked);
            // client.stop();
            client.clearStore();
        }
    }, [client, userSub, mocked]);

    const Provider = mocked ? MockedProvider : ApolloProvider;
    return (
        <ToggleMockProvider value={{ useMock: mocked, toggleMocked: () => setMocked(!mocked) }}>
            <Provider client={client}>
                {children}
            </Provider>
        </ToggleMockProvider>
    );
};

const checkBox = {
    margin: '4px 6px 0px 6px',
    verticalAlign: 'top',
};

export const ToggleMockConsumer = () => (
    <Consumer>
        {({ useMock, toggleMocked }) => (
            <span style={{ fontWeight: 'bold' }}>
                <input style={checkBox} type="checkbox" defaultChecked={useMock} onClick={toggleMocked} />
                Mocked Provider
            </span>
        )}
    </Consumer>
);
