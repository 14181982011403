import React from 'react';
import { TimePicker as TimePickerBase, TimePickerProps as TimePickerPropsBase } from '@shift/design-system';

export const TimePicker = ({
    ...otherProps
}: TimePickerPropsBase) => (
    <TimePickerBase
        format="TWENTY_FOUR"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
    />
);
