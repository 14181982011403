import React from 'react';
import {
    ModalHeader, StyledHeader, ModalContent, GridItem, Grid,
    TextButton, Modal, CheckboxInput, withCustomStyles,
    withCustomVariant,
} from '@shift/design-system';
import { translate } from '@gears/translations';
import { SecondaryTextButton } from '../../components/button/SecondaryTextButton';

export interface I18nModalBillableAction {
    header?: string;
    content?: React.ReactNode;
}
export interface ModalBillableActionProps {
    isVisible: boolean;
    i18n?: I18nModalBillableAction;
    actionType: string;
    onConfirm: (showModalAgain: boolean) => void;
    onCancel: () => void;
}
const CustomModal = withCustomStyles(Modal);
const CustomCheckboxInput = withCustomVariant(CheckboxInput, 'checkboxes', 'secondary');

export const ModalBillableAction = ({
    isVisible, onConfirm, onCancel, i18n, actionType,
}: ModalBillableActionProps) => {
    const [showModalAgain, setShowModalAgain] = React.useState(false);
    return (
        <CustomModal
            visible={isVisible}
            onEnter={() => {
                onConfirm(showModalAgain);
            }}
            onHide={onCancel}
            style={{ width: '400px' }}
        >
            <ModalHeader>
                <StyledHeader weight="semiBold" type="h2">
                    {i18n?.header ?? translate('billable-actions:modal-title')}
                </StyledHeader>
            </ModalHeader>
            <ModalContent>
                <Grid direction="column">
                    <GridItem style={{ padding: '8px' }}>
                        {i18n?.content ?? actionType === 'person'
                            ? translate('billable-actions:modal-desc-person') : translate('billable-actions:modal-desc-vehicle')}
                    </GridItem>
                    <GridItem style={{ padding: '8px' }}>
                        <CustomCheckboxInput
                            name="show-modal-again"
                            value="show-modal-again"
                            checked={showModalAgain}
                            onChange={setShowModalAgain}
                        >
                            {translate('billable-actions:modal-ask-again')}
                        </CustomCheckboxInput>
                    </GridItem>
                    <GridItem>
                        <Grid justifyPlacement="flex-end">
                            <GridItem style={{ padding: '0 8px' }}>
                                <SecondaryTextButton onClick={onCancel}>{translate('billable-actions:modal-cancel')}</SecondaryTextButton>
                            </GridItem>
                            <GridItem>
                                <TextButton onClick={() => onConfirm(showModalAgain)}>{translate('billable-actions:modal-confirm')}</TextButton>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </ModalContent>
        </CustomModal>
    );
};
