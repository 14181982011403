import {
    ParameterType, SearchOperationType, RecoveriesFields,
} from 'src/libs/filters';
import { recoveryStates } from 'src/constants';
import { FormatterFactories, Formatters } from '../../../formatters';
import { Widgetters } from '../../../widgets';
import { RecoveryConfig } from '../../types';

const rootContext = ['recovery'];

const defaultColumns = {
    referenceCode: {
        i18NId: 'recovery-referenceCode',
        chrooters: [
            ['recoveryId'],
            ['referenceCode'],
        ],
        formatter: Formatters.GoToRecoveryPageFormatter,
        widgetter: Widgetters.LinksWidgetter,
        filter: {
            field: { id: RecoveriesFields.ReferenceCode },
            operation: {
                type: SearchOperationType.Match,
                name: 'StringMatch',
                parameters: [
                    { value: '', name: 'searchedText', type: ParameterType.String },
                ],
            },
            widgetter: Widgetters.Filter.TextFilterWidgetter,
        },
    },
    initiationDate: {
        i18NId: 'recovery-initiationDate',
        chrooters: [
            [
                'initiationDate',
            ],
        ],
        formatter: Formatters.UtcDateFormatter,
        filter: {
            field: { id: RecoveriesFields.InitiationDate },
            operation: {
                type: SearchOperationType.Between,
                name: 'DateBetween',
                parameters: [
                    { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                    { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                ],
            },
            widgetter: Widgetters.Filter.DateFilterWidgetter,
        },
    },
    deadline: {
        i18NId: 'recovery-workflowStep-deadline',
        chrooters: [
            ['recovery', 'workflow', 'lastWorkflowStep', 'deadline', 'expirationDateUtc'],
            ['recovery', 'workflow', 'lastWorkflowStep', 'deadline', 'state'],
        ],
        formatter: Formatters.RecoveryDeadlineFormatter,
        filter: {
            field: { id: RecoveriesFields.Deadline },
            operation: {
                type: SearchOperationType.Between,
                name: 'DateBetween',
                parameters: [
                    { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                    { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                ],
            },
            widgetter: Widgetters.Filter.DaysLeftFilterWidgetter,
        },
    },
    accidentDate: {
        chrooters: [
            ['accidentDate'],
        ],
        formatter: Formatters.OffsetDateFormatter,
        filter: {
            field: { id: RecoveriesFields.AccidentDate },
            operation: {
                type: SearchOperationType.Between,
                name: 'DateBetween',
                parameters: [
                    { value: '', name: 'StartDate', type: ParameterType.DateTimeOffset },
                    { value: '', name: 'EndDate', type: ParameterType.DateTimeOffset },
                ],
            },
            widgetter: Widgetters.Filter.DateFilterWidgetter,
        },
    },
    status: {
        chrooters: [
            ['recovery', 'workflow', 'lastWorkflowStep', 'state'],
        ],
        formatter: Formatters.BindedNameEnumValueFormatterFactory('RecoveryState'),
        filter: {
            field: { id: RecoveriesFields.Status },
            operation: {
                type: SearchOperationType.Equal,
                name: 'EnumEqual',
                parameters: [
                    { value: '', name: 'equalEnum', type: ParameterType.Enum },
                ],
            },
            widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('RecoveryState'),
        },
    },
    action: {
        chrooters: [
            ['recovery', 'workflow', 'lastWorkflowStep', 'action'],
        ],
        formatter: Formatters.BindedNameEnumValueFormatterFactory('RecoveryAction'),
        filter: {
            field: { id: RecoveriesFields.Action },
            operation: {
                type: SearchOperationType.Equal,
                name: 'EnumEqual',
                parameters: [
                    { value: '', name: 'equalEnum', type: ParameterType.Enum },
                ],
            },
            widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('RecoveryAction'),
        },
    },
    caseType: {
        i18NId: 'case-type',
        chrooters: [
            ['caseTypeEnumIdentifier'],
            ['caseTypeEnumValueId'],
        ],
        formatter: Formatters.EnumValuesIdFormatter,
        filter: {
            field: { id: RecoveriesFields.CaseType },
            operation: {
                type: SearchOperationType.EnumIdEqual,
                name: 'EnumEqual',
                parameters: [
                    { value: '', name: 'equalEnum', type: ParameterType.Enum },
                ],
            },
            widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('MotorRecoveryType'),
        },
    },
};

export const recoveryConfig: RecoveryConfig = {
    list: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'recoveries',
        transforms: [
            defaultColumns.referenceCode,
            defaultColumns.initiationDate,
            defaultColumns.deadline,
            defaultColumns.accidentDate,
            defaultColumns.status,
            defaultColumns.action,
            defaultColumns.caseType,
            {
                chrooters: [
                    [
                        'recovery', 'workflow', 'officer', 'identity', 'insurerEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name',
                    ],
                ],
                filter: {
                    field: { id: RecoveriesFields.OfficerInCharge },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'party-side',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                ],
                formatter: Formatters.EnumValuesFormatter,
                filter: {
                    field: { id: RecoveriesFields.OwnSide },
                    operation: {
                        type: SearchOperationType.EnumIdEqual,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('WorkflowPartySide'),
                },
            },
            {
                i18NId: 'own-liability',
                chrooters: [
                    ['ownLiability'],
                ],
                formatter: Formatters.LiabilityFormatter,
                filter: {
                    field: { id: RecoveriesFields.OwnLiability },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: '',
                        parameters: [
                            { value: '', name: '', type: ParameterType.Decimal },
                        ],
                    },
                    widgetter: Widgetters.Filter.LiabilityFilterWidgetter,
                },
            },
            {
                i18NId: 'own-claim-number',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    ['recovery', 'workflow', 'claims', 'referenceCode'],
                ],
                formatter: Formatters.OwnPartyFormatter,
                filter: {
                    field: { id: RecoveriesFields.OwnClaimNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'own-vehicle-number',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'objects',
                        {
                            name: '',
                            selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.OwnPartyFormatter,
                filter: {
                    field: { id: RecoveriesFields.OwnVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'tp-insurance-company',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'specializations',
                        'mapping_Claim_Insurer',
                        'insurer',
                    ],
                ],
                formatter: Formatters.OtherPartyFormatterFactory(Formatters.EnumValuesFormatter),
                filter: {
                    field: { id: RecoveriesFields.OtherPartyInsurer },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('InsurerCode'),
                },
                widgetter: Widgetters.MaxWidthTextWidgetter,
            },
            {
                i18NId: 'tp-vehicle-number',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'objects',
                        {
                            name: '',
                            selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.OtherPartyFormatter,
                filter: {
                    field: { id: RecoveriesFields.OtherPartyVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
        ],
    },
    giaList: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'recoveries',
        transforms: [
            defaultColumns.referenceCode,
            defaultColumns.initiationDate,
            defaultColumns.deadline,
            {
                i18NId: 'days-in-drm',
                chrooters: [
                    [
                        'recovery',
                        'workflow',
                        'workflowSteps',
                        {
                            name: '',
                            selector: { path: ['state'], values: [recoveryStates.pendingDrmGiaAdjudication] },
                        },
                        'effectiveDateUtc',
                    ],
                ],
                formatter: Formatters.DaysFromFormatter,
                filter: {
                    field: { id: RecoveriesFields.DaysInDrm },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'DateBetween',
                        parameters: [
                            { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                            { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                        ],
                    },
                    widgetter: Widgetters.Filter.DaysSinceFilterWidgetter,
                },
            },
            defaultColumns.accidentDate,
            defaultColumns.status,
            defaultColumns.action,
            defaultColumns.caseType,
            {
                i18NId: 'ip-liability',
                chrooters: [
                    ['ownLiability'],
                ],
                formatter: Formatters.LiabilityFormatter,
                filter: {
                    field: { id: RecoveriesFields.OwnLiability },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: '',
                        parameters: [
                            { value: '', name: '', type: ParameterType.Decimal },
                        ],
                    },
                    widgetter: Widgetters.Filter.LiabilityFilterWidgetter,
                },
            },
            {
                i18NId: 'ip-insurance-company',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'specializations',
                        'mapping_Claim_Insurer',
                        'insurer',
                    ],
                ],
                formatter: Formatters.InitiatingPartyFormatterFactory(Formatters.EnumValuesFormatter),
                filter: {
                    field: { id: RecoveriesFields.OwnInsurer },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('InsurerCode'),
                },
                widgetter: Widgetters.MaxWidthTextWidgetter,
            },
            {
                i18NId: 'ip-vehicle-number',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'objects',
                        {
                            name: '',
                            selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                // eslint-disable-next-line max-len
                formatter: Formatters.MultipleFormatters([Formatters.InitiatingPartyFormatter, Formatters.LinksExplorationSearchFormatter('vehicle')]),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
                filter: {
                    field: { id: RecoveriesFields.OwnVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'rp-liability',
                chrooters: [
                    ['otherLiability'],
                ],
                formatter: Formatters.LiabilityFormatter,
                filter: {
                    field: { id: RecoveriesFields.OtherLiability },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: '',
                        parameters: [
                            { value: '', name: '', type: ParameterType.Decimal },
                        ],
                    },
                    widgetter: Widgetters.Filter.LiabilityFilterWidgetter,
                },
            },
            {
                i18NId: 'rp-insurance-company',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'specializations',
                        'mapping_Claim_Insurer',
                        'insurer',
                    ],
                ],
                formatter: Formatters.ReceivingPartyFormatterFactory(Formatters.EnumValuesFormatter),
                filter: {
                    field: { id: RecoveriesFields.OtherPartyInsurer },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('InsurerCode'),
                },
                widgetter: Widgetters.MaxWidthTextWidgetter,
            },
            {
                i18NId: 'rp-vehicle-number',
                chrooters: [
                    ['recovery', 'workflow', 'party'],
                    ['recovery', 'workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
                    [
                        'recovery',
                        'workflow',
                        'claims',
                        'objects',
                        {
                            name: '',
                            selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [1] },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.MultipleFormatters([Formatters.ReceivingPartyFormatter, Formatters.LinksExplorationSearchFormatter('vehicle')]),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
                filter: {
                    field: { id: RecoveriesFields.OtherPartyVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'recovery-involved-insurer',
                chrooters: [
                    ['involvedInsurers'],
                ],
                formatter: Formatters.EnumListValuesCommaFormatter,
                widgetter: Widgetters.MultiLineTextWidgetter,
            },
            {
                i18NId: 'unwarranted-drm',
                chrooters: [
                    ['unwarrantedDrm'],
                ],
                formatter: Formatters.BooleanValueFormatter,
                filter: {
                    field: { id: RecoveriesFields.UnwarrantedDRM },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'BooleanEqual',
                        parameters: [
                            { value: '', name: 'boolean', type: ParameterType.Boolean },
                        ],
                    },
                    widgetter: Widgetters.Filter.BooleanFilterWidgetterFactory('left'),
                },
            },
        ],
    },
    ipInformation: {
        configType: 'KeyValueCard',
        context: ['claim'],
        i18Namespace: 'recovery',
        transforms: [
            {
                chrooters: [['specializations', 'mapping_Claim_Insurer', 'insurer']],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [['persons', 'specializations', 'mapping_Person_Gears', 'name']],
            },
            {
                chrooters: [['objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number']],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                chrooters: [['referenceCode']],
            },
        ],
    },
    rpInformation: {
        configType: 'KeyValueCard',
        context: ['claim'],
        i18Namespace: 'recovery',
        transforms: [
            {
                chrooters: [['specializations', 'mapping_Claim_Insurer', 'insurer']],
                formatter: Formatters.EnumValuesFormatter,
            },
            {
                chrooters: [['objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number']],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                chrooters: [['referenceCode']],
            },
        ],
    },
    tpInformation: {
        configType: 'KeyValueCard',
        context: ['claim'],
        i18Namespace: 'recovery',
        transforms: [
            {
                i18NId: 'tp-vehicle-number',
                chrooters: [
                    [
                        'objects',
                        {
                            name: '',
                            selector: { path: ['links', 'mapping_ClaimObject', 'roleEnumValueId'], values: [3] },
                        },
                        'specializations',
                        'mapping_Object_Motor_VehicleRegistration',
                        'number',
                    ],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
        ],
    },
    addresses: {
        configType: 'SingleValueCard',
        context: ['claim'],
        transforms: [
            {
                chrooters: [['addresses', 'fullAddress']],
                formatter: FormatterFactories.DefaultTranslationValueFormatterFactory('common:no-content'),
            },
        ],
    },
    descriptions: {
        configType: 'SingleValueCard',
        context: ['claim'],
        i18Namespace: 'recovery',
        transforms: [
            {
                chrooters: [['specializations', 'mapping_Claim_Cause', 'detailedCircumstances']],
                formatter: FormatterFactories.DefaultTranslationValueFormatterFactory('common:no-content'),
            },
        ],
    },
    accidentReports: {
        configType: 'Table',
        context: rootContext.concat(['accidentReports']),
        i18Namespace: 'recovery',
        transforms: [
            {
                i18NId: 'accident-report-referenceCode',
                chrooters: [
                    ['id'],
                    ['referenceCode'],
                ],
                formatter: Formatters.GoToDetailPageFormatter,
                widgetter: Widgetters.LinksWidgetter,
            },
            {
                chrooters: [
                    ['objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
                ],
                formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
                widgetter: Widgetters.LinksExplorationSearchWidgetter,
            },
            {
                // TODO: check if this is working and potentially fix
                chrooters: [
                    ['links', 'mapping_RecoveryClaim', 'roleEnumValueId'],
                ],
                formatter: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    transform: (value: any) => {
                        const val = value && value.length > 0 && value[0][0];
                        let role = null;
                        if (val) {
                            role = val === 3 ? 'TP' : 'RP';
                        } else {
                            role = 'IP';
                        }
                        return {
                            children: role,
                        };
                    },
                },
            },
        ],
    },
    recovery: {
        configType: 'Processed',
        context: rootContext,
        fields: [
            ['id'],
            ['referenceCode'],
            ['createDateUtc'],
            ['amounts', 'specializations', 'mapping_Recovery_Amount', 'identifier'],
            ['amounts', 'specializations', 'mapping_Recovery_Amount', 'amount'],
            ['amounts', 'specializations', 'mapping_Recovery_Amount', 'currency'],
            ['amounts', 'specializations', 'mapping_Recovery_Amount', 'createDateUtc'],
            ['payments', 'date'],
            ['payments', 'mode'],
            ['accidentReports', 'links', 'mapping_RecoveryTarget', 'entityRoleEnumValueId'],
            ['accidentReports', 'specializations', 'mapping_Claim_Event', 'eventDateOffset'],
            ['workflow', 'id'],
            ['workflow', 'party'],
            ['workflow', 'officer', 'identity', 'identityId'],
            ['workflow', 'officer', 'identity', 'email'],
            ['workflow', 'officer', 'partySide'],
            ['workflow', 'officer', 'identity', 'insurerEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name'],
            ['workflow', 'ipOfficer', 'identity', 'identityId'],
            ['workflow', 'ipOfficer', 'identity', 'email'],
            ['workflow', 'ipOfficer', 'partySide'],
            ['workflow', 'ipOfficer', 'identity', 'insurerEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name'],
            ['workflow', 'rpOfficer', 'identity', 'identityId'],
            ['workflow', 'rpOfficer', 'identity', 'email'],
            ['workflow', 'rpOfficer', 'partySide'],
            ['workflow', 'rpOfficer', 'identity', 'insurerEntity', 'person', 'specializations', 'mapping_Person_Gears', 'name'],
            ['workflow', 'claims', 'id'],
            ['workflow', 'claims', 'specializations', 'mapping_Claim_Insurer', 'insurer'],
            ['workflow', 'claims', 'specializations', 'mapping_Claim_Amounts', 'amount'],
            ['workflow', 'claims', 'specializations', 'mapping_Claim_Amounts', 'currency'],
            ['workflow', 'claims', 'links', 'ui_WorkflowToClaim', 'roleEnumValueId'],
            ['workflow', 'claims', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
            ['workflow', 'nextWorkflowSteps'],
            ['workflow', 'workflowSteps', 'effectiveDateUtc'],
            ['workflow', 'workflowSteps', 'expirationDateUtc'],
            ['workflow', 'workflowSteps', 'id'],
            ['workflow', 'workflowSteps', 'state'],
            ['workflow', 'workflowSteps', 'action'],
            ['workflow', 'workflowSteps', 'creator', 'identity', 'insurerEntity', 'insurer', 'insurerCode'],
            ['workflow', 'workflowSteps', 'creator', 'partySide'],
            ['workflow', 'workflowSteps', 'comments', 'content'],
            ['workflow', 'workflowSteps', 'comments', 'links', 'ui_WorkflowStepToComment', 'roleEnumValueId'],
            ['workflow', 'workflowSteps', 'accidentCase', 'caseEnumValueId'],
            ['workflow', 'workflowSteps', 'accidentCase', 'caseEnumIdentifier'],
            ['workflow', 'workflowSteps', 'accidentCase', 'specializations', 'mapping_AccidentCase_LiabilityScenario_Gia', 'xLiablity'],
            ['workflow', 'workflowSteps', 'accidentCase', 'specializations', 'mapping_AccidentCase_LiabilityScenario_Gia', 'yLiablity'],
            ['workflow', 'workflowSteps', 'specializations', 'ui_WorkflowStep_Reason', 'reasonEnumIdentifier'],
            ['workflow', 'workflowSteps', 'specializations', 'ui_WorkflowStep_Reason', 'reasonEnumValueId'],
            ['workflow', 'workflowSteps', 'specializations', 'ui_WorkflowStep_Adjudication_Gia', 'ipDrmFee'],
            ['workflow', 'workflowSteps', 'specializations', 'ui_WorkflowStep_Adjudication_Gia', 'rpDrmFee'],
            ['workflow', 'workflowSteps', 'specializations', 'ui_WorkflowStep_Adjudication_Gia', 'rpLiabilityApportionment'],
            ['workflow', 'documents', 'id'],
            ['workflow', 'documents', 'documentName'],
            ['workflow', 'documents', 'specializations', 'document_Document_DestinationDiskStorageInformation', 'fileSize'],
            ['workflow', 'documents', 'links', 'ui_WorkflowToDocument', 'roleEnumValueId'],
            ['workflow', 'workflowSteps', 'documents', 'id'],
            ['workflow', 'workflowSteps', 'documents', 'documentName'],
            ['workflow', 'workflowSteps', 'documents', 'specializations', 'document_Document_DestinationDiskStorageInformation', 'fileSize'],
            ['workflow', 'workflowSteps', 'documents', 'links', 'ui_WorkflowStepToDocument', 'roleEnumValueId'],
        ],
    },
    accidentLocation: {
        configType: 'Processed',
        context: ['claim', 'addresses'],
        fields: [
            ['fullAddress'],
            ['latitude'],
            ['longitude'],
        ],
    },
    comments: {
        configType: 'Processed',
        context: rootContext.concat(['workflow']),
        fields: [
            ['workflowSteps', 'comments', 'content'],
            ['workflowSteps', 'comments', 'createDateUtc'],
            ['workflowSteps', 'comments', 'author', 'userName'],
            ['workflowSteps', 'comments', 'links', 'ui_WorkflowStepToComment', 'roleEnumValueId'],
        ],
    },
};
