/* eslint-disable no-underscore-dangle */
import { QueryOptions } from '@apollo/client';
import React, {
    createContext, useState, useContext,
} from 'react';

import deepEqual from 'deep-equal';

type GraphQLQueryOptionsContextState<T extends readonly string[]> = {
    index: {
        [key in T[number]]: QueryOptions;
    };
    __keys: T;
};

type GraphQLQueryOptionsContext<T extends readonly string[]> = [
    GraphQLQueryOptionsContextState<T>,
    (queryOptionsContext: GraphQLQueryOptionsContextState<T>) => void
] | null;


export const graphQLQueryOptionsContextFactory = <T extends readonly string[]>(keys: T) => {
    const context = createContext<GraphQLQueryOptionsContext<T>>(null);

    const GraphQLQueryOptionsProvider = ({ children }: {children: React.ReactNode}) => {
        const [graphQLQueryOptions, setGraphQLQueryOptions] = useState<GraphQLQueryOptionsContextState<T>>({ __keys: keys, index: {} as any });

        return (
            <context.Provider value={[graphQLQueryOptions, setGraphQLQueryOptions]}>
                {children}
            </context.Provider>
        );
    };

    const useGraphQLQueryOptions = (key?: T[number]): [QueryOptions | undefined, (queryOptions: QueryOptions) => void] => {
        const ctx = useContext(context);

        if (!ctx) {
            throw new Error('Query options context is null');
        }

        const [queryOptions, setQueryOptions] = ctx;

        const cb = React.useCallback((newQueryOptions: QueryOptions) => {
            // This check is required to avoid infinite updates in case it is used inside a useEffect
            if (key && !deepEqual(queryOptions.index[key], newQueryOptions)) {
                setQueryOptions({
                    ...queryOptions,
                    index: {
                        ...queryOptions.index,
                        [key as string]: newQueryOptions,
                    },
                });
            }
        }, [queryOptions, setQueryOptions, key]);

        if (!key) {
            return [undefined, () => ({})];
        }

        if (!queryOptions.__keys.includes(key)) {
            throw new Error(`${key} is not supported by the query options provider`);
        }

        return [queryOptions.index[key], cb];
    };

    return {
        GraphQLQueryOptionsProvider,
        useGraphQLQueryOptions,
    };
};
