import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { formSections } from 'src/config/form';
import { formTranslate } from 'src/utils/forms';
import {
    FormField, FormSection,
    YesNoRadio, ExpandableField,
} from 'src/components/form';
import { WitnessItem } from '../array-items';

export const WitnessSection = () => {
    const { name, title, fields } = formSections.witnessSection;
    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.anyWitnesses}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <Field name={fields.anyWitnesses.path} subscription={{ value: true }}>
                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                    <ExpandableField
                        fieldInfo={fields.witnesses}
                        itemTitle="witness"
                        addItemLabel="add-witness"
                        Item={WitnessItem}
                        initialItemsCount={input.value ? 1 : 0}
                        enableAdd={!!input.value}
                    />
                )}
            </Field>
        </FormSection>
    );
};
