/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment-timezone';
import { get } from 'object-path';
import {
    Location, COUNTRIES_INFOS,
    TimeEntry, SelectInputOption, FileInfo, PhoneNumber,
} from '@shift/design-system';

import { translate } from '@gears/translations';
import {
    Formatter, AnyType, EnumValue,
} from 'src/types';
import {
    DateOffsetFormat, DateFormat, otherEnum, phoneTypeEnum, phoneType, DefaultTimezone, unknownEnum, DateUtcFormat,
} from 'src/constants';
import { isNumber } from 'util';
import { parseOffsetDate } from 'src/utils/index';
import {
    formatOffsetDateTime, FormatEnum,
    isNoneEnum,
    mergeDateTime,
} from './utils';
import { apiConfig } from '../../config';
import { getStateTimezone } from './enumValues';
import { vehicleDrivingLicenseClasses } from '../mapping/mapReportStateToGears/constants/drivingLicenseType';
import { mapDrivingLicenceClass } from '../mapping/mapReportStateToGears/mappers';


export const VoidFormatter: Formatter = {};

export const getOffsetDateFormatter = (
    timeField: string | null = null,
    dateTimeFormat: string = DateOffsetFormat,
    dateFormat: string = DateFormat,
): Formatter => ({
    additionalSources: timeField ? { time: timeField } : undefined,
    format: (value: string) => parseOffsetDate(value, dateTimeFormat).format(dateFormat),
    transform: ({ value, additionalData }) => formatOffsetDateTime(value, timeField ? additionalData.time : null, dateTimeFormat),
});

export const DrivingLicenseTypeFormatter: Formatter = {
    additionalSources: {
        drivingLicenseClass: 'ownVehicle.driverLicence.licenseTypeSourceName',
    },
    transform: ({ additionalData }: {
        additionalData:
        {
            drivingLicenseClass: { label: string; value: string };
        };
    }) => mapDrivingLicenceClass(additionalData.drivingLicenseClass.label),
};

export const TimeFormatter: Formatter = {
    format: (value: string): TimeEntry | undefined => {
        if (value) {
            const date = moment(value, DateOffsetFormat);

            return {
                hour: date.hour(),
                minute: date.minute(),
            };
        }

        return undefined;
    },
};


export const FileWithTypeFormatter: Formatter = {
    transform: ({ value: files }: { value: FileInfo[] }) => {
        if (!files?.length) {
            return undefined;
        }
        const docs = files?.map((file) => ({
            document: {
                id: file.id,
                documentName: file.name,
            },
            category: file.type?.value,
        }));
        return docs;
    },
};

export interface LocationBackendInfo {
    fullAddress?: string;
    country?: any; // Enum
    postCode?: string;
    latitude?: number;
    longitude?: number;
}

export const LocationFormatter: Formatter = {
    format: (value: LocationBackendInfo): Location | undefined => (value && value.fullAddress && value.latitude && value.longitude ? ({
        fullAddress: value.fullAddress,
        country: value.country?.Name,
        postCode: value.postCode,
        coordinates: {
            lat: value.latitude,
            lng: value.longitude,
        },
    }) : undefined),
    transform: ({ value }): LocationBackendInfo => ({
        fullAddress: value?.fullAddress,
        country: value?.country ? FormatEnum('Country', (value.country as string).replace(' ', '')) : undefined,
        postCode: value?.postCode,
        latitude: isNumber(value?.coordinates?.lat) ? value?.coordinates?.lat : value?.coordinates?.lat(),
        longitude: isNumber(value?.coordinates?.lng) ? value?.coordinates?.lng : value?.coordinates?.lng(),
    }),
};

export interface PhoneNumberBackendInfo extends Omit<PhoneNumber, 'countryCode'> {
    phoneNumberType: string;
    countryCode?: string;
}

export const PhoneNumberFormatter: Formatter = {
    format: (value?: PhoneNumberBackendInfo): PhoneNumber | undefined => {
        if (value) {
            const phoneNumber: PhoneNumber = {
                number: value.number,
            };
            if (value.countryCode) {
                phoneNumber.dialCode = value.countryCode;
                phoneNumber.countryCode = COUNTRIES_INFOS.find((r) => r.dialCode === `+${value.countryCode}`)?.code;
            }
            return phoneNumber;
        }
        return undefined;
    },
    transform: ({ value }: { value: PhoneNumber }): PhoneNumberBackendInfo => ({
        number: value.number,
        countryCode: value.dialCode,
        phoneNumberType: `${phoneTypeEnum}.${phoneType}`,
    }),
};

export interface DrivingLicenseBackendInfo {
    startDate: string;
    endDate?: string;
    licenseTypeSourceName: string;
    id: string;
    licenseType: string;
    licenseValidity: string;
    licenseValiditySourceName: string;
}
export const DrivingLicenseFormatter: Formatter = {
    // eslint-disable-next-line arrow-body-style
    format: (value?: DrivingLicenseBackendInfo): DrivingLicenseBackendInfo | undefined => {
        if (value) { return value; }
        return undefined;
    },
    transform: ({ value }: { value: DrivingLicenseBackendInfo }): DrivingLicenseBackendInfo => ({
        startDate: value.startDate,
        endDate: value.endDate,
        licenseTypeSourceName: value.licenseTypeSourceName,
        id: value.id,
        licenseType: value.licenseType,
        licenseValiditySourceName: value.licenseValiditySourceName,
        licenseValidity: value.licenseValidity,
    }),
};

export const DrivingLicenseClassFormatter: Formatter = {
    // eslint-disable-next-line arrow-body-style
    format: (value?: string): string | undefined => {
        if (value) { return translate(`enums:DrivingLicenseSourceType.${value}`); }
        return undefined;
    },
    transform: ({ value }): string => vehicleDrivingLicenseClasses[value],
};
export const RadioFormatter: Formatter = {
    format: (value: EnumValue): string | undefined => (value ? `${value.Type}.${value.Name}` : undefined),
    transform: ({ value }): string => value,
};

export const EnumFormatter: Formatter = {
    format: (value: EnumValue): SelectInputOption | undefined => {
        if (value) {
            const enumValue = value.Name !== '0' ? `${value.Type}.${value.Name}` : otherEnum;
            return {
                value: enumValue,
                label: translate(`enums:${enumValue}`, { defaultValue: value.Name }),
            };
        }
        return undefined;
    },
    transform: ({ value: option }): string => ((!option || isNoneEnum(option.value)) ? undefined : option.value),
};

export const CheckboxesToEnumFormatter: Formatter = {
    transform: ({ value }) => {
        if (value) {
            const values = value.split('.');
            const reasonEnumIdentifier = values[0];
            const reasonEnumValueId = values[1];
            return {
                reasonEnumIdentifier,
                reasonEnumValueId: parseInt(reasonEnumValueId, 10),
            };
        }
        return undefined;
    },
};

export const BackendListFormatter: Formatter = {
    format: (value: number): SelectInputOption | undefined => {
        if (value) {
            return {
                value,
                label: '',
            };
        }
        return undefined;
    },
    transform: ({ value: option }): string => ((!option || isNoneEnum(option.value)) ? undefined : option.value),
};

export const PoliceStationFormatter: Formatter = {
    format: ({ id, recordOwner }: { id: number; recordOwner: EnumValue }): SelectInputOption | undefined => {
        if (!id) {
            return undefined;
        }

        return {
            value: (recordOwner.Name === 'SHIFT') ? 0 : id,
            label: '',
        };
    },
    transform: ({ value: option }) => ({
        id: (!option || isNoneEnum(option.value)) ? undefined : option.value,
    }),
};

export const EnumIdentifierValuePairFormatter: Formatter = {
    format: (value): SelectInputOption | undefined => {
        if (!value) {
            return undefined;
        }
        return { value: `${value.identifier}.${value?.valueId}`, label: '' };
    },
    transform: ({ value: option }) => {
        const [identifier, valueId] = option.value.toString().split('.');
        return { identifier, valueId: parseInt(valueId, 10) };
    },
};

export const ListEmptyFormatter: Formatter = {
    format: (value: AnyType[]): boolean => (value ? value.length > 0 : false),
};

export const getFileFormatter = (isMulti = false): Formatter => ({
    format: (values: any): FileInfo[] | undefined => ((!values || Array.isArray(values)) ? values : [values])?.map((value: any) => ({
        id: value.id,
        name: value.name,
        size: value.size,
        src: `${apiConfig.documentUri}/${value.id}`,
        thumbnailSrc: `${apiConfig.documentPreviewUri}/${value.id}`,
    })),
    transform: ({ value: files }: { value: FileInfo[] }) => {
        if (!files?.length) {
            return undefined;
        }
        const docs = files?.map((file) => ({
            id: file.id,
            name: file.name,
        }));
        return isMulti ? docs : docs[0];
    },
});

export interface EnumNoTypeBackendInfo {
    type?: EnumValue;
    noType?: boolean;
}

const EnumNoTypeFormatterFactory: (noTypeEnum: string) => Formatter = (noTypeEnum: string) => ({
    format: (value: EnumNoTypeBackendInfo): SelectInputOption | undefined => {
        if (value && (value.type || value.noType)) {
            const enumValue = value.noType ? noTypeEnum : `${value.type?.Type}.${value.type?.Name}`;
            return {
                value: enumValue,
                label: translate(`enums:${enumValue}`, { defaultValue: value.type?.Name }),
            };
        }
        return undefined;
    },
    transform: ({ value: option }): EnumNoTypeBackendInfo | undefined => {
        if (!option) {
            return undefined;
        }
        return isNoneEnum(option.value) ? { noType: true } : { type: option.value };
    },
});


export const OtherNoEnumTypeFormatter = EnumNoTypeFormatterFactory(otherEnum);
export const UnknownNoEnumTypeFormatter = EnumNoTypeFormatterFactory(unknownEnum);

export const obfuscateNationalIdentifier = (ident: string) => `${ident[0]}${ident.slice(1, -4).replace(/./g, 'X')}${ident.slice(-4)}`;

export const AccidentDateFormatter: Formatter = {
    additionalSources: {
        time: 'generalInformation.time',
        state: 'generalInformation.accidentAddress.areaOrState',
    },
    format: (value: string, formData?: AnyType) => {
        const accidentTimezone = formData ? getStateTimezone(get(formData, 'generalInformation.accidentAddress.areaOrState')) : null;
        return moment.tz(value, DateOffsetFormat, accidentTimezone ?? DefaultTimezone).format(DateFormat);
    },
    transform: ({ value, additionalData }: { value: string; additionalData: { time: TimeEntry; state: SelectInputOption } }) => {
        const accidentTimezone = getStateTimezone(additionalData.state?.value?.toString());
        return mergeDateTime(value, additionalData.time, accidentTimezone ?? DefaultTimezone)?.format(DateOffsetFormat);
    },
};

export const AccidentTimeFormatter: Formatter = {
    additionalSources: {
        time: 'generalInformation.time',
        state: 'generalInformation.accidentAddress.areaOrState',
    },
    format: (value: string, formData?: AnyType) => {
        const accidentTimezone = formData ? getStateTimezone(get(formData, 'generalInformation.accidentAddress.areaOrState')) : null;
        const date = moment.tz(value, DateOffsetFormat, accidentTimezone ?? DefaultTimezone);
        if (!date) return undefined;
        return {
            hour: date.hour(),
            minute: date.minute(),
        };
    },
    transform: ({ value, additionalData }: { value: TimeEntry; additionalData: { date: string; state: SelectInputOption } }) => {
        const accidentTimezone = getStateTimezone(additionalData.state?.value?.toString());
        return mergeDateTime(additionalData.date, value, accidentTimezone ?? DefaultTimezone)?.format(DateOffsetFormat);
    },
};
export const FirstRegistrationDateTimeFormatter: Formatter = {
    format: (value: string) => {
        if (value) { return value; }
        return undefined;
    },
    transform: ({ value }: { value: string }) => {
        const firstRegistrationDate = moment.utc(value, DateUtcFormat);
        return `${firstRegistrationDate.format(DateUtcFormat)}`;
    },
};
export const PurchaseDateFormatter: Formatter = {
    additionalSources: {
        time: 'ownVehicle.time',
        state: 'generalInformation.accidentAddress.areaOrState',
    },
    format: (value: string) => {
        const dateUtc = moment.utc(value, DateUtcFormat);
        if (!dateUtc) return undefined;
        const result = dateUtc.tz(DefaultTimezone).format(DateFormat);
        return result;
    },
    transform: ({ value, additionalData }: { value: string; additionalData: { time: TimeEntry; state: SelectInputOption } }) => {
        const sgTime = mergeDateTime(value, additionalData.time, DefaultTimezone);
        return sgTime?.utc().format(DateUtcFormat);
    },
};

export const PurchaseTimeFormatter: Formatter = {
    additionalSources: {
        time: 'ownVehicle.time',
        state: 'generalInformation.accidentAddress.areaOrState',
    },
    format: (value: string) => {
        const dateUtc = moment.utc(value, DateUtcFormat);
        if (!dateUtc) return undefined;
        const result = dateUtc.tz(DefaultTimezone);

        return {
            hour: result.format('HH'),
            minute: result.format('mm'),
        };
    },
    transform: ({ value, additionalData }: { value: TimeEntry; additionalData: { date: string; state: SelectInputOption } }) => {
        const sgTime = mergeDateTime(additionalData.date, value, DefaultTimezone);
        return sgTime?.utc().format(DateUtcFormat);
    },
};

export const UpperCaseFormatter: Formatter = {
    format: (value) => value,
    transform: ({ value }: { value: string }) => value.toUpperCase(),
};

export const TrimWhitespacesFormatter: Formatter = {
    format: (value) => value,
    transform: ({ value }: { value: string }) => value.trim(),
};
