import React, { createContext, useCallback } from 'react';
import { IMyInfo, IMyInfoState } from 'src/types/myInfo';
import { clearState, loadState, saveState } from './persistance';


const myInfoInitialState: IMyInfo = {
    myInfoState: {},
    updateState: () => null,
    updateAndStore: () => null,
    clearReportState: () => null,
};

const MyInfoStateContext = createContext<IMyInfo>(myInfoInitialState);

const loadedState = loadState(myInfoInitialState.myInfoState);

export const MyInfoStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [myInfoState, setMyInfoState] = React.useState<IMyInfo>({
        myInfoState: loadedState, updateState: () => null, updateAndStore: () => null, clearReportState: () => null,
    });

    myInfoState.updateState = useCallback((payload: IMyInfoState) => {
        setMyInfoState((prev) => {
            const newState = { ...prev, myInfoState: { ...prev.myInfoState, ...payload } };
            return newState;
        });
    }, []);

    myInfoState.updateAndStore = useCallback((payload: IMyInfoState) => {
        setMyInfoState((prev) => {
            const newState = { ...prev, myInfoState: { ...prev.myInfoState, ...payload } };
            saveState(newState.myInfoState);
            return newState;
        });
    }, []);

    myInfoState.clearReportState = useCallback(() => {
        setMyInfoState((prev) => {
            const newState = { ...prev, myInfoState: {} };
            clearState();
            return newState;
        });
    }, []);

    return (
        <MyInfoStateContext.Provider value={myInfoState}>
            {children}
        </MyInfoStateContext.Provider>
    );
};


export const useMyInfoState = () => React.useContext(MyInfoStateContext);
