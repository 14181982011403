import { Formatter } from '@shift/transform';
import { WorflowPartySide } from 'src/constants';

function isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PartyConditionalFormatterFactory = (party: number): Formatter<any[], { children: any }> => ({
    transform: (params) => {
        const partyIndex = params[1].indexOf(party);
        return {
            children: params[2][partyIndex] ?? '',
        };
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserPartyConditionalFormatterFactory = (ownParty: boolean): Formatter<any[], { children: any }> => ({
    transform: (params) => {
        const partySide = `${params[0][0]?.Name}`;
        if (isObjKey(partySide, WorflowPartySide)) {
            const partySideInt = WorflowPartySide[partySide];
            const ownPartyIndex = params[1].indexOf(partySideInt);
            const otherPartyIndex = ownPartyIndex === 0 ? 1 : 0;
            const correspondingPartySideIndex = ownParty
                ? ownPartyIndex
                : otherPartyIndex;
            return {
                children: params[2][correspondingPartySideIndex],
            };
        }

        return {
            children: '',
        };
    },
});

export function PartyConditionalChainableFormatterFactory<T, K>(
    party: number,
    formatter: Formatter<T[], { children: K }>,
): Formatter<any[], { children: K }> {
    return ({
        transform: (values) => {
            const value = PartyConditionalFormatterFactory(party).transform(
                values,
            );
            return formatter.transform([[value.children]]);
        },
    });
}

export function UserPartyConditionalChainableFormatterFactory<T, K>(
    ownParty: boolean,
    formatter: Formatter<T[], { children: K }>,
): Formatter<any[], { children: K }> {
    return ({
        transform: (values) => {
            const value = UserPartyConditionalFormatterFactory(ownParty).transform(
                values,
            );
            return formatter.transform([[value.children]]);
        },
    });
}

export const PartyFormatters = {
    OwnPartyFormatter: UserPartyConditionalFormatterFactory(true),
    OtherPartyFormatter: UserPartyConditionalFormatterFactory(false),
    OwnPartyFormatterFactory: (formatter: any) => UserPartyConditionalChainableFormatterFactory(true, formatter),
    OtherPartyFormatterFactory: (formatter: any) => UserPartyConditionalChainableFormatterFactory(false, formatter),
    InitiatingPartyFormatter: PartyConditionalFormatterFactory(WorflowPartySide.InitiatingParty),
    ReceivingPartyFormatter: PartyConditionalFormatterFactory(WorflowPartySide.ReceivingParty),
    InitiatingPartyFormatterFactory: (formatter: any) => PartyConditionalChainableFormatterFactory(WorflowPartySide.InitiatingParty, formatter),
    ReceivingPartyFormatterFactory: (formatter: any) => PartyConditionalChainableFormatterFactory(WorflowPartySide.ReceivingParty, formatter),
};
