import React, { useContext } from 'react';
import { File, LinkSvg } from '@shift/icons';
import { Widgetter } from '@shift/transform';
import { Icon, ThemeContext, withCustomStyles } from '@shift/design-system';
import { DownloadWidgetter, DownloadWidgetterProps } from './DownloadWidget';

type FileIconDownloadWidgetterProps = Omit<DownloadWidgetterProps, 'children'>

export interface DocumentLinkWidgetterProps extends FileIconDownloadWidgetterProps {
    isLink: boolean;
}


const StyledIcon = withCustomStyles(Icon);

const DocumentLinkWidget = ({
    filename, url, isLink,
}: DocumentLinkWidgetterProps) => {
    const { colors: { brand: { primary: { base: brandPrimaryBaseColor } } } } = useContext(ThemeContext);

    return !isLink ? (
        <DownloadWidgetter.transform
            filename={filename}
            url={url}
        >
            <StyledIcon width={1} height={1} style={{ margin: 'auto' }}>
                <File />
            </StyledIcon>
        </DownloadWidgetter.transform>
    ) : (
        <a target="_blank" rel="noopener noreferrer" href={url}>
            <StyledIcon width={1} height={1} style={{ margin: 'auto' }} color={brandPrimaryBaseColor}>
                <LinkSvg />
            </StyledIcon>
        </a>
    );
};

export const DocumentLinkWidgetter: Widgetter<DocumentLinkWidgetterProps> = {
    transform: DocumentLinkWidget,
};
