/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectInputOption } from '@shift/design-system';
import {
    isValidDate, validateListIfCondition, getOccupationTypes, phoneNumberRequired, isRequiredIfCondition,
    isValidPhoneNumber, isValidNationalIdentifier, isValidEmail, validateIfCondition, isValidAlphanumeric, isValidPassDate,
} from 'src/utils/forms';
import {
    EnumFormatter, getOffsetDateFormatter,
    PhoneNumberFormatter, OtherNoEnumTypeFormatter, UpperCaseFormatter, TrimWhitespacesFormatter,
    DrivingLicenseTypeFormatter,
    DrivingLicenseFormatter,
} from 'src/utils/forms/formatters';
import { AnyType } from 'src/types';
import { get } from 'object-path';

const isDriverPolicyHolderCondition = (formValues: AnyType) => (!get(formValues, 'ownVehicle.driver.isDriverPolicyHolder'));

export const driverSection = {
    name: 'ownVehicle.driver',
    title: 'driver-information',
    fields: {
        isDriverPolicyHolder: {
            path: 'ownVehicle.driver.isDriverPolicyHolder',
            target: 'ownVehicle.driverIsSameAsOwner',
            label: 'is-policy-holder',
        },
        name: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_Gears.name',
            label: 'driver-name',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
        },
        gender: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_Individual.gender',
            label: 'driver-gender',
            formatter: EnumFormatter,
            isRequired: true,
        },
        typeOfId: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_NationalIdentifier.0.identifier',
            label: 'driver-id-type',
            formatter: EnumFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
        },
        driverId: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_NationalIdentifier.0.nationalIdentifier',
            label: 'driver-id',
            formatter: TrimWhitespacesFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
            validate: isValidNationalIdentifier('ownVehicle.driver.person.specializations.mapping_Person_NationalIdentifier.0.identifier'),
        },
        birthDate: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_Individual.dateOfBirth',
            label: 'driver-birthday',
            formatter: getOffsetDateFormatter(null),
            isRequired: true,
            validate: isValidDate,
        },
        drivingPassInformation: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_DrivingLicenseInformation',
            label: 'driving-pass-information',
            item: {
                endDate: {
                    path: 'endDate',
                    label: 'driving-license-end-date',
                },
                startDate: {
                    path: 'startDate',
                    label: 'driving-license-start-date',
                },
                licenseTypeSourceName: {
                    path: 'licenseTypeSourceName',
                    label: 'license-type-source-name',
                },
                number: {
                    path: 'number',
                    label: 'license-type-number',
                },
                licenseType: {
                    path: 'licenseType',
                    label: 'license-type',
                    formatter: EnumFormatter,
                },
                licenseValidity: {
                    path: 'licenseValidity',
                    label: 'license-validity',
                    formatter: EnumFormatter,
                },
                licenseValiditySourceName: {
                    path: 'licenseValiditySourceName',
                    label: 'license-validity-source-name',
                },
            },
            formatter: DrivingLicenseFormatter,
        },
        passDate: {
            path: 'ownVehicle.driver.startDate',
            target: 'ownVehicle.driverLicence.startDate',
            label: 'driver-pass-date',
            formatter: getOffsetDateFormatter(null),
            isRequired: true,
            validate: isValidPassDate('ownVehicle.driver.person.specializations.mapping_Person_Individual.dateOfBirth'),
        },
        licenseType: {
            path: 'ownVehicle.driver.licenseType',
            target: 'ownVehicle.driverLicence.licenseType',
            label: 'driver-pass-type',
            formatter: DrivingLicenseTypeFormatter,
        },
        passClass: {
            path: 'ownVehicle.driver.licenseTypeSourceName',
            target: {
                type: 'ownVehicle.driverLicence.licenseTypeSourceName',
                noType: 'ownVehicle.driverLicence.licenseTypeSourceNameDescription',
            },
            label: 'driver-pass-class',
            formatter: OtherNoEnumTypeFormatter,
            isRequired: true,
        },
        passClassSourceName: {
            path: 'ownVehicle.driver.licenseTypeSourceNameDescription',
            target: 'ownVehicle.driverLicence.licenseTypeSourceNameDescription',
            label: 'driver-pass-class-source-name',
            isRequired: true,
        },
        drivingLicenseValidity: {
            path: 'ownVehicle.driver.licenseValidity',
            target: {
                type: 'ownVehicle.driverLicence.licenseValidity',
                noType: 'ownVehicle.driverLicence.licenseValiditySourceName',
            },
            isRequired: true,
            label: 'driving-license-validity',
            formatter: OtherNoEnumTypeFormatter,
        },
        drivingLicenseValiditySourceName: {
            path: 'ownVehicle.driver.licenseValiditySourceName',
            target: 'ownVehicle.driverLicence.licenseValiditySourceName',
            label: 'driving-license-validity-source-name',
            isRequired: true,
        },
        primaryMobilePhone: {
            path: 'ownVehicle.driver.primaryPhoneNumber',
            label: 'driver-phone',
            target: {
                number: 'ownVehicle.driver.primaryPhoneNumber.number',
                countryCode: 'ownVehicle.driver.primaryPhoneNumber.countryCode',
                phoneNumberType: 'ownVehicle.driver.primaryPhoneNumber.phoneNumberType',
            },
            formatter: PhoneNumberFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition, phoneNumberRequired),
            validate: validateIfCondition(isDriverPolicyHolderCondition, isValidPhoneNumber),
        },
        altPhones: {
            path: 'ownVehicle.driver.alternativePhoneNumbers',
            label: 'alt-phones',
            item: {
                type: {
                    path: 'type',
                    label: 'driver-alt-phone-type',
                    target: {
                        type: 'phoneNumberType',
                        noType: 'phoneNumberTypeNotInEnum',
                    },
                    formatter: OtherNoEnumTypeFormatter,
                },
                phone: {
                    path: 'phone',
                    label: 'driver-alt-phone',
                    target: {
                        number: 'number',
                        countryCode: 'countryCode',
                    },
                    formatter: PhoneNumberFormatter,
                    validate: validateIfCondition(isDriverPolicyHolderCondition, isValidPhoneNumber),
                },
            },
        },
        address1: {
            path: 'ownVehicle.driver.address.fullAddress',
            label: 'driver-address-1',
            isRequired: true,
        },
        address2: {
            path: 'ownVehicle.driver.address.complement',
            label: 'driver-address-2',
        },
        postcode: {
            path: 'ownVehicle.driver.address.postCode',
            label: 'driver-postcode',
        },
        email: {
            path: 'ownVehicle.driver.email.address',
            label: 'driver-email',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
            validate: validateIfCondition(isDriverPolicyHolderCondition, isValidEmail),
        },
        occupation: {
            path: 'ownVehicle.driver.person.specializations.mapping_Person_Individual.isOccupationOutdoor',
            label: 'driver-occupation',
            formatter: {
                format: (value: boolean) => getOccupationTypes().find((option) => option.value === value.toString()),
                transform: ({ value: option }: { value: SelectInputOption }) => option?.value,
            },
            isRequired: true,
        },
        driverOwnerRelation: {
            path: 'ownVehicle.driver.driverOwnerRelation',
            target: 'ownVehicle.driverOwnerRelation',
            label: 'driver-owner-relation',
            formatter: EnumFormatter,
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
        },
        driverOwnerRelationDescription: {
            path: 'ownVehicle.driver.driverOwnerRelationDescription',
            target: 'ownVehicle.driverOwnerRelationDescription',
            label: 'driver-owner-relation-description',
            isRequired: true,
            requiredFunction: isRequiredIfCondition(isDriverPolicyHolderCondition),
        },
        hasOtherVehicles: {
            path: 'ownVehicle.driver.driverOwnsOtherVehicles',
            target: 'ownVehicle.driverOwnsOtherVehicles',
            label: 'is-own-other-vehicle',
            isRequired: true,
        },
        otherVehicles: {
            path: 'ownVehicle.driver.otherVehiclesOwnedByDriver',
            target: 'ownVehicle.otherVehiclesOwnedByDriver',
            label: 'other-vehicles',
            item: {
                number: {
                    path: 'vehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                    label: 'vehicle-registration-number',
                    isRequired: true,
                    validate: isValidAlphanumeric,
                    formatter: UpperCaseFormatter,
                },
                insurance: {
                    path: 'policy.specializations.mapping_Policy_Insurer.insurer',
                    label: 'insurance-company',
                    formatter: EnumFormatter,
                    isRequired: true,
                },
            },
            validate: validateListIfCondition('ownVehicle.driver.driverOwnsOtherVehicles'),
        },
    },
};
