import React from 'react';

import {
    SearchFilterProps, BaseFilterProps, SearchFilter, NumberInput,
} from '@shift/design-system';
import { translate } from '../../../utils/forms';

export type IntegerRangeValue = {
    minValue: number | null;
    maxValue: number | null;
}
export type IntegerInputFilterProps = BaseFilterProps<IntegerRangeValue> & SearchFilterProps<IntegerRangeValue>

export const IntegerInputFilter = ({
    filter,
    onFilterChange,
    ...props
}: IntegerInputFilterProps) => {
    const { minValue, maxValue } = filter || { minValue: null, maxValue: null };
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SearchFilter {...props}>
            <div className="integer-input-filter">
                <NumberInput
                    placeholder={translate('common:minimum')}
                    name="min-value"
                    min={0}
                    value={filter?.minValue?.toString()}
                    onChange={(val) => onFilterChange && onFilterChange({ minValue: val ? parseInt(val, 10) : null, maxValue })}
                />
                <NumberInput
                    placeholder={translate('common:maximum')}
                    name="max-value"
                    min={filter?.minValue ?? 0}
                    value={filter?.maxValue?.toString()}
                    onChange={(val) => onFilterChange && onFilterChange({ minValue, maxValue: val ? parseInt(val, 10) : null })}
                />
            </div>
        </SearchFilter>
    );
};
