import { Widgetters } from 'src/config/widgets';
import { Formatters } from 'src/config/formatters';
import {
    ProviderFields, SearchOperationType, ParameterType,
} from 'src/libs/filters';
import { ArcConfig } from '../../types/ArcConfig';

const rootContext = ['arc'];

export const arcConfig: ArcConfig = {
    list: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'arcs',
        transforms: [
            {
                chrooters: [
                    ['person', 'specializations', 'mapping_Person_Gears', 'name'],
                ],
                filter: {
                    field: { id: ProviderFields.ARCName },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                i18NId: 'main-phone',
                chrooters: [
                    [
                        'person',
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Professional' }],
                            },
                        },
                        'countryCode',
                    ],
                    [
                        'person',
                        'phoneNumbers',
                        {
                            name: '',
                            selector: {
                                path: ['links', 'mapping_ContactInformation', 'role'],
                                values: [{ Type: 'ContactInformationRole', Name: 'Professional' }],
                            },
                        },
                        'number',
                    ],
                ],
                formatter: Formatters.PhoneNumberFormatter,
                filter: {
                    field: { id: ProviderFields.PhoneNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    [
                        'person', 'addresses', 'fullAddress',
                    ],
                ],
                filter: {
                    field: { id: ProviderFields.Address },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    ['insurers', 'insurerCode'],
                ],
                formatter: Formatters.EnumListValuesCommaFormatter,
                widgetter: Widgetters.MultiLineTextWidgetter,
                filter: {
                    field: { id: ProviderFields.Insurer },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'EnumEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('InsurerCode'),
                },
            },
        ],
    },
};
