import React from 'react';
import { Widgetter } from '@shift/transform';


export const NoopWidgetter: Widgetter<{ children: string[] }> = {
    transform: ({ children }: { children: string[] }) => (
        <>
            {children}
        </>
    ),
};
