import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter, ParameterType } from '../../../libs/filters';
import { EqualValueBooleanFilterProps, EqualValueBooleanFilter } from '../../../components/Table/Filters';

export const EqualConditionBooleanFilterWidgetterFactory: (
           value: string,
           parameterType: ParameterType,
           side?: EqualValueBooleanFilterProps['side']
       ) => Widgetter<FilterProps, FilterProps, Filter> = (
           value,
           parameterType,
           side,
       ) => ({
           transform: ({ children }, options) => (options ? (
               <EqualValueBooleanFilter
                   side={side}
                   field={options.field}
                   value={value}
                   parameterType={parameterType}
               >
                   {children}
               </EqualValueBooleanFilter>
           ) : (
               (children as ReactElement)
           )),
       });
