import { combineReducers } from 'redux-immutable';
import { Record } from 'immutable';

import { applicationReducer } from './application/application-reducer';

export const RootReducer = combineReducers({
    application: applicationReducer,
});

export type RootReducerType = Record<ReturnType<typeof RootReducer>>
