import { KeyValueCardConfig } from '../../../../../libs/config';
import { Formatters, FormatterFactories } from '../../../../formatters';
import { Widgetters } from '../../../../widgets';
import { permissions } from '../../../../permissions';

const policyExpandableSection1: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['policy'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'name',
            chrooters: [
                [
                    'persons',
                    'specializations',
                    'mapping_Person_Gears',
                    'name',
                ],
            ],
        },
        {
            chrooters: [
                ['objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        {
            i18NId: 'policy',
            chrooters: [
                [
                    'referenceCode'],
            ],
        },
        {
            chrooters: [
                [
                    'persons',
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumIdentifier'],
                            values: ['PersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumValueId'],
                            values: [1],
                        },
                    },
                    'specializations',
                    'mapping_Person_NationalIdentifier',
                    'nationalIdentifier',
                ],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('person'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        {
            chrooters: [
                [
                    'effectiveDateOffset',
                ],
            ],
            formatter: Formatters.OffsetDateFormatter,
        },
        {
            chrooters: [
                [
                    'expirationDateOffset',
                ],
            ],
            formatter: Formatters.OffsetDateFormatter,
        },
        {
            i18NId: 'cancellationDateOffset',
            chrooters: [
                [
                    'objects',
                    'links',
                    {
                        name: 'mapping_PolicyObject',
                        selector: {
                            path: ['specializations',
                                'mapping_PolicyObject_Motor_TerminationReason',
                                'policyObjectTerminationReason'],
                            values: [{ Type: 'PolicyObjectTerminationReason', Name: 'Cancelled' }],
                        },
                    },
                    'expirationDateOffset',
                ],
            ],
            formatter: Formatters.OffsetDateFormatter,
        },
    ],
};

const policyExpandableSection2: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['policy'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'last-process-date',
            chrooters: [
                ['modifyDateUtc'],
            ],
            formatter: Formatters.UtcDateFormatter,
        },
        {
            i18NId: 'termination-reason',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'specializations',
                    'mapping_PolicyObject_Motor_TerminationReason',
                    'policyObjectTerminationReason',
                ],
            ],
            formatter: Formatters.ExplorationReferenceFormatter,
        }, {
            i18NId: 'current-ncd',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'coefficient'],
            ],
            widgetter: Widgetters.PercentageWidgetExpandableRow,
            formatter: Formatters.CurrentNcdValuesFormatter,
        },
        {
            i18NId: 'renewal-ncd',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' }] } },
                    'coefficient'],
            ],
            widgetter: Widgetters.PercentageWidgetExpandableRow,
            formatter: Formatters.CurrentNcdValuesFormatter,
        },
        {
            i18NId: 'ncd-protection',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_PolicyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'protection'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            chrooters: [
                ['specializations', 'mapping_Policy_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
        },
    ],
};

const claimExpandableSection1: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'claim-year',
            chrooters: [
                ['specializations', 'mapping_Claim_Handling', 'clientSystemCreateDateOffset'],
            ],
            formatter: Formatters.OffsetYearFormatter,
        },
        {
            i18NId: 'claim-type',
            chrooters: [
                ['specializations', 'mapping_Claim_Category', 'categoryEnumIdentifier'],
                ['specializations', 'mapping_Claim_Category', 'categoryEnumValueId'],
            ],
            formatter: Formatters.EnumValuesIdFormatter,
        },
        {
            i18NId: 'claim-status',
            chrooters: [
                ['status'],
            ],
            formatter: Formatters.EnumValuesFormatter,
        },
        {
            i18NId: 'incurred-amount',
            chrooters: [
                [
                    'specializations',
                    { name: 'mapping_Claim_Amounts', selector: { path: ['identifier'], values: [{ Type: 'ClaimAmount', Name: 'ValueClaimed' }] } },
                    'amount',
                ],
            ],
        },
        {
            i18NId: 'claim-accidentDate',
            chrooters: [
                ['specializations', 'mapping_Claim_Event', 'eventDateOffset'],
            ],
            formatter: Formatters.OffsetDateFormatter,
        },
    ],
};

const claimExpandableSection2: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'liability-code',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_ClaimObject',
                    'specializations',
                    'mapping_ClaimObject_Motor_Liability',
                    'liabilityCode',
                ],
            ],
            formatter: Formatters.EnumValuesFormatter,
        },
        {
            i18NId: 'liability-percent',
            chrooters: [
                [
                    'objects',
                    'links',
                    'mapping_ClaimObject',
                    'specializations',
                    'mapping_ClaimObject_Motor_Liability',
                    'liabilityPercent',
                ],
            ],
        },
        {
            i18NId: 'policy',
            chrooters: [
                [
                    'policy', 'referenceCode'],
            ],
        },
        {
            i18NId: 'claim-insurer',
            chrooters: [
                ['policy', 'specializations', 'mapping_Policy_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
        },
        {
            i18NId: 'claim-submissionDate',
            chrooters: [
                ['versions', 'submissionDate'],
            ],
            formatter: FormatterFactories.ElementAtFormatterFactory('last', Formatters.UtcDateTimeFormatter),
        },
    ],
};

const claimExpandableSection3: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'claim-detailedCircumstances',
            chrooters: [
                ['specializations', 'mapping_Claim_Cause', 'detailedCircumstances'],
            ],
            formatter: Formatters.SingleValueFormatter,
            widgetter: Widgetters.TextWidgetter,
        },

    ],
};

const accidentReportExpandableSection1: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'owner-name',
            chrooters: [
                [
                    'policy',
                    'persons',
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumIdentifier'],
                            values: ['PersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_PolicyPerson', 'roleEnumValueId'],
                            values: [1],
                        },
                    },
                    'specializations',
                    'mapping_Person_Gears',
                    'name',
                ],
            ],
        },
        {
            i18NId: 'driver-name',
            chrooters: [
                [
                    'persons',
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                            values: [{ Type: 'PartySide', Name: 'FirstParty' }],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                            values: ['MotorClaimPersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                            values: [3],
                        },
                    },
                    'specializations',
                    'mapping_Person_Gears',
                    'name',
                ],
            ],
        },
        {
            i18NId: 'driver-ic',
            chrooters: [
                [
                    'persons',
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                            values: [{ Type: 'PartySide', Name: 'FirstParty' }],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                            values: ['MotorClaimPersonFunction'],
                        },
                    },
                    {
                        name: '',
                        selector: {
                            path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                            values: [3],
                        },
                    },
                    'specializations',
                    'mapping_Person_NationalIdentifier',
                    'nationalIdentifier',
                ],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('person'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,
        },
        {
            i18NId: 'make',
            chrooters: [
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_Vehicle', 'makeNotInEnum'],
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_Vehicle', 'make'],
                ['policy', 'objects', 'specializations', 'mapping_Object_Motor_Vehicle', 'makeDescription'],
            ],
            formatter: Formatters.VehicleMakeFormatter,
        },
        {
            i18NId: 'claim-lateReport',
            chrooters: [
                ['specializations', 'mapping_Claim_Accident', 'isLateReported'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            i18NId: 'claim-anyInjuredPersons',
            chrooters: [
                ['specializations', 'mapping_Claim_Motor_Checklist', 'anyInjuredPersons'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            i18NId: 'claim-anyOtherVehicleDamage',
            chrooters: [
                ['specializations', 'mapping_Claim_Motor_Checklist', 'anyOtherVehicleDamage'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            i18NId: 'claim-insurer',
            chrooters: [
                ['policy', 'specializations', 'mapping_Policy_Insurer', 'insurer'],
            ],
            formatter: Formatters.EnumValuesFormatter,
        },
    ],
};

const accidentReportExpandableSection2: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'claim-matchedReports',
            chrooters: [
                ['matchedReports', 'id'],
                ['matchedReports', 'referenceCode'],
                ['matchedReports', 'paidAccess'],
                ['matchedReports', 'policy', 'objects', 'specializations', 'mapping_Object_Motor_VehicleRegistration', 'number'],
                ['matchedReports', 'isStatusUpdateAllowed'],
            ],
            formatter: Formatters.LinksBillableActionFormatter(
                ['UN_MATCH_AR', 'EXPLORATION_SEARCH'],
                [permissions.canPurchaseReportsInExploration, permissions.canSeeExploration],
                true,
            ),
            widgetter: Widgetters.LinksBillableActionWidgetter,
            permissions: permissions.canSeeMatchedReports,
        },
    ],
};

const accidentReportExpandableSection3: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['explorationClaim'],
    i18Namespace: 'history-exploration',
    transforms: [
        {
            i18NId: 'purchased-by',
            chrooters: [
                ['billableActions', 'identity', 'insurerEntity', 'insurer', 'insurerCode'],
            ],
            formatter: Formatters.PurchasedByFormatter,
            widgetter: Widgetters.MultiLineWidgetter,
        },
    ],
};

export const expandableRowSections = {
    policyExpandableSection1,
    policyExpandableSection2,
    claimExpandableSection1,
    claimExpandableSection2,
    claimExpandableSection3,
    accidentReportExpandableSection1,
    accidentReportExpandableSection2,
    accidentReportExpandableSection3,
};
