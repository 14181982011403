/* eslint-disable @typescript-eslint/camelcase */
import { DateFormat, DateOffsetFormat } from 'src/constants';
import { parseOffsetDate } from 'src/utils';
import { buildExplorationQuery, buildNcdEmailQuery } from '../query-builder';
import { ProcessedSubConfiguration } from '../../libs/config/QueryConfigTypes';
import { ExplorationConfig } from '../../config/configuration/types/ExplorationConfig';

const id = 10001;

const policy = {
    id,
    effectiveDateOffset: '18/08/2019T07:09:36',
    expirationDateOffset: parseOffsetDate('14/10/2020', DateFormat).format(DateOffsetFormat),
    referenceCode: 'A26121269',
    specializations: {
        id,
        mapping_Policy_Insurer: {
            id,
            insurer: { Type: 'InsurerCode', Name: 'InsurerA' },
        },
    },
    objects: {
        id,
        specializations: {
            id,
            mapping_Object_Motor_Vehicle: {
                id,
                make: { Type: 'VehicleMake', Name: 'Toyota' },
                model: 'Prius',
                type: {
                    Type: 'VehicleType', Name: 'PrivateCar',

                },
            },
            mapping_Object_Motor_VehicleRegistration: [
                { id, number: 'ABC1564DEF' },
            ],
        },
        links: {
            mapping_PolicyObject: {
                id,
                specializations: {
                    id,
                    mapping_PolicyObject_NoClaimsDiscount: [
                        {
                            identifier: { Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' },
                            coefficient: 30,
                        },
                        {
                            identifier: { Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' },
                            coefficient: 20,
                        },
                    ],
                    mapping_PolicyObject_Motor_TerminationReason: {
                        Name: 'TerminationReason',
                        Value: 'None',
                    },
                },
            },
        },
    },
};
const policy1 = {
    id,
    effectiveDateOffset: '18/08/2019T07:09:36',
    expirationDateOffset: parseOffsetDate('16/10/2020', DateFormat).format(DateOffsetFormat),
    referenceCode: 'A26121269',
    specializations: {
        id,
        mapping_Policy_Insurer: {
            id,
            insurer: { Type: 'InsurerCode', Name: 'InsurerB' },
        },
    },
    objects: {
        id,
        specializations: {
            id,
            mapping_Object_Motor_Vehicle: {
                id,
                make: { Type: 'VehicleMake', Name: 'Toyota2' },
                model: 'Prius',
                type: {
                    Type: 'VehicleType', Name: 'PrivateCar',

                },
            },
            mapping_Object_Motor_VehicleRegistration: [
                { id, number: 'FEG' },
            ],
        },
        links: {
            mapping_PolicyObject: {
                id,
                specializations: {
                    id,
                    mapping_PolicyObject_NoClaimsDiscount: [
                        {
                            identifier: { Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' },
                            coefficient: 30,
                        },
                        {
                            identifier: { Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' },
                            coefficient: 20,
                        },
                    ],
                    mapping_PolicyObject_Motor_TerminationReason: {
                        Name: 'TerminationReason',
                        Value: 'None',
                    },
                },
            },
        },
    },
};

const policies = [
    policy,
    policy1,
];

const personNumberAccident = {
    reconstruction: {
        explorePerson: {
            specializations: {
                shift_Person_Motor_ClaimHistoryStatistics: [
                    {
                        startDate: '19/07/2017T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyDriver: 2,
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyDriverAndVehicleOwner: 2,
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyVehicleOwner: 5,
                    },
                    {
                        startDate: '19/07/2015T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyDriver: 5,
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyDriverAndVehicleOwner: 3,
                        numberOfAccidentReportsThatThePersonWasInvolvedAsFirstPartyVehicleOwner: 8,
                    },
                ],
            },
        },
    },
};
const personTotalClaimAmount = {
    reconstruction: {
        explorePerson: {
            specializations: {
                shift_Person_Motor_ClaimHistoryStatistics: [
                    {
                        startDate: '19/07/2017T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        totalClaimedAmountsWhereThePersonWasInvolvedAsFirstPartyVehicleOwner: 999,
                    },
                    {
                        startDate: '19/07/2015T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        totalClaimedAmountsWhereThePersonWasInvolvedAsFirstPartyVehicleOwner: 1999,
                    },
                ],
            },
        },
    },
};
const vehicleNumberAccident = {
    reconstruction: {
        exploreVehicle: {
            specializations: {
                shift_Object_Motor_ClaimHistoryStatistics: [
                    {
                        startDate: '19/07/2017T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        numberOfAccidentReportsThatTheVehicleInvolvedAsFirstPartyVehicle: 3,
                    },
                    {
                        startDate: '19/07/2015T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        numberOfAccidentReportsThatTheVehicleInvolvedAsFirstPartyVehicle: 7,
                    },
                ],
            },
        },
    },
};
const vehicleTotalClaimAmount = {
    reconstruction: {
        exploreVehicle: {
            specializations: {
                shift_Object_Motor_ClaimHistoryStatistics: [
                    {
                        startDate: '19/07/2017T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        totalClaimedAmountsWhereTheVehicleWasInvolvedAsFirstPartyVehicle: 799,
                    },
                    {
                        startDate: '19/07/2015T07:09:36',
                        endDate: '19/07/2020T07:09:36',
                        totalClaimedAmountsWhereTheVehicleWasInvolvedAsFirstPartyVehicle: 1799,
                    },
                ],
            },
        },
    },
};


export const explorationMock = (config: ProcessedSubConfiguration<ExplorationConfig>) => [
    {
        request: {
            query: buildExplorationQuery(
                config.personDetails.context,
                config.personDetails.fields,
            ),
            variables: {
                ident: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: {
                    explorePerson: {
                        specializations: {
                            mapping_Person_Gears: {
                                id: 1,
                                name: 'Cheng Soh',
                            },
                        },
                    },
                },
            },
        }),
    },
    {
        request: {
            query: buildExplorationQuery(
                config.vehiclesByPerson.context,
                config.vehiclesByPerson.fields,
            ),
            variables: {
                ident: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: {
                    explorePerson: {
                        policies,
                    },
                },
            },
        }),
    },
    {
        request: {
            query: buildExplorationQuery(
                config.personNumberAccident.context,
                config.personNumberAccident.fields,
            ),
            variables: {
                ident: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: personNumberAccident.reconstruction,
            },
        }),
    },
    {
        request: {
            query: buildExplorationQuery(
                config.personTotalClaimAmount.context,
                config.personTotalClaimAmount.fields,
            ),
            variables: {
                ident: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: personTotalClaimAmount.reconstruction,
            },
        }),
    },
    {
        request: {
            query: buildExplorationQuery(
                config.vehicleNumberAccident.context,
                config.vehicleNumberAccident.fields,
            ),
            variables: {
                vrn: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: vehicleNumberAccident.reconstruction,
            },
        }),
    },
    {
        request: {
            query: buildExplorationQuery(
                config.vehicleTotalClaimAmount.context,
                config.vehicleTotalClaimAmount.fields,
            ),
            variables: {
                vrn: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: vehicleTotalClaimAmount.reconstruction,
            },
        }),
    },
    {
        request: {
            query: buildNcdEmailQuery(
                config.requestStatusFromInsurer.context,
                config.requestStatusFromInsurer.fields,
            ),
            variables: {
                ident: 'a',
            },
        },
        newData: () => ({
            data: {
                reconstruction: {
                    explorePerson: {
                        specializations: {
                            mapping_Person_Gears: {
                                id: 1,
                                name: 'Cheng Soh',
                            },
                        },
                        policies,
                    },
                },
            },
        }),
    },
];
