/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */
import {
    insertAccidentReportMutation, buildFormAccidentReportQuery,
} from '..';

export const accidentReportMock = {
    generalInformation: {
        accidentInformation: {
            id: 1,
            referenceCode: 'ABCD1234',
            status: { Type: 'ClosingType', Name: 'AtDraftStage' },
            specializations: {
                mapping_Claim_Statement: [
                    {
                        dateOffset: '07/04/2020T00:00:00+08:00',
                    },
                ],
                mapping_Claim_Motor_RoadAccident: {
                    weatherCondition: { Type: 'WeatherCondition', Name: 'Good' },
                    roadSurface: { Type: 'RoadSurface', Name: 'Dry' },
                },
                mapping_Claim_Cause: {
                    enumIdentifier: 'MotorClaimCause',
                    enumValueId: 83,
                    detailedCircumstances: 'I was driving my car along the road. There is a traffic light turning red so i then stopped. Suddenly a car from behind collided with my rear.',
                },
                mapping_Claim_Event: {
                    eventDateOffset: '01/11/2019T11:30:00+08:00',
                },
                mapping_Claim_Motor_Checklist: {
                    anyPoliceReport: true,
                    anyProsecutionGiven: true,
                },
                mapping_Claim_PurposeIndication: {
                    purpose: {
                        Name: 'ClaimAgainstOwnInsuranceForCompensation',
                        Type: 'ReportPurpose',
                    },
                },
            },
        },
        accidentAddress: {
            fullAddress: '165 Tg Pagar Rd, Singapore 088539',
            complement: '#125',
            country: { Type: 'Country', Name: 'Singapore' },
            postCode: '088539',
            latitude: 1.2750316,
            longitude: 103.8435506,
            areaOrState: 'Singapore',
        },
        policeStationInformation: {
            person: {
                specializations: {
                    mapping_Person_Gears: {
                        name: 'Rochor Neighbourhood Police Centre',
                    },
                },
            },
        },
        prosecutionInformation: {
            specializations: {
                mapping_Assessment_FreeText: [{
                    text: 'John Doe',
                }],
            },
        },
        translation: {
            email: {
                address: 'text@email.com',
            },
            isTranslatedReport: true,
            person: {
                specializations: {
                    mapping_Person_Gears: {
                        name: 'sometranslator',
                    },
                    mapping_Person_NationalIdentifier: [{
                        identifier: { Type: 'NationalIdentifierType', Name: 'NationalIdentifier' },
                        nationalIdentifier: 'S0000001I',
                    }],
                },
            },
            phoneNumber: {
                number: '+65 4444-2541',
            },
            translatedReport: undefined,
        },
    },
    ownVehicle: {
        vehiclePolicy: {
            vehicle: {
                specializations: {
                    mapping_Object_Motor_VehicleRegistration: [
                        {
                            number: 'SCK1117J',
                        },
                    ],
                    mapping_Object_Motor_Vehicle: {
                        make: { Type: 'VehicleMake', Name: 'Honda' },
                        model: 'Fit',
                        type: { Type: 'VehicleType', Name: 'PrivateCar' },
                    },
                },
            },
            usagePurpose: 'Private use',
            passengers: [
                {
                    specializations: {
                        mapping_Person_Gears: {
                            id: 1,
                            name: 'John W.',
                        },
                        mapping_Person_Individual: {
                            gender: { Type: 'EntityGender', Name: 'Male' },
                        },
                    },
                },
            ],
            policy: {
                referenceCode: 'A26121269',
                specializations: {
                    id: 1,
                    mapping_Policy_Motor: {
                        type: { Type: 'MotorPolicyType', Name: 'Fleet' },
                    },
                },
            },
        },
        owner: {
            person: {
                specializations: {
                    mapping_Person_Gears: {
                        id: 1,
                        name: 'John Tan',
                    },
                    mapping_Person_NationalIdentifier: [
                        {
                            identifier: { Type: 'NationalIdentifierType', Name: 'NationalIdentifier' },
                            nationalIdentifier: 'S8284938B',
                        },
                    ],
                },
            },
            email: {
                address: 'john_tan@gmail.com',
            },
            primaryPhoneNumber: {
                number: '+65 8536-2541',
            },
            alternativePhoneNumbers: [
                {
                    type: { Type: 'PhoneNumberType', Name: 'Landline' },
                    number: '+65 1234-5678',
                },
            ],
        },
        driver: {
            person: {
                links: {
                    mapping_ClaimPerson: {
                        specializations: {
                            mapping_ClaimPerson_DrivingLicense: {
                                startDate: '10/11/2014T00:00:00.000 +01:00',
                            },
                        },
                    },
                },
                specializations: {
                    mapping_Person_Gears: {
                        id: 1,
                        name: 'Cheng Soh',
                    },
                    mapping_Person_NationalIdentifier: [
                        {
                            identifier: { Type: 'NationalIdentifierType', Name: 'Passport' },
                            nationalIdentifier: 'S1191122A',
                        },
                    ],
                    mapping_Person_Individual: {
                        dateOfBirth: '11/02/1986T00:00:00.000 +01:00',
                        isOccupationOutdoor: false,
                        gender: { Type: 'EntityGender', Name: 'Male' },
                    },
                },
            },
            primaryPhoneNumber: {
                countryCode: '65',
                number: '91158859',
            },
            alternativePhoneNumbers: [
                {
                    phoneNumberType: { Type: 'PhoneNumberType', Name: 'Office' },
                    countryCode: '65',
                    number: '98765432',
                },
            ],
            address: {
                fullAddress: ' 171A Edgedale plains',
                complement: '#08-11',
                postCode: '415979',
            },
            email: {
                address: 'soh_chentiong@gmail.com',
            },
        },
        driverOwnerRelation: { Type: 'OwnerDriverRelationship', Name: 'Friend' },
        driverOwnsOtherVehicles: true,
        otherVehiclesOwnedByDriver: [
            {
                vehicle: {
                    specializations: {
                        mapping_Object_Motor_VehicleRegistration: [
                            {
                                number: 'SZR7283L',
                            },
                        ],
                    },
                },
                policy: {
                    specializations: {
                        mapping_Policy_Insurer: {
                            insurer: { Type: 'InsurerCode', Name: 'InsurerA' },
                        },
                    },
                },
            },
        ],
    },
    thirdPartyVehicles: [
        {
            vehiclePolicy: {
                vehicle: {
                    specializations: {
                        mapping_Object_Motor_VehicleRegistration: [
                            {
                                number: 'SBZ2228E',
                            },
                        ],
                        mapping_Object_Motor_Vehicle: {
                            type: { Type: 'VehicleType', Name: 'Taxi' },
                        },
                    },
                },
                passengers: [
                    {
                        specializations: {
                            mapping_Person_Gears: {
                                id: 1,
                                name: 'Jane V.',
                            },
                            mapping_Person_Individual: {
                                gender: { Type: 'EntityGender', Name: 'Female' },
                            },
                        },
                    },
                ],
            },
            driver: {
                person: {
                    specializations: {
                        mapping_Person_Gears: {
                            id: 1,
                            name: 'Melissa Lee',
                        },
                        mapping_Person_NationalIdentifier: [
                            {
                                identifier: { Type: 'NationalIdentifierType', Name: 'NationalIdentifier' },
                                nationalIdentifier: 'S2225469B',
                            },
                        ],
                    },
                },
                primaryPhoneNumber: {
                    number: '+65 9226-2886',
                },
                address: {
                    fullAddress: '2 Hougang street',
                    complement: 'Appartement 111',
                    postCode: '75001',
                },
            },
        },
    ],
    injuredPersons: [
        {
            person: {
                specializations: {
                    mapping_Person_Gears: {
                        id: 1,
                        name: 'Cheng Soh',
                    },
                },
            },
            address: {
                fullAddress: ' 171A Edgedale plains',
                complement: '#08-11',
                postCode: '415979',
            },
        },
    ],
    witnesses: [
        {
            person: {
                specializations: {
                    mapping_Person_Gears: {
                        id: 1,
                        name: 'Huan Qin',
                    },
                },
            },
            primaryPhoneNumber: {
                number: '+65 5678-1234',
            },
            email: {
                address: 'huan.qin@gmail.com',
            },
        },
    ],
};

export const accidentReportingsMock = [
    // Get accident report
    {
        request: {
            query: buildFormAccidentReportQuery(),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                accidentReport: accidentReportMock,
            },
        }),
    },
    // Insert accident report
    {
        request: {
            query: insertAccidentReportMutation,
            variables: {
                report: accidentReportMock,
            },
        },
        newData: () => ({
            data: {
                accidentReport: accidentReportMock,
            },
        }),
    },
];
