import {
    EnumFormatter, BackendListFormatter, FileWithTypeFormatter,
} from 'src/utils/forms/formatters';
import { isValidFileWithType } from 'src/utils/forms';

export const counterOffer = {
    bolaScenarioStep: {
        name: 'bolaCase',
        title: 'bola-scenario',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'bolaCase.actionType',
                target: 'nextAction.step.action',
            },
            bolaScenario: {
                path: 'bolaCase.bolaScenario',
                target: 'nextAction.bolaScenario.id',
                label: 'bola-scenario',
                isRequired: true,
                formatter: EnumFormatter,
            },
            officer: {
                path: 'bolaCase.officerIdentity',
                target: 'receivingParty.officerIdentity',
                label: 'officer-in-charge',
                isRequired: true,
                formatter: BackendListFormatter,
            },
            claimNumber: {
                path: 'bolaCase.referenceCode',
                target: 'receivingParty.claim.referenceCode',
                isRequired: true,
                label: 'claim-number',
            },
            comment: {
                path: 'bolaCase.comment',
                target: 'comment.text.content',
                label: 'rp-version',
            },
            commentType: {
                path: 'comment.commentType',
                label: '',
            },
        },
    },
    attachmentsStep: {
        name: 'attachmentStep',
        title: 'attachments',
        fields: {
            attachments: {
                path: 'attachmentStep.attachments',
                label: 'new-attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        document: 'document',
                        category: 'category',
                    },
                },
                formatter: FileWithTypeFormatter,
                validate: isValidFileWithType,
            },
        },
    },
};
