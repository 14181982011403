import React from 'react';
import {
    FormField, ArrayItemProps, EnumSelectInput,
} from 'src/components/form';
import { SingleFieldInformation } from 'src/types';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export interface PassengerItemFields {
    name: SingleFieldInformation;
    gender: SingleFieldInformation;
}

export const GetPassengerItem = ({ name, gender }: PassengerItemFields) => ({ setItemPath }: ArrayItemProps) => (
    <>
        <div className="row">
            <div className="col-xs-6 form-group">
                <FormField
                    fieldInfo={setItemPath(name)}
                    component={TextInput}
                />
            </div>
            <div className="col-xs-6 form-group">
                <FormField
                    fieldInfo={setItemPath(gender)}
                    component={EnumSelectInput}
                    enumName="Gender"
                />
            </div>
        </div>
    </>
);
