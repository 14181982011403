/* eslint-disable no-bitwise */
import { Claims } from '../libs/auth/Authenticator';

export enum Roles {
    Admin = 'GEARS-SHIFT-ADMIN',
    Insurer = 'GEARS-Insurer',
    InsurerUnderwriter = 'GEARS-Insurer-Underwriter',
    InsurerClaimHandler = 'GEARS-Insurer-ClaimHandler',
    InsurerRecovery = 'GEARS-Insurer-Recovery',
    InsurerRecoveryManager = 'GEARS-Insurer-RecoveryManager',
    InsurerSiu = 'GEARS-Insurer-SIU',
    Arc = 'GEARS-ARC',
    ArcArManager = 'GEARS-ARC-ARManager',
    ArcClaimsManager = 'GEARS-ARC-ClaimsManager',
    GiaSecretariat = 'GEARS-GIASecretariat',
    Lawyer = 'GEARS-Lawyer',
    TrafficPoliceCad = 'GEARS-TrafficPoliceCAD',
    Intermediaries = 'GEARS-Intermediaries',
    MotorInsuranceBureau = 'GEARS-MotorInsuranceBureau',
}

enum Permissions {
    None = 0x0,
    Insurer = 0x1,
    InsurerUnderwriter = Insurer | 0x2,
    InsurerClaimHandler = InsurerUnderwriter | 0x4,
    InsurerRecovery = InsurerClaimHandler | 0x10,
    InsurerRecoveryManager = InsurerRecovery | 0x20,
    InsurerSiu = InsurerClaimHandler | 0x40,
    Arc = 0x1000,
    ArcArManager = Arc | 0x2000,
    ArcClaimsManager = 0x4000,
    GiaSecretariat = 0x8000,
    Lawyer = 0x10000,
    TrafficPoliceCad = 0x20000,
    Intermediaries = 0x40000,
    MotorInsuranceBureau = 0x80000,
    All = 0x100000,
}

type PermissionMap = {
    [name in Roles]: Permissions;
};

const permissionsMap: PermissionMap = {
    [Roles.Admin]: Permissions.All,
    [Roles.Insurer]: Permissions.Insurer,
    [Roles.InsurerUnderwriter]: Permissions.InsurerUnderwriter,
    [Roles.InsurerClaimHandler]: Permissions.InsurerClaimHandler,
    [Roles.InsurerRecovery]: Permissions.InsurerRecovery,
    [Roles.InsurerRecoveryManager]: Permissions.InsurerRecoveryManager,
    [Roles.InsurerSiu]: Permissions.InsurerSiu,
    [Roles.Arc]: Permissions.Arc,
    [Roles.ArcArManager]: Permissions.ArcArManager,
    [Roles.ArcClaimsManager]: Permissions.ArcClaimsManager,
    [Roles.GiaSecretariat]: Permissions.GiaSecretariat,
    [Roles.Lawyer]: Permissions.Lawyer,
    [Roles.TrafficPoliceCad]: Permissions.TrafficPoliceCad,
    [Roles.Intermediaries]: Permissions.Intermediaries,
    [Roles.MotorInsuranceBureau]: Permissions.MotorInsuranceBureau,
};

export type TokenPredicate = (user: Claims) => boolean;

const hasPermission = (perm: Permissions): TokenPredicate => (user) => {
    const permissions = user?.roles?.map((role: Roles) => permissionsMap[role]);
    return permissions?.some((permission: Permissions) => permission && (permission & perm) === perm) || permissions?.includes(Permissions.All);
};

const hasPermissionAny = (...perms: Permissions[]): TokenPredicate => (user) => perms.findIndex((p) => hasPermission(p)(user)) !== -1;
const hasPermissionAnyAndNot = (
    perms: Permissions[],
    notPerms: Permissions[],
): TokenPredicate => (user) => perms.findIndex((p) => hasPermission(p)(user)) !== -1 && notPerms.findIndex((p) => hasPermission(p)(user)) === -1;

export const permissions = {
    canCreateReport: hasPermission(Permissions.Arc),
    canEditDraft: hasPermission(Permissions.Arc),
    canCreateAddendum: hasPermission(Permissions.ArcArManager),
    canCreateOrUpdateReport: hasPermission(Permissions.Arc),
    canListReport: hasPermissionAny(
        Permissions.Insurer, Permissions.Arc, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canReadReport: hasPermissionAny(
        Permissions.Insurer, Permissions.Arc, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canSeeMatchedReports: hasPermissionAny(
        Permissions.Insurer, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
        Permissions.InsurerUnderwriter, Permissions.InsurerClaimHandler,
    ),
    canSeeInsurerColumn: hasPermissionAnyAndNot([Permissions.Arc], [Permissions.Insurer]),
    canSeeDraftColumn: hasPermissionAnyAndNot([Permissions.Arc], [Permissions.Insurer]),
    canUpdateStatus: hasPermission(Permissions.Insurer),
    canSeeVersions: hasPermissionAny(
        Permissions.Insurer, Permissions.Arc, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canSeeCompleteId: hasPermissionAny(
        Permissions.Insurer, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canListRecovery: hasPermissionAny(Permissions.InsurerRecovery, Permissions.GiaSecretariat),
    canReadRecovery: hasPermissionAny(Permissions.InsurerRecovery, Permissions.GiaSecretariat),
    canSeeExploration: hasPermissionAny(
        Permissions.InsurerClaimHandler, Permissions.InsurerUnderwriter, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canSeeNcd: hasPermission(Permissions.Intermediaries),
    canSeeThirdPartyReports: hasPermissionAny(Permissions.ArcClaimsManager, Permissions.TrafficPoliceCad, Permissions.Lawyer),
    canSeeInsurerEnquiry: hasPermissionAny(Permissions.ArcClaimsManager, Permissions.Lawyer),
    canPurchaseReports: hasPermissionAny(
        Permissions.InsurerClaimHandler, Permissions.InsurerUnderwriter, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau,
    ),
    canPurchaseReportsInExploration: hasPermissionAny(Permissions.InsurerClaimHandler, Permissions.GiaSecretariat, Permissions.MotorInsuranceBureau),
    isAdmin: hasPermission(Permissions.All),
    canListArc: hasPermissionAny(
        Permissions.Insurer, Permissions.GiaSecretariat,
    ),
    canDownloadDecisionDocument: hasPermission(Permissions.GiaSecretariat),
    isNeutralUser: hasPermissionAny(Permissions.GiaSecretariat, Permissions.All),
    isGIASecretariat: hasPermission(Permissions.GiaSecretariat),
    isArcOnly: hasPermissionAnyAndNot([Permissions.Arc], [Permissions.All, Permissions.Insurer]),
    canSeeDashboard: hasPermissionAny(Permissions.All, Permissions.Insurer, Permissions.GiaSecretariat),
};
