import { Formatter } from '@shift/transform';
import { SingleValueFormatter } from './SingleValueFormatter';
import { ListFormatter, ListFormatterWithOptions } from './ListFormatter';
import { TranslateValueFormatter, TranslateWithNamespaceValueFormatter } from './TranslateValueFormatter';
import { JoinsValuesFormatter } from './JoinsValuesFormatter';
import { EnumFormatters } from './EnumValuesFormatter';
import { BooleanValueFormatter, BooleanValueFallbackNoFormatter, ConditionalBooleanValueFormatterFactory } from './BooleanValueFormatter';
import { DateTimeFormatters } from './DateTimeFormatters';
import { DocumentLinkFormatter } from './DocumentLinkFormatter';
import { ThumbnailFormatter } from './ThumbnailFormatter';
import { PDFLinkFormatter } from './PDFLinkFormatter';
import {
    IdLinkFormatterFactory, GoToDetailPageFormatter,
    GoToRecoveryPageFormatter, GoToFolderPageFormatter,
} from './IdLinkFormatter';
import { ElementAtFormatterFactory } from './ElementAtFormatterFactory';
import { LinksBillableActionFormatter } from './LinksBillableActionFormatter';
import { LinksExplorationSearchFormatter } from './LinksExplorationSearchFormatter';
import { PhoneNumberFormatter } from './PhoneNumberFormatter';
import { MultiFormatterFactory } from './MultiFormatter';
import { PartyFormatters } from './PartyConditionalFormatter';
import { LiabilityFormatter } from './LiabilityFormatter';
import { NcdValuesFormatter, CurrentNcdValuesFormatter } from './NcdValuesFormatter';
import { VehicleNumberSelectFormatter } from './VehicleNumberSelectFormatter';
import { ExplorationReferenceFormatter } from './ExplorationReferenceFormatter';
import { NationalIdentifierFormatter } from './NationalIdentifierFormatter';
import { PurchasedByFormatter } from './PurchasedByFormatter';
import { RecoveryDeadlineFormatter } from './RecoveryDeadlineFormatter';
import { TernaryConditionFormatter } from './TernaryConditionFormatter';
import { NameAndCompanyFormatter } from './NameAndCompanyFormatter';
import {
    VehicleModelFormatter, VehicleVariantFormatter, VehicleMakeFormatter, VehicleFuelFormatter,
} from './VehicleFormatters';
import { DefaultValueFormatterFactory, DefaultTranslationValueFormatterFactory } from './DefaultValueFormatter';
import { EnumListValuesCommaFormatter } from './EnumListValuesCommaFormatter';
import { ClaimantEnumFormatter } from './ClaimantEnumFormatter';
import { PassengerFormatter, GenderFormatter } from './PassengerFromatter';
import { DrivingLicenseClassEnumFormatter } from './DrivingLicenseClassEnumFormatter';
import { DrivingLicenseValidityFormatter } from './DrivingLicenseValidityFromatter';


export const MultipleFormatters = (formatters: Formatter<any[], { children: any }>[]): Formatter<any[], { children: any }> => ({
    transform: (params: any) => {
        let result = params;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < formatters.length; i++) {
            const tmp = formatters[i].transform(result).children;
            result = Array.isArray(tmp) ? [tmp] : [[tmp]];
        }
        result = result.flat();
        return {
            children: result,
        };
    },
});

export const Formatters = {
    SingleValueFormatter,
    ListFormatter,
    ListFormatterWithOptions,
    TranslateValueFormatter,
    TranslateWithNamespaceValueFormatter,
    JoinsValuesFormatter,
    PDFLinkFormatter,
    ...EnumFormatters,
    BooleanValueFormatter,
    BooleanValueFallbackNoFormatter,
    ConditionalBooleanValueFormatterFactory,
    ThumbnailFormatter,
    ...DateTimeFormatters,
    DocumentLinkFormatter,
    GoToDetailPageFormatter,
    GoToFolderPageFormatter,
    PurchasedByFormatter,
    TernaryConditionFormatter,
    GoToRecoveryPageFormatter,
    LinksBillableActionFormatter,
    LinksExplorationSearchFormatter,
    LiabilityFormatter,
    PhoneNumberFormatter,
    MultiFormatterFactory,
    NcdValuesFormatter,
    CurrentNcdValuesFormatter,
    ExplorationReferenceFormatter,
    ...PartyFormatters,
    VehicleNumberSelectFormatter,
    NationalIdentifierFormatter,
    RecoveryDeadlineFormatter,
    MultipleFormatters,
    NameAndCompanyFormatter,
    VehicleModelFormatter,
    VehicleVariantFormatter,
    VehicleMakeFormatter,
    VehicleFuelFormatter,
    EnumListValuesCommaFormatter,
    ClaimantEnumFormatter,
    PassengerFormatter,
    GenderFormatter,
    DrivingLicenseClassEnumFormatter,
    DrivingLicenseValidityFormatter,
};

export const FormatterFactories = {
    IdLinkFormatterFactory,
    ElementAtFormatterFactory,
    DefaultValueFormatterFactory,
    DefaultTranslationValueFormatterFactory,
};
