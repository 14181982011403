import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Login } from 'src/pages/login/Login';
import { useSecurity } from 'src/libs/auth';
import { getUserCompany } from 'src/graphql';
import { useAsyncQuery } from 'src/templates/useAsyncQuery';
import { LoggedContent } from './LoggedContent';

import { runtimeConfig } from '../../config/runtime-config';

const { intercomId } = runtimeConfig();

const intercom = (...args: any[]) => (window as any).Intercom?.(...args);

export const Application = () => {
    useEffect(() => {
        intercom('boot', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            app_id: intercomId,
        });
    });

    const { user } = useSecurity();
    const [getCompany, { data }] = useAsyncQuery(getUserCompany);
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            intercom('shutdown');
            return;
        }
        intercom('update', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_id: user.sub,
            name: user.name,
            email: user.email,
        });
    }, [user]);

    useEffect(() => {
        intercom('update');
    }, [location]);

    useEffect(() => {
        if (!data && user) {
            getCompany();
        }
        if (user && data && data.me && data.me.company && data.me.company.name && data.me.company.id) {
            intercom('update', {
                company: {
                    name: data.me.company.name,
                    id: data.me.company.id,
                },
            });
        }
    }, [data, user, getCompany]);

    return !user ? <Login /> : <LoggedContent />;
};
