import { Record } from 'immutable';
import { Locale } from '@gears/translations';

interface BaseApplicationState {
    locale: Locale;
}

export type ApplicationState = Record<BaseApplicationState>

export const SET_LOCALE = '@APPLICATION/SET_LOCALE';

interface SetLocaleAction {
    type: typeof SET_LOCALE;
    locale: Locale;
}

export type ApplicationActionTypes = SetLocaleAction
