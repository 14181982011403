import { Formatter } from '@shift/transform';
import { AnyType } from '../../types/anyType';

export type TernaryConditionOptions = {
    conditionFct: (conditionValue: any) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InnerTernaryConditionFormatter = (
    { conditionFct }: TernaryConditionOptions,
): Formatter<any[], { children: AnyType[] | null }> => ({
    transform: ([conditionValue, a, b]) => {
        const result = conditionFct(conditionValue) ? a : b;

        return {
            children: result,
        };
    },
});

export const TernaryConditionFormatter = (option: TernaryConditionOptions) => InnerTernaryConditionFormatter(option);
