/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import styled from 'styled-components';

import { useOctopusConfig } from 'src/utils/hooks';

const VersionContainer = styled.div`
    position: absolute;
    bottom: 15px;
    right: 15px;
`;

export const Version = () => {
    const octopusConfig = useOctopusConfig();

    if (!octopusConfig) return null;

    const { deploymentDate, projectName, releaseVersion } = octopusConfig;

    return (
        <VersionContainer>
            {`${projectName} - ${deploymentDate} - v${releaseVersion}`}
        </VersionContainer>
    );
};
