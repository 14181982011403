import React from 'react';
import { Widgetter } from '@shift/transform';
import { Grid, GridItem } from '@shift/design-system';
import { useLocation } from 'react-router-dom';
import { BillableLinkAction } from '../../containers/billableAction/BillableLinkAction';
import { BILLABLE_ACTIONS } from '../../containers/billableAction/BillableAction';
import { TokenPredicate } from '../permissions';
import { Secure, useSecurity } from '../../libs/auth';
import { ExplorationSearchType } from '../../pages/exploration/constants';
import { formatNationalIdentifier } from '../formatters/NationalIdentifierFormatter';

const LinksWidget = ({ children }: LinksExplorationSearchWidgetterProps) => {
    const { pathname, search } = useLocation();
    const currentPath = `${pathname}${search}`;
    const { user } = useSecurity();
    return (
        <Grid alignPlacement="center" justifyPlacement="center" direction="column">
            {
                children.map((child, index) => {
                    const {
                        content, action, link, permission, searchType,
                    } = child;
                    const transformedContent = searchType === 'person' ? formatNationalIdentifier(user, content) : content;
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <GridItem key={index}>
                            {
                                link
                                    // we are already searching for the content
                                    // no need to display a link
                                    && currentPath !== link ? (
                                        <Secure
                                            fallback={<>{transformedContent}</>}
                                            permissions={permission}
                                        >
                                            <BillableLinkAction
                                                paidAccess={false}
                                                href={link}
                                                action={action}
                                            >
                                                {transformedContent}
                                            </BillableLinkAction>
                                        </Secure>
                                    ) : content
                            }
                        </GridItem>
                    );
                })
            }
        </Grid>
    );
};


export interface LinksExplorationSearchWidgetterProps {
    children: {
        content: string | undefined;
        link: string | undefined;
        searchType: ExplorationSearchType;
        action: keyof typeof BILLABLE_ACTIONS;
        permission?: TokenPredicate;
    }[];
}
export const LinksExplorationSearchWidgetter: Widgetter<LinksExplorationSearchWidgetterProps> = {
    // eslint-disable-next-line react/jsx-props-no-spreading
    transform: (props) => <LinksWidget {...props} />,
};
