/* eslint-disable @typescript-eslint/no-explicit-any */
import { translate } from '@gears/translations';
import moment, { Moment } from 'moment-timezone';
import { TimeEntry } from '@shift/design-system';
import { AnyType, FieldTargets, ArrayFieldTargets } from 'src/types';
import merge from 'deepmerge';
import { set } from 'object-path';
import {
    DateFormat, DateOffsetFormat, otherEnum, DefaultTimezone, unknownEnum,
} from 'src/constants';
import { GraphQLError } from 'graphql';

export { translate };

export const formTranslate = (text: string) => translate(`accident-report-form:${text}`);

export const commonTranslate = (text: string) => translate(`common:${text}`);

export const onlyPreventDefault = (e: React.FormEvent) => e.preventDefault();

export const mergeDateTime = (date: Moment | string | null, time?: TimeEntry, timezone: string = DefaultTimezone): Moment | undefined => {
    if (!date) {
        return undefined;
    }

    const dateTime = typeof date === 'string' ? moment.tz(date, DateFormat, timezone) : date;
    if (time) {
        dateTime
            .set('hour', time?.hour ?? 0)
            .set('minute', time?.minute ?? 0);
    }
    return dateTime;
};

export const formatOffsetDateTime = (
    date: Moment | string | null,
    time?: TimeEntry,
    format: string = DateOffsetFormat,
): string | undefined => mergeDateTime(date, time)?.format(format);

export const FormatEnum = (enumName: string, value: string | undefined): string => `${enumName}.${value}`;

export const undefinedFunc = () => undefined;

export const isNoneEnum = (value: string | number) => value === otherEnum || value === unknownEnum || value === '0' || value === 0;

export const isOtherPersonRelation = (value: string | number) => value === 'PersonRelation.Other' || value === 'PersonRelation.18';

// a small wrapper for set function that returns the object's value
export const setValue = (object: AnyType, path: string, value: any): AnyType => {
    set(object, path, value);
    return object;
};

// This method expects the targets and data objects to have the same set of keys
// it is used by field transforms that define both objects.
export const setDataOnTarget = (target: string | FieldTargets | ArrayFieldTargets | undefined, data: AnyType): AnyType => {
    if (!target) {
        throw new Error('target can\'t be undefined');
    }

    if (typeof target === 'string') {
        return setValue({}, target as string, data);
    }

    const result: AnyType = {};
    const arraytargets = target as ArrayFieldTargets;
    if (arraytargets?.item) {
        set(result, arraytargets.name, data.map((d: any) => {
            const item: AnyType = {};
            Object.entries(arraytargets.item).forEach(([key, value]) => {
                if (d[key] !== undefined) {
                    set(item, value, d[key]);
                }
            });
            return item;
        }));
    }

    Object.entries(target).forEach(([key, value]) => {
        if (data[key] !== undefined) {
            set(result, value, data[key]);
        }
    });
    return result;
};

export const combineMerge = (target: any, source: any, options: any) => {
    const destination = target.slice();
    source.forEach((item: any, index: number) => {
        if (destination[index] === undefined) {
            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
        } else if (options.isMergeableObject(item)) {
            destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
            destination.push(item);
        }
    });
    return destination;
};

export const getYearsFromAccident = (accidentDate?: Date, suffix = '') => (_day?: number, _month?: number, _year?: number, date?: Date) => {
    if (!accidentDate || !date) return '';

    const duration = moment.duration(moment(accidentDate).diff(moment(date)));
    return `At time of accident: ${duration.years() > 0 ? `${duration.years()} year${duration.years() > 1 ? 's' : ''}` : '<1 year'}${suffix}`;
};

const monthNames = [undefined, 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];
// eslint-disable-next-line max-len
export const formatToReadableDate = (day?: number, month?: number, year?: number, _date?: Date) => `${day ?? 'DD'} ${month ? monthNames[month] : 'MONTH'} ${year ?? 'YYYY'}`;

export const formatGQLErrors = (errors: readonly GraphQLError[]): string[] => errors.map((e) => e.message);
