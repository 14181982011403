import React from 'react';
import { PhoneInput } from '@shift/design-system';
import {
    FormField, ArrayItemProps, EnumSelectInput,
} from 'src/components/form';
import { sgCountryCode } from 'src/constants';
import { SingleFieldInformation } from 'src/types';

export interface PhoneItemFields {
    phone: SingleFieldInformation;
    type: SingleFieldInformation;
}

export const GetPhoneItem = ({ phone, type }: PhoneItemFields) => ({ setItemPath }: ArrayItemProps) => (
    <div className="row">
        <div className="col-xs-6 form-group">
            <FormField
                fieldInfo={setItemPath(type)}
                component={EnumSelectInput}
                enumName="PhoneNumberType"
            />
        </div>
        <div className="col-xs-6 form-group">
            <FormField
                fieldInfo={setItemPath(phone)}
                component={PhoneInput}
                defaultValue={sgCountryCode}
            />
        </div>
    </div>
);
