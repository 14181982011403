import moment from 'moment-timezone';
import { DefaultTimezone } from 'src/constants';

// Define date locale configurations
const englishMomentConfig = {
    parentLocale: 'en',
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY LT',
        LLLL: 'dddd D MMMM YYYY LT',
    },
};

// Set up locales for the our different display formats
moment.updateLocale('en', englishMomentConfig);
moment.defineLocale('en-SG', { parentLocale: 'en' });

// used when sending date to the backend (keep the timezone)
moment.fn.toJSON = moment.fn.format;

export const setDefaultTimeZone = () => {
    moment.tz.setDefault(DefaultTimezone);
};
