/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    OperationVariables, DocumentNode,
} from '@apollo/client';

import {
    MutationHookOptions, useQuery as useQueryBase,
    useMutation as useMutationBase, QueryHookOptions,
} from '@apollo/react-hooks';

export const GqlMutationApi = {
    submitAccidentReport: 'SubmitAccidentReport',
    insertComment: 'InsertComment',
    updateClaimStatus: 'UpdateClaimStatus',
    executeWorkflowStep: 'ExecuteWorkflowStep',
    assignOfficer: 'AssignRecoveryOfficer',
    uploadWorkflowDocument: 'UploadWorkflowDocument',
    sendTpReport: 'SendTpReportRequest',
    recoveryAccidentReports: 'RecoveryAccidentReports',
};

export const GqlQueryName = {
    textInformation: 'TextInformation',
    titleContent: 'TitleContent',
    fetchRecoveryInitData: 'FetchRecoveryInitData',
    accidentReportReferenceCode: 'AccidentReportReferenceCode',
    historyExploration: 'HistoryExploration',
    explorationNric: 'ExplorationNric',
    folderDetail: 'FolderDetails',
    accidentLocation: 'AccidentLocation',
    recoveryAccidentReports: 'RecoveryAccidentReports',
    recoveryDetails: 'RecoveryDetails',
    enums: 'Enums',
    backendList: 'backendList',
    tableFilter: 'TableFilter',
    keyValueCard: 'KeyValueCard',
    locationCard: 'LocationCard',
    commentsList: 'CommentsList',
    keyValueList: 'KeyValueList',
    searchResultKeyValuesCard: 'SearchResultKeyValuesCard',
    getAccidentReport: 'GetAccidentReport',
    getSubmittedAccidentReport: 'GetSubmittedAccidentReport',
    getBolaCases: 'GetBolaCases',
    accidentReportAttachments: 'AccidentReportAttachments',
    getRecoveryDrmAdjudicationLosingPartyItemPrices: 'GetRecoveryDrmAdjudicationLosingPartyItemPrices',
    getBillableItem: 'GetBillableItem',
};

// TODO remove this
export const useQuery = <TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options: QueryHookOptions<TData, TVariables> = {},
) => useQueryBase(query, options);

// TODO remove this
export const useMutation = <TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>,
) => useMutationBase(mutation, options);
