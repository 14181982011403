/* eslint-disable max-len */
import React, { PropsWithChildren, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import styled from 'styled-components';

import {
    Navbar, NavbarTab as BaseNavbarTab, withCustomStyles, StyledText, withCustomVariant,
} from '@shift/design-system';
import { UserCircleSvg } from '@shift/icons';

import { useSecurity, Secure } from 'src/libs/auth';
import { SecureProps } from 'src/libs/auth/Secure';
import { translate } from 'src/utils/forms';

import { RouterNavbarTab } from '../../components/router-navbar-tab';
import {
    accidentReportList, recoveries, exploration,
    settings, ncd, arcList, insurerEnquiry, folderList, thirdPartyReports, reporting,
} from '../../config/routes';
import logo from './gears_logo.svg';
import { UserTooltip } from './UserTooltip';
import { ExplorationRoutes } from '../exploration/constants';

const NavbarTab = withCustomStyles(BaseNavbarTab);

const TabStyledText: React.FC = ({ children }) => <StyledText weight="bold" size="large">{children}</StyledText>;

const HeaderTab: React.FC<{ path: string }> = ({ path, children }) => (
    <RouterNavbarTab path={path}>
        <TabStyledText>
            {children}
        </TabStyledText>
    </RouterNavbarTab>
);

const SecureHeaderTab = ({ permissions: p, ...headerProps }: SecureProps & PropsWithChildren<{ path: string }>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Secure permissions={p}><HeaderTab {...headerProps} /></Secure>
);

const LoginIcon = styled.div`
    font-size: 2em;
`;

export type HeaderProps = {
    hasAccreditationsIfArc: boolean;
}
export const Header = ({ hasAccreditationsIfArc }: HeaderProps) => {
    const isExplorationRoute = useRouteMatch(exploration.path);
    return (
        <>
            {/*
             Disable StickyContainer since we have still some issues
             before the go live
         <StickyContainer>
         */}
            <Menu id="header" hasAccreditationsIfArc={hasAccreditationsIfArc} />
            {
                isExplorationRoute && (
                    <ExplorationSubMenu />
                )
            }
        </>
        // </StickyContainer>
    );
};

const Menu = ({ id, hasAccreditationsIfArc }: { id?: string} & HeaderProps) => {
    const loginIconRef = useRef(null);
    const [isLoginOpen, toggleLoginTooltip] = useState(false);
    const { user } = useSecurity();
    const history = useHistory();

    return (
        <Navbar id={id}>
            <NavbarTab onClick={() => history.push('/')}>
                <img style={{ padding: '0 0.5rem', verticalAlign: 'baseline' }} src={logo} alt="logo" />
            </NavbarTab>
            {user && (
                <>
                    <SecureHeaderTab permissions={(u) => accidentReportList.permissions!(u) && hasAccreditationsIfArc} path={accidentReportList.path}>
                        {translate('pages:accident-reports')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={recoveries.permissions} path={recoveries.path}>
                        {translate('pages:recoveries')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={exploration.permissions} path={exploration.path}>
                        {translate('pages:exploration')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={ncd.permissions} path={ncd.path}>
                        {translate('pages:ncd')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={insurerEnquiry.permissions} path={insurerEnquiry.path}>
                        {translate('pages:insurer-enquiry')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={thirdPartyReports.permissions} path={thirdPartyReports.path}>
                        {translate('pages:third-party-reports')}
                    </SecureHeaderTab>
                    <SecureHeaderTab permissions={arcList.permissions} path={arcList.path}>
                        {translate('pages:arcs')}
                    </SecureHeaderTab>
                    <SecureHeaderTab path={folderList.path}>
                        {translate('pages:files')}
                    </SecureHeaderTab>
                    {process.env.NODE_ENV === 'development' && (
                        <SecureHeaderTab permissions={settings.permissions} path={settings.path}>
                            {translate('pages:settings')}
                        </SecureHeaderTab>
                    )}
                    <SecureHeaderTab permissions={reporting.permissions} path={reporting.path}>
                        {translate('pages:reporting')}
                    </SecureHeaderTab>
                    <NavbarTab
                        style={{ float: 'right', padding: '0 2rem', height: '65px' }}
                        onClick={() => toggleLoginTooltip(!isLoginOpen)}
                    >
                        <LoginIcon

                            ref={loginIconRef}
                        >
                            <UserCircleSvg withDefault={false} />
                        </LoginIcon>
                        {isLoginOpen && <UserTooltip onClickOutside={() => toggleLoginTooltip(false)} anchorRef={loginIconRef} />}
                    </NavbarTab>
                </>
            )}
        </Navbar>
    );
};

const SubNavbar = withCustomVariant(Navbar, 'navbar', 'subheader');

const ExplorationSubMenu = () => {
    const history = useHistory();
    return (
        <SubNavbar>
            <NavbarTab onClick={() => history.push(ExplorationRoutes.person)}>{translate('exploration:person-header')}</NavbarTab>
            <NavbarTab onClick={() => history.push(ExplorationRoutes.vehicle)}>{translate('exploration:vehicle-header')}</NavbarTab>
        </SubNavbar>
    );
};
