import React, {
    useState, useEffect, useMemo,
} from 'react';

import {
    FilterableHeader,
    CheckboxOptions,
    SelectInputOption,
    SelectSearchFilter,
    FilterableHeaderSide,
} from '@shift/design-system';


import {
    useFieldFilter,
    Operation,
    Field,
    Parameter,
    ParameterType,
    useFieldSort,
    unmapOrder,
    mapOrder,
} from '../../../libs/filters';

import { translate } from '../../../utils/forms';

const filterToOptions = (options: CheckboxOptions | undefined, filterParam: Operation | null): string[] | undefined => (options
    ? Object.keys(options)
        .filter((k) => filterParam?.parameters.map((p) => p.value).includes(k))
    : undefined);

const optionsToFilter = (operation: Operation, optionsParam: CheckboxOptions, enableFilters: string[], parameterType: ParameterType) => {
    const mappedFilter = Object.entries<{ labelName: string }>(optionsParam)
        .filter(([k]) => enableFilters.includes(k))
        .map<Parameter>(([k, v]) => (
            {
                name: v.labelName,
                type: parameterType,
                value: k,
            }));
    return mappedFilter
        && Array.isArray(mappedFilter)
        && mappedFilter.length > 0 ? {
            ...operation,
            parameters: mappedFilter,
        } : null;
};

export interface SelectFilterProps {
    options?: SelectInputOption[];
    field: Field;
    operation: Operation;
    children: React.ReactNode;
    parameterType: ParameterType;
    side?: FilterableHeaderSide;
    sortable?: boolean;
}

export function SelectFilter({
    children, field, operation, options, parameterType, side, sortable = false,
}: SelectFilterProps) {
    const [filter, setFilter] = useFieldFilter(field);
    const [sort, setSort] = useFieldSort(field);

    const checkboxOptions: CheckboxOptions = useMemo(
        () => (options ? options.reduce((p, c) => ({ ...p, [c.value]: { labelName: c.label } }), {}) : {}), [options],
    );

    const [pendingFilter, setPendingFilter] = useState<string[]>(Object.keys(options || {}));

    useEffect(() => {
        if (checkboxOptions) {
            const newValue = filterToOptions(checkboxOptions, filter);
            setPendingFilter(newValue || []);
        }
    }, [filter, checkboxOptions]);

    return (
        <FilterableHeader
            activeFilter={filter ? Array.isArray(filter?.parameters) && Boolean(filter?.parameters?.length) && filter?.parameters.length > 0 : false}
            side={side}
            filter={pendingFilter}
            checkboxes={{
                options: checkboxOptions,
            }}
            onFilterChange={(o: string[]) => setPendingFilter(o)}
            onSubmit={() => setFilter(optionsToFilter(operation, checkboxOptions, pendingFilter, parameterType))}
            onClear={() => { setFilter(null); setPendingFilter([]); }}
            FilterComponent={SelectSearchFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            selectAllText={translate('common:all')}
            deselectAllText={translate('common:none')}
            sortAscText={translate('common:sort-number-asc')}
            sortDescText={translate('common:sort-number-desc')}
            order={mapOrder(sort)}
            onOrderChange={(o) => setSort(unmapOrder(o))}
            sortable={sortable}
        >
            {children}
        </FilterableHeader>
    );
}
