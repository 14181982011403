import React, { createContext } from 'react';

const { Provider, Consumer } = createContext(false);

export interface ChangeTrackerProviderProps {
    enabled: boolean;
    children: React.ReactNode;
}

export const ChangeTrackerProvider = ({ enabled, children }: ChangeTrackerProviderProps) => (
    <Provider value={enabled}>
        {children}
    </Provider>
);

export interface ChangeTrackerProps {
    hasChanged: boolean;
}

export const ChangeTracker = ({ hasChanged }: ChangeTrackerProps) => (
    <Consumer>
        {(value) => value && hasChanged && (<div className="has-changed">Changed</div>)}
    </Consumer>
);
