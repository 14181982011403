import { get } from 'object-path';
import { getFileFormatter } from 'src/utils/forms/formatters';
import { isValidFile, isRequiredIfCondition } from 'src/utils/forms';
import { AnyType } from 'src/types';

export const attachmentsSection = {
    name: 'attachments',
    title: 'attachments',
    fields: {
        anyAccidentPhoto: {
            path: 'attachments.anyAccidentPhoto',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyAccidentPhoto',
            label: 'any-accident-photo',
            isRequired: true,
        },
        anyAccidentVideo: {
            path: 'attachments.anyAccidentVideo',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyAccidentVideo',
            label: 'any-accident-video',
            isRequired: true,
        },
        sketchPlan: {
            path: 'attachments.sketchPlan',
            target: {
                name: 'attachments.sketchPlan',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'sketch-plan',
            formatter: getFileFormatter(true),
            validate: isValidFile,
            isRequired: true,
        },
        accidentOrVehiclePhotos: {
            path: 'attachments.accidentOrVehiclePhotos',
            target: {
                name: 'attachments.accidentOrVehiclePhotos',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'accident-vehicle-photos',
            formatter: getFileFormatter(true),
            validate: isValidFile,
            isRequired: true,
            requiredFunction: isRequiredIfCondition((formValues: AnyType) => get(formValues, 'attachments.anyAccidentPhoto')),
        },
        privateHirePhoto: {
            path: 'attachments.privateHirePhoto',
            target: {
                id: 'attachments.privateHirePhoto.id',
                name: 'attachments.privateHirePhoto.documentName',
                size: 'attachments.privateHirePhoto.specializations.document_Document_DestinationDiskStorageInformation.fileSize',
            },
            label: 'private-hire-button',
            formatter: getFileFormatter(),
            validate: isValidFile,
            isRequired: true,
        },
        addendumForm: {
            path: 'attachments.addendumForm',
            target: {
                id: 'attachments.addendumForm.id',
                name: 'attachments.addendumForm.documentName',
                size: 'attachments.addendumForm.specializations.document_Document_DestinationDiskStorageInformation.fileSize',
            },
            label: 'addendum-form',
            validate: isValidFile,
            formatter: getFileFormatter(),
            isRequired: true,
        },
        canUploadVideo: {
            path: 'attachments.isVideoUploaded',
            label: 'can-upload-video',
            isRequired: true,
        },
        noVideoUploadReason: {
            path: 'attachments.reasonForNotUploadingAvailableVideo',
            label: 'no-upload-reason',
            isRequired: true,
        },
        accidentVideos: {
            path: 'attachments.accidentVideos',
            target: {
                name: 'attachments.accidentVideos',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'accident-video',
            formatter: getFileFormatter(true),
            validate: isValidFile,
            isRequired: true,
        },
        personalDocuments: {
            path: 'attachments.personalDocuments',
            target: {
                name: 'attachments.personalDocuments',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'personal-documents',
            formatter: getFileFormatter(true),
            validate: isValidFile,
        },
        otherDocuments: {
            path: 'attachments.otherDocuments',
            target: {
                name: 'attachments.otherDocuments',
                item: {
                    id: 'id',
                    name: 'documentName',
                    size: 'specializations.document_Document_DestinationDiskStorageInformation.fileSize',
                },
            },
            label: 'other-documents',
            formatter: getFileFormatter(true),
            validate: isValidFile,
        },
    },
};
