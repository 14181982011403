import { DownloadType } from 'src/libs/fetch';
import { FileFormats } from '@shift/design-system';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const download = async (dl: DownloadType, url: string, setLoading: (b: boolean) => void, filename?: string) => {
    try {
        setLoading(true);
        await dl(url, filename);
    } finally {
        setLoading(false);
    }
};

export const loadPreview = async (
    src: string,
    imgPreview: (url: string, fileFormat?: FileFormats | undefined) => Promise<string>,
    setData: any,
    setLoading: any,
    fileFormat?: FileFormats,
) => {
    try {
        setLoading(true);
        const result = await imgPreview(src, fileFormat);
        setData(result);
    } catch {
        setData('');
    } finally {
        setLoading(false);
    }
};
