/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { SingleFieldInformation, ArrayFieldInformation } from 'src/types';

export const ResetFormInput = ({ input }: FieldRenderProps<string, HTMLElement>) => {
    input.onChange(undefined);
    return null;
};

export interface ConditionalFormFieldProps {
    sourceField: SingleFieldInformation | ArrayFieldInformation;
    condition?: (sourceValue: any) => any;
    targetFields: Array<SingleFieldInformation | ArrayFieldInformation>;
    children: React.ReactNode;
}

export const ConditionalFormField = ({
    sourceField,
    condition = (value: unknown) => !!value,
    targetFields,
    children,
}: ConditionalFormFieldProps) => (
    <Field name={sourceField.path} subscription={{ value: true }}>
        {
            ({ input }: FieldRenderProps<string, HTMLElement>) => {
                if (condition(input.value)) {
                    return children;
                }
                return targetFields.map((field) => (
                    <Field name={field.path} key={field.path} render={ResetFormInput} />
                ));
            }
        }
    </Field>
);
