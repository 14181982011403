import React from 'react';

import { ParameterType } from 'src/libs/filters';
import { useEnumOptions } from '../../../utils/hooks';

import { SelectFilter, SelectFilterProps } from './SelectFilter';

export interface EnumFilterProps extends Omit<SelectFilterProps, 'options' | 'parameterType'>{
    enumName: string;
    side?: SelectFilterProps['side'];
}

export const EnumFilter = ({
    enumName, operation, field, children, side,
}: EnumFilterProps) => {
    const { options: enums } = useEnumOptions(enumName);

    return (
        <SelectFilter
            side={side}
            options={enums}
            operation={operation}
            field={field}
            parameterType={ParameterType.Enum}
        >
            {children}
        </SelectFilter>
    );
};
