/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { DateFormat } from 'src/constants';
import { DatePicker, DatePickerProps, enGB } from '@shift/design-system';

type DateInputProps = {
    name?: string;
    value: string;
    onChange: (date: string) => void;
    error?: boolean;
    openUp: boolean;
    minDate?: Moment;
    maxDate?: Moment;
    disabled?: boolean;
} & Pick<DatePickerProps, 'position' | 'inline' | 'placeholder' | 'onBlur' | 'onFocus'>

export const DateInput = ({
    onChange, value, error,
    minDate, maxDate, name,
    onBlur, onFocus, openUp,
    position, inline, disabled,
    placeholder,
}: DateInputProps) => {
    const onDateChange = (date?: Date) => {
        const val = date ? moment(date).format(DateFormat) : '';
        onChange(val);
    };

    const date = value ? moment.tz(value, DateFormat, moment.tz.guess(true)).toDate() : undefined;
    return (
        <DatePicker
            name={name}
            locale={enGB}
            position={position}
            inline={inline}
            error={error}
            placeholder={placeholder}
            min={minDate?.toDate()}
            max={maxDate?.toDate()}
            value={date}
            onChange={onDateChange}
            onBlur={onBlur}
            isOpen={openUp}
            onFocus={onFocus}
            disabled={disabled}
        />
    );
};
