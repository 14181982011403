import React from 'react';
import { NavbarTab } from '@shift/design-system';
import { Link, useRouteMatch } from 'react-router-dom';

interface RouterNavbarTabProps {
    path: string;
}

export const RouterNavbarTab: React.FC<RouterNavbarTabProps> = ({ path, children }) => {
    const match = useRouteMatch({
        path,
        exact: false,
    });

    return (
        <Link to={path}>
            <NavbarTab isActive={!!match}>
                {children}
            </NavbarTab>
        </Link>
    );
};
