import { Formatter } from '@shift/transform';
import { translate } from '../../utils/forms';

type VehicleNumberSelectFormatterOptions = {
    getter?(specialization: any): string;
    prefix: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InnerVehicleNumberSelectFormatter = (
    option: VehicleNumberSelectFormatterOptions,
): Formatter<any[], { children: string[] }> => {
    const getter = option.getter ?? ((spec) => spec?.mapping_Object_Motor_VehicleRegistration[0]?.number);
    return ({
        transform: ([data]) => ({
            children: data.map((datum, index) => {
                const vrn = getter(datum);
                return `${translate(option.prefix)} ${index + 1} ${vrn ? `- ${vrn}` : ''}`;
            }),
        }),
    });
};

export const VehicleNumberSelectFormatter = (option: VehicleNumberSelectFormatterOptions) => InnerVehicleNumberSelectFormatter(option);
