/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { DeepPartial, InputForm } from '@shift/design-system';
import { ContextTranslate as CT } from 'src/utils/translation';
import { isRequired } from 'src/utils/forms';
import deepEqual from 'deep-equal';
import { ChangeTracker } from './change-tracker';
import { SingleFieldInformation } from '../../types/formTypes';

const convertComponentToRender = (Component: any, render: any, children: any) => {
    if (Component) {
        return (renderProps: any) => {
            const { input, ...others } = renderProps;
            return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Component {...{ ...input, ...others }}>{children}</Component>
            );
        };
    }
    return render || children;
};

export type FieldInfo = SingleFieldInformation

export type FormFieldProps<T> = {
    component?: (props: T) => JSX.Element;
    fieldInfo: FieldInfo;
    displayLabel?: boolean;
    displayErrorText?: boolean;
    className?: string;
    children?: React.ReactNode;
    render?: any;
    label?: string;
    hint?: string;
    tooltip?: string;
    inputPosition?: 'beside' | 'stack';
    defaultValue?: any;
}
    & DeepPartial<T>


export const FormField = <T extends any>({
    component,
    fieldInfo,
    displayLabel = true,
    displayErrorText = true,
    children,
    render,
    label,
    hint,
    tooltip,
    inputPosition = 'stack',
    ...otherProps
}: FormFieldProps<T>) => {
    const newRender = convertComponentToRender(component, render, children);
    const validate = useMemo(() => {
        const requiredFunc = fieldInfo?.requiredFunction ?? isRequired;
        return (value: any, formValues: any) => (fieldInfo?.isRequired && requiredFunc(value, formValues))
            || (fieldInfo.validate && fieldInfo.validate(value, formValues));
    }, [fieldInfo]);

    return (
        <Field
            name={fieldInfo.path}
            isEqual={deepEqual}
            render={(renderProps) => newRender && (
                <>
                    <InputForm
                        position={inputPosition}
                        hint={hint}
                        tooltip={tooltip}
                        label={
                            displayLabel
                                && (label || fieldInfo?.label)
                                ? (
                                    <span
                                        className={classNames({
                                            'mandatory-field':
                                                fieldInfo?.isRequired,
                                        })}
                                    >
                                        <CT k={label ?? fieldInfo?.label} />
                                    </span>
                                )
                                : undefined
                        }
                        error={
                            renderProps.meta.visited
                            && !renderProps.meta.active
                            && (displayErrorText
                                ? renderProps.meta.error
                                : !!renderProps.meta.error)
                        }
                    >
                        {newRender(renderProps)}
                    </InputForm>
                    <ChangeTracker
                        hasChanged={
                            (
                                renderProps?.meta?.dirty
                                && renderProps?.meta?.visited
                            )
                            || false
                        }
                    />
                </>
            )}
            validate={validate}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
        />
    );
};
