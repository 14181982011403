import { SelectInput, SelectInputOption, SubmitButton } from '@shift/design-system';
import React, { useState } from 'react';
import { IVehicleInfo } from 'src/types/myInfo';
import styled from 'styled-components';

interface VehicleInformationProps {
    ownedVehicles: IVehicleInfo[] | undefined;
    setVehicleInfo: (vehicleInfo: IVehicleInfo) => void;
    onSubmit: () => void;
    isSubmitting: boolean;
}

export const VehicleInformation: React.FC<VehicleInformationProps> = ({
    ownedVehicles, setVehicleInfo, onSubmit, isSubmitting,
}) => {
    const isSelectInputOption = (value: any): value is SelectInputOption => (!Array.isArray(value));

    const otherOption = { label: 'Other', value: 'other' };
    const selectionValues: SelectInputOption[] = (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ownedVehicles?.filter((x) => !!x.vehicleno).map<SelectInputOption>((x) => ({ label: x.vehicleno!, value: x.vehicleno! }))
        ?? []
    ).concat([otherOption]);

    const [vrnDropdownValue, setVrnDropdownValue] = useState<SelectInputOption | undefined>();

    const onVrnSelection = (e: SelectInputOption[]) => {
        if (isSelectInputOption(e)) {
            setVrnDropdownValue(e);
            setVehicleInfo(ownedVehicles?.find((x) => x.vehicleno === e.value) ?? {});
        }
    };

    const title = 'Vehicle Information';
    const subtitle = 'Select the vehicle registration number of the vehicle that was involved in the accident.';
    const vrn = 'Vehicle registration number';
    const submitText = 'Confirm and continue';


    return (
        <Container>
            <Content>
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
                <Label>{vrn}</Label>
                <SelectInput options={selectionValues} onChange={onVrnSelection} value={vrnDropdownValue ? [vrnDropdownValue] : undefined} />
                <StyledSubmitButton onClick={onSubmit} submitting={isSubmitting} disabled={!vrnDropdownValue || isSubmitting}>
                    {submitText}
                </StyledSubmitButton>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
`;

const Content = styled.div`
    width: 100%;
    display: flex; /* Use flexbox layout */
    justify-content: flex-start; /* Center content horizontally */
    flex-direction: column;
    height: 100vh; /* Make the container take up the full viewport height */
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 24px;
    padding-top: 20px;
    width: 100%; // Add this line to make the title full width
`;

const SubTitle = styled.div`
    font-size: 16px;
    padding: 20px 0;
    width: 100%; // Add this line to make the subtitle full width
`;

const Label = styled.div`
    font-weight: 700;
`;

const StyledSubmitButton = styled(SubmitButton)`
    margin-top: 10px;
`;
