import React from 'react';

import { useSecurity } from 'src/libs/auth';
import { Claims } from 'src/libs/auth/Authenticator';
import {
    configHookFactory, configContextFactory, ConfigProvider, ConfigProviderProps,
} from './ConfigProvider';
import {
    RawConfiguration, ProcessedConfiguration, isConfig, ProcessedConfig,
    TableQueryConfig, KeyValueQueryConfig, SingleValueQueryConfig, MergedTableQueryConfig,
} from './QueryConfigTypes';
import { queryConfig } from './QueryConfig';

function fromEntries<K extends string, T>(entries: [K, T][]) {
    return entries.reduce(
        (previous, current) => ({ ...previous, [current[0]]: current[1] }), {},
    );
}

function processConfig<TRawConfig = RawConfiguration, TConfig = ProcessedConfiguration<TRawConfig>>(
    rawConfig: TRawConfig,
    user: Claims | null,
): TConfig {
    return rawConfig ? fromEntries(Object.entries(rawConfig)
        .map(([mainContext, mainConfig]) => [
            mainContext,
            fromEntries<
                string,
                TableQueryConfig | MergedTableQueryConfig | KeyValueQueryConfig | SingleValueQueryConfig | ProcessedConfig
            >(Object.entries(mainConfig).map(([subContext, subConfig]) => {
                if (isConfig(subConfig)) {
                    switch (subConfig.configType) {
                    case 'KeyValueCard':
                        return [subContext, queryConfig.cards.getLabelValueFields(subConfig, user)];
                    case 'Table':
                        return [subContext, queryConfig.table.getHeaderColumnFields(subConfig, user)];
                    case 'MergedTable':
                        return [subContext, queryConfig.table.getMergedTableFields(subConfig)];
                    case 'SelectKeyValueCard':
                        return [subContext, queryConfig.cards.getLabelValueFields(subConfig as any, user)];
                    case 'SingleValueCard':
                        return [subContext, queryConfig.cards.getSingleField(subConfig, user)];
                    case 'Processed':
                        return [subContext, subConfig];
                    default:
                        throw new Error(`Error while parsing config at ${mainContext}.${subContext}`);
                    }
                }
                throw new Error(`Error while parsing config at ${mainContext}.${subContext}`);
            }))])) as any // Cast as any as this is too much to handle types properly
        : undefined;
}

export function configProviderFactory<TRawConfig = RawConfiguration, TConfig = ProcessedConfiguration<TRawConfig>>(defaultConfig?: TRawConfig) {
    const context = configContextFactory<TConfig>(processConfig(defaultConfig, null));

    return {
        useConfig: configHookFactory(context),
        Provider: ({ children, configuration }: Omit<ConfigProviderProps<TRawConfig, TConfig>, 'configurationContext'>) => {
            const { user } = useSecurity();
            return (
                <ConfigProvider<TConfig>
                    configurationContext={context}
                    configuration={processConfig(configuration, user)}
                >
                    {children}
                </ConfigProvider>
            );
        },
        Consumer: context.Consumer,
    };
}
