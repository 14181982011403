import { useMemo, useContext, useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useTranslations, makeTranslationFunc } from '@gears/translations';
import { SelectInputOption, RadioOptions } from '@shift/design-system';
import { EnumsContainer, EnumItem } from 'src/types';
import { MessageModalContext } from 'src/MessageModal';
import { getEnums, GqlQueryName, useQuery } from 'src/graphql';
import { stringCompare } from '..';
import { translate } from '../forms';

const enumsTranslate = useTranslations('enums');

export const useEnums = () => {
    const {
        loading, data, error, refetch, networkStatus,
    } = useQuery(getEnums, { notifyOnNetworkStatusChange: true, displayName: GqlQueryName.enums });
    const { displayMessage } = useContext(MessageModalContext);

    useEffect(() => {
        if (!loading && !data) {
            displayMessage([`${translate('common:load-options-fail')}: ${error?.message}`]);
        }
    }, [loading, data, error, displayMessage]);

    return {
        enums: data?.enums as EnumsContainer | null,
        loading: loading || networkStatus === NetworkStatus.refetch,
        error,
        refetch: () => { refetch().catch(() => { /* silent error */ }); },
    };
};

const emptyList: never[] = [];

type UseEnumOptions = {
    exclude?: string[];
}

export const useEnum = (enumName: string, { exclude }: UseEnumOptions = { exclude: [] }) => {
    const {
        loading, enums, error, refetch,
    } = useEnums();
    const { displayMessage } = useContext(MessageModalContext);

    useEffect(() => {
        if (!enumName) {
            return;
        }
        if (!loading && enums && !enums[enumName]) {
            displayMessage([`${translate('common:load-option-fail')}: "${enumName}"`]);
        }
    }, [enums, enumName, loading, displayMessage]);

    return {
        selectedEnum: enumName && enums ? enums[enumName]?.filter((it) => !(exclude ?? []).includes(it.Value)) : emptyList,
        error,
        loading: enumName ? loading : false,
        refetch,
    };
};

interface EnumOptionsResult {
    options: SelectInputOption[];
    loading: boolean;
    error?: Error;
    refetch: () => void;
}

export const useEnumOptions = (
    enumName: string,
    hasNumberValue = false,
    sort = false,
    { exclude }: UseEnumOptions = { exclude: [] },
): EnumOptionsResult => {
    const {
        loading, selectedEnum, error, refetch,
    } = useEnum(enumName, { exclude });

    const opts = useMemo(() => {
        if (!loading && selectedEnum) {
            const options = selectedEnum.map((item) => ({
                value: `${item.Name}.${hasNumberValue ? item.ValueId : item.Value}`,
                label: enumsTranslate(`${item.Name}.${item.Value}`, { defaultValue: item.Value }),
            }));

            if (sort) {
                options.sort((a, b) => stringCompare(a.label, b.label));
            }
            return options;
        }
        return [];
    }, [selectedEnum, hasNumberValue, sort, loading]);

    return {
        options: opts,
        loading,
        error,
        refetch,
    };
};

export const useEnumRadioOptions = (enumName: string, hasNumberValue = false, customTranslateContext = ''): RadioOptions => {
    const { loading, selectedEnum } = useEnum(enumName);

    return useMemo(() => {
        if (!loading && selectedEnum) {
            const translateFunc = !customTranslateContext ? enumsTranslate : makeTranslationFunc(customTranslateContext);
            const options = selectedEnum.reduce((acc: RadioOptions, item: EnumItem) => {
                const value = `${item.Name}.${hasNumberValue ? item.ValueId : item.Value}`;
                acc[value] = {
                    labelName: translateFunc(`${item.Name}.${item.Value}`, { defaultValue: item.Value }),
                };
                return acc;
            }, {});


            return options;
        }
        return {};
    }, [selectedEnum, hasNumberValue, loading, customTranslateContext]);
};


export const useEnumItemById = (enumName?: string, valueId?: number) => {
    const { loading, enums } = useEnums();
    const { displayMessage } = useContext(MessageModalContext);

    return useMemo(() => {
        const defaultResult = {
            loading: false,
            selectedEnum: undefined,
            i18n: undefined,
        };
        if (!enumName) {
            return defaultResult;
        }
        if (!loading) {
            if (!enums || !enums[enumName]) {
                displayMessage([`${translate('common:load-option-fail')}: "${enumName}"`]);
                return defaultResult;
            }
            const selectedEnum = enums[enumName].find((r) => r.ValueId === valueId);
            const i18n = selectedEnum ? enumsTranslate(`${selectedEnum.Name}.${selectedEnum.Value}`) : undefined;
            return {
                loading,
                selectedEnum,
                i18n,
            };
        }

        return { ...defaultResult, loading };
    }, [enums, enumName, loading, displayMessage, valueId]);
};

export const useBola = (enumName?: string, valueId?: number) => {
    const { selectedEnum: bolaEnum, i18n: bolaI18n } = useEnumItemById(enumName, valueId);

    return {
        title: bolaEnum ? `BOLA ${enumsTranslate(`BolaScenarioNumber.${bolaEnum.Value}`)}` : '',
        description: bolaI18n,
    };
};
