import { SelectInputOption } from '@shift/design-system';
import { AnyType } from 'src/types';
import { formTranslate } from './utils';

const getOptions = (values: string[]): SelectInputOption[] => values.map((item) => ({ value: item, label: item }));
export const days = Array.from(Array(31).keys()).map((num) => (num + 1));
export const months = Array.from(Array(12).keys()).map((num) => (num + 1));
export const years = Array.from(Array(100).keys()).map((num) => (num + 1920));

// The values are defined in the context of the field `isOccupationOutdoor`
export const getOccupationTypes = (): SelectInputOption[] => [
    { value: 'false', label: formTranslate('indoor') },
    { value: 'true', label: formTranslate('outdoor') },
];

export const statesTimezones = [
    { state: 'Singapore', timezone: 'Asia/Singapore' },
    { state: 'Malaysia', timezone: 'Asia/Kuala_Lumpur' },
    { state: 'Thailand', timezone: 'Asia/Bangkok' },
];

export const countriesOrStates = getOptions(statesTimezones.map((item) => item.state));
export const languages = getOptions(['English', 'Mandarin', 'Malay', 'Tamil']).concat([{ value: 0, label: 'Others' }]);

export const getStateTimezone = (state: string) => statesTimezones.find((item) => item.state === state)?.timezone;

export const computeInjuredVehicleList = (tpVehicles: AnyType[]): SelectInputOption[] => {
    const vehicles = [
        { value: -1, label: 'Own Vehicle' },
    ];
    if (tpVehicles) {
        tpVehicles.map((thirdParty: AnyType, idx: number) => vehicles.push({
            value: thirdParty.vehiclePolicy.vehicle.id,
            label: `${formTranslate('injured-tp-vehicle')} ${idx + 1}`,
        }));
    }
    return vehicles;
};
