import React from 'react';
import { StyledText } from '@shift/design-system';
import { formSections } from 'src/config/form';
import { formTranslate } from 'src/utils/forms';
import { FormSection } from 'src/components/form';
import { Field } from 'react-final-form';

export const AdditionalCommentSection = () => {
    const {
        name, title, useDifferentSectionIcon, collapsible, fields,
    } = formSections.additionalCommentSection;

    return (
        <FormSection title={formTranslate(title)} name={name} useDifferentSectionIcon={useDifferentSectionIcon} collapsible={collapsible}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <StyledText weight="bold" textStyle="italic">
                        <Field
                            name={fields.additionalComment.path}
                            subscription={{ value: true }}
                            render={({ input: { value } }) => (value.trim().length === 0 ? formTranslate('no-additional-comment') : `"${value}"`)}
                        />
                    </StyledText>
                </div>
            </div>
        </FormSection>
    );
};
