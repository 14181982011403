/* eslint-disable max-len */
import React, {
    useEffect, useMemo, useContext, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ApolloConsumer } from '@apollo/react-hooks';
import { ApolloClient } from '@apollo/client';
import { useIdFromParams } from 'src/utils/hooks';
import { validateForm } from 'src/utils/forms';
import {
    insertAccidentReportMutation, transformForm,
    buildFormAccidentReportQuery, loadForm, useQuery, useMutation, GqlQueryName,
} from 'src/graphql';
import { AnyType, ValidationError } from 'src/types';
import { GridCenteredCircularLoader } from 'src/components/Loader/GridCenteredCircularLoader';
import { formSections } from 'src/config/form';
import { MessageModalContext } from 'src/MessageModal';
import { formTranslate, formatGQLErrors } from 'src/utils/forms/utils';
import { DateOffsetFormat, now } from 'src/constants';
import { AccidentReportForm } from '../components/AccidentReportForm';

const formatErrorMessage = (errors: ValidationError[]): string[] => ([
    formTranslate('invalid-fields'),
    ...errors.map((error) => (`\n\u2022 ${error.field}: ${error.error}`))]
);

export const AccidentReportFormPage = () => {
    const accidentReportId = useIdFromParams();
    const history = useHistory();
    const { displayMessage } = useContext(MessageModalContext);

    const [draft, setDraft] = useState(false);
    const [insertForm, { loading: submitting }] = useMutation(insertAccidentReportMutation);

    const onSubmit = (client: ApolloClient<unknown>, values: AnyType, errors: AnyType, isDraft: boolean) => {
        setDraft(isDraft);
        const validationErrors = validateForm(formSections, values, errors, isDraft);
        if (validationErrors.length) {
            displayMessage(formatErrorMessage(validationErrors));
            return;
        }

        insertForm({
            variables: {
                accidentReport: transformForm(formSections, values),
                saveAsDraft: isDraft,
            },
            fetchPolicy: 'no-cache',
        }).then((resp) => {
            if (resp.errors?.length) {
                displayMessage(formatGQLErrors(resp.errors));
            } else {
                client.clearStore().then(() => {
                    history.push(`/accident-report-submitted/${resp.data.insertAccidentReport.id}`);
                });
            }
        }).catch((error) => {
            displayMessage(formatGQLErrors(error.graphQLErrors));
        });
    };

    const accidentReportFormQuery = buildFormAccidentReportQuery();
    const { loading, error: loadError, data } = useQuery(
        accidentReportFormQuery,
        {
            variables: {
                id: accidentReportId,
            },
            skip: !accidentReportId,
            displayName: GqlQueryName.getAccidentReport,
        },
    );

    // eslint-disable-next-line @typescript-eslint/camelcase
    const entryReportInitialInformation = { generalInformation: { accidentInformation: { specializations: { mapping_Claim_Statement: [{ entryReportDateOffset: '' }] } } } };
    const accidentReport = useMemo(() => {
        if (data?.accidentReport) {
            return loadForm(data.accidentReport);
        }
        entryReportInitialInformation.generalInformation.accidentInformation.specializations
            .mapping_Claim_Statement[0].entryReportDateOffset = now.format(DateOffsetFormat);
        return entryReportInitialInformation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (loadError?.graphQLErrors?.length) {
            displayMessage(formatGQLErrors(loadError?.graphQLErrors.map((e) => e)));
        }
    }, [loadError, displayMessage]);

    if (loading) {
        return (
            <GridCenteredCircularLoader />
        );
    }
    return (
        <>
            <ApolloConsumer>
                {(client) => (
                    <AccidentReportForm
                        onSubmit={
                            (accidenReport: AnyType, formErrors: AnyType, isDraft: boolean) => onSubmit(
                                client as ApolloClient<unknown>,
                                accidenReport,
                                formErrors,
                                isDraft,
                            )
                        }
                        submitting={submitting}
                        initialValue={accidentReport}
                        isDraft={draft}
                    />
                )}
            </ApolloConsumer>
        </>
    );
};
