import { getFileFormatter, BackendListFormatter } from '../../../utils/forms/formatters';
import { isValidFile } from '../../../utils/forms/validations';

export const acceptOffer = {
    singleSection: {
        name: 'acceptOffer',
        title: 'accept-offer',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'nextAction.step.action',
            },
            commentType: {
                path: 'comment.commentType',
            },
            officer: {
                path: 'acceptOffer.officer',
                target: 'receivingParty.officerIdentity',
                label: 'officer-in-charge',
                formatter: BackendListFormatter,
            },
            claimNumber: {
                path: 'acceptOffer.referenceCode',
                target: 'receivingParty.claim.referenceCode',
                label: 'claim-number',
            },
            attachments: {
                path: 'acceptOffer.attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        id: 'document.id',
                        name: 'document.documentName',
                    },
                },
                label: 'attachments',
                formatter: getFileFormatter(true),
                validate: isValidFile,
            },
            comment: {
                path: 'acceptOffer.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
        },
    },
};
