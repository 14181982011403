import React from 'react';
import { translate } from '@gears/translations';
import { Page } from '../../Page';
import { AccidentReportSubmittedPage as BaseAccidentReportSubmittedPage } from './containers/AccidentReportSubmitted';

export const AccidentReportSubmittedPage = () => (
    <div className="arc-reporting-submitted-page">
        <Page title={translate('pages:accident-reports')}>
            <BaseAccidentReportSubmittedPage />
        </Page>
    </div>
);
