import React, { useMemo } from 'react';

import { SelectInputOption } from '@shift/design-system';

import { ParameterType } from 'src/libs/filters';
import { translate } from 'src/utils/forms';
import { SelectFilter, SelectFilterProps } from './SelectFilter';

export type LiabilityFilterProps = Omit<SelectFilterProps, 'options' | 'parameterType'> & {flipValues?: boolean};

export const LiabilityFilter = ({
    operation, field, children, side,
}: LiabilityFilterProps) => {
    const options: SelectInputOption[] = useMemo(() => [
        { label: '0%', value: '0' },
        { label: '50%', value: '50' },
        { label: '100%', value: '100' },
        { label: translate('common:NA'), value: '' },
    ], []);

    return (
        <SelectFilter
            side={side}
            options={options}
            operation={operation}
            field={field}
            parameterType={ParameterType.Decimal}
            sortable
        >
            {children}
        </SelectFilter>
    );
};
