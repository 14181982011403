export interface Filter {
    field: Field;
    operation: Operation;
}

export interface Field {
    /**
     * @deprecated For backend search
     */
    name?: string;
    id: ClaimFields | ExplorationHistorySearch | ProviderFields | DocumentFields | RecoveriesFields | TpReportFields;
    /**
     * Used for filter formatting
     * Default value: column's i18NId
     */
    i18NId?: string;
    /**
     * Used for filter formatting
     * Default value: filter's search operation
     */
    formatType?: SearchOperationFormat;
}

export interface Operation {
    type: SearchOperationType;
    name: string;
    parameters: Parameter[];
}

export interface Parameter {
    name: string;
    type: ParameterType;
    value: string;
}

// Think twice before adding a prop since it is used in config/widgetters
export interface FilterProps {
    children?: React.ReactNode;
}

export enum ParameterType {
    IntegerParameter = 'ParameterType.IntegerParameter',
    Decimal = 'ParameterType.DecimalParameter',
    String = 'ParameterType.StringParameter',
    NumberRange = 'ParameterType.NumberRangeParameter',
    Enum = 'ParameterType.EnumParameter',
    DateTimeUtc = 'ParameterType.DateTimeUtcParameter',
    DateTimeOffset = 'ParameterType.DateTimeOffsetParameter',
    Boolean = 'ParameterType.BooleanParameter'
}

export enum SearchOperationType {
    Equal = 'SearchOperationType.Equal',
    Between = 'SearchOperationType.Between',
    Match = 'SearchOperationType.Match',
    EnumIdEqual = 'SearchOperationType.EnumIdEqual',
    NotEqual = 'SearchOperationType.NotEqual',
}

/**
 * This are dummy search operations used for formatting only
 */
export enum SearchOperationFormatSpecificType {
    ConditionalBooleanEquality = 'SearchOperationType.ConditionalBooleanEquality',
}

export type SearchOperationFormat = SearchOperationType | SearchOperationFormatSpecificType

export enum ExplorationHistorySearch {
    HistoryByType = 'ExplorationHistorySearch.HistoryByType',
    HistoryByPolicy = 'ExplorationHistorySearch.HistoryByPolicy',
    HistoryByVrn = 'ExplorationHistorySearch.HistoryByVrn',
    HistoryByNric = 'ExplorationHistorySearch.HistoryByNric',
    HistoryByDate = 'ExplorationHistorySearch.HistoryByDate',
    HistoryByInsurer = 'ExplorationHistorySearch.HistoryByInsurer',
    HistoryByReference = 'ExplorationHistorySearch.HistoryByReference',
}

export enum RecoveriesFields {
    ReferenceCode = 'RecoveriesFields.ReferenceCode',
    Deadline = 'RecoveriesFields.Deadline',
    AccidentDate = 'RecoveriesFields.AccidentDate',
    Status = 'RecoveriesFields.Status',
    Action = 'RecoveriesFields.Action',
    CaseType = 'RecoveriesFields.CaseType',
    OfficerInCharge = 'RecoveriesFields.OfficerInCharge',
    OwnSide = 'RecoveriesFields.OwnSide',
    OwnClaimNumber = 'RecoveriesFields.OwnClaimNumber',
    OwnVehicleNumber = 'RecoveriesFields.OwnVehicleNumber',
    OtherPartyVehicleNumber = 'RecoveriesFields.OtherPartyVehicleNumber',
    OwnInsurer = 'RecoveriesFields.OwnInsurer',
    OtherPartyInsurer = 'RecoveriesFields.OtherPartyInsurer',
    DaysInDrm = 'RecoveriesFields.DaysInDrm',
    InitiationDate = 'RecoveriesFields.InitiationDate',
    OwnLiability = 'RecoveriesFields.OwnLiability',
    OtherLiability = 'RecoveriesFields.OtherLiability',
    UnwarrantedDRM = 'RecoveriesFields.UnwarrantedDrm'
}

export enum ClaimFields {
    ReferenceCode = 'ClaimFields.ReferenceCode',
    OwnInsurer = 'ClaimFields.OwnInsurer',
    ProviderId = 'ClaimFields.ProviderId',
    ARCName = 'ClaimFields.ARCName',
    AccidentDate = 'ClaimFields.AccidentDate',
    PolicyNo = 'ClaimFields.PolicyNo',
    OwnerName = 'ClaimFields.OwnerName',
    OwnerId = 'ClaimFields.OwnerId',
    VehicleNo = 'ClaimFields.VehicleNo',
    TypeOfAccident = 'ClaimFields.TypeOfAccident',
    DriverName = 'ClaimFields.DriverName',
    DriverId = 'ClaimFields.DriverId',
    Status = 'ClaimFields.Status',
    LateReport = 'ClaimFields.LateReport',
    MatchedReports = 'ClaimFields.MatchedReports',
    Type = 'ClaimFields.Type',
    SubmissionDate = 'ClaimFields.SubmissionDate',
    FirstSubmissionDate = 'ClaimFields.FirstSubmissionDate',
    VersionsCount = 'ClaimFields.VersionsCount',
}

export enum DocumentFields {
    Name = 'DocumentFields.Name',
    Category = 'DocumentFields.Category',
    Description = 'DocumentFields.Description',
}

export enum ProviderFields {
    ARCName = 'ProviderFields.ARCName',
    PhoneNumber = 'ProviderFields.PhoneNumber',
    Address = 'ProviderFields.Address',
    Insurer = 'ProviderFields.Insurer',
}

export enum TpReportFields {
    ReferenceCode = 'TpReportFields.ReferenceCode',
    Status = 'TpReportFields.Status',
    FpVehicleNumber = 'TpReportFields.FpVehicleNumber',
    TpVehicleNumber = 'TpReportFields.TpVehicleNumber',
    CreateDate = 'TpReportFields.CreateDate',
}

export type SearchTypes = 'ClaimFields' | 'ProviderFields' | 'DocumentFields' | 'RecoveriesFields' | 'TpReportFields'
