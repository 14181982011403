import React, { useEffect } from 'react';

import { Field, Operation, Filter } from './filters';
import { Order } from './sort';
import {
    FilterState, FilterTablesState, Tables, SortTablesState, SortState,
    PageTablesState, PageState, SavedFilterState, SavedFilterTableState,
} from './types';
import { defaultSortConfig } from './filtersConfig';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

const SAVED_FILTER_KEY = 'saved-filters';


const defaultSavedFilterState = (): SavedFilterState => ({
    namedFilters: [],
    defaultFilter: undefined,
});
const defaultFilterState: FilterState = [];
const defaultSortState: SortState = null;
const defaultPageState: PageState = 1;

export const defaultSavedFiltersState: SavedFilterTableState = {
    AccidentReports: defaultSavedFilterState(),
    Recoveries: defaultSavedFilterState(),
    GiaRecoveries: defaultSavedFilterState(),
    ExplorationHistories: defaultSavedFilterState(),
    Arcs: defaultSavedFilterState(),
    Folders: defaultSavedFilterState(),
    ThirdPartyReports: defaultSavedFilterState(),
};
const defaultFiltersState: FilterTablesState = {
    AccidentReports: defaultFilterState,
    Recoveries: defaultFilterState,
    GiaRecoveries: defaultFilterState,
    ExplorationHistories: defaultFilterState,
    Arcs: defaultFilterState,
    Folders: defaultFilterState,
    ThirdPartyReports: defaultFilterState,
};

const defaultSortsState: SortTablesState = {
    AccidentReports: defaultSortState,
    Recoveries: defaultSortState,
    GiaRecoveries: defaultSortState,
    ExplorationHistories: defaultSortState,
    Arcs: defaultSortState,
    Folders: defaultSortState,
    ThirdPartyReports: defaultSortState,
    ...defaultSortConfig,
};

const defaultPagesState: PageTablesState = {
    AccidentReports: defaultPageState,
    Recoveries: defaultPageState,
    GiaRecoveries: defaultPageState,
    ExplorationHistories: defaultPageState,
    Arcs: defaultPageState,
    Folders: defaultPageState,
    ThirdPartyReports: defaultPageState,
};

type StateCallback<K> = [K, (k: K) => void]

type FiltersContextState = StateCallback<FilterTablesState> | null;
type SortsContextState = StateCallback<SortTablesState> | null;
type PagessContextState = StateCallback<PageTablesState> | null;


const filtersContext = React.createContext<FiltersContextState>(null);
const sortsContext = React.createContext<SortsContextState>(null);
const pagesContext = React.createContext<PagessContextState>(null);

const tableContext = React.createContext<Tables>(null);

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
    const storage = useLocalStorage();

    const [filters, setFilters] = React.useState(defaultFiltersState);
    const [sorts, setSorts] = React.useState(defaultSortsState);
    const pages = React.useState(defaultPagesState);
    useEffect(() => {
        const savedFilter = storage.getItem<SavedFilterTableState>(SAVED_FILTER_KEY);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const savedFilters = getDefaultSavedFilters(savedFilter);
        // Load saved filters only once
        if (savedFilters?.filterTablesState && filters === defaultFiltersState) {
            setFilters(savedFilters.filterTablesState);
        }
        // Load saved sorting filters only once
        if (savedFilters?.sortTablesState && sorts === defaultSortsState) {
            setSorts(savedFilters.sortTablesState);
        }
    }, [storage, setFilters, filters, setSorts, sorts]);

    return (
        <filtersContext.Provider value={[filters, setFilters]}>
            <sortsContext.Provider value={[sorts, setSorts]}>
                <pagesContext.Provider value={pages}>
                    {children}
                </pagesContext.Provider>
            </sortsContext.Provider>
        </filtersContext.Provider>
    );
};

export const FilterTableProvider = ({ tableType, children }: { tableType: Tables; children: React.ReactNode }) => (
    <tableContext.Provider value={tableType}>{children}</tableContext.Provider>
);

/**
 * Hook that provides the current sort for a given field and
 * a setter function for the sort on that field in a table
 * To be used inside a filter component
 * @param field Field on which we will be sorting
 */
export const useFieldSort = (field: Field): [Order | null, (o: Order | null) => void] => {
    const ctx = React.useContext(sortsContext);
    const tctx = React.useContext(tableContext);

    if (!ctx) {
        throw new Error('Missing filters context');
    }

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }

    const [state, setState] = ctx;
    const sort = state[tctx];
    const cb = React.useCallback((o: Order | null) => setState({
        ...state,
        [tctx]: o ? { order: o, field } : defaultSortsState[tctx],
    }), [state, setState, tctx, field]);

    return [
        sort && (!sort.field.name || sort.field.name === field.name) && sort.field.id === field.id ? sort.order : null,
        cb,
    ];
};

/**
 * Hook that provides the current filters for a given field and
 * a setter function for the filters on that field
 * To be used inside a filter component
 * @param field Field on which the filters are to be set
 */
export const useFieldFilter = (field: Field): [Operation | null, (o: Operation | null) => void] => {
    const ctx = React.useContext(filtersContext);
    const tctx = React.useContext(tableContext);

    if (!ctx) {
        throw new Error('Missing filters context');
    }

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }

    const [state, setState] = ctx;
    const filters = state[tctx];
    const cb = React.useCallback((o: Operation | null) => {
        const newFilters = state[tctx].filter((f) => (!f.field.name || f.field.name !== field.name) && f.field.id !== field.id);
        setState({
            ...state,
            [tctx]: o ? [...newFilters, { field, operation: o }] : newFilters,
        });
    }, [state, setState, tctx, field]);

    return [
        filters.find((f) => (!f.field.name || f.field.name === field.name) && f.field.id === field.id)?.operation ?? null,
        cb,
    ];
};

export const useFilters = (): [
    FilterState,
    (filter: Filter) => void,
    () => void,
    (filters: Filter[]) => void,
] => {
    const ctx = React.useContext(filtersContext);
    const tctx = React.useContext(tableContext);

    if (!ctx) {
        throw new Error('Missing filters context');
    }

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }

    const [filtersState, setState] = ctx;
    const clearField = React.useCallback(
        (filter: Filter) => {
            setState({
                ...filtersState,
                [tctx]: filtersState[tctx].filter((f) => f !== filter),
            });
        },
        [filtersState, setState, tctx],
    );
    const setAllField = React.useCallback(
        (filters: Filter[]) => {
            setState({
                ...filtersState,
                [tctx]: filters,
            });
        },
        [filtersState, setState, tctx],
    );
    const clearAllField = React.useCallback(
        () => {
            setState({
                ...filtersState,
                [tctx]: [],
            });
        },
        [filtersState, setState, tctx],
    );

    return [
        filtersState[tctx],
        clearField,
        clearAllField,
        setAllField,
    ];
};

export const useSort = (): [
    SortState,
    () => void,
    (sort?: SortState | null | undefined) => void
] => {
    const ctx = React.useContext(sortsContext);
    const tctx = React.useContext(tableContext);

    if (!ctx) {
        throw new Error('Missing sorts context');
    }

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }

    const [state, setState] = ctx;

    const clearSort = React.useCallback(
        () => {
            setState({
                ...state,
                [tctx]: defaultSortsState[tctx],
            });
        }, [state, tctx, setState],
    );
    const setSort = React.useCallback(
        (sort?: SortState) => {
            setState({
                ...state,
                [tctx]: sort,
            });
        }, [state, tctx, setState],
    );

    return [
        state[tctx],
        clearSort,
        setSort,
    ];
};

export const usePagination = (): [
    PageState,
    (page: number) => void,
] => {
    const ctx = React.useContext(pagesContext);
    const tctx = React.useContext(tableContext);

    if (!ctx) {
        throw new Error('Missing pages context');
    }

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }

    const [state, setState] = ctx;

    const setPage = React.useCallback(
        (page: number) => {
            if (page <= 0) {
                throw new Error('Page number should be strictly positive');
            }
            setState({
                ...state,
                [tctx]: page,
            });
        }, [state, tctx, setState],
    );

    return [
        state[tctx],
        setPage,
    ];
};


type TablesState = {
    sortTablesState: SortTablesState;
    filterTablesState: FilterTablesState;
}
const getDefaultSavedFilters = (savedFilter?: SavedFilterTableState): TablesState | undefined => {
    if (!savedFilter) {
        return undefined;
    }
    return Object.keys(defaultFiltersState)
        .reduce<TablesState>((acc, key) => {
            const k = key as Tables;
            if (k) {
                const tableSavedFilter = savedFilter[k];
                acc.filterTablesState[k] = tableSavedFilter?.defaultFilter !== undefined
                    ? tableSavedFilter?.namedFilters[tableSavedFilter?.defaultFilter].filters
                    : defaultFiltersState[k];
                acc.sortTablesState[k] = tableSavedFilter?.defaultFilter !== undefined
                    ? tableSavedFilter.namedFilters[tableSavedFilter?.defaultFilter].sort as SortState
                    : defaultSortsState[k];
            }
            return acc;
        }, {
            filterTablesState: {},
            sortTablesState: {},
        } as TablesState);
};

export const useSavedFilterTableState = (): [
    // GetAll
    () => SavedFilterState,
    // setDefault
    (index?: number) => void,
    // Set
    (name: string, item: FilterState, sort?: SortState, makeDefault?: boolean) => void,
    // Remove
    (index: number) => void,
] => {
    const tctx = React.useContext(tableContext);

    if (!tctx) {
        throw new Error('Missing table context for filters');
    }
    const storage = useLocalStorage();
    const savedFilters = () => storage.getItem<SavedFilterTableState>(SAVED_FILTER_KEY) || { ...defaultSavedFiltersState };
    return [
        () => (savedFilters() ? savedFilters()[tctx] : { namedFilters: [] }),
        (index?: number) => {
            const currentFilters = savedFilters();
            const savedFilter = currentFilters[tctx];
            if (savedFilter) {
                const state: SavedFilterTableState = {
                    ...currentFilters,
                    [tctx]: {
                        defaultFilter: index,
                        namedFilters: savedFilter.namedFilters,
                    },
                };
                storage.setItem(SAVED_FILTER_KEY, state);
            }
        },
        (name: string, item: FilterState, sort?: SortState, makeDefault?: boolean) => {
            const currentFilters = savedFilters();
            const { namedFilters, defaultFilter } = { ...currentFilters[tctx] };
            const size = namedFilters?.push({
                name,
                filters: item,
                sort,
            });
            const state: SavedFilterTableState = {
                ...currentFilters,
                [tctx]: {
                    defaultFilter: makeDefault ? size - 1 : defaultFilter,
                    namedFilters,
                },
            };
            storage.setItem(SAVED_FILTER_KEY, state);
        },
        (index: number) => {
            const currentFilters = savedFilters();
            const savedFilter = currentFilters[tctx];
            if (savedFilter) {
                const state: SavedFilterTableState = {
                    ...currentFilters,
                    [tctx]: {
                        defaultFilter: index === savedFilter.defaultFilter ? undefined : savedFilter.defaultFilter,
                        namedFilters: savedFilter.namedFilters.filter((_, i) => i !== index),

                    },
                };
                storage.setItem(SAVED_FILTER_KEY, state);
            }
        },
    ];
};
