import { Formatter } from '@shift/transform';
import { EnumFormatters } from './EnumValuesFormatter';

export const DrivingLicenseClassEnumFormatter: Formatter<any[], { children: string }> = ({
    transform: ([raw]) => {
        const enumString: string = raw[0];
        const enumValue = { Type: 'DrivingLicenseSourceType', Name: enumString };
        return EnumFormatters.EnumValuesFormatter.transform([[enumValue]]);
    },
});
