import { runtimeConfig } from './runtime-config';

export const removeTrailingSlash = (url: string): string => {
    if (!url) return '';
    return url.replace(/\/$/g, '');
};

const {
    clientId,
    authUri,
    redirectUri,
    resourceUri,
    telemetryEnabled,
    telemetryUri,
    telemetryEnv,
} = runtimeConfig();

export const oktaConfig = {
    baseUrl: removeTrailingSlash(authUri),
    clientId,
    issuer: `${removeTrailingSlash(authUri)}/oauth2/default`,
    redirectUri: `${removeTrailingSlash(redirectUri)}/implicit/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: false,
};

export const apiEndPoint = {
    resource: 'graphql',
    upload: 'document/upload',
    pdf: 'document/AccidentReportPdf',
    document: 'document/Id',
    documentPreview: 'document/Preview',
    documentZip: 'document/RecoveryAttachments',
    importedDocument: 'document/ImportedDocument',
    letterOfDemand: 'document/LetterOfDemandPdf',
    reporting: 'Reporting/encrypted-claims',
};

export const apiConfig = {
    resourceUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.resource}`,
    uploadUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.upload}`,
    pdfUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.pdf}`,
    documentUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.document}`,
    documentPreviewUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.documentPreview}`,
    documentZipUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.documentZip}`,
    importedDocumentUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.importedDocument}`,
    letterOfDemandUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.letterOfDemand}`,
    // frontendMetricsUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.frontendMetrics}`,
    // apiMetricsUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.apiMetrics}`,
    reportingSecuredClaimsUri: `${removeTrailingSlash(resourceUri)}/${apiEndPoint.reporting}`,
};

export const telemetryConfig = {
    active: telemetryEnabled === 'true',
    serviceName: 'gears-ui',
    serverUrl: telemetryUri,
    serviceVersion: '',
    environment: telemetryEnv,
    breakdownMetrics: true,
    distributedTracingOrigins: [removeTrailingSlash(redirectUri), removeTrailingSlash(resourceUri)],
    propagateTracestate: true,
};

export const dashboardLink = {
    insurer: 'InsurerDashboard',
    gia: 'GiaDashboard',
};
