import React, { useEffect, useState } from 'react';
import { parseOffsetDate, parseUtcDate } from 'src/utils';
import { FilterableHeader } from '@shift/design-system';
import { DateOffsetFormat, DateUtcFormat } from 'src/constants';
import { Moment, utc } from 'moment-timezone';
import {
    useFieldSort, useFieldFilter, Operation, mapOrder, unmapOrder, Field, ParameterType,
} from '../../../libs/filters';
import { translate } from '../../../utils/forms';
import { DateInputFilter } from './DateInputFilter';


export const DateFilter = ({
    children, field, operation,
}: { field: Field; operation: Operation; children: React.ReactNode }) => {
    const [sort, setSort] = useFieldSort(field);
    const [filter, setFilter] = useFieldFilter(field);

    const [pendingFilter, setPendingFilter] = useState(filter);

    useEffect(() => {
        setPendingFilter(filter);
    }, [filter]);

    const isUtc = operation?.parameters[0]?.type === ParameterType.DateTimeUtc;
    const parse = isUtc ? parseUtcDate : parseOffsetDate;
    const format = (date: Moment) => {
        if (!date) {
            return null;
        }
        if (isUtc) {
            return utc(date).format(DateUtcFormat);
        }
        return date.format(DateOffsetFormat);
    };
    const pendingStartDate = pendingFilter?.parameters[0].value ? parse(pendingFilter?.parameters[0].value) : null;
    const pendingEndDate = pendingFilter?.parameters[1].value ? parse(pendingFilter?.parameters[1].value) : null;
    const startDate = filter?.parameters[0].value ? parse(filter?.parameters[0].value) : null;
    const endDate = filter?.parameters[1].value ? parse(filter?.parameters[1].value) : null;
    return (
        <FilterableHeader
            activeFilter={Boolean(startDate) || Boolean(endDate)}
            filter={{ startDate: pendingStartDate, endDate: pendingEndDate }}
            onFilterChange={({ startDate: sDate, endDate: eDate }) => {
                setPendingFilter({
                    ...operation,
                    parameters: [
                        { ...operation.parameters[0], value: format(sDate) ?? operation?.parameters[0].value },
                        { ...operation.parameters[1], value: format(eDate) ?? operation?.parameters[1].value },
                    ],
                });
            }}
            onSubmit={() => setFilter(pendingFilter)}
            onClear={() => { setFilter(null); setPendingFilter(null); setSort(null); }}
            FilterComponent={DateInputFilter}
            buttonText={translate('common:submit')}
            clearText={translate('common:clear')}
            sortAscText={translate('common:sort-date-asc')}
            sortDescText={translate('common:sort-date-desc')}
            order={mapOrder(sort)}
            onOrderChange={(o) => setSort(unmapOrder(o))}
        >
            {children}
        </FilterableHeader>
    );
};
