import React from 'react';
import { Route, RouteProps } from 'src/components/Route';

import { Secure, SecureProps } from './Secure';

type SecureRouteProps = RouteProps & SecureProps;

export const SecureRoute = ({ permissions, ...props }: SecureRouteProps) => (
    <Secure permissions={permissions}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Route {...props} />
    </Secure>
);
