import React, { useMemo } from 'react';
import {
    NumberInput, SelectInputOption, PhoneInput,
} from '@shift/design-system';
import { formSections } from 'src/config/form';
import {
    FormField, ArrayItemProps, ExpandableField,
    EnumSelectInput, ConditionalFormField,
} from 'src/components/form';
import { isNoneEnum } from 'src/utils/forms';
import { sgCountryCode } from 'src/constants';
import { VehicleModelField } from '../fields';
import { GetPassengerItem } from './passenger-item';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const ThirdPartyItem = ({ setItemPath }: ArrayItemProps) => {
    const { item: itemInformation } = formSections.thirdPartySection.fields.vehiclesOrProperties;
    const PassengerItem = useMemo(() => GetPassengerItem(itemInformation.passengers.item), [itemInformation]);
    return (
        <>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.vehicleNumber)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.manufacturer)}
                        component={EnumSelectInput}
                        enumName="VehicleMake"
                        sort
                    />
                </div>
                <ConditionalFormField
                    sourceField={setItemPath(itemInformation.manufacturer)}
                    targetFields={[setItemPath(itemInformation.manufacturerText)]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={setItemPath(itemInformation.manufacturerText)}
                            component={TextInput}
                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <VehicleModelField
                        manufacturerFieldInfo={setItemPath(itemInformation.manufacturer)}
                        modelFieldInfo={setItemPath(itemInformation.model)}
                        modelTextFieldPath={setItemPath(itemInformation.modelText).path}
                        disabled={false}
                    />
                </div>
                <ConditionalFormField
                    sourceField={setItemPath(itemInformation.model)}
                    targetFields={[setItemPath(itemInformation.modelText)]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={setItemPath(itemInformation.modelText)}
                            component={TextInput}
                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.color)}
                        component={EnumSelectInput}
                        enumName="Color"
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.category)}
                        component={EnumSelectInput}
                        enumName="TPVehicleType"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.insuranceCompany)}
                        component={EnumSelectInput}
                        enumName="InsurerCode"
                        sort
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.propertyDetails)}
                        component={TextInput}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.damageNature)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverName)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverIdType)}
                        component={EnumSelectInput}
                        enumName="NationalIdentifierType"
                        exclude={['CompanyIdentificationNumber']}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverId)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverContactNumber)}
                        component={PhoneInput}
                        defaultValue={sgCountryCode}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverAddress1)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverAddress2)}
                        component={TextInput}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.driverPostCode)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.passengerCount)}
                        component={NumberInput}
                        min={0}
                    />
                </div>
            </div>
            <ExpandableField
                fieldInfo={setItemPath(itemInformation.passengers)}
                itemTitle="PASSENGER"
                addItemLabel="add-passenger-details"
                Item={PassengerItem}
            />
        </>
    );
};
