/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable max-len */
import { buildQuery, buildListQuery } from '..';
import { updateClaimStatusMutation } from '../mutation-builder';
import { AccidentReportConfig } from '../../config/configuration/types';
import { ProcessedSubConfiguration } from '../../libs/config/QueryConfigTypes';

const referenceCodeResult = {
    id: 0,
    referenceCode: 'TBD',
    status: { Type: 'ClosingType', Name: 'InProgress' },
    versions: [
        { id: 0, verison: new Date(2012, 9, 3), submissionDate: new Date(2012, 9, 3) },
        { id: 1, verison: new Date(2016, 2, 12), submissionDate: new Date(2016, 2, 12) },
    ],
    isCommentAllowed: true,
    isRecoveriesAccessAllowed: true,
    isRecoveryCreationAllowed: true,
};

export const accidentReportsMock = (config: ProcessedSubConfiguration<AccidentReportConfig>) => [
    // Basic list
    {
        request: {
            query: buildListQuery(
                config.list.context,
                config.list.fields,
                'ClaimFields',
            ),
            variables: {
                parameters: {
                    start: 0,
                    length: 10,
                    filters: [],
                    sort: null,
                },
            },
        },
        newData: () => ({
            data: {
                claimList: {
                    totalCount: 2,
                    itemsCount: 2,
                    items: [
                        {
                            id: 1,
                            referenceCode: 'AR1011',
                            policy: {
                                id: 1,
                                persons: [{
                                    id: 1,
                                    specializations: {
                                        id: 1,
                                        mapping_Person_Gears: {
                                            id: 1,
                                            name: 'John Tan',
                                        },
                                    },
                                }],
                            },
                            status: 'closed',
                            objects: {
                                id: 1,
                                specializations: {
                                    id: 1,
                                    mapping_Object_Motor_VehicleRegistration: [{
                                        id: 10,
                                        number: 'SCK1117J',
                                    }],
                                },
                            },
                            specializations: {
                                id: 1,
                                mapping_Claim_Cause: {
                                    id: 1,
                                    enumIdentifier: null,
                                },
                                mapping_Claim_Statement: {
                                    id: 1,
                                    dateOffset: new Date(2020, 3, 14),
                                },
                            },
                            versions: [
                                { id: 0, verison: new Date(2020, 3, 21), submissionDate: new Date(2020, 3, 21) },
                                { id: 1, verison: new Date(2020, 3, 23), submissionDate: new Date(2020, 3, 23) },
                                { id: 2, verison: new Date(2020, 3, 23), submissionDate: new Date(2020, 3, 23) },
                            ],
                        },
                        {
                            id: 2,
                            referenceCode: 'AR6061',
                            policy: {
                                id: 2,
                                persons: [{
                                    id: 2,
                                    specializations: {
                                        id: 2,
                                        mapping_Person_Gears: {
                                            id: 2,
                                            name: 'Jane Lim',
                                        },
                                    },
                                }],
                            },
                            objects: {
                                id: 2,
                                specializations: {
                                    id: 2,
                                    mapping_Object_Motor_VehicleRegistration: null,
                                },
                            },
                            status: null,
                            specializations: {
                                id: 2,
                                mapping_Claim_Cause: {
                                    id: 2,
                                    enumIdentifier: 'MotorClaimCause',
                                    enumValueId: 20,
                                },
                            },
                        },
                    ],
                },
            },
        }),
    },
    // List with filters
    {
        request: {
            query: buildListQuery(
                config.list.context,
                config.list.fields,
                'ClaimFields',
            ),
            variables: {
                parameters: {
                    start: 0,
                    length: 10,
                    filters: [{
                        field: { name: 'ReferenceCode', object: 'Claim' },
                        operation: {
                            type: 'SearchOperationType.Match',
                            name: 'StringMatch',
                            parameters: [{ value: 'AR1011', name: 'searchedText', type: 'ParameterType.StringParameter' }],
                        },
                    }],
                    sort: null,
                },
            },
        },
        newData: () => ({
            data: {
                claimList: {
                    totalCount: 1,
                    itemsCount: 1,
                    items: [
                        {
                            id: 1,
                            referenceCode: 'AR1011',
                            policy: {
                                id: 1,
                                persons: [{
                                    id: 1,
                                    specializations: {
                                        id: 1,
                                        mapping_Person_Gears: {
                                            id: 1,
                                            name: 'John Tan',
                                        },
                                    },
                                }],
                            },
                            status: 'closed',
                            objects: {
                                id: 1,
                                specializations: {
                                    id: 1,
                                    mapping_Object_Motor_VehicleRegistration: [{
                                        id: 10,
                                        number: 'SCK1117J',
                                    }],
                                },
                            },
                            specializations: {
                                id: 1,
                                mapping_Claim_Cause: {
                                    id: 1,
                                    enumIdentifier: null,
                                },
                            },
                        },
                    ],
                },
            },
        }),
    },
    // General information: Own insured
    {
        request: {
            query: buildQuery(
                config.ownDriver.context,
                config.ownDriver.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    persons: [{
                        id: 3,
                        addresses: [{
                            id: 3,
                            fullAddress: '171A Edgedale plains',
                            postcode: '821171',
                        }],
                        mails: [{
                            id: 3,
                            address: 'soh_chentiong@gmail.com',
                        }],
                        phoneNumbers: [{
                            id: 3,
                            number: '+65 91158859',
                        }],
                        links: {
                            mapping_ClaimPerson: {
                                specializations: {
                                    mapping_ClaimPerson_DrivingLicense: {
                                        startDate: '11/10/2014T09:30:00+08:00',
                                    },
                                },
                            },
                        },
                        specializations: {
                            id: 3,
                            mapping_Person_Gears: {
                                id: 3,
                                name: 'Cheng Soh',
                            },
                            mapping_Person_Individual: {
                                id: 3,
                                firstName: 'Soh',
                                lastName: 'Cheng',
                                gender: { Type: 'EntityGender', Name: 'Male' },
                                dateOfBirth: '11/01/1986T02:15:00+08:00',
                            },
                            mapping_Person_NationalIdentifier: {
                                id: 3,
                                nationalIdentifier: 'S1191122A',
                                identifier:
                                {
                                    Type: 'NationalIdentifierType',
                                    Name: 'NationalIdentifier',
                                },
                            },
                        },
                    }, {
                        id: 4,
                        addresses: [{
                            id: 3,
                            fullAddress: '1 Main Street',
                            postcode: '821171',
                        }],
                        mails: [{
                            id: 4,
                            address: 'li_ming@gmail.com',
                        }],
                        phoneNumbers: [{
                            id: 4,
                            number: '+65 91158859',
                        }],
                        links: {
                            mapping_ClaimPerson: {
                                specializations: {
                                    mapping_ClaimPerson_DrivingLicense: {
                                        startDate: '13/10/2014T09:30:00+08:00',
                                    },
                                },
                            },
                        },
                        specializations: {
                            id: 4,
                            mapping_Person_Gears: {
                                id: 4,
                                name: 'Li Ming',
                            },
                            mapping_Person_Individual: {
                                id: 4,
                                firstName: 'Li',
                                lastName: 'Ming',
                                gender: { Type: 'EntityGender', Name: 'Male' },
                                dateOfBirth: '15/10/1986T04:10:00+08:00',
                            },
                            mapping_Person_NationalIdentifier: {
                                id: 4,
                                nationalIdentifier: 'S1191122A',
                                identifier:
                                {
                                    Type: 'NationalIdentifierType',
                                    Name: 'NationalIdentifier',
                                },
                            },
                        },
                    }],
                    specializations: {
                        id: 2,
                        mapping_Claim_Cause: {
                            id: 2,
                            detailedCircumstances: `I was driving my car along the road.
                            There is a traffic light turning red so i then stopped.
                            Suddenly a car from behind collided with my rear.`,
                        },
                    },
                },
            },
        }),
    },
    // General information: Own policy
    {
        request: {
            query: buildQuery(
                config.ownPolicy.context,
                config.ownPolicy.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    policy: {
                        id: 1,
                        recordOwner: { Type: 'RecordOwner', Name: 'Insurer A' },
                        referenceCode: 'A26121269',
                        creationDate: '11/10/2019T09:15:00+08:00',
                        persons: [{
                            id: 1,
                            mails: [{
                                id: 1,
                                address: 'john_tan@gmail.com',
                            }],
                            phoneNumbers: [{
                                id: 1,
                                number: '+65 85362541',
                            }],
                            specializations: {
                                id: 1,
                                mapping_Person_Gears: {
                                    id: 1,
                                    name: 'John Tan',
                                },
                                mapping_Person_Individual: {
                                    id: 1,
                                    firstName: 'John',
                                    lastName: 'Tan',
                                },
                                mapping_Person_NationalIdentifier: {
                                    id: 1,
                                    nationalIdentifier: 'S8284938B',
                                    identifier: {
                                        Type: 'NationalIdentifierType',
                                        Name: 'NationalIdentifier',
                                    },
                                },
                            },
                        }],
                        specializations: {
                            id: 1,
                            mapping_Policy_Motor: {
                                type: { Type: 'MotorPolicyType', Name: 'Fleet' },
                            },
                        },
                    },
                },
            },
        }),
    },
    // General information: Third party
    {

        request: {
            query: buildQuery(
                config.thirdParty.context,
                config.thirdParty.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    persons: [{
                        id: 2,
                        addresses: [{
                            id: 2,
                            fullAddress: '2 Hougang street 92200 Paris',
                        }],
                        mails: [{
                            id: 3,
                            address: 'melissa.lee@gmail.com',
                        }],
                        phoneNumbers: [{
                            id: 2,
                            number: '+65 92262886',
                        }],
                        policies: [{
                            id: 2,
                            recordOwner: { Type: 'RecordOwner', Name: 'Insurer B' },
                            referenceCode: 'B26234669',
                        }],
                        specializations: {
                            id: 2,
                            mapping_Person_Gears: {
                                id: 2,
                                name: 'Melissa Lee',
                            },
                            mapping_Person_Individual: {
                                id: 2,
                                firstName: 'Melissa',
                                lastName: 'Lee',
                            },
                            mapping_Person_NationalIdentifier: {
                                id: 2,
                                nationalIdentifier: 'S2225469B',
                            },
                        },
                    }],
                    objects: [{
                        specializations: {
                            mapping_Object_Motor_VehicleRegistration: [{
                                number: 'SBZ2228E',
                            }],
                            mapping_Object_Motor_Vehicle: {
                                make: { Type: 'VehicleMake', Name: 'Toyota' },
                                model: 'Corolla Altis',
                                type: { Type: 'VehicleType', Name: 'PrivateCar' },
                                constructionDateOffset: '15/10/2016T09:25:00+08:00',
                            },
                        },
                    }],
                },
            },
        }),
    },
    // General information: Own vehicle
    {
        request: {
            query: buildQuery(
                config.ownVehicle.context,
                config.ownVehicle.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    objects: [{
                        specializations: {
                            mapping_Object_Motor_Vehicle: {
                                make: { Type: 'VehicleMake', Name: 'Honda' },
                                model: 'Fit',
                                type: { Type: 'VehicleType', Name: 'PrivateCar' },
                                constructionDateOffset: '15/11/2019T09:25:00+08:00',
                            },
                            mapping_Object_Motor_VehicleRegistration: [{
                                number: 'SCK1117J',
                            }],
                        },
                        links: {
                            mapping_ClaimObject: [
                                {
                                    specializations: {
                                        mapping_ClaimObject_Motor_InvolvedVehicle: {
                                            usagePurpose: 'Private use',
                                        },
                                    },
                                },
                            ],
                        },
                    }],
                },
            },
        }),
    },
    // Accident summary: Accident location
    {
        request: {
            query: buildQuery(
                config.accidentLocation.context,
                config.accidentLocation.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    addresses: [{
                        id: 2,
                        fullAddress: '165 Tanjong Pagar Plaza, Singapore 088539',
                        latitude: 1.275651,
                        longitude: 103.843374,
                    }],
                },
            },
        }),
    },
    // Accident summary: Accident
    {
        request: {
            query: buildQuery(
                config.accident.context,
                config.accident.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    InjuredPersons: 'Yes',
                    createDateUtc: '26/11/2019T11:15:00Z',
                    hasPoliceReport: 'Yes',
                    specializations: {
                        id: 2,
                        mapping_Claim_Event: {
                            id: 2,
                            eventDateOffset: '25/11/2019T09:35:00+08:00',
                        },
                        mapping_Claim_Motor_RoadAccident: {
                            id: 2,
                            weatherCondition: {
                                Type: 'WeatherCondition',
                                Name: 'Clear',
                            },
                            weatherConditionDescription: 'It is clear sky',
                            roadSurface: {
                                Type: 'RoadSurface',
                                Name: 'Dry',
                            },
                        },
                        mapping_Claim_Motor_Checklist: {
                            id: 3,
                            numberOfVehicles: 2,
                            anyForeignVehicle: 'No',
                            anyInjuredPersons: 'No',
                            anyInjuredPersonConveyedByAmbulance: 'No',
                            anyWitness: 'No',
                            anyUnknownPersonAssistance: 'No',
                            anyPoliceReport: 'No',
                            anyVehiclePhoto: 'No',
                            anyAccidentPhoto: 'No',
                            anyAccidentVideo: 'No',
                        },
                    },
                },
            },
        }),
    },
    // Accident summary: Statement
    {
        request: {
            query: buildQuery(
                config.statement.context,
                config.statement.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    specializations: {
                        id: 2,
                        mapping_Claim_Cause: {
                            id: 2,
                            detailedCircumstances: `I was driving my car along the road.
                            There is a traffic light turning red so i then stopped.
                            Suddenly a car from behind collided with my rear.`,
                        },
                    },
                },
            },
        }),
    },
    // Fraud Elements
    {
        request: {
            query: buildQuery(
                config.fraudElements.context,
                config.fraudElements.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    fraudElements: [{
                        score: '0',
                        link: 'https://newdemo.shift-technology.com',
                    }],
                },
            },
        }),
    },
    // Recovery
    {
        request: {
            query: buildQuery(
                config.recoveries.context,
                config.recoveries.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    recoveries: [
                        {
                            id: 1,
                            referenceCode: 'RCV7104',
                            insurer: 'Insurer A',
                            officerInCharge: 'Thibault H',
                            links: {
                                mapping_RecoveryTarget: {
                                    entityRoleEnumIdentifier: 'MotorRecoveryType',
                                    entityRoleEnumValueId: 1,
                                },
                            },
                            workflow: {
                                id: 1,
                                lastWorkflowStep: {
                                    id: 42,
                                    state: 'Initial offer',
                                },
                                claims: [
                                    {
                                        id: 12,
                                        links: {
                                            ui_WorkflowToClaim: {
                                                roleEnumValueId: 1,
                                            },
                                        },
                                        objects: [
                                            {
                                                id: 2,
                                                specializations: {
                                                    id: 38393,
                                                    mapping_Object_Motor_VehicleRegistration: [{ id: 2, number: 'SAK1915C' }],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                        {
                            id: 2,
                            referenceCode: 'RCV9992',
                            insurer: 'Insurer B',
                            officerInCharge: 'Matthieu O H',
                            links: {
                                mapping_RecoveryTarget: {
                                    entityRoleEnumIdentifier: 'MotorRecoveryType',
                                    entityRoleEnumValueId: 1,
                                },
                            },
                            workflow: {
                                id: 2,
                                lastWorkflowStep: {
                                    id: 22,
                                    state: 'Counter-offer',
                                },
                                claims: [
                                    {
                                        id: 2,
                                        links: {
                                            ui_WorkflowToClaim: {
                                                roleEnumValueId: 1,
                                            },
                                        },
                                        objects: [
                                            {
                                                id: 6,
                                                specializations: {
                                                    id: 394720,
                                                    mapping_Object_Motor_VehicleRegistration: [{ id: 6, number: 'SAK1110C' }],
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        }),
    },
    // Reference code
    {
        request: {
            query: buildQuery(config.referenceCode.context, config.referenceCode.fields),
            variables: { id: 1 },
        },
        newData: () => ({
            data: {
                claim: {
                    ...referenceCodeResult,
                    id: 1,
                    referenceCode: 'AR6061',
                },
            },
        }),
    },
    // Reference code - Comments not allowed
    {
        request: {
            query: buildQuery(config.referenceCode.context, config.referenceCode.fields),
            variables: { id: 2 },
        },
        newData: () => ({
            data: {
                claim: {
                    ...referenceCodeResult,
                    id: 2,
                    referenceCode: 'AR6062',
                    isCommentAllowed: false,
                },
            },
        }),
    },
    // Reference code - Recovery access not allowed
    {
        request: {
            query: buildQuery(config.referenceCode.context, config.referenceCode.fields),
            variables: { id: 3 },
        },
        newData: () => ({
            data: {
                claim: {
                    ...referenceCodeResult,
                    id: 3,
                    referenceCode: 'AR6063',
                    isRecoveriesAccessAllowed: false,
                },
            },
        }),
    },
    // Reference code - Recovery creation not allowed
    {
        request: {
            query: buildQuery(config.referenceCode.context, config.referenceCode.fields),
            variables: { id: 4 },
        },
        newData: () => ({
            data: {
                claim: {
                    ...referenceCodeResult,
                    id: 4,
                    referenceCode: 'AR6064',
                    isRecoveryCreationAllowed: false,
                },
            },
        }),
    },
    // Attachments
    {
        request: {
            query: buildQuery(
                config.attachments.context,
                config.attachments.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    documents: [{
                        id: 1,
                        documentName: 'left_back',
                        type: 'Damage pictures',
                        modifyDateUtc: '27/01/2020T11:15:00Z',
                        description: 'Accident photo #1',
                        specializations: {
                            id: 1,
                            document_Document_DestinationDiskStorageInformation: {
                                id: 1,
                                storagePath: 'https://s3-alpha-sig.figma.com/img/8aa3/5440/09c5da4bb616f13ede9b88959f63ee72?Expires=1580083200&Signature=OCAcEJspetJDD5QBoGw~BmtmMHFsiAYZVBz2KsIxTCql-f6sIwXUn1oD862jHtP8-HiUvuC9LEWTWV43S20knWHqx8eAPFx3M9-kTJsNmkh-dPUzzEt2T2EtshGhAXFRy4usabnravyytiJwnd~dFWfOLD0QWJCHHqlsdqNV3g4BnflwGwUoFWlVbwAcH2hxk6rtRJOTaQQtr7uj~qr5q1kGJys7T09pIKavRpBi-kN6eJFvHd2mwA8qgV4gk1YcGNuzYG1Q-OKgEcDye59S8kHwXZtiFLTDPqJOWk8LBkwIs6UEB41FSpMEjfkCIHYch6x0rih8tdkeZY9DJj~iMg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
                            },
                        },
                        persons: [{
                            id: 1,
                            specializations: {
                                id: 1,
                                mapping_Person_Gears: {
                                    id: 2,
                                    name: 'John Doe',
                                },
                            },
                        }],
                    },
                    {
                        id: 2,
                        documentName: 'left_side',
                        type: 'Damage pictures',
                        modifyDateUtc: '27/02/2020T11:15:00Z',
                        description: 'Accident photo #2',
                        specializations: {
                            id: 2,
                            document_Document_DestinationDiskStorageInformation: {
                                id: 2,
                                storagePath: 'https://s3-alpha-sig.figma.com/img/b8c4/0e4a/3f5b104593c490784b746c9afeaeea68?Expires=1580083200&Signature=TFFQ5VX5E6F2oiApk8NtizRdEtIO~tGL7tVCjN4LN25eK9SgALJHht2ga6sHBirN7--Qtzfxx3bBtqMw~iWczkDzHCZKQ33KG3iOE6blnaUjgtM1hX7MqDQMaeX2MhvmogwtZ3fCilpT~wDP7STjGqizCEUaGfGjIcTKP6ChVtwPiGSxS56X9B9Esb3SAVrJHDrTtN0X4XwCUCQxZG1k3yyY6CMBawDMe6KYza26YFyH0XI3gFHdsYWbx1h8qbk510XofPcFvjho5btutIRkvkbDqagDD7Kv2UrFgaWDzAyOInnb7h~ECwKGaOlmDTiGRE-KLxEIBNhPkiBAms~g~g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
                            },
                        },
                        persons: [{
                            id: 1,
                            specializations: {
                                id: 1,
                                mapping_Person_Gears: {
                                    id: 2,
                                    name: 'John Doe',
                                },
                            },
                        }],
                    }],
                },
            },
        }),
    },
    // Versions
    {
        request: {
            query: buildQuery(
                config.versions.context,
                config.versions.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    versions: [
                        { id: 0, verison: new Date(2012, 9, 3), submissionDate: new Date(2012, 9, 3) },
                        { id: 1, verison: new Date(2016, 2, 12), submissionDate: new Date(2016, 2, 12) },
                    ],
                },
            },
        }),
    },
    // Comments
    {
        request: {
            query: buildQuery(
                config.comments.context,
                config.comments.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    comments: [{
                        id: 1,
                        content: 'A first comment',
                    }],
                },
            },
        }),
    },
    // Update claim status: Closed
    {
        request: {
            query: updateClaimStatusMutation,
            variables: {
                id: 1, close: true,
            },
        },
        newData: () => ({
            data: {
                updateStatus: {
                    id: 1,
                    status: { Type: 'ClosingType', Name: 'Closed' },
                },
            },
        }),
    },
    // Update claim status: In progress
    {
        request: {
            query: updateClaimStatusMutation,
            variables: {
                id: 1, close: false,
            },
        },
        newData: () => ({
            data: {
                updateStatus: {
                    id: 1,
                    status: { Type: 'ClosingType', Name: 'InProgress' },
                },
            },
        }),
    },
    // Get information for initiate recovery
    {
        request: {
            query: buildQuery(
                config.initiateRecovery.context,
                config.initiateRecovery.fields,
            ),
            variables: {
                id: 1,
            },
        },
        newData: () => ({
            data: {
                claim: {
                    id: 1,
                    specializations: {
                        id: 1,
                        mapping_Claim_Insurer: {
                            id: 1,
                            insurer: { Type: 'RecordOwner', Name: 'Insurer A' },
                        },
                    },
                    policy: {
                        id: 1,
                        persons: [{
                            id: 1,
                            specializations: {
                                id: 1,
                                mapping_Person_Gears: {
                                    id: 1,
                                    name: 'John Tan',
                                },
                            },
                        }],
                    },
                    objects: [{
                        id: 1,
                        specializations: {
                            mapping_Object_Motor_VehicleRegistration: [{
                                number: 'SCK1117J',
                            }],
                        },
                        links: {
                            id: 1,
                            mapping_ClaimObject: [{
                                id: 1,
                                roleEnumIdentifier: 'PartySide',
                                roleEnumValueId: 1,
                            }],
                        },
                    }],
                },
            },
        }),
    },
];
