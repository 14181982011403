import { Formatter } from '@shift/transform';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SingleValueFormatter: Formatter<any[], { children: string }> = {
    transform: (params) => {
        const value = params && params.length > 0 && params[0][0];
        return {
            children: value ?? null,
        };
    },
};
