import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './oktaPolyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { init as initApm } from '@elastic/apm-rum';
import { Locale } from '@gears/translations';
import './styles/index.scss';
import * as serviceWorker from 'src/serviceWorker';
import { ApplicationWrapper } from 'src/entrypoint/application/ApplicationWrapper';
import { configureLocale } from 'src/utils/translation';
import { setDefaultTimeZone } from './utils/translation/moment-config';
import { telemetryConfig } from './config';

initApm(telemetryConfig);

setDefaultTimeZone();
configureLocale(Locale.enSG);
/* eslint-env browser */
ReactDOM.render(
    <ApplicationWrapper />,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
