import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
    Grid, GridItem, Button, StyledHeader, StyledText, withCustomStyles,
    PasswordInput, ThemeContext, StyledLink, InputForm, InputComponentProps,
} from '@shift/design-system';


import { useSecurity } from 'src/libs/auth';
import { translate } from '@gears/translations';

import { FactorActivate, FACTOR_TYPES } from 'src/libs/auth/State';
import background from './login_background.jpg';
import backgroundWebp from './login_background.webp';
import giaLogo from './gia_logo.svg';
import gearsLogo from './gears_logo.svg';
import { GridCenteredCircularLoader } from '../../components/Loader/GridCenteredCircularLoader';
import { Version } from './Version';
import { TextInput } from '../../components/TextInput/TextInput';

const objectKeys = <T extends {}>(object: T): (keyof T)[] => Object.keys(object) as any;

const StylableStyledText = withCustomStyles(StyledText);

const InputLabel = ({ children }: { children: React.ReactNode }) => (
    <StylableStyledText style={{ marginBottom: '4px' }} size="medium" weight="semiBold">{children}</StylableStyledText>
);

const MainGridItem = ({ children }: { children: React.ReactNode }) => (
    <GridItem style={{ margin: '25px 15px' }}>{children}</GridItem>
);

const FormGridItem = ({ children }: { children: React.ReactNode }) => (
    <GridItem style={{ margin: '12px' }}>{children}</GridItem>
);

const FormGrid = ({ onSubmit, children }: { onSubmit: (ev: React.FormEvent<HTMLFormElement>) => void; children: React.ReactNode }) => (
    <form onSubmit={onSubmit}>
        <Grid style={{ minWidth: '300px', width: '80%', margin: '-22px -12px -12px -12px' }} direction="column">
            {children}
        </Grid>
    </form>
);

const useRecoveryToken = (url: string) => {
    const match = useRouteMatch(url);

    if (!match) {
        return { matched: false };
    }

    const { token = '' } = match.params as any;

    return { matched: true, token: token as string };
};

const LoginFormContent = ({
    children, button, links, error, footer,
}: {
    children: React.ReactNode;
    button: React.ReactNode;
    links?: React.ReactNode | React.ReactNode[];
    error?: Error | null;
    footer?: React.ReactNode;
}) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <>
            {children}
            <FormGridItem>
                <Grid justifySpacing="space-between" alignSpacing="baseline">
                    <GridItem>
                        {button}
                    </GridItem>
                    {links && (Array.isArray(links) ? links : [links]).map((l, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <GridItem key={i}>{l}</GridItem>
                    ))}
                </Grid>
            </FormGridItem>
            {error && (
                <FormGridItem>
                    <StyledText color={colors.status.error}>{error.message}</StyledText>
                </FormGridItem>
            )}
            {footer && (
                <FormGridItem>
                    {footer}
                </FormGridItem>
            )}
        </>
    );
};

const ResumeRecoveryForm = ({ error, resumeRecovery, cancel }: any) => {
    const [token, setToken] = useState('');
    const history = useHistory();

    const cb = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resumeRecovery(token);
    };

    return (
        <FormGrid onSubmit={cb}>
            <LoginFormContent
                button={
                    /* login function call has been moved to the form in order to avoid page refresh (happens only in edge) */
                    <Button disabled={!token} onClick={() => ({})}>Recover Password</Button>
                }
                links={<StyledLink onClick={cancel ?? (() => { history.push('/'); })}>Cancel</StyledLink>}
                error={error}
                footer={(
                    <StyledText size="small" textStyle="italic" textAlign="justify">
                        Check your emails. You should have received the recovery code. Copy/paste it here to reset your password.
                    </StyledText>
                )}
            >
                <FormGridItem>
                    <InputLabel>Recovery Code</InputLabel>
                    <TextInput name="code" value={token} onChange={setToken} />
                </FormGridItem>
            </LoginFormContent>
        </FormGrid>
    );
};

const LoginForm = () => {
    const {
        error, login, forgotPassword, resumeRecovery,
    } = useSecurity('UNAUTHENTICATED');
    const [recoveringPassword, setRecoveringPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const { matched, token } = useRecoveryToken('/reset-password/:token?');

    React.useLayoutEffect(() => {
        if (!matched || error) {
            return;
        }

        if (token) {
            history.push('/');
            resumeRecovery(token);
        }
        // we disable the `exhaustive-deps` because `resumeRecovery` we ignore changes to it on purpose
        // (its reference can change between renders and we don't want this effect to execute)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matched, token, error]);

    const cb = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (recoveringPassword) {
            forgotPassword(username);
        } else {
            login(username, password);
        }
    };

    if (matched && !token) {
        return (
            <ResumeRecoveryForm error={error} resumeRecovery={resumeRecovery} />
        );
    }

    return (
        <FormGrid onSubmit={cb}>
            <LoginFormContent
                button={(
                    /* login function call has been moved to the form in order to avoid page refresh (happens only in edge) */
                    <Button disabled={!username || (!recoveringPassword && !password)} onClick={() => ({})}>
                        {recoveringPassword ? 'Recover' : 'Login'}
                    </Button>
                )}
                links={<StyledLink onClick={() => setRecoveringPassword((s) => !s)}>{recoveringPassword ? 'Cancel' : 'Forgot Password?'}</StyledLink>}
                error={error}
            >
                <FormGridItem>
                    <InputLabel>Email</InputLabel>
                    <TextInput name="email" value={username} onChange={(value) => setUsername(value)} />
                </FormGridItem>
                {recoveringPassword || (
                    <FormGridItem>
                        <InputLabel>Password</InputLabel>
                        <PasswordInput autocomplete="current-password" value={password} onChange={(value) => setPassword(value)} />
                    </FormGridItem>
                )}
            </LoginFormContent>
        </FormGrid>
    );
};

const EnrollFactorForm = () => {
    const { factors, error } = useSecurity('FACTOR_ENROLL');
    const { colors } = React.useContext(ThemeContext);

    return (
        <FormGrid onSubmit={(event) => {
            event.preventDefault();
        }}
        >
            <FormGridItem>
                {translate('account:factor:enroll-prompt')}
            </FormGridItem>
            {
                Object.entries(factors)
                    .map(([key, factor]) => !!factor && (
                        <FormGridItem>
                            <Button key={key} disabled={!!error} onClick={factor.enroll}>
                                {translate(`account:factor:enroll-${key}`)}
                            </Button>
                        </FormGridItem>
                    ))
            }
            {error && (
                <FormGridItem>
                    <StyledText color={colors.status.error}>{error.message}</StyledText>
                </FormGridItem>
            )}
        </FormGrid>
    );
};

const RequiredFactorForm = () => {
    const { factors, error } = useSecurity('FACTOR_REQUIRED');
    const { colors } = React.useContext(ThemeContext);

    const isFactorUnique = Object.keys(factors).length === 1;

    React.useEffect(() => {
        if (!error && isFactorUnique) {
            factors[objectKeys(factors)[0]]!.verify();
        }
    }, [factors, error, isFactorUnique]);

    return isFactorUnique
        ? null
        : (
            <FormGrid onSubmit={(event) => {
                event.preventDefault();
            }}
            >
                <FormGridItem>
                    {translate('account:factor:required-prompt')}
                </FormGridItem>
                {
                    Object.entries(factors)
                        .map(([key, factor]) => !!factor && (
                            <FormGridItem key={key}>
                                <Button disabled={!!error} onClick={factor.verify}>
                                    {translate(`account:factor:enroll-${key}`)}
                                </Button>
                            </FormGridItem>
                        ))
                }
                {error && (
                    <FormGridItem>
                        <StyledText color={colors.status.error}>{error.message}</StyledText>
                    </FormGridItem>
                )}
            </FormGrid>
        );
};

const CodeTextInput = ({
    value, onChange, label, tooltipText,
}: InputComponentProps<string> & {label: string; tooltipText: string}) => (
    <InputForm
        label={label}
        tooltip={tooltipText}
    >
        <TextInput value={value} onChange={onChange} />
    </InputForm>
);

const ChallengeFactorForm = () => {
    const { verify, error, factorType } = useSecurity('FACTOR_CHALLENGE');
    const [code, setCode] = useState('');
    const { colors } = React.useContext(ThemeContext);

    return (
        <FormGrid onSubmit={(event) => {
            event.preventDefault();
            verify(code);
        }}
        >
            <FormGridItem>
                <CodeTextInput
                    value={code}
                    onChange={setCode}
                    label={translate('account:factor:mfa-code')}
                    tooltipText={translate(`account:factor:mfa-tooltip-${factorType}`)}
                />
            </FormGridItem>
            <FormGridItem>
                {/* login function call has been moved to the form in order to avoid page refresh (happens only in edge) */}
                <Button disabled={!code} onClick={() => ({})}>Confirm</Button>
            </FormGridItem>
            {error && (
                <FormGridItem>
                    <StyledText color={colors.status.error}>{error.message}</StyledText>
                </FormGridItem>
            )}
        </FormGrid>
    );
};

const ActivateFactorForm = () => {
    const {
        activate, factor, error,
    } = useSecurity('FACTOR_ACTIVATE');
    const { colors } = React.useContext(ThemeContext);

    const [code, setCode] = useState('');

    return (
        <FormGrid onSubmit={(event) => {
            event.preventDefault();
            activate(code);
        }}
        >
            {factor.type === FACTOR_TYPES.AUTH_OTP && <AuthOtpActivateFactorInfo factor={factor} />}

            <FormGridItem>
                <CodeTextInput
                    value={code}
                    onChange={setCode}
                    label={translate('account:factor:mfa-code')}
                    tooltipText={translate(`account:factor:mfa-tooltip-${factor.type}`)}
                />
            </FormGridItem>
            <FormGridItem>
                {/* login function call has been moved to the form in order to avoid page refresh (happens only in edge) */}
                <Button disabled={!code} onClick={() => ({})}>Confirm</Button>
            </FormGridItem>
            {error && (
                <FormGridItem>
                    <StyledText color={colors.status.error}>{error.message}</StyledText>
                </FormGridItem>
            )}
        </FormGrid>
    );
};

const AuthOtpActivateFactorInfo = ({ factor }: {factor: FactorActivate}) => (
    <>
        <FormGridItem>
            <img src={factor.qrCode?.href} style={{ width: '100px', height: '100px' }} alt="auth qr code" />
        </FormGridItem>
        <FormGridItem>
            {translate('account:factor:enroll-otp-auth-prompt', { sharedSecret: factor.sharedSecret })}
        </FormGridItem>
    </>
);

const ChangePasswordForm = () => {
    const {
        changePassword, cancel, skip, error,
    } = useSecurity('PASSWORD_EXPIRATION');
    const [password, setPassword] = useState('');

    return (
        <FormGrid onSubmit={(event) => {
            event.preventDefault();
            changePassword(password);
        }}
        >
            <LoginFormContent
                button={
                    /* login function call has been moved to the form in order to avoid page refresh (happens only in edge) */
                    <Button disabled={!password} onClick={() => ({})}>Confirm</Button>
                }
                links={(cancel || skip) && <StyledLink onClick={skip ?? cancel}>{skip ? 'Skip' : 'Cancel'}</StyledLink>}
                error={error}
            >
                <FormGridItem>
                    <InputLabel>New Password</InputLabel>
                    <PasswordInput autocomplete="new-password" value={password} onChange={setPassword} />
                </FormGridItem>
            </LoginFormContent>
        </FormGrid>
    );
};

const RecoveryChallengeForm = () => {
    const { error, resumeRecovery, cancel } = useSecurity('RECOVERY_CHALLENGE');

    return <ResumeRecoveryForm error={error} resumeRecovery={resumeRecovery} cancel={cancel} />;
};

const AuthForm = () => {
    const { status } = useSecurity();

    switch (status) {
    case 'UNAUTHENTICATED':
        return <LoginForm />;
    case 'FACTOR_ENROLL':
        return <EnrollFactorForm />;
    case 'FACTOR_ACTIVATE':
        return <ActivateFactorForm />;
    case 'FACTOR_REQUIRED':
        return <RequiredFactorForm />;
    case 'FACTOR_CHALLENGE':
        return <ChallengeFactorForm />;
    case 'PASSWORD_EXPIRATION':
        return <ChangePasswordForm />;
    case 'RECOVERY_CHALLENGE':
        return <RecoveryChallengeForm />;
    default:
        throw new Error('unreachable');
    }
};

const LoginContent = () => {
    const { authenticating } = useSecurity();

    if (authenticating) {
        return (
            <GridCenteredCircularLoader />
        );
    }
    return (
        <Grid direction="column" style={{ margin: '50px' }}>
            <MainGridItem>
                <img alt="gia logo" style={{ height: '90px' }} src={giaLogo} />
            </MainGridItem>
            <MainGridItem>
                <StyledHeader type="h1" weight="semiBold">Login</StyledHeader>
            </MainGridItem>
            <MainGridItem>
                <AuthForm />
            </MainGridItem>
        </Grid>
    );
};

export const Login = () => (
    <Grid
        style={{
            width: '100%',
            height: '100%',
        }}
        direction="row"
    >
        <GridItem
            grow={1}
            style={{
                position: 'relative',
                overflow: 'hidden',
                height: '100vh',
            }}
        >
            <picture>
                <source srcSet={backgroundWebp} type="image/webp" />
                <source srcSet={background} type="image/jpeg" />
                <img
                    alt="gears background"
                    style={{
                        position: 'absolute',
                        width: '2595px',
                        height: '1129px',
                        right: '-0',
                        top: '0px',
                        opacity: '100%',
                    }}
                    src={background}
                />
            </picture>
            <img
                alt="gears logo"
                style={{
                    position: 'absolute',
                    height: '170px',
                    top: '75px',
                    right: '60px',
                }}
                src={gearsLogo}
            />
        </GridItem>
        <GridItem style={{
            width: '35%',
        }}
        >
            <LoginContent />
            <Version />
        </GridItem>
    </Grid>
);
