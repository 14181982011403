import React, { useMemo } from 'react';
import {
    Grid, GridItem,
    SelectInputOption, NumberInput, RadioGroupInput,
} from '@shift/design-system';

import { formSections } from 'src/config/form';
import {
    formTranslate, isNoneEnum,
    isValuePopulatedByMyinfo,
} from 'src/utils/forms';
import {
    FormField, FormSection, ExpandableField,
    ConditionalFormField,
    EnumSelectInput,
    IntegerInput,
    DateInput,
} from 'src/components/form';
import { useEnumRadioOptions } from 'src/utils/hooks';
import { AnyType } from 'src/types';
import { isMyInfoInitiated } from 'src/constants';
import styled from 'styled-components';
import { TimePicker } from '../../../../../components/form/fields/time-input';
import { VehicleModelField } from '../fields';
import { GetPassengerItem } from '../array-items';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const OwnVehicleSection = ({ initialValue }: AnyType) => {
    const { name, title, fields } = formSections.ownVehicleSection;
    const PassengerItem = useMemo(() => GetPassengerItem(fields.passengers.item), [fields]);
    const isMyInfoInitiatedStatus = isMyInfoInitiated(initialValue?.generalInformation?.accidentInformation?.status?.Name);
    const transmissionTypes = useEnumRadioOptions('VehicleTransmission');
    const StyledTimePickerDiv = styled.div`
    svg {
        display: none !important;
    }
}`;
    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.registrationNumber}
                        component={TextInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.registrationNumber.path, initialValue)}

                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.category}
                        component={EnumSelectInput}
                        enumName="VehicleType"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.manufacturer}
                        component={EnumSelectInput}
                        enumName="VehicleMake"
                        sort
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.manufacturer.path, initialValue)}

                    />
                </div>
                <ConditionalFormField
                    sourceField={fields.manufacturer}
                    targetFields={[fields.manufacturerText]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.manufacturerText}
                            component={TextInput}
                            disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.manufacturerText.path, initialValue)}

                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <VehicleModelField
                        manufacturerFieldInfo={fields.manufacturer}
                        modelFieldInfo={fields.model}
                        modelTextFieldPath={fields.modelText.path}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.model.path, initialValue)}
                    />
                </div>
                <ConditionalFormField
                    sourceField={fields.model}
                    targetFields={[fields.modelText]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.modelText}
                            component={TextInput}
                            disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.modelText.path, initialValue)}


                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.variant}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.transmission}
                        component={RadioGroupInput}
                        options={transmissionTypes}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.engineDisplacement}
                        component={IntegerInput}
                        min={0}
                        tooltip={formTranslate('engine-displacement-hint')}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.engineDisplacement.path, initialValue)}


                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.firstRegistrationDateUtc}
                        component={DateInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.firstRegistrationDateUtc.path, initialValue)}

                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.vIN}
                        component={TextInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.vIN.path, initialValue)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.purchaseDateUtc}
                        component={DateInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.purchaseDateUtc.path, initialValue)}

                    />
                </div>
                {isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.purchaseTime.path, initialValue) ? (
                    <StyledTimePickerDiv className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.purchaseTime}
                            component={TimePicker}
                            disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.purchaseTime.path, initialValue)}

                        />
                    </StyledTimePickerDiv>
                ) : (
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.purchaseTime}
                            component={TimePicker}
                            disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.purchaseTime.path, initialValue)}

                        />
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.vehicleFuel}
                        component={EnumSelectInput}
                        enumName="VehicleFuel"
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.vehicleFuel.path, initialValue)}

                    />
                </div>
                <ConditionalFormField
                    sourceField={fields.vehicleFuel}
                    targetFields={[fields.vehicleFuelText]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.vehicleFuelText}
                            component={TextInput}
                            disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.vehicleFuelText.path, initialValue)}

                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <Grid style={{ alignItems: 'baseline' }}>
                    <GridItem className="col-xs-12 form-group">
                        <FormField
                            fieldInfo={fields.usagePurpose}
                            component={EnumSelectInput}
                            enumName="VehiclePurpose"
                        />
                    </GridItem>
                </Grid>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.passengerCount}
                        component={NumberInput}
                        min={0}
                        tooltip={formTranslate('passenger-count-hint')}
                    />
                </div>
            </div>

            <ExpandableField
                fieldInfo={fields.passengers}
                itemTitle="PASSENGER"
                addItemLabel="add-passenger-details"
                Item={PassengerItem}
                enableAdd
            />
        </FormSection>
    );
};
