import {
    OktaAuth, isIDToken, isAccessToken, TokenResponse,
    IDToken, AccessToken, AuthTransaction, Token,
} from '@okta/okta-auth-js';

import deepEqual from 'deep-equal';
import { Authenticator, AuthState, FactorActivate } from '../Authenticator';
import { FACTOR_TYPES } from '../State';

export type OktaOptions = {
    issuer: string;
    clientId?: string;
    redirectUri?: string;
    scopes?: string[];
    pkce?: boolean;
};

const normalizeOptions = (options: OktaOptions) => ({
    ...options,
    tokenManager: {
        secure: true,
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractTokens = (tokens: TokenResponse): [IDToken | undefined, AccessToken | undefined] => {
    if (!tokens.tokens) {
        console.warn('Missing tokens');
    }

    const { idToken, accessToken } = tokens.tokens;

    if (!idToken) {
        console.warn('idToken missing');
    }
    if (!accessToken) {
        console.warn('accessToken missing');
    }

    return [idToken, accessToken];
};

const debugLog = (msg: any, ...params: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.debug(`[okta] ${msg}`, ...params);
    }
};

const EMAIL_FACTOR_ID = 'OKTA_email';
const GOOGLE_AUTH_FACTOR_ID = 'GOOGLE_token:software:totp';

const FACTOR_MAP = {
    [GOOGLE_AUTH_FACTOR_ID]: FACTOR_TYPES.AUTH_OTP,
    [EMAIL_FACTOR_ID]: FACTOR_TYPES.EMAIL,
};

type Factor = AuthTransaction['factor'];

const getFactorType = <T extends Factor>(factor: T) => !!factor && (FACTOR_MAP as any)[`${factor.vendorName}_${factor.factorType}`];

const factorExistPredicate = <T extends Factor>(factor: T): factor is NonNullable<T> => !!getFactorType(factor);

const filterFactors = <T extends Factor>(factors: T[]) => factors.filter(factorExistPredicate);

const filterMapFactors = <T extends Factor, K extends object>(
    factors: T[],
    mapFunction: (factor: NonNullable<Factor>) => K,
) => filterFactors(factors)
    ?.reduce<Record<FACTOR_TYPES, K>>((acc, factor) => ({
        ...acc,
        [getFactorType(factor)]: mapFunction(factor),
    }), {} as Record<FACTOR_TYPES, K>);

class OktaAuthenticator implements Authenticator {
    private okta: OktaAuth;

    private scopes: string[];

    private currentState: AuthState;

    // TODO remove `scopes` when upgrading okta-auth-js to 4.1.X
    // in 4.0.X, `scopes` must be provided directly to the `OktaAuth.token.getXXX`
    // but in 4.1.X, `scopes` is propagated by `OktaAuth` from its `options`
    constructor(okta: OktaAuth, scopes: string[]) {
        this.okta = okta;
        this.scopes = scopes;
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.token = this.token.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.state = this.state.bind(this);
        this.checkState = this.checkState.bind(this);
        this.cancel = this.cancel.bind(this);
        this.resend = this.resend.bind(this);
        this.activate = this.activate.bind(this);
        this.challengeStepVerify = this.challengeStepVerify.bind(this);
        this.requireStepVerify = this.requireStepVerify.bind(this);
        this.enroll = this.enroll.bind(this);
        this.previous = this.previous.bind(this);
        this.resumeRecovery = this.resumeRecovery.bind(this);

        this.currentState = {
            status: 'UNAUTHENTICATED',
            login: this.login,
            forgotPassword: this.forgotPassword,
            resumeRecovery: this.resumeRecovery,
        };
    }


    async checkState() {
        const wasAuth = this.currentState.status === 'AUTHENTICATED';
        try {
            debugLog('checkUser:getTokens');
            const idToken = await this.okta.tokenManager.get('idToken');
            const accessToken = await this.okta.tokenManager.get('token');

            const { idToken: it } = await this.validateTokens(idToken, accessToken);

            debugLog('checkUser:successIdToken', it);

            this.currentState = {
                status: 'AUTHENTICATED',
                logout: this.logout,
                token: this.token,
                user: it.claims,
            };
        } catch (e) {
            await this.okta.tokenManager.clear();
            try {
                const it = await this.loginWithSession();

                this.currentState = {
                    status: 'AUTHENTICATED',
                    logout: this.logout,
                    token: this.token,
                    user: it.claims,
                };
            } catch (_) {
                this.currentState = {
                    status: 'UNAUTHENTICATED',
                    login: this.login,
                    forgotPassword: this.forgotPassword,
                    resumeRecovery: this.resumeRecovery,
                };
                if (wasAuth) {
                    throw new Error('Your session has ended');
                }
            }
        }
    }

    state() { return this.currentState; }

    private async validateTokens(idToken: Token | undefined, accessToken: Token | undefined) {
        if (!accessToken || !idToken) {
            debugLog('login:error', 'missing tokens');
            throw new Error('missing tokens');
        }
        if (!isAccessToken(accessToken) || !isIDToken(idToken)) {
            debugLog('login:error', 'invalid tokens');
            throw new Error('invalid tokens');
        }
        if (!idToken.claims) {
            debugLog('login:error', 'emptyIdToken');
            throw new Error('no id');
        }

        debugLog('login:fetchUserInfo');
        const claims = await this.okta.token.getUserInfo(accessToken, idToken);
        if (claims.sub !== idToken.claims.sub) {
            debugLog('login:error', 'mismatched tokens');
            throw new Error('mismatch between id token and access token');
        }

        return { idToken, accessToken };
    }

    private async validateAndSaveTokens(idToken: Token | undefined, accessToken: Token | undefined) {
        const { idToken: it, accessToken: at } = await this.validateTokens(idToken, accessToken);
        debugLog('login:saveTokens');
        await Promise.all([
            this.okta.tokenManager.add('idToken', it),
            this.okta.tokenManager.add('token', at),
        ]);

        debugLog('login:success');
        return it;
    }

    private async loginWithSession(sessionToken?: string) {
        debugLog('login:promptTokensWithSession', sessionToken);
        const [idToken, token] = extractTokens(await this.okta.token.getWithoutPrompt({
            sessionToken,
            responseType: ['id_token', 'token'],
            scopes: this.scopes,
        }));

        return this.validateAndSaveTokens(idToken, token);
    }

    private async login(username: string, password: string) {
        const res = await this.okta.signIn({ username, password });

        // workaround to avoid having to enter the oldPassword again
        if (res.status === 'PASSWORD_WARN' || res.status === 'PASSWORD_EXPIRED') {
            (res as any).oldPassword = password;
        }

        await this.transition(res);
    }

    private async forgotPassword(username: string) {
        // TODO: fix to use either otp or email
        const res = await this.okta.forgotPassword({ username, factorType: 'EMAIL' });
        await this.transition(res);
    }

    private async resumeRecovery(token: string) {
        const res = await this.okta.verifyRecoveryToken({ recoveryToken: token });
        await this.transition(res);
    }

    private async logout() {
        await this.okta.signOut();
        this.currentState = {
            status: 'UNAUTHENTICATED',
            login: this.login,
            forgotPassword: this.forgotPassword,
            resumeRecovery: this.resumeRecovery,
        };
    }

    private async token() {
        debugLog('token:getAccessToken');
        const currentToken = await this.okta.tokenManager.get('token');
        if (!isAccessToken(currentToken)) {
            debugLog('token:error', 'missing token');
            throw new Error('no token');
        }

        debugLog('token:success');
        return currentToken;
    }

    private activate(activate: (params: unknown) => Promise<AuthTransaction>) {
        return async (param: unknown) => {
            const res = await activate({ passCode: param });
            await this.transition(res);
        };
    }

    private enroll(enroll: () => Promise<AuthTransaction>) {
        return async () => {
            const res = await enroll();
            await this.transition(res);
        };
    }

    private requireStepVerify(verify: (p: any) => Promise<AuthTransaction>) {
        return async () => {
            const res = await verify({ passCode: '' });
            await this.transition(res);
        };
    }

    private challengeStepVerify(verify: (p: any) => Promise<AuthTransaction>) {
        return async (params: unknown) => {
            if (typeof params !== 'string') {
                throw new Error('passCode format error');
            }
            const res = await verify({ passCode: params });
            await this.transition(res);
        };
    }

    private cancel(cancel?: () => Promise<AuthTransaction>) {
        return async () => {
            if (!cancel) {
                throw new Error('impossible to cancel');
            }
            const res = await cancel();
            await this.transition(res);
        };
    }

    private resend(resend?: () => Promise<AuthTransaction>) {
        if (!resend) {
            return undefined;
        }

        return async () => {
            const res = await resend();
            await this.transition(res);
        };
    }

    private previous(previous?: () => Promise<AuthTransaction>) {
        return async () => {
            if (!previous) {
                throw new Error('impossible to go back');
            }
            const res = await previous();
            await this.transition(res);
        };
    }

    private changePassword(changePassword: (params: unknown) => Promise<AuthTransaction>, oldPassword?: string) {
        return async (password: string) => {
            const param = oldPassword ? { newPassword: password, oldPassword } : { newPassword: password };
            const res = await changePassword(param);
            await this.transition(res);
        };
    }

    private skip(skip?: () => Promise<AuthTransaction>) {
        if (!skip) {
            return undefined;
        }

        return async () => {
            const res = await skip();
            await this.transition(res);
        };
    }

    private async transition(res: AuthTransaction) {
        if (res.status === 'SUCCESS') {
            const { sessionToken } = res;

            const idToken = await this.loginWithSession(sessionToken!);

            this.currentState = {
                status: 'AUTHENTICATED',
                user: idToken.claims,
                token: this.token,
                logout: this.logout,
            };
        } else if (res.status === 'MFA_REQUIRED') {
            const { factors, cancel } = res;

            if (filterFactors(factors!).length === 0) {
                throw new Error('no 2FA available');
            }

            this.currentState = {
                status: 'FACTOR_REQUIRED',
                cancel: this.cancel(cancel),
                factors: filterMapFactors(factors!, ({ verify }) => ({
                    verify: this.requireStepVerify(verify),
                })),
            };
        } else if (res.status === 'MFA_CHALLENGE') {
            const {
                cancel, prev, verify, factor,
            } = res as any;

            if (!factorExistPredicate(factor)) {
                throw new Error('factor not handled');
            }

            this.currentState = {
                status: 'FACTOR_CHALLENGE',
                cancel: this.cancel(cancel),
                previous: this.previous(prev),
                verify: this.challengeStepVerify(verify),
                factorType: getFactorType(factor),
            };
        } else if (res.status === 'PASSWORD_RESET') {
            const { resetPassword, cancel } = res as any;

            this.currentState = {
                status: 'PASSWORD_EXPIRATION',
                changePassword: this.changePassword(resetPassword),
                cancel: this.cancel(cancel),
            };
        } else if (res.status === 'PASSWORD_WARN' || res.status === 'PASSWORD_EXPIRED') {
            const {
                oldPassword, changePassword, cancel, skip,
            } = res as any;

            this.currentState = {
                status: 'PASSWORD_EXPIRATION',
                changePassword: this.changePassword(changePassword, oldPassword),
                cancel: this.cancel(cancel),
                skip: this.skip(skip),
            };
        } else if (res.status === 'RECOVERY_CHALLENGE') {
            const { cancel } = res;

            this.currentState = {
                status: 'RECOVERY_CHALLENGE',
                resumeRecovery: this.resumeRecovery,
                cancel: this.cancel(cancel),
            };
        } else if (res.status === 'MFA_ENROLL') {
            const { cancel, factors } = res;

            this.currentState = {
                status: 'FACTOR_ENROLL',
                factors: filterMapFactors(factors!, ({
                    enrollment, enroll,
                }) => ({
                    required: enrollment === 'REQUIRED',
                    enroll: this.enroll(enroll),
                })),
                cancel: this.cancel(cancel),
            };
        } else if (res.status === 'MFA_ENROLL_ACTIVATE') {
            const {
                cancel, activate, resend, prev, data,
            } = res as any;

            const rawFactor = data?._embedded?.factor as NonNullable<Factor>;

            if (!factorExistPredicate(rawFactor)) {
                throw new Error('Factor not handled');
            }

            const factorType = getFactorType(rawFactor);

            let factor: FactorActivate = { type: factorType };
            if (factorType === FACTOR_TYPES.AUTH_OTP) {
                factor = {
                    ...factor,
                    sharedSecret: rawFactor?._embedded?.activation?.sharedSecret,
                    qrCode: rawFactor?._embedded?.activation?._links?.qrcode,
                };
            }

            this.currentState = {
                status: 'FACTOR_ACTIVATE',
                activate: this.activate(activate),
                previous: this.previous(prev),
                resend: this.resend(resend),
                cancel: this.cancel(cancel),
                factor,
            };
        } else {
            this.currentState = {
                status: 'UNAUTHENTICATED',
                forgotPassword: this.forgotPassword,
                login: this.login,
                resumeRecovery: this.resumeRecovery,
            };

            if (!(
                // when canceling, res == {}
                deepEqual(res, {})
                // and sometimes it's weird
                // like when canceling the password reset during a password recovery
                || deepEqual(res, { data: { _embedded: {} } })
            )) {
                throw new Error('invalid auth state');
            }
        }
    }
}

// TODO: id token and access token may be out of sync
export const newOkta = (options: OktaOptions): Authenticator => {
    const opts = normalizeOptions(options);
    const auth = new OktaAuth(opts);
    return new OktaAuthenticator(auth, opts.scopes || []);
};
