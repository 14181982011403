import { fromJS } from 'immutable';
import { Locale } from '@gears/translations';
import { ApplicationState, ApplicationActionTypes, SET_LOCALE } from './application-types';

export const initialState: ApplicationState = fromJS(
    { locale: Locale.en },
);

export const applicationReducer = (
    state = initialState,
    action: ApplicationActionTypes,
): ApplicationState => {
    switch (action.type) {
    case SET_LOCALE:
        return state.set('locale', action.locale);
    default:
        return state;
    }
};
