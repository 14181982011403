/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import {
    Textarea, FileFormats,

    SelectInput, SelectInputOption, NumberInput, LocationPickerInput, PhoneInput,
} from '@shift/design-system';
import { Locale } from '@gears/translations';

import { formSections } from 'src/config/form';
import { SubHeaderForm } from 'src/components/SubHeader/SubHeaderForm';
import {
    formTranslate,
    countriesOrStates,
    languages,
    isNoneEnum,
} from 'src/utils/forms';
import {
    now, maxTextAreaCharacterLength, companyRegistrationNumberEnum, sgCountryCode,
    isMyInfoInitiated,
} from 'src/constants';
import { AnyType, DocumentCategory } from 'src/types';
import { usePoliceStationsList, useEnumOptions } from 'src/utils/hooks';
import {
    FormField, FormSection, ConditionalFormField,
    YesNoRadio, DateInput, FilesUpload, EnumSelectInput, ExpandableField,
} from 'src/components/form';
import { ForeignVehicleItem } from '../array-items';
import { runtimeConfig } from '../../../../../config/runtime-config';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import { TimePicker } from '../../../../../components/form/fields/time-input';

const { googleMapClientId, googleMapApiKey } = runtimeConfig();
export const AccidentSection = ({ initialValue }: AnyType) => {
    const policeStationsList = usePoliceStationsList();
    const { name, title, fields } = formSections.accidentSection;
    const { options: idTypes } = useEnumOptions('NationalIdentifierType');
    const filteredIdTypes = useMemo(() => idTypes.filter((idType) => idType.value !== companyRegistrationNumberEnum), [idTypes]);
    const isMyInfoInitiatedStatus = isMyInfoInitiated(initialValue?.generalInformation?.accidentInformation?.status?.Name);


    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.claimant}
                        component={EnumSelectInput}
                        enumName="Claimant"
                        disabled={isMyInfoInitiatedStatus}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.reportPurpose}
                        component={EnumSelectInput}
                        enumName="ReportPurpose"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.areaOrState}
                        component={SelectInput}
                        options={countriesOrStates}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.date}
                        component={DateInput}
                        maxDate={now}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.time}
                        component={TimePicker}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.location}
                        component={LocationPickerInput}
                        countryRestriction={['SG', 'MY', 'TH']}
                        // Singapore lat/lng
                        defaultLatLng={{
                            lat: 1.34992,
                            lng: 103.8343295,
                        }}
                        googleMapApiClientId={googleMapClientId}
                        googleMapApiKey={googleMapApiKey}
                        locale={Locale.enSG}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.locationAdditionalInformation}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.type}
                        component={EnumSelectInput}
                        enumName="AccidentType"
                        hasNumberValue
                        sort
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.weatherCondition}
                        component={EnumSelectInput}
                        enumName="WeatherCondition"
                    />
                </div>
                <ConditionalFormField
                    sourceField={fields.weatherCondition}
                    targetFields={[fields.weatherConditionDescription]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.weatherConditionDescription}
                            component={TextInput}
                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.roadSurface}
                        component={EnumSelectInput}
                        enumName="RoadSurface"
                    />
                </div>
                <ConditionalFormField
                    sourceField={fields.roadSurface}
                    targetFields={[fields.roadSurfaceDescription]}
                    condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.roadSurfaceDescription}
                            component={TextInput}
                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.description}
                        component={Textarea}
                        maxCharacterLength={maxTextAreaCharacterLength}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.foreignVehicleInvolved}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <Field name={fields.foreignVehicleInvolved.path} subscription={{ value: true }}>
                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                    <ExpandableField
                        fieldInfo={fields.foreignVehicles}
                        itemTitle="VEHICLE"
                        addItemLabel="add-other-vehicle"
                        Item={ForeignVehicleItem}
                        initialItemsCount={input.value ? 1 : 0}
                        enableAdd={!!input.value}
                    />
                )}
            </Field>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.vehicleCount}
                        component={NumberInput}
                        min={1}
                    />
                    <sub>Including own vehicle</sub>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.approachedByAssistance}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.reportedToPolice}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <ConditionalFormField
                sourceField={fields.reportedToPolice}
                targetFields={[fields.policeStation, fields.policeStationName, fields.policeReport]}
            >
                <>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.policeStation}
                                component={EnumSelectInput}
                                options={policeStationsList?.options}
                            />
                        </div>
                        <Field name={fields.policeStation.path} subscription={{ value: true }}>
                            {({ input }: FieldRenderProps<any, HTMLElement>) => {
                                const contactInfo = input?.value?.contactInformation as AnyType;
                                return (contactInfo) ? (
                                    <div className="col-xs-6 form-group" style={{ marginTop: '15px' }}>
                                        {`Address: ${contactInfo.address ? contactInfo.address : ''}`}
                                        <br />
                                        {`Phone: ${contactInfo.phone ? contactInfo.phone : ''}`}
                                        <br />
                                        {`Fax: ${contactInfo.fax ? contactInfo.fax : ''}`}
                                        <br />
                                    </div>
                                ) : null;
                            }}
                        </Field>
                        <ConditionalFormField
                            sourceField={fields.policeStation}
                            targetFields={[fields.policeStationName]}
                            condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                        >
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.policeStationName}
                                    component={TextInput}
                                />
                            </div>
                        </ConditionalFormField>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 form-group">
                            <SubHeaderForm text={formTranslate('police-report')} />
                            <FormField
                                fieldInfo={fields.policeReport}
                                component={FilesUpload}
                                addText={formTranslate('police-report')}
                                displayLabel={false}
                                maxFileSize={50}
                                acceptedFormats={[FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF]}
                                documentCategory={DocumentCategory.PoliceTranscript}
                                isMulti
                            />
                        </div>
                    </div>
                </>
            </ConditionalFormField>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.noticeOfProsecutionGiven}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <div className="row">
                <ConditionalFormField
                    sourceField={fields.noticeOfProsecutionGiven}
                    targetFields={[fields.prosecutedName]}
                >
                    <div className="col-xs-6 form-group">
                        <FormField
                            fieldInfo={fields.prosecutedName}
                            component={TextInput}
                        />
                    </div>
                </ConditionalFormField>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.isTranslatedReport}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <ConditionalFormField
                sourceField={fields.isTranslatedReport}
                targetFields={[
                    fields.translatorName, fields.translatorTypeOfId, fields.translatorId,
                    fields.translatorMobilePhone, fields.translatorEmail,
                ]}
            >
                <>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.translatorName}
                                component={TextInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.translatorTypeOfId}
                                component={EnumSelectInput}
                                options={filteredIdTypes}
                            />
                        </div>
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.translatorId}
                                component={TextInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.translatorMobilePhone}
                                component={PhoneInput}
                                defaultValue={sgCountryCode}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                // tooltip={translate('accident-report-form:email-hint')}
                                fieldInfo={fields.translatorEmail}
                                component={TextInput}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 form-group">
                            <SubHeaderForm text={formTranslate('original-report')} />
                            <FormField
                                fieldInfo={fields.originalReport}
                                component={FilesUpload}
                                addText={formTranslate('original-report')}
                                displayLabel={false}
                                maxFileSize={50}
                                acceptedFormats={[
                                    FileFormats.PDF, FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF,
                                ]}
                                documentCategory={DocumentCategory.UnClassified}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6 form-group">
                            <FormField
                                fieldInfo={fields.originalLanguage}
                                component={SelectInput}
                                options={languages}
                            />
                        </div>
                        <ConditionalFormField
                            sourceField={fields.originalLanguage}
                            targetFields={[fields.originalLanguageOthers]}
                            condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                        >
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.originalLanguageOthers}
                                    component={TextInput}
                                />
                            </div>
                        </ConditionalFormField>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 form-group">
                            <SubHeaderForm text={formTranslate('translated-report')} />
                            <FormField
                                fieldInfo={fields.translatedReport}
                                component={FilesUpload}
                                addText={formTranslate('translated-report')}
                                displayLabel={false}
                                maxFileSize={50}
                                acceptedFormats={[
                                    FileFormats.PDF, FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF,
                                ]}
                                documentCategory={DocumentCategory.UnClassified}
                            />
                        </div>
                    </div>
                </>
            </ConditionalFormField>
        </FormSection>
    );
};
