import React, { ReactElement } from 'react';
import { Widgetter } from '@shift/transform';
import { FilterProps, Filter } from '../../../libs/filters';
import { TextFilter, TextFilterProps } from '../../../components/Table/Filters';

export const TextFilterWidgetterFactory:
    (side?: TextFilterProps['side']) => Widgetter<FilterProps, FilterProps, Filter> = (side) => ({
        transform: ({ children }, options) => (options ? (
            <TextFilter
                side={side}
                field={options.field}
                operation={options.operation}
            >
                {children}
            </TextFilter>
        ) : children as ReactElement),
    });

export const TextFilterWidgetter = TextFilterWidgetterFactory();
