import React from 'react';
import {
    GridItem, Grid,
} from '@shift/design-system';
import { Widgetter } from '@shift/transform';
import { BillableLinkAction } from '../../containers/billableAction/BillableLinkAction';
import { BILLABLE_ACTIONS } from '../../containers/billableAction/BillableAction';
import { TokenPredicate } from '../permissions';
import { billReportPurchaseMutation } from '../../graphql/mutation-builder';
import { Secure } from '../../libs/auth';
import { translate } from '../../utils/forms';


const LinksWidget = ({ children }: LinksBillableActionWidgetterProps) => (
    <>
        {children.map(({
            reportId, paidAccess, content, links, actions, permissions,
            isAllowed,
        }, idx) => {
            const [arDetailContent, explorationContent] = content;
            const [arDetailLink, explorationLink] = links;
            const [arDetailAction, explorationAction] = actions;
            const [arPurchasePermissions, explorationPermissions] = permissions;
            const { location } = window;
            const currentPath = `${location.pathname}${location.search}`;
            if (!isAllowed) {
                return null;
            }
            return (
                <Grid
                    alignPlacement="center"
                    justifyPlacement="center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                >
                    <GridItem>
                        {
                            arDetailLink ? (
                                <Secure
                                    fallback={<>{arDetailContent}</>}
                                    permissions={arPurchasePermissions}
                                >
                                    <BillableLinkAction
                                        paidAccess={paidAccess === true}
                                        href={arDetailLink}
                                        action={arDetailAction}
                                        i18nModal={
                                            {
                                                content: paidAccess === true ? (
                                                    <>
                                                        <p>
                                                            {translate('billable-actions:ncd-modal-desc')}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                {translate('billable-actions:ncd-modal-dec-tp-report-investigation')}
                                                            </strong>
                                                        </p>
                                                    </>
                                                ) : translate('billable-actions:ncd-modal-desc'),
                                            }
                                        }
                                        request={{
                                            cleanCache: true,
                                            mutationQuery: billReportPurchaseMutation,
                                            variables: {
                                                id: reportId,
                                            },
                                        }}
                                    >
                                        {arDetailContent}
                                    </BillableLinkAction>
                                </Secure>
                            ) : arDetailContent
                        }
                    </GridItem>
                    <GridItem
                        style={{ paddingLeft: '2px' }}
                    >
                        {
                            explorationLink
                                // we are already searching for the content
                                // no need to display a link
                                && currentPath !== explorationLink
                                ? (
                                    <Secure
                                        fallback={<>{explorationContent}</>}
                                        permissions={explorationPermissions}
                                    >
                                        <BillableLinkAction
                                            paidAccess={false}
                                            href={explorationLink}
                                            action={explorationAction}
                                        >
                                            {explorationContent}
                                        </BillableLinkAction>
                                    </Secure>
                                ) : explorationContent
                        }
                    </GridItem>
                </Grid>
            );
        })}
    </>
);

export interface LinksBillableActionWidgetterProps {
    children: {
        content: (string | undefined)[];
        links: (string | undefined)[];
        paidAccess: boolean | undefined;
        actions: (keyof typeof BILLABLE_ACTIONS)[];
        reportId?: number;
        permissions?: [TokenPredicate, TokenPredicate];
        skipConfirmation?: boolean;
        isAllowed?: boolean;
    }[];
}

export const LinksBillableActionWidgetter: Widgetter<LinksBillableActionWidgetterProps> = {
    // eslint-disable-next-line react/jsx-props-no-spreading
    transform: (props) => <LinksWidget {...props} />,
};
