import React, { useState } from 'react';

import {
    SearchFilterProps, BaseFilterProps,
    SearchFilter, DatePicker, enGB,
} from '@shift/design-system';
import moment from 'moment-timezone';
import { translate } from '../../../utils/forms';
import { toDateTimeWithoutTz } from '../../../utils';

export type DateRangeValue = {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
}
export type DateInputFilterProps = BaseFilterProps<DateRangeValue> & SearchFilterProps<DateRangeValue>

type StepEnum = 'startDate' | 'endDate';

const getStep = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (startDate && !endDate) {
        return 'endDate';
    }
    if (!startDate && endDate) {
        return 'startDate';
    }
    return undefined;
};


export const DateInputFilter = ({
    filter,
    onFilterChange,
    ...props
}: DateInputFilterProps) => {
    const [{ startDate, endDate }, updateDates] = useState({
        startDate: filter?.startDate ? toDateTimeWithoutTz(filter?.startDate) : undefined,
        endDate: filter?.endDate ? toDateTimeWithoutTz(filter?.endDate) : undefined,
    });
    const [focus, setFocus] = useState<StepEnum | undefined>(getStep(startDate, endDate));

    const onChangeStart = (date: Date | undefined) => {
        if (onFilterChange) {
            onFilterChange({ startDate: date ? moment(date).startOf('day') : null, endDate: endDate ? moment(endDate).endOf('day') : null });
            setFocus(getStep(date, endDate));
            updateDates({ startDate: date, endDate });
        }
    };

    const onChangeEnd = (date: Date | undefined) => {
        if (onFilterChange) {
            onFilterChange({ endDate: date ? moment(date).endOf('day') : null, startDate: startDate ? moment(startDate).startOf('day') : null });
            setFocus(getStep(startDate, date));
            updateDates({ endDate: date, startDate });
        }
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SearchFilter {...props}>
            <div className="date-input-filter">
                <DatePicker
                    placeholder={translate('common:start-date')}
                    locale={enGB}
                    name="start-date"
                    value={startDate}
                    onChange={onChangeStart}
                    isOpen={focus === 'startDate' ? true : undefined}
                    max={endDate}
                />
                <DatePicker
                    placeholder={translate('common:end-date')}
                    locale={enGB}
                    name="end-date"
                    value={endDate}
                    onChange={onChangeEnd}
                    isOpen={focus === 'endDate' ? true : undefined}
                    min={startDate}
                />
            </div>
        </SearchFilter>
    );
};
