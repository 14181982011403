import { Formatter } from '@shift/transform';

export function MultiFormatterFactory<T, U>(formatter: Formatter<T[], U>, skipEmptyValues?: boolean): Formatter<T[], U[]> {
    return {
        transform: (params: T[][]) => {
            const result = [];
            for (let i = 0; i < params[0].length; i += 1) {
                const value = formatter.transform(params.map((arr) => [arr[i]]));
                if (skipEmptyValues) {
                    if (value !== null && value !== undefined) {
                        result.push(value);
                    }
                } else {
                    result.push(value);
                }
            }
            return result;
        },
    };
}
