import { GearsConfig } from '../../types';
import { accidentReportsConfig } from './accidentReportsConfig-GIA';
import { explorationConfig } from './explorationConfig-GIA';
import { recoveryConfig } from './recoveryConfig-GIA';
import { ncdConfig } from './ncdConfig-GIA';
import { insurerEnquiryConfig } from './insurerEnquiryConfig-GIA';
import { arcConfig } from './arcConfig-GIA';
import { folderConfig } from './folderConfig-GIA';
import { thirdPartyReportConfig } from './thirdPartyReportConfig-GIA';

export const gearsConfig: GearsConfig = {
    accidentReport: accidentReportsConfig,
    recovery: recoveryConfig,
    exploration: explorationConfig,
    ncd: ncdConfig,
    insurerEnquiry: insurerEnquiryConfig,
    arc: arcConfig,
    folder: folderConfig,
    thirdPartyReport: thirdPartyReportConfig,
    tests: {},
};
