import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Field, FormSpy, useField } from 'react-final-form';
import { formSections } from 'src/config/form';
import { formTranslate, isSectionValid } from 'src/utils/forms';
import { AnyType } from 'src/types';
import { StyledText } from '@shift/design-system';

const scrollTo = (name: string) => {
    window.scrollTo(0, (document.getElementById(name)?.offsetTop ?? 0) + 40);
};

const DriverPolicyInfos = styled(StyledText)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export interface FormSideBarProps {
    className?: string;
    sections: AnyType;
}

export const FormSideBar = ({ sections, className = '' }: FormSideBarProps) => {
    const [selectedSection, setSelectedSection] = useState();
    const { input: isDriverPolicyHolder } = useField(formSections.driverSection.fields.isDriverPolicyHolder.path, { subscription: { value: true } });

    useEffect(() => {
        const handleScroll = () => {
            const array = Object.values(sections);
            let sectionName = array[0].name;
            array.forEach((section) => {
                const element = document.getElementById(section.name);
                const elementRect = element?.getBoundingClientRect();

                if ((elementRect?.top ?? 0) < 80) {
                    sectionName = section.name;
                }
            });
            setSelectedSection(sectionName);
        };

        setSelectedSection(sections[Object.keys(sections)[0]].name);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [sections]);

    return (
        <div className={className}>
            <div className="user-summary">
                <Field
                    name={isDriverPolicyHolder.value ? formSections.ownVehiclePolicySection.fields.registeredOwnerName.path
                        : formSections.driverSection.fields.name.path}
                    subscription={{ value: true }}
                    render={({ input: { value } }) => (<DriverPolicyInfos weight="bold">{value}</DriverPolicyInfos>)}
                />
                <Field
                    name={isDriverPolicyHolder.value ? formSections.ownVehiclePolicySection.fields.primaryMobilePhone.path
                        : formSections.driverSection.fields.primaryMobilePhone.path}
                    subscription={{ value: true }}
                    render={({ input: { value } }) => {
                        if (!value?.dialCode || !value?.number) {
                            return null;
                        }

                        const fullNumber = `+${value.dialCode} ${value.number}`;
                        return (<div><a href={`tel:${fullNumber}`}>{fullNumber}</a></div>);
                    }}
                />
                <Field
                    name={isDriverPolicyHolder.value ? formSections.ownVehiclePolicySection.fields.email.path
                        : formSections.driverSection.fields.email.path}
                    subscription={{ value: true }}
                    render={({ input: { value } }) => (<DriverPolicyInfos><a href={`mailto:${value}`} title={value}>{value}</a></DriverPolicyInfos>)}
                />
            </div>
            <FormSpy
                subscription={{ errors: true }}
                render={({ errors }) => (
                    <div>
                        <div className="navigation">{formTranslate('navigation')}</div>
                        {Object.values(sections).map((section) => {
                            let computedClassNames;
                            if (section.useDifferentSectionIcon) {
                                computedClassNames = 'fas fa-exclamation-triangle';
                            } else {
                                computedClassNames = isSectionValid(errors, section.name) ? 'fas fa-check-circle' : 'far fa-circle';
                            }
                            return (
                                <li key={section.name}>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => scrollTo(section.name)}
                                        onKeyDown={() => scrollTo(section.name)}
                                        className={classNames({ selected: selectedSection === section.name })}
                                    >
                                        <span className={classNames(computedClassNames)} />
                                        {formTranslate(section.title)}
                                    </div>
                                </li>
                            );
                        })}
                    </div>
                )}
            />
        </div>
    );
};
