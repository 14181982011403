import {
    validateListIfCondition,
} from 'src/utils/forms';
import { BackendListFormatter, EnumFormatter, PhoneNumberFormatter } from 'src/utils/forms/formatters';

export const injuredSection = {
    name: 'injured',
    title: 'injured-persons',
    fields: {
        anyInjuredPersons: {
            path: 'injured.anyInjuredPersons',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyInjuredPersons',
            label: 'any-injured-person',
            isRequired: true,
        },
        anyInjuredTookAmbulance: {
            path: 'injured.anyInjuredTookAmbulance',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyInjuredPersonConveyedByAmbulance',
            label: 'any-injured-took-ambulance',
            isRequired: true,
        },
        injuredPersons: {
            path: 'injured.persons',
            target: 'injuredPersons',
            label: 'injured-persons',
            item: {
                name: {
                    path: 'person.specializations.mapping_Person_Gears.name',
                    label: 'injured-name',
                },
                gender: {
                    path: 'person.specializations.mapping_Person_Individual.gender',
                    label: 'gender',
                    formatter: EnumFormatter,
                },
                primaryMobilePhone: {
                    path: 'primaryPhoneNumber',
                    label: 'phone-number',
                    target: {
                        number: 'primaryPhoneNumber.number',
                        countryCode: 'primaryPhoneNumber.countryCode',
                        phoneNumberType: 'primaryPhoneNumber.phoneNumberType',
                    },
                    formatter: PhoneNumberFormatter,
                },
                address1: {
                    path: 'address.fullAddress',
                    label: 'address-1',
                },
                address2: {
                    path: 'address.complement',
                    label: 'address-2',
                },
                postCode: {
                    path: 'address.postCode',
                    label: 'post-code',
                },
                age: {
                    path: 'approximateAge',
                    label: 'approximate-age',
                },
                injuriesDescription: {
                    path: 'damage.specializations.mapping_Damage_FreeText.0.freeTextDescription',
                    label: 'injury-description',
                },
                injuredVehicle: {
                    path: 'associatedVehicleId',
                    label: 'injured-vehicle',
                    formatter: BackendListFormatter,
                },
                hadSeatBeltOn: {
                    path: 'seatBeltWorn',
                    label: 'seat-belt-on',
                },
                tookAmbulance: {
                    path: 'injuredTookAmbulance',
                    label: 'injured-took-ambulance',
                },
            },
            validate: validateListIfCondition('injuredPersons.anyInjuredPersons'),
        },
    },
};
