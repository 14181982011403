import { PhoneNumberFormatter } from 'src/utils/forms/formatters';
import { isValidPhoneNumber, isValidEmail } from 'src/utils/forms';

export const witnessSection = {
    name: 'witness',
    target: 'witnesses',
    title: 'witnesses',
    fields: {
        anyWitnesses: {
            path: 'witness.anyWitnesses',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyWitness',
            label: 'any-witness',
            isRequired: true,
        },
        witnesses: {
            path: 'witness.persons',
            target: 'witnesses',
            label: 'witnesses',
            item: {
                name: {
                    path: 'person.specializations.mapping_Person_Gears.name',
                    label: 'witness-name',
                },
                phone: {
                    path: 'primaryPhoneNumber',
                    target: {
                        number: 'primaryPhoneNumber.number',
                        countryCode: 'primaryPhoneNumber.countryCode',
                        phoneNumberType: 'primaryPhoneNumber.phoneNumberType',
                    },
                    formatter: PhoneNumberFormatter,
                    label: 'witness-phone',
                    validate: isValidPhoneNumber,
                },
                email: {
                    path: 'email.address',
                    label: 'witness-email',
                    validate: isValidEmail,
                },
            },
        },
    },
};
