import React, { useMemo } from 'react';
import { FieldRenderProps, Field, useField } from 'react-final-form';
import { SelectInputOption } from '@shift/design-system';
import { useVehicleModelsList } from 'src/utils/hooks';
import { FormField, EnumSelectInput } from 'src/components/form';
import { SingleFieldInformation, VehicleModelSelectInputOption } from 'src/types';
import { isNoneEnum } from 'src/utils/forms';

const InternalField = ({
    fieldInfo, vehicleMake, vehicleModel, modelTextFieldPath, disabled, onChange,
}: {
    fieldInfo: SingleFieldInformation;
    vehicleMake: string;
    vehicleModel: VehicleModelSelectInputOption;
    modelTextFieldPath: string;
    disabled: boolean | undefined;
    onChange?: (value: SelectInputOption | undefined) => void;
}) => {
    const { input: modelText } = useField(modelTextFieldPath, { subscription: {} });
    const {
        options: vehicleModelList, loading, error, refetch,
    } = useVehicleModelsList(vehicleMake);
    const computedValue = useMemo(() => {
        let update = false;
        let model: SelectInputOption | undefined = vehicleModel;
        // This is triggered when retrieving from backend
        if (vehicleModelList && vehicleModelList?.length > 0 && vehicleModel.value && !vehicleModel.make) {
            model = vehicleModelList.find((m) => m.value === vehicleModel.value);
            // Set model to 'Other' if the value cannot be found in the list and update the description
            if (!model) {
                modelText.onChange(vehicleModel.value);
                model = vehicleModelList[vehicleModelList.length - 1];
            }
            update = true;
        } else if (!vehicleMake || vehicleModel.make !== vehicleMake) {
            // Reset model when make has been cleared or changed
            model = undefined;
            update = true;
        }
        // Set model to 'Other' automatically when make is 'Other'
        // This is trigerred when retrieving value from backend or ONCE after setting the make to 'Other'
        if (vehicleModelList && isNoneEnum(vehicleMake) && !vehicleModel.make) {
            model = vehicleModelList[vehicleModelList.length - 1];
            modelText.onChange(vehicleModel.value);
            update = true;
        }
        if (update && onChange) {
            onChange(model);
        }

        return model;
    }, [vehicleModelList, vehicleMake, vehicleModel, modelText, onChange]);

    return (
        <FormField
            fieldInfo={fieldInfo}
            component={EnumSelectInput}
            options={vehicleModelList}
            value={[computedValue]}
            loading={loading}
            error={Boolean(error)}
            refetch={refetch}
            disabled={isNoneEnum(vehicleMake) || disabled}
        />
    );
};

export interface VehicleModelFieldProps {
    manufacturerFieldInfo: SingleFieldInformation;
    modelFieldInfo: SingleFieldInformation;
    modelTextFieldPath: string;
    disabled: boolean | undefined;
}

export const VehicleModelField = ({
    manufacturerFieldInfo,
    modelFieldInfo,
    modelTextFieldPath,
    disabled,
}: VehicleModelFieldProps) => (
    <Field name={manufacturerFieldInfo.path} subscription={{ value: true }}>
        {({ input: make }: FieldRenderProps<SelectInputOption, HTMLElement>) => (
            <Field name={modelFieldInfo.path} subscription={{ value: true }}>
                {({ input: model }: FieldRenderProps<VehicleModelSelectInputOption, HTMLElement>) => (
                    <InternalField
                        fieldInfo={modelFieldInfo}
                        vehicleMake={make.value?.value as string}
                        vehicleModel={model.value}
                        onChange={model.onChange}
                        modelTextFieldPath={modelTextFieldPath}
                        disabled={disabled}
                    />
                )}
            </Field>
        )}
    </Field>
);
