/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import {
    CircularLoader, Grid, GridItem, Icon, ThemeContext,
} from '@shift/design-system';
import { InfoCircleSvg } from '@shift/icons';
import { translate } from '@gears/translations';
import { AnyType } from 'src/types';
import { DownloadType, useFetch } from 'src/libs/fetch';
import { inProgressObjectEnum, closedObjectEnum, isDraft as isDraftFn } from 'src/constants';
import { Formatters } from 'src/config/formatters';
import { Link } from 'react-router-dom';

const { PDFLinkFormatter } = Formatters;

export interface AccidentReportSubmittedProps {
    accidentReport: AnyType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const downloader = (dl: DownloadType, id: string, referenceCode: string, submitDate: number, versionNumber: number) => {
    // when submitting through the UI, the lastVersion is equal to both the submitDate and version so it's used twice here
    const { filename, url } = PDFLinkFormatter.transform([id, referenceCode, submitDate, versionNumber]);
    return dl(url, filename);
};

export const AccidentReportSubmitted = ({
    accidentReport,
}: AccidentReportSubmittedProps) => {
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const { colors } = useContext(ThemeContext);
    const { download: dl } = useFetch();
    const isDraft = isDraftFn(accidentReport?.status?.Name);
    const isAddendum = accidentReport?.status?.Name === 'Addendum';
    const isAR = (accidentReport?.status?.Name === inProgressObjectEnum.Name || accidentReport?.status?.Name === closedObjectEnum.Name);
    const lastVersionNumber = accidentReport?.versions?.[0]?.number;
    const lastSubmitDate = accidentReport?.versions?.[0]?.submissionDate;
    return (
        <div id="arc-reporting-submitted-page" className="row">
            <div className="col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                <h3>{translate('accident-report-submitted:thank-you')}</h3>
                <h3>
                    {isDraft && translate('accident-report-submitted:draft-saved')}
                    {isAddendum && translate('accident-report-submitted:addendum-submitted')}
                    {isAR && translate('accident-report-submitted:report-submitted')}
                </h3>
                <div className="uppercase">{translate('accident-report-submitted:report-number')}</div>
                <h2>
                    <a href={`/accident-reports/details/${accidentReport?.id}`}>{accidentReport?.referenceCode}</a>
                </h2>
                {isDraft && (
                    <div>
                        <Grid>
                            <GridItem style={{ paddingRight: '8px', marginTop: '4px' }}>
                                <Icon width={1} height={1}><InfoCircleSvg /></Icon>
                            </GridItem>
                            <GridItem>
                                {translate('accident-report-submitted:draft-saved-message')}
                            </GridItem>
                        </Grid>
                    </div>
                )}
                {isDraft && (
                    <Link to={`/accident-report-form/${accidentReport?.id}`} className="btn btn-default">
                        {translate('accident-report-submitted:resume-report')}
                    </Link>
                )}
                {isAR && (
                    <a
                        className="btn btn-default"
                        onClick={() => {
                            if (lastVersionNumber == null) {
                                return undefined;
                            }
                            setIsDownloadingPdf(true);
                            return downloader(dl, accidentReport?.id, accidentReport?.referenceCode, lastSubmitDate, lastVersionNumber)
                                .finally(() => setIsDownloadingPdf(false));
                        }}
                        download
                    >
                        <Grid alignPlacement="center">
                            <GridItem grow={1}>
                                {translate('accident-report-submitted:download-pdf')}
                            </GridItem>
                            {
                                isDownloadingPdf && (
                                    <GridItem>
                                        <CircularLoader color={colors.brand.primary.base} width="0.2rem" size="1rem" />
                                    </GridItem>
                                )
                            }
                        </Grid>
                    </a>
                )}
                <Link to="/accident-reports" className="btn btn-primary">{translate('accident-report-submitted:go-to-list')}</Link>
            </div>
        </div>
    );
};
