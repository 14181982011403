declare global {
    interface Window { config: any }
}

type IndexHTMLConfig = {
    clientId: string;
    authUri: string;
    redirectUri: string;
    resourceUri: string;
    projectName: string;
    releaseVersion: string;
    deploymentDate: string;
    analyticsCode: string;
    tpReportExternal: string;
    tpReportLegal: string;
    tpReportPurchasesGstRate: string;
    telemetryEnabled: string;
    telemetryUri: string;
    telemetryEnv: string;
    intercomId: string;
    myInfoClientId: string;
    myInfoRedirectUrl: string;
    myInfoPurpose: string;
    myInfoEnvironment: string;
    myInfoPersonUrl: string;
    myInfoAuthUrl: string;
};

export type RuntimeConfig = IndexHTMLConfig & {
    googleMapClientId: string;
    googleMapApiKey: string;
};

const getConfig = (): IndexHTMLConfig => {
    const { config } = window;
    if (!config || typeof config !== 'object') {
        if (process.env.NODE_ENV === 'test') { // in test mode, we don't need the config
            return {
                authUri: 'https://shift-technology.okta.com/',
            } as any;
        }
        throw new Error('invalid config');
    }
    const keys = [
        'clientId',
        'authUri',
        'redirectUri',
        'resourceUri',
        'projectName',
        'releaseVersion',
        'deploymentDate',
        'analyticsCode',
        'tpReportExternal',
        'tpReportLegal',
        'tpReportPurchasesGstRate',
        'telemetryEnabled',
        'telemetryUri',
        'telemetryEnv',
        'intercomId',
        'myInfoClientId',
        'myInfoRedirectUrl',
        'myInfoPurpose',
        'myInfoEnvironment',
        'myInfoPersonUrl',
    ];
    keys.forEach((k) => {
        if (!config[k] || config[k].startsWith('#{') || config[k].endsWith('}')) {
            throw new Error(`invalid config: key ${k}`);
        }
    });
    return config;
};

export const runtimeConfig = (): RuntimeConfig => ({
    ...getConfig(),
    googleMapClientId: 'gme-shifttechnology',
    googleMapApiKey: 'AIzaSyAGqQ2Jda3J-CZ5mjK0iY_2o5Zeomgk8HQ',
});
