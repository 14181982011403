import React, { useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { formSections } from 'src/config/form';
import {
    formTranslate,
} from 'src/utils/forms';
import {
    FormField, FormSection,
    YesNoRadio, ExpandableField,
} from 'src/components/form';
import { ThirdPartyItem } from '../array-items';

export const ThirdPartySection = () => {
    const { name, title, fields } = formSections.thirdPartySection;

    const [lastId, setLastId] = useState(-1);

    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={fields.anyThirdParties}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <Field name={fields.anyThirdParties.path} subscription={{ value: true }}>
                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                    <ExpandableField
                        fieldInfo={fields.vehiclesOrProperties}
                        itemTitle="third-party"
                        addItemLabel="add-third-party"
                        Item={ThirdPartyItem}
                        initialItemsCount={input.value ? 1 : 0}
                        enableAdd={!!input.value}
                        emptyElement={() => {
                            // Assign temporary id (negative) in order to track the third parties when adding/removing some.
                            const id = lastId - 1;
                            setLastId(id);
                            return { vehiclePolicy: { vehicle: { id } } };
                        }}
                    />
                )}
            </Field>
        </FormSection>
    );
};
