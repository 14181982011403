import { Formatter } from '@shift/transform';
import { concatenateUri } from 'src/utils';
import { DocumentLinkWidgetterProps } from '../widgets/DocumentLinkWidget';
import { apiConfig, apiEndPoint } from '..';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DocumentLinkFormatter: Formatter<any, DocumentLinkWidgetterProps> = {
    transform: (params) => {
        const [[id], [documentName], [scheme], [userInfo], [host], [port], [path], [query], [fragment]] = params;
        const isLink = !!scheme && !!path;
        return {
            url: isLink ? concatenateUri(scheme, path, userInfo, host, port, query, fragment) : `${apiConfig.documentUri}/${id}`,
            endPointUri: apiEndPoint.document,
            filename: documentName,
            isLink,
        };
    },
};
