import { IMyInfoState } from 'src/types/myInfo';
import { mapVehicle } from './mapVehicle';
import {
    mapIdentifierType,
    mapFullAddress,
    mapGender,
    mapDate,
    mapIsLicenceRevoked,
    mapDrivingLicenses,
    mapLicenseValidity,
} from './mappers';

interface IMapping {
    gearsPath: string;
    value: any;
    mappingFn?: Function;
    onlyIf?: boolean;
}

const isOnlyDriver = ({ isVehicleDriver, isVehicleOwner }: IMyInfoState) => isVehicleDriver && !isVehicleOwner;

const isBoth = ({ isVehicleDriver, isVehicleOwner }: IMyInfoState) => isVehicleDriver && isVehicleOwner;

const isDriver = ({ isVehicleDriver }: IMyInfoState) => isVehicleDriver;


const mapClaimant = (rs: IMyInfoState) => {
    const claimant = 'Claimant.';

    if (isBoth(rs)) return claimant.concat('Both');
    if (isOnlyDriver(rs)) return claimant.concat('Driver');
    return claimant.concat('Owner');
};

export const getMapping = (reportState: IMyInfoState): IMapping[] => [
    {
        gearsPath: 'ownVehicle.claimant',
        value: reportState,
        mappingFn: mapClaimant,
    },
    {
        gearsPath: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_VehicleRegistration',
        value: reportState.vehicleInfo?.vehicleno,
        mappingFn: (value: string) => [{ number: value }],
    },
    {
        gearsPath:
            'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle',
        value: reportState,
        mappingFn: mapVehicle,
    },
    {
        gearsPath:
            'ownVehicle.owner.person.specializations.mapping_Person_Gears.name',
        value: reportState.personalInfo?.name,
        onlyIf: reportState.isVehicleOwner,
    },
    {
        gearsPath:
            'ownVehicle.owner.person.specializations.[mapping_Person_NationalIdentifier].0.identifier',
        value: reportState.personalInfo?.ufin,
        onlyIf: reportState.isVehicleOwner,
        mappingFn: (value: string) => `NationalIdentifierType.${mapIdentifierType(value)}`,
    },
    {
        gearsPath:
            'ownVehicle.owner.person.specializations.[mapping_Person_NationalIdentifier].0.nationalIdentifier',
        value: reportState.personalInfo?.ufin,
        onlyIf: reportState.isVehicleOwner,
    },
    {
        gearsPath: 'ownVehicle.owner.email.address',
        value: reportState.contactInfo?.email,
        onlyIf: reportState.isVehicleOwner,
    },
    {
        gearsPath: 'ownVehicle.owner.primaryPhoneNumber.number',
        value: reportState.contactInfo?.phone?.number,
        onlyIf: reportState.isVehicleOwner,
    },
    {
        gearsPath: 'ownVehicle.owner.primaryPhoneNumber.countryCode',
        value: reportState.contactInfo?.phone?.dialCode,
        onlyIf: reportState.isVehicleOwner,
    },
    {
        gearsPath: 'ownVehicle.owner.primaryPhoneNumber.phoneNumberType',
        value: reportState.contactInfo?.phone?.number,
        onlyIf: reportState.isVehicleOwner,
        mappingFn: () => 'PhoneNumberType.Phone',
    },
    {
        gearsPath: 'ownVehicle.driverIsSameAsOwner',
        value: isBoth(reportState),
    },
    {
        gearsPath: 'ownVehicle.driver.address.fullAddress',
        value: reportState.regAdd,
        mappingFn: mapFullAddress,
        onlyIf: reportState.isVehicleDriver,
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.mapping_Person_Individual.gender',
        value: reportState.personalInfo?.sex,
        mappingFn: mapGender,
        onlyIf: reportState.isVehicleDriver,
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.mapping_Person_Individual.dateOfBirth',
        value: reportState.personalInfo?.dob,
        mappingFn: mapDate,
        onlyIf: reportState.isVehicleDriver,
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.[mapping_Person_DrivingLicenseInformation].0.drivingLicenseHasBeenRevoked',
        value: reportState.licenceInfo?.validity,
        mappingFn: mapIsLicenceRevoked,
        onlyIf: reportState.isVehicleDriver,
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.mapping_Person_Gears.name',
        value: reportState.personalInfo?.name,
        onlyIf: isOnlyDriver(reportState),
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.[mapping_Person_NationalIdentifier].0.identifier',
        value: reportState.personalInfo?.ufin,
        onlyIf: isOnlyDriver(reportState),
        mappingFn: (value: string) => `NationalIdentifierType.${mapIdentifierType(value)}`,
    },
    {
        gearsPath:
            'ownVehicle.driver.person.specializations.[mapping_Person_NationalIdentifier].0.nationalIdentifier',
        value: reportState.personalInfo?.ufin,
        onlyIf: isOnlyDriver(reportState),
    },
    {
        gearsPath: 'ownVehicle.driver.primaryPhoneNumber.number',
        value: reportState.contactInfo?.phone?.number,
        onlyIf: isOnlyDriver(reportState),
    },
    {
        gearsPath: 'ownVehicle.driver.primaryPhoneNumber.countryCode',
        value: reportState.contactInfo?.phone?.dialCode,
        onlyIf: isOnlyDriver(reportState),
    },
    {
        gearsPath: 'ownVehicle.driver.primaryPhoneNumber.phoneNumberType',
        value: reportState.contactInfo?.phone?.number,
        onlyIf: isOnlyDriver(reportState),
        mappingFn: () => 'PhoneNumberType.Phone',
    },
    {
        gearsPath: 'ownVehicle.driver.email.address',
        value: reportState.contactInfo?.email,
        onlyIf: isOnlyDriver(reportState),
    },
    {
        gearsPath: 'ownVehicle.driver.person.specializations.[mapping_Person_DrivingLicenseInformation]',
        value: reportState.licenceInfo,
        onlyIf: isDriver(reportState),
        mappingFn: mapDrivingLicenses,
    },
    {
        gearsPath: 'ownVehicle.driverLicence',
        value: reportState.licenceInfo,
        onlyIf: isDriver(reportState),
        mappingFn: mapLicenseValidity,
    },
];
