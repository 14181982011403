import { EnumFormatter, getOffsetDateFormatter } from 'src/utils/forms/formatters';
import { DateFormat } from 'src/constants';
import { parseOffsetDate } from 'src/utils';

export const confirmPayment = {
    singleSection: {
        name: 'confirmPayment',
        title: 'confirm-payment',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'nextAction.step.action',
            },
            amount: {
                path: 'amount',
                label: 'payment-amount',
            },
            paymentSentDate: {
                label: 'date-processed',
                path: 'processedPaymentDate',
                formatter: {
                    format: (value: string) => parseOffsetDate(value).format(DateFormat),
                },
            },
            paymentMode: {
                path: 'confirmPayment.paymentMode',
                target: 'paymentMode',
                label: 'payment-mode',
                formatter: EnumFormatter,
                isRequired: true,
            },
            sendPaymentDate: {
                label: 'date-processed',
                path: 'confirmPayment.processedDate',
                target: 'processedPaymentDate',
                formatter: getOffsetDateFormatter(null),
            },
            receivePaymentDate: {
                label: 'date-received',
                path: 'confirmPayment.receivedDate',
                target: 'paymentDate',
                formatter: getOffsetDateFormatter(null),
                isRequired: true,
            },
            comment: {
                path: 'confirmPayment.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
            commentType: {
                path: 'confirmPayment.commentType',
                target: 'comment.commentType',
                label: '',
            },
        },
    },
};
