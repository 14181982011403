/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import {
    Field, FieldRenderProps,
} from 'react-final-form';
import {
    SelectInput, SelectInputOption, PhoneInput,
} from '@shift/design-system';
import { formSections } from 'src/config/form';
import {
    formTranslate,
    getOccupationTypes,
    isOtherPersonRelation,
    getYearsFromAccident, translate,
    isNoneEnum,
    isValuePopulatedByMyinfo,
    availableDrivingLicenseInformation,
} from 'src/utils/forms';
import {
    sgCountryCode, companyRegistrationNumberEnum, DateFormat, isMyInfoInitiated,
} from 'src/constants';
import { useEnumOptions } from 'src/utils/hooks';
import {
    FormField, FormSection, YesNoRadio,
    ExpandableField, SplitDateInput, ConditionalFormField, EnumSelectInput,
} from 'src/components/form';
import { TableInfo } from 'src/components/form/details-table';
import { AnyType } from 'src/types';
import {
    OtherVehicleItem, GetPhoneItem,
} from '../array-items';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import { GetDrivingLicenseItem } from '../array-items/driving-license-item';

export const OwnDriverSection = ({ initialValue }: AnyType) => {
    const { name, title, fields } = formSections.driverSection;
    const { options: idTypes } = useEnumOptions('NationalIdentifierType');
    const filteredIdTypes = useMemo(() => idTypes.filter((idType) => idType.value !== companyRegistrationNumberEnum), [idTypes]);
    const PhoneItem = useMemo(() => GetPhoneItem(fields.altPhones.item), [fields]);
    const DrivingLicenseItem = useMemo(() => GetDrivingLicenseItem(fields.drivingPassInformation.item), [fields]);
    const isMyInfoInitiatedStatus = isMyInfoInitiated(initialValue?.generalInformation?.accidentInformation?.status?.Name);

    return (
        <FormSection title={formTranslate(title)} name={name}>
            <ConditionalFormField
                sourceField={fields.isDriverPolicyHolder}
                targetFields={[
                    fields.name,
                    fields.typeOfId,
                    fields.driverId,
                    fields.primaryMobilePhone,
                    fields.altPhones,
                    fields.email,
                    fields.driverOwnerRelation,
                    fields.driverOwnerRelationDescription,
                ]}
                condition={(value: boolean) => !value}
            >
                <Field
                    name={fields.primaryMobilePhone.path}
                    render={({ input: { value, onChange } }) => {
                        // some magic to set the country code when reseting the phone input...
                        if (!value) {
                            onChange({ countryCode: 'SG' });
                        }
                        return null;
                    }}
                />
            </ConditionalFormField>
            <Field name={fields.isDriverPolicyHolder.path} subscription={{ value: true }}>
                {({ input: isDriverSameAsOwner }: FieldRenderProps<boolean, HTMLElement>) => (
                    <>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                { !isMyInfoInitiatedStatus && (
                                    <FormField
                                        fieldInfo={fields.isDriverPolicyHolder}
                                        component={YesNoRadio}
                                        className="radio-option"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.name}
                                    component={TextInput}
                                    disabled={!!isDriverSameAsOwner.value || (isMyInfoInitiatedStatus
                                        && isValuePopulatedByMyinfo(fields.name.path, initialValue))}
                                />
                            </div>
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.gender}
                                    component={EnumSelectInput}
                                    enumName="Gender"
                                    disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.gender.path, initialValue)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.typeOfId}
                                    component={EnumSelectInput}
                                    options={filteredIdTypes}
                                    // eslint-disable-next-line max-len
                                    disabled={!!isDriverSameAsOwner.value || (isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.typeOfId.path, initialValue))}
                                />
                            </div>
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.driverId}
                                    component={TextInput}
                                    // eslint-disable-next-line max-len
                                    disabled={!!isDriverSameAsOwner.value || (isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.driverId.path, initialValue))}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <Field name={formSections.accidentSection.fields.date.path} subscription={{ value: true }}>
                                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                                    <>
                                        <div className="col-xs-6 form-group">
                                            <FormField
                                                fieldInfo={fields.birthDate}
                                                component={SplitDateInput}
                                                formatValue={getYearsFromAccident(
                                                    input.value ? moment(input.value, DateFormat).toDate() : undefined,
                                                    ' old',
                                                )}
                                                disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.birthDate.path, initialValue)}

                                            />
                                        </div>
                                        <div className="col-xs-12 form-group">
                                            {
                                                availableDrivingLicenseInformation(fields.drivingPassInformation.path, initialValue) && (
                                                    <TableInfo
                                                        fieldInfo={fields.drivingPassInformation}
                                                        itemTitle="DrivingLicense"
                                                        Item={DrivingLicenseItem}
                                                        label={fields.drivingPassInformation.label}
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="col-xs-6 form-group">
                                            <FormField
                                                fieldInfo={fields.passClass}
                                                component={EnumSelectInput}
                                                enumName="DrivingLicenseSourceType"
                                            />
                                        </div>
                                        <div className="col-xs-6 form-group">
                                            <FormField
                                                fieldInfo={fields.passDate}
                                                component={SplitDateInput}
                                                formatValue={getYearsFromAccident(
                                                    input.value ? moment(input.value, DateFormat).toDate() : undefined,
                                                )}
                                            />
                                        </div>
                                    </>
                                )}
                            </Field>
                        </div>
                        <div className="row">
                            <ConditionalFormField
                                sourceField={fields.passClass}
                                targetFields={[fields.passClassSourceName]}
                                condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                            >
                                <div className="col-xs-6 form-group">
                                    <FormField
                                        fieldInfo={fields.passClassSourceName}
                                        component={TextInput}
                                    />
                                </div>
                            </ConditionalFormField>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.drivingLicenseValidity}
                                    component={EnumSelectInput}
                                    enumName="DrivingLicenseValidityStatus"
                                    disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.drivingLicenseValidity.path, initialValue)}
                                />
                            </div>
                            <ConditionalFormField
                                sourceField={fields.drivingLicenseValidity}
                                targetFields={[fields.drivingLicenseValiditySourceName]}
                                condition={(enumValue: SelectInputOption) => isNoneEnum(enumValue.value)}
                            >
                                <div className="col-xs-6 form-group">
                                    <FormField
                                        fieldInfo={fields.drivingLicenseValiditySourceName}
                                        component={TextInput}
                                        disabled={isMyInfoInitiatedStatus
                                            && isValuePopulatedByMyinfo(fields.drivingLicenseValiditySourceName.path, initialValue)}
                                    />
                                </div>
                            </ConditionalFormField>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.primaryMobilePhone}
                                    component={PhoneInput}
                                    defaultValue={sgCountryCode}
                                    disabled={!!isDriverSameAsOwner.value}
                                />
                            </div>
                        </div>
                        <ExpandableField
                            fieldInfo={fields.altPhones}
                            addItemLabel="add-alt-phone-no"
                            itemTitle="Alt Phone"
                            Item={PhoneItem}
                            emptyElement={{
                                phone: {
                                    countryCode: 'SG',
                                },
                            }}
                            disabled={!!isDriverSameAsOwner.value}
                        />
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.address1}
                                    component={TextInput}
                                    disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.address1.path, initialValue)}

                                />
                            </div>
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.address2}
                                    component={TextInput}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.postcode}
                                    component={TextInput}
                                />
                            </div>
                            <div className="col-xs-6 form-group">
                                <FormField
                                    tooltip={translate('accident-report-form:email-hint')}
                                    fieldInfo={fields.email}
                                    component={TextInput}
                                    disabled={!!isDriverSameAsOwner.value}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.occupation}
                                    component={SelectInput}
                                    options={getOccupationTypes()}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.driverOwnerRelation}
                                    component={EnumSelectInput}
                                    enumName="OwnerDriverRelationship"
                                    disabled={!!isDriverSameAsOwner.value}
                                />
                            </div>
                            <ConditionalFormField
                                sourceField={fields.driverOwnerRelation}
                                targetFields={[fields.driverOwnerRelationDescription]}
                                condition={(enumValue: SelectInputOption) => isOtherPersonRelation(enumValue.value)}
                            >
                                <div className="col-xs-6 form-group">
                                    <FormField
                                        fieldInfo={fields.driverOwnerRelationDescription}
                                        component={TextInput}
                                    />
                                </div>
                            </ConditionalFormField>
                        </div>
                    </>
                )}
            </Field>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.hasOtherVehicles}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <Field name={fields.hasOtherVehicles.path} subscription={{ value: true }}>
                {({ input }: FieldRenderProps<string, HTMLElement>) => (
                    <ExpandableField
                        fieldInfo={fields.otherVehicles}
                        itemTitle="VEHICLE"
                        addItemLabel="add-other-vehicle"
                        Item={OtherVehicleItem}
                        initialItemsCount={input.value ? 1 : 0}
                        enableAdd={!!input.value}
                    />
                )}
            </Field>
        </FormSection>
    );
};
