import { Formatter } from '@shift/transform';
import { LinksWidgetterProps } from '../widgets/LinksWidget';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IdLinkFormatterFactory = (path: string): Formatter<any[], LinksWidgetterProps> => ({
    transform: ([ids, names]) => ({
        children: names.map((name, idx) => ({
            content: name || null,
            link: ids.length > idx && ids[idx] ? `${path}/${ids[idx]}` : null,
        })),
    }),
});

export const GoToDetailPageFormatter = IdLinkFormatterFactory('/accident-reports/details');
export const GoToRecoveryPageFormatter = IdLinkFormatterFactory('/recoveries/details');
export const GoToFolderPageFormatter = IdLinkFormatterFactory('/folders/details');
