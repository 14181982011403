import React from 'react';
import { FormField, ArrayItemProps, EnumSelectInput } from 'src/components/form';
import { formSections } from 'src/config/form';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const OtherVehicleItem = ({ setItemPath }: ArrayItemProps) => {
    const { item: itemInformation } = formSections.driverSection.fields.otherVehicles;

    return (
        <>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField fieldInfo={setItemPath(itemInformation.number)} component={TextInput} />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField fieldInfo={setItemPath(itemInformation.insurance)} component={EnumSelectInput} enumName="InsurerCode" />
                </div>
            </div>
        </>
    );
};
