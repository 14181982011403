import { Order as DSOrder } from '@shift/design-system';
import { Field } from './filters';

export enum OrderType {
    Asc = 'Order.Asc',
    Desc = 'Order.Desc',
}

export interface Order {
    name: string;
    order: OrderType;
}

export interface Sort {
    field: Field;
    order: Order;
}

export const mapOrder = (o: Order | null): DSOrder => {
    if (!o) {
        return DSOrder.None;
    }

    return o.order === OrderType.Asc ? DSOrder.Asc : DSOrder.Desc;
};

export const unmapOrder = (o: DSOrder): Order | null => {
    if (o === DSOrder.None) {
        return null;
    }

    return {
        name: '',
        order: o === DSOrder.Asc ? OrderType.Asc : OrderType.Desc,
    };
};

export const revertOrder = (o: DSOrder): DSOrder => {
    if (o === DSOrder.None) return o;

    return o === DSOrder.Asc ? DSOrder.Desc : DSOrder.Asc;
};
