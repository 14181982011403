import { Formatter } from '@shift/transform';
import { useSecurity } from 'src/libs/auth';
import { SingleValueFormatter } from './SingleValueFormatter';
import { permissions } from '../permissions';
import { Claims } from '../../libs/auth/Authenticator';
import { obfuscateNationalIdentifier } from '../../utils/forms/formatters';

export interface CustomHookNationalIdentifierFormatter extends Formatter<[string], { children: string }> {
    Transform: any;
}

export const formatNationalIdentifier = (user: Claims | null, ident?: string) => {
    if (typeof ident !== 'string' || (user && permissions.canSeeCompleteId(user))) {
        return ident;
    }
    return obfuscateNationalIdentifier(ident);
};

export const NationalIdentifierFormatter: CustomHookNationalIdentifierFormatter = {
    transform: (params) => NationalIdentifierFormatter.Transform(params),
    Transform: (params: any) => {
        const { user } = useSecurity();
        const idValue = params && params[0] && params[0][0];
        const ident = formatNationalIdentifier(user, idValue);
        if (ident === idValue) {
            return SingleValueFormatter.transform([[ident]]);
        }

        return {
            children: ident,
        };
    },
};
