/* eslint-disable no-shadow */
import { Formatter } from '@shift/transform';
import { LinksBillableActionWidgetterProps } from '../widgets/LinksBillableActionWidget';
import { BILLABLE_ACTIONS } from '../../containers/billableAction/BillableAction';
import { TokenPredicate } from '../permissions';


const addVrn = (vrn: any) => (vrn ? `(${vrn})` : '');
const addVrnParam = (vrn: any) => (vrn ? `?vrn=${vrn}` : '');

export const LinkBillableActionFormatterFactory = (
    paths: string[],
    actions: (keyof typeof BILLABLE_ACTIONS)[],
    permissions?: [TokenPredicate, TokenPredicate],
    isAllowed?: boolean,
): Formatter<any[], LinksBillableActionWidgetterProps> => ({
    transform: ([ids, names, paidAccess, vrns, isStatusUpdateAllowed]) => ({
        children: names.map((name, idx) => {
            const vrn = vrns[idx];
            const [explorationPath, vrnPath] = paths;
            const id = ids[idx];
            return {
                content: [`${name}`, `${addVrn(vrn)}`],
                paidAccess: !!paidAccess[idx],
                actions,
                isAllowed: typeof isAllowed === 'boolean' ? isAllowed : isStatusUpdateAllowed[idx],
                reportId: id,
                permissions,
                links: [
                    (ids.length > idx && ids[idx] && name ? `${explorationPath}/${ids[idx]}` : undefined),
                    (vrn ? `${vrnPath}/${addVrnParam(vrn)}` : undefined),
                ],
            };
        }),
    }),
});

export const LinksBillableActionFormatter = (
    actions: (keyof typeof BILLABLE_ACTIONS)[],
    permissions?: [TokenPredicate, TokenPredicate],
    isAllowed?: boolean,
) => LinkBillableActionFormatterFactory(['/accident-reports/details', '/exploration/vehicle'], actions, permissions, isAllowed);
