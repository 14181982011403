import { EnumFormatter, getFileFormatter, EnumIdentifierValuePairFormatter } from 'src/utils/forms/formatters';
import { isValidFile } from 'src/utils/forms';

export const drmAdjudication = {
    singleSection: {
        name: 'drmAdjudicate',
        title: 'drmAdjudicate',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'nextAction.step.action',
            },
            commentType: {
                path: 'comment.commentType',
            },
            bolaScenario: {
                path: 'drmAdjudicate.bolaScenario',
                target: 'nextAction.bolaScenario.id',
                label: 'bola-scenario',
                isRequired: true,
                formatter: EnumFormatter,
            },
            liability: {
                path: 'drmAdjudicate.liability',
                label: 'adjudication-liability',
                target: 'nextAction.liability',
                formatter: EnumFormatter,
                isRequired: true,
            },
            losingParty: {
                path: 'drmAdjudicate.losingParty',
                label: 'losing-party',
                target: {
                    identifier: 'nextAction.step.specializations.ui_WorkflowStep_Reason.reasonEnumIdentifier',
                    valueId: 'nextAction.step.specializations.ui_WorkflowStep_Reason.reasonEnumValueId',
                },
                formatter: EnumIdentifierValuePairFormatter,
                isRequired: true,
            },
            decisionDocument: {
                path: 'drmAdjudicate.decisionDocument',
                label: 'decision-document',
                target: {
                    id: 'nextAction.attachments.0.document.id',
                    name: 'nextAction.attachments.0.document.documentName',
                },
                formatter: getFileFormatter(),
                validate: isValidFile,
                isRequired: true,
            },
            comment: {
                path: 'drmAdjudicate.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
        },
    },
};
