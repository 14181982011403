/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Path, Segment,
} from '@shift/transform';
import { Widgetters } from 'src/config/widgets';
import { Formatters } from 'src/config/formatters';
import { buildQuery, useQuery } from 'src/graphql';
import {
    TableConfig,
    SingleValueCardConfig,
    RawContentTransformConfig,
    KeyValueCardConfig,
    KeyValueQueryConfig,
    SingleValueQueryConfig,
    TableQueryConfig,
    RawConfig,
    MergedTableConfig,
    MergedTableQueryConfig,
    QueryConfig,
} from './QueryConfigTypes';
import { Claims } from '../auth/Authenticator';

const pathToString = (path: Path) => path
    .map((segment) => (typeof segment === 'object' ? segment.name : segment))
    .filter((name) => name !== '');

const isValidSegmentName = (segmentName: string) => segmentName && segmentName !== '';

const getAllFieldsFromPath = (path: Path, currentPath: string[] = []): string[][] => {
    let paths: string[][] = [];
    path.forEach((segment) => {
        if (typeof segment === 'object') {
            if (isValidSegmentName(segment.name)) {
                currentPath.push(segment.name);
            }
            if (segment.selector) {
                const result = getAllFieldsFromPath(segment.selector.path, [...currentPath]);
                paths = paths.concat(result);
            }
        } else {
            // String
            currentPath.push(segment);
        }
    });
    paths.push(currentPath);
    return paths;
};

export const buildFields = (context: Path, transforms: RawContentTransformConfig[]) => transforms
    .flatMap((t) => t.chrooters)
    .flatMap((chrooter) => getAllFieldsFromPath(context.concat(chrooter)));

const buildI18n = (transform: RawContentTransformConfig, context: Path, i18Namespace = '') => {
    const hasSeveralChrooter = transform.chrooters.length > 1;

    if (hasSeveralChrooter || transform?.i18NId) {
        return i18Namespace && transform.i18NId
            ? `${i18Namespace}:${transform.i18NId}` : '';
    }
    const p = transform.chrooters
        .flatMap((segment) => pathToString(segment));
    return `${i18Namespace}:${pathToString(context).join('-')}-${p.join('-')}`;
};

const splitContext = (context: Path): [string[], Path] => {
    if (!context || context.length === 0) {
        throw new Error('empty context...');
    }
    const idx = context.findIndex((segment: Segment) => typeof segment === 'object' && segment.selector);
    if (idx === 0) {
        throw new Error('you cannot have selector on the first segment');
    }
    if (idx === -1) {
        return [pathToString(context), []];
    }
    return [pathToString(context.slice(0, idx)), context.slice(idx)];
};

const parseSingleValueCardConfig = ({ context, transforms, configType }: SingleValueCardConfig): SingleValueQueryConfig => {
    const [initContext, restContext] = splitContext(context);
    const fields = buildFields(restContext, transforms);
    return ({
        type: configType,
        context: initContext,
        fields,
        transformers: {
            context,
            content: transforms.map((t) => [
                {
                    chrooters: t?.chrooters,
                    formatter: t.formatter ?? Formatters.SingleValueFormatter,
                    widgetter: t.widgetter ?? Widgetters.TextWidgetter,
                },
            ]),
        },
    });
};

const parseKeyValueCardConfig = ({
    context, i18Namespace, transforms, header, configType,
}: KeyValueCardConfig & { header?: RawContentTransformConfig }): KeyValueQueryConfig => {
    const [initContext, restContext] = splitContext(context);
    const fields = buildFields(restContext, header ? transforms.concat(header) : transforms);
    return ({
        type: configType,
        context: initContext,
        fields,
        header: header ? {
            chrooters: header.chrooters,
            formatter: header.formatter ?? Formatters.ListFormatter,
            widgetter: header.widgetter ?? Widgetters.SelectWidgetter,
        } : null,
        transformers: {
            context,
            content: transforms.map((t) => [
                {
                    i18NId: buildI18n(t, context, i18Namespace),
                    formatter: Formatters.TranslateValueFormatter,
                    widgetter: Widgetters.CardLabelWidgetter,
                },
                {
                    chrooters: t?.chrooters,
                    formatter: t.formatter ?? Formatters.SingleValueFormatter,
                    widgetter: t.widgetter ?? Widgetters.CardValueWidgetter,
                },
            ]),
        },
    });
};

const parseTableConfig = ({
    context, i18Namespace, transforms, configType,
}: TableConfig): TableQueryConfig => {
    const [initContext, restContext] = splitContext(context);
    const fields = buildFields(restContext, transforms);

    return ({
        type: configType,
        context: initContext,
        fields,
        transformers: {
            context,
            content: transforms.map((t) => [
                {
                    i18NId: buildI18n(t, context, i18Namespace),
                    formatter: Formatters.TranslateValueFormatter,
                    widgetter: Widgetters.MultiLineTextWidgetter,
                    filter: t.filter ?? null,
                },
                {
                    chrooters: t?.chrooters,
                    formatter: t.formatter ?? Formatters.SingleValueFormatter,
                    widgetter: t.widgetter ?? Widgetters.NoopWidgetter,
                },
            ]),
        },
    });
};
const parseMergedTableConfig = ({
    transforms, header, i18Namespace,
}: MergedTableConfig): MergedTableQueryConfig => {
    const mergedTableQueryConfig: MergedTableQueryConfig = {
        header: header.map((i) => ({
            i18NId: `${i18Namespace}:${i.i18n}`,
            formatter: Formatters.TranslateValueFormatter,
            widgetter: Widgetters.MultiLineTextWidgetter,
            filter: i.filter,
        })),
        rows: transforms.map((t) => parseSingleValueCardConfig(t)),
    };
    return mergedTableQueryConfig;
};

function handlePermissions<P extends RawConfig<any>, T extends(config: P) => any>(func: T): (config: P, user?: Claims | null) => ReturnType<T> {
    return (config: P, user?: Claims | null): ReturnType<T> => func({
        ...config,
        transforms: config
            .transforms
            .filter((transform) => (transform.permissions && user ? transform.permissions(user) : true)),
    });
}

export const queryConfig = {
    cards: {
        getSingleField: handlePermissions(parseSingleValueCardConfig),
        getLabelValueFields: handlePermissions(parseKeyValueCardConfig),
    },
    table: {
        getHeaderColumnFields: handlePermissions(parseTableConfig),
        getMergedTableFields: parseMergedTableConfig,
    },
};

export const useBuiltQuery = (id: number, config: QueryConfig | TableQueryConfig, apiName?: string) => {
    const { fields, context } = config;
    const query = buildQuery(context, fields);

    const result = useQuery(query, {
        variables: { id },
        displayName: apiName,
    });

    return result;
};
