import { SelectInputOption } from '@shift/design-system';
import {
    EnumFormatter, VoidFormatter, RadioFormatter, UpperCaseFormatter, OtherNoEnumTypeFormatter, PurchaseTimeFormatter, PurchaseDateFormatter,
    FirstRegistrationDateTimeFormatter,
} from 'src/utils/forms/formatters';
import {
    isNoneEnum, isValidAlphanumeric, isValidCCValue, validatePassengerListCount,
} from 'src/utils/forms';

export const ownVehicleSection = {
    name: 'ownVehicle.vehiclePolicy.vehicle',
    title: 'own-vehicle-information',
    fields: {
        registrationNumber: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
            label: 'vehicle-registration-number',
            isRequired: true,
            validate: isValidAlphanumeric,
            formatter: UpperCaseFormatter,
        },
        category: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.type',
            label: 'vehicle-category',
            formatter: EnumFormatter,
            isRequired: true,
        },
        manufacturer: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.make',
            target: {
                type: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.make',
                noType: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.makeNotInEnum',
            },
            label: 'vehicle-manufacturer',
            formatter: OtherNoEnumTypeFormatter,
            isRequired: true,
        },
        manufacturerText: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.makeDescription',
            label: 'vehicle-manufacturer-description',
            isRequired: true,
        },
        model: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.model',
            label: 'vehicle-model',
            formatter: {
                additionalSources: {
                    modelText: 'ownVehicle.vehiclePolicy.vehicle.modelText',
                    variant: 'ownVehicle.vehiclePolicy.vehicle.variant',
                },
                format: (value: string): SelectInputOption | undefined => {
                    const splittedValues = value?.split('___');
                    return splittedValues?.length > 0 ? { value: splittedValues[0], label: '' } : undefined;
                },
                transform: ({ value, additionalData }:
                    { value: SelectInputOption; additionalData: { modelText: string; variant: string } }) => (
                    `${value && !isNoneEnum(value.value) ? value.value : (additionalData.modelText ?? '')}___${additionalData.variant ?? ''}`
                ),
            },
            isRequired: true,
        },
        modelText: {
            path: 'ownVehicle.vehiclePolicy.vehicle.modelText',
            target: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.model',
            label: 'vehicle-model-description',
            formatter: VoidFormatter,
            isRequired: true,
        },
        variant: {
            path: 'ownVehicle.vehiclePolicy.vehicle.variant',
            target: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.model',
            label: 'vehicle-variant',
            formatter: {
                format: (value: string): string | undefined => {
                    const splittedValues = value?.split('___');
                    return splittedValues?.length > 1 ? splittedValues[1] : 'undefined';
                },
            },
        },
        transmission: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.transmission',
            label: 'transmission',
            formatter: RadioFormatter,
            isRequired: true,
        },
        engineDisplacement: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.engineCapacityValue',
            label: 'engine-displacement',
            validate: isValidCCValue,
            isRequired: true,
        },
        purchaseDateUtc: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.purchaseDateUtc',
            label: 'effective-date-of-ownership',
            formatter: PurchaseDateFormatter,
        },
        purchaseTime: {
            path: 'ownVehicle.time',
            target: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.purchaseDateUtc',
            label: 'effective-time-of-ownership',
            formatter: PurchaseTimeFormatter,
        },
        vIN: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.vIN',
            label: 'vin',
        },
        firstRegistrationDateUtc: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.firstRegistrationDateUtc',
            label: 'first-registration-date',
            formatter: FirstRegistrationDateTimeFormatter,
            isRequired: false,
        },
        vehicleFuel: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.vehicleFuel',
            target: {
                type: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.vehicleFuel',
                noType: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.vehicleFuelSourceName',
            },
            label: 'vehicle-fuel',
            formatter: OtherNoEnumTypeFormatter,
        },
        vehicleFuelText: {
            path: 'ownVehicle.vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.vehicleFuelSourceName',
            label: 'vehicle-fuel-description',
        },
        usagePurpose: {
            path: 'ownVehicle.vehiclePolicy.vehicle.usagePurpose',
            target: 'ownVehicle.vehiclePolicy.usagePurpose',
            label: 'vehicle-usage-purpose',
            formatter: EnumFormatter,
        },
        passengerCount: {
            path: 'ownVehicle.vehiclePolicy.vehicle.numberOfPassengers',
            target: 'ownVehicle.vehiclePolicy.numberOfPassengers',
            label: 'passenger-count',
            isRequired: true,
            validate: validatePassengerListCount('ownVehicle.vehiclePolicy.vehicle.passengers'),
        },
        passengers: {
            path: 'ownVehicle.vehiclePolicy.vehicle.passengers',
            target: 'ownVehicle.vehiclePolicy.passengers',
            label: 'passengers',
            item: {
                name: {
                    path: 'specializations.mapping_Person_Gears.name',
                    label: 'passenger-name',
                    isRequired: true,
                },
                gender: {
                    path: 'specializations.mapping_Person_Individual.gender',
                    label: 'passenger-gender',
                    formatter: EnumFormatter,
                    isRequired: true,
                },
            },
        },
    },
};
