import { Formatter } from '@shift/transform';
import { FileInfo } from '@shift/design-system';
import { apiConfig } from '..';

interface FreeTextIdentifier {
    Name: string;
    Type: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ThumbnailFormatter: Formatter<any, FileInfo | string> = {
    transform: (params) => {
        const [[id], ftIdentifiers, ftDescriptions, [name], [size]] = params;
        const src = `${apiConfig.documentUri}/${id}`;
        const thumbnailSrc = `${apiConfig.documentPreviewUri}/${id}`;

        const unavailableTextIdx = ftIdentifiers
            .findIndex((ftId: FreeTextIdentifier) => ftId.Type === 'DocumentFreeTextType' && ftId.Name === 'VideoAvailabilityJustification');

        if (unavailableTextIdx >= 0) {
            return `Video unavailable (${ftDescriptions[unavailableTextIdx]})`;
        }

        return {
            id,
            src,
            thumbnailSrc,
            name,
            size,
        };
    },
};
