import React from 'react';
import { Widgetter } from '@shift/transform';

export function MultiWidgetterFactory<T, P>(widgetter: Widgetter<T>): Widgetter<T[]> {
    return {
        transform: (params) => (
            <>
                {
                    params.map((param) => (
                        <span>
                            { widgetter.transform(param) }
                        </span>
                    ))
                }
            </>
        ),
    };
}
