import React, { useContext } from 'react';
import {
    GridItem, Grid, StyledSubHeader, ThemeContext,
} from '@shift/design-system';

export interface SubHeaderFormProps {
    text: string;
    icon?: JSX.Element;
}
export const SubHeaderForm = ({ text, icon }: SubHeaderFormProps) => {
    const { colors } = useContext(ThemeContext);
    return (
        <Grid style={{
            borderBottom: `1px solid ${colors.brand.secondary.base}`,
            paddingBottom: '8px',
            marginBottom: '24px',
            marginTop: '8px',
        }}
        >
            <GridItem style={{
                width: '50%',
            }}
            >
                <StyledSubHeader
                    type="s2"
                    weight="bold"
                    color={colors.brand.secondary.base}
                    textTransform="uppercase"
                >
                    {text}
                </StyledSubHeader>
            </GridItem>
            <GridItem style={{
                width: '50%',
                textAlign: 'right',
            }}
            >
                {icon && icon}
            </GridItem>
        </Grid>
    );
};
