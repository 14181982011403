import { graphQLQueryOptionsContextFactory } from 'src/libs/graphql-query-options';

const AR_LIST = 'arList';
const TPR_LIST = 'tprList';

export const QUERY_OPTIONS_KEYS = {
    AR_LIST,
    TPR_LIST,
} as const;

const keys = [AR_LIST, TPR_LIST] as const;

export const { GraphQLQueryOptionsProvider, useGraphQLQueryOptions } = graphQLQueryOptionsContextFactory(keys);

export type QueryOptionsKeys = Parameters<typeof useGraphQLQueryOptions>[0];
