import React, { useContext } from 'react';
import styled from 'styled-components';
import { StyledText, ThemeContext } from '@shift/design-system';
import { Widgetter } from '@shift/transform';

const Text = styled(StyledText)`
    white-space: pre-line;
    max-width: 400px;
    overflow-x: auto;
`;

const TextWidget = ({ children }: { children: string[] }) => {
    const { colors } = useContext(ThemeContext);
    return (
        <Text color={colors.text.primary} type="div">
            {children}
        </Text>
    );
};
export const TextWidgetter: Widgetter<{ children: string[] }> = {
    transform: TextWidget,
};
