export const giaConfigHelpers = {
    persons: {
        selectors: {
            firstPartyPersons: [
                {
                    name: '',
                    selector: {
                        path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_PartySide', 'partySide'],
                        values: [{ Type: 'PartySide', Name: 'FirstParty' }],
                    },
                },
            ],
            driver: [
                {
                    name: '',
                    selector: {
                        path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumIdentifier'],
                        values: ['MotorClaimPersonFunction'],
                    },
                },
                {
                    name: '',
                    selector: {
                        path: ['links', 'mapping_ClaimPerson', 'specializations', 'mapping_ClaimPerson_Function', 'enumValueId'],
                        values: [3],
                    },
                },
            ],
            owner: [
                {
                    name: '',
                    selector: {
                        path: ['objects', 'links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                        values: ['MotorClaimPersonFunction'],
                    },
                },
                {
                    name: '',
                    selector: {
                        path: ['objects', 'links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'],
                        values: [1],
                    },
                },
            ],
            driverOfVehicle: [
                {
                    name: '',
                    selector: {
                        path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumIdentifier'],
                        values: ['MotorClaimPersonFunction'],
                    },
                },
                {
                    name: '',
                    selector: {
                        path: ['links', 'mapping_ClaimPersonToClaimObject', 'roleEnumValueId'],
                        values: [3],
                    },
                },
            ],
            nric: [
                'specializations',
                {
                    name: 'mapping_Person_NationalIdentifier',
                    selector: {
                        path: ['identifier'],
                        values: [{ Type: 'NationalIdentifierType', Name: 'NationalIdentifier' }],
                    },
                },
                'nationalIdentifier',
            ],
            anyIds: [
                'specializations', 'mapping_Person_NationalIdentifier', 'nationalIdentifier',
            ],
            policiesPolicyHolder: [
                {
                    name: '',
                    selector: {
                        path: ['policies', 'links', 'mapping_PolicyPerson', 'roleEnumIdentifier'],
                        values: ['PersonFunction'],
                    },
                },
                {
                    name: '',
                    selector: {
                        path: ['policies', 'links', 'mapping_PolicyPerson', 'roleEnumValueId'],
                        values: [1],
                    },
                },
            ],
        },
    },
};
