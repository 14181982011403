/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
    SelectInput, SelectInputProps, SelectInputOption, StyledText, Grid, GridItem, StyledLink, withCustomStyles,
} from '@shift/design-system';
import { useEnumOptions } from 'src/utils/hooks';
import { GridCenteredCircularLoader } from 'src/components/Loader/GridCenteredCircularLoader';

export interface EnumSelectInputProps extends SelectInputProps {
    enumName?: string;
    exclude?: string[];
    hasNumberValue?: boolean;
    options: SelectInputOption[];
    loading?: boolean;
    sort?: boolean;
    refetch?: () => void;
}

const label = (value: SelectInputOption, options: SelectInputOption[]) => options.filter((e) => e.value === value.value)[0];

const CustomLink = withCustomStyles(StyledLink);

const noop = () => { /* noop */ };

const Loader = () => <GridCenteredCircularLoader height="32px" size="32px" width="4px" />;
const Error = ({ refetch = noop }: { refetch?: () => void }) => (
    <Grid justifyPlacement="center" alignPlacement="center" style={{ height: '100%', overflow: 'hidden' }}>
        <GridItem>
            <StyledText type="span" weight="bold" size="large" textStyle="italic">
                Failed to load data.
                <CustomLink style={{ display: 'inline' }} size="large" onClick={refetch}> Try again?</CustomLink>
            </StyledText>
        </GridItem>
    </Grid>
);

const makeDisabledOption = (component: React.ReactNode) => ({
    label: '',
    value: '',
    displayOption: component,
    isDisabled: true,
} as SelectInputOption);

// TODO: extract the fetch logic into another component
// especially, the `selectXXXX = enumName ? ... : ...` variables
export const EnumSelectInput = ({
    enumName = '', exclude, hasNumberValue = false, options, value, onChange, error, loading, refetch, ...otherProps
}: EnumSelectInputProps) => {
    const {
        options: enumNameOptions, loading: enumNameLoading, error: enumNameError, refetch: enumNameRefetch,
    } = useEnumOptions(enumName, hasNumberValue, false, { exclude });

    const selectOptions = enumName ? enumNameOptions : options;
    const selectLoading = enumName ? enumNameLoading : loading;
    const selectError = enumName ? enumNameError : error;
    const selectRefetch = enumName ? enumNameRefetch : refetch;

    const labelledValue = useMemo(() => {
        if (value && selectOptions.length) {
            let update = false;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newValue: any = value;
            if (Array.isArray(value)) {
                newValue = (Array.isArray(value) ? value : [value]).map((v) => {
                    update = !v.label;
                    return update ? label(v, selectOptions) : v;
                });
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                update = !(value as any).label;
                newValue = update ? label(value, selectOptions) : value;
            }
            if (update) {
                if (onChange) {
                    onChange(newValue);
                }
            }
            return newValue;
        }
        return value;
    }, [value, selectOptions, onChange]);

    const loadingOption = makeDisabledOption(<Loader />);
    const errorOption = makeDisabledOption(<Error refetch={selectRefetch} />);

    const onChangeCb = onChange ? (opts: SelectInputOption[]) => {
        // FIX: it seems `onChange` can have as a parameter either a `SelectInputOption` or an array of `SelectInputOption`
        if (Array.isArray(opts)) {
            onChange(opts.filter((opt) => opt !== loadingOption && opt !== errorOption));
            return;
        }
        if (opts === loadingOption || opts === errorOption) {
            return;
        }
        onChange(opts);
    } : undefined;

    // eslint-disable-next-line no-nested-ternary
    const opts = selectLoading ? [loadingOption] : selectError ? [errorOption] : selectOptions;

    return (
        <SelectInput
            value={labelledValue}
            options={opts}
            onChange={onChangeCb}
            {...otherProps}
        />
    );
};
