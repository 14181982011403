import {
    ParameterType, SearchOperationType,
} from 'src/libs/filters';
import { Formatters } from 'src/config/formatters';
import { Widgetters } from '../../../widgets';
import { ThirdPartyReportConfig } from '../../types';
import { TpReportFields } from '../../../../libs/filters';

const rootContext = ['tpReport'];

export const thirdPartyReportConfig: ThirdPartyReportConfig = {
    list: {
        configType: 'Table',
        context: rootContext,
        i18Namespace: 'third-party-report',
        transforms: [
            {
                chrooters: [
                    ['referenceCode'],
                ],
                filter: {
                    field: { id: TpReportFields.ReferenceCode },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    ['status'],
                ],
                formatter: Formatters.EnumValuesFormatter,
                filter: {
                    field: { id: TpReportFields.Status },
                    operation: {
                        type: SearchOperationType.Equal,
                        name: 'StringEqual',
                        parameters: [
                            { value: '', name: 'equalEnum', type: ParameterType.Enum },
                        ],
                    },
                    widgetter: Widgetters.Filter.EnumFilterWidgetterFactory('TpReportPurchaseStatus'),
                },
            },
            {
                chrooters: [
                    ['fpVehicleNumber'],
                ],
                filter: {
                    field: { id: TpReportFields.FpVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetter,
                },
            },
            {
                chrooters: [
                    ['tpVehicleNumber'],
                ],
                filter: {
                    field: { id: TpReportFields.TpVehicleNumber },
                    operation: {
                        type: SearchOperationType.Match,
                        name: 'StringMatch',
                        parameters: [
                            { value: '', name: 'searchedText', type: ParameterType.String },
                        ],
                    },
                    widgetter: Widgetters.Filter.TextFilterWidgetterFactory(),
                },
            },
            {
                chrooters: [
                    ['createDate'],
                ],
                formatter: Formatters.UtcDateFormatter,
                filter: {
                    field: { id: TpReportFields.CreateDate },
                    operation: {
                        type: SearchOperationType.Between,
                        name: 'DateBetween',
                        parameters: [
                            { value: '', name: 'StartDate', type: ParameterType.DateTimeUtc },
                            { value: '', name: 'EndDate', type: ParameterType.DateTimeUtc },
                        ],
                    },
                    widgetter: Widgetters.Filter.DateFilterWidgetter,

                },
            },
        ],
    },

};
