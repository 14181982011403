/* global XMLHttpRequest, FormData */

import { apiConfig } from 'src/config';
import { DocumentCategory } from 'src/types';

export const uploadFile = (
    file: File,
    onProgress: (progress: number) => void,
    onFinish: (id: string) => void,
    onError: (errorMessage: string) => void,
    documentCategory: DocumentCategory = DocumentCategory.UnClassified,
    token: () => Promise<string | null>,
) => {
    token()
        .then((t) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = (e) => onProgress((e.loaded / e.total) * 100);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        onFinish(xhr.response);
                    } else {
                        onError(xhr.response);
                    }
                }
            };
            xhr.onerror = () => {
                onError('Failed');
            };
            xhr.open('POST', apiConfig.uploadUri, true);
            if (t) {
                xhr.setRequestHeader('Authorization', `Bearer ${t}`);
            }
            xhr.setRequestHeader('X-FileName', encodeURI(file.name)); // Pass the filename along
            const formData = new FormData();
            formData.append('file', file);
            formData.append('documentCategory', documentCategory.toString());
            xhr.send(formData);
        }, () => onError('Failed'));
};
