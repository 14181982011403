import React, { useContext } from 'react';
import { Widgetter } from '@shift/transform';
import { StyledText, ThemeContext } from '@shift/design-system';
import { translate } from '@gears/translations';

export interface PercentageWidgetProps {
    currentNcd: number;
}

const ValueWidget = ({ children }: { children: PercentageWidgetProps }) => {
    const { colors } = useContext(ThemeContext);
    return (
        children ? (
            <StyledText weight="bold" type="span">
                {`${children} %`}
            </StyledText>
        )
            : (
                <StyledText type="div" weight="bold" color={colors?.text?.primary} wrap="nowrap">
                    {translate('ncd:not-available')}
                </StyledText>

            )
    );
};

const ValueWidgetExpandableRow = ({ currentNcd }: PercentageWidgetProps) => {
    const { colors } = useContext(ThemeContext);
    return (
        currentNcd !== undefined ? (
            <StyledText weight="bold" type="span">
                {`${currentNcd} %`}
            </StyledText>
        )
            : (
                <StyledText type="div" weight="bold" color={colors?.text?.primary} wrap="nowrap">
                    {translate('ncd:not-available')}
                </StyledText>

            )
    );
};

export const PercentageWidgetExpandableRow: Widgetter<PercentageWidgetProps> = {
    transform: ValueWidgetExpandableRow,
};

export const PercentageWidget: Widgetter<{ children: PercentageWidgetProps }> = {
    transform: ValueWidget,
};
