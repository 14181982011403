import React, { useContext } from 'react';
import {
    GridItem, Grid, ThemeContext, CircularLoader as BaseCircularLoader,
} from '@shift/design-system';

export interface GridCenteredCircularLoaderProps {
    height?: string;
    width?: string;
    size?: string;
    color?: string;
}
export const GridCenteredCircularLoader = ({
    height = '100%',
    width = '16px',
    size = '120px',
    color,
}: GridCenteredCircularLoaderProps) => {
    const { colors } = useContext(ThemeContext);
    return (
        <Grid justifyPlacement="center" alignPlacement="center" style={{ height, overflow: 'hidden' }}>
            <GridItem>
                <BaseCircularLoader color={color || colors.brand.primary.base} width={width} size={size} />
            </GridItem>
        </Grid>
    );
};
