import moment from 'moment-timezone';
import { init, Locale } from '@gears/translations';

import './moment-config';

export * from './context-translate';

// set the Locale to use for translation and moment config
export const configureLocale = (locale: Locale) => {
    init(locale);
    moment.locale(locale);
};
