import React from 'react';
import { DateFormat } from 'src/constants';
import { parseOffsetDate, parseUtcDate } from 'src/utils';
import {
    Filter, SearchOperationType, Parameter, ParameterType, SearchOperationFormatSpecificType,
} from './filters';
import { translate } from '../../utils/forms';

// TODO: Use formatters
const formatParameterValue = (param: Parameter): {formatted: string; value: string} => {
    if (!param.value) {
        return { value: param.value, formatted: translate('common:NA') };
    }

    const result = { value: param.value, formatted: '' };
    switch (param.type) {
    case ParameterType.Enum:
        result.formatted = translate(`enums:${param.value}`);
        break;
    case ParameterType.DateTimeOffset:
        result.formatted = parseOffsetDate(param.value).format(DateFormat);
        break;
    case ParameterType.DateTimeUtc:
        result.formatted = parseUtcDate(param.value).format(DateFormat);
        break;
    default:
        result.formatted = param.value;
    }
    return result;
};

export const FilterText = ({ filter }: { filter: Filter }) => {
    const { field: { formatType, ...field }, operation } = filter;
    const name = field.i18NId ? translate(field.i18NId) : translate(`filters:${field.id}`);
    const or = translate('filter-list:or');
    const { parameters, type: operationType } = operation;
    const values = parameters.map((p) => formatParameterValue(p));
    const formattedValues = values.map((p) => p.formatted);

    switch (formatType) {
    case SearchOperationType.Equal:
    case SearchOperationType.EnumIdEqual:
        return <>{`${name} = ${formattedValues.join(` ${or} `)}`}</>;
    case SearchOperationType.Between:
        return (<>{(values[0].value ? `${formattedValues[0]} <= ` : '') + name + (values[1].value ? ` <= ${formattedValues[1]}` : '')}</>);
    case SearchOperationType.Match:
        return <>{`${name} = ${formattedValues[0]}`}</>;
    case SearchOperationType.NotEqual:
        return <>{`${name} != ${formattedValues[0]}`}</>;
    case SearchOperationFormatSpecificType.ConditionalBooleanEquality:
        return <>{`${name} = ${translate(operationType === SearchOperationType.Equal ? 'common:yes' : 'common:no')}`}</>;
    default:
        throw new Error('unknown search type');
    }
};
