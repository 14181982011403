export const vehicleMakes: Record<string, string> = {
    // "Gears value": "MyInfo value"
    alpina: 'Alpina',
    ankai: 'Ankai',
    BeiBen: 'BeiBen',
    Bertone: 'Bertone',
    BlueCar: 'Bluecar',
    Bollore: 'Bollore',
    BRP: 'BRP',
    Bsa: 'BSA',
    Cadillac: 'Cadillac',
    Cagiva: 'Cagiva',
    Camc: 'CAMC',
    Carbodies: 'Carbodies',
    Carmichael: 'Carmichael',
    Changjiang: 'Changjiang',
    Chunlan: 'Chunlan',
    Columbia: 'Columbia',
    Condiesel: 'Condiesel',
    COSA: 'COSA',
    Cpi: 'CPI',
    Daimler: 'Daimler',
    Datsun: 'Datsun',
    Daytona: 'Daytona',
    Dennis: 'Dennis',
    ElectricBicycle: 'Electric Bicycle',
    FTBCI: 'FTBCI',
    Gac: 'GAC',
    Galloper: 'Galloper',
    Gasgas: 'GasGas',
    GoldenDragon: 'Golden Dragon',
    Hartford: 'Hartford',
    Higer: 'Higer',
    HockNamSeng: 'Hock Nam Seng',
    Hummer: 'Hummer',
    Inokom: 'Inokom',
    Italjet: 'Italjet',
    Jac: 'JAC',
    JianShe: 'Jianshe',
    Jinbei: 'Jinbei',
    Johnston: 'Johnston',
    Joylong: 'Joylong',
    KimEngSeng: 'Kim Eng Seng',
    Kinglong: 'King Long',
    Lada: 'Lada',
    Lambretta: 'Lambretta',
    Lancia: 'Lancia',
    Liebherr: 'Liebherr',
    Linde: 'Linde',
    Mark: 'Mark',
    Megelli: 'Megelli',
    MLE: 'MLE',
    Morgan: 'Morgan',
    MotoGuzzi: 'Moto Guzzi',
    Norton: 'Norton',
    Paijifa: 'Paijifa',
    Puyuan: 'Puyuan',
    Sachs: 'Sachs',
    Sany: 'Sany',
    Sanyang: 'Sanyang',
    SCNeustar: 'SC Neustar',
    Scomadi: 'Scomadi',
    Shuchi: 'Shuchi',
    Sinotruk: 'Sinotruk',
    SoongWingTrailer: 'Soong Wing Trailer',
    Specialized: 'Specialized',
    TD2000: 'TD2000',
    Tesla: 'Tesla',
    TheLondonTaxiCompany: 'The London Taxi Company',
    Tiger: 'Tiger',
    Trailer: 'Trailer',
    Treelektrik: 'Treelektrik',
    Victory: 'Victory',
    Wuling: 'Wuling',
    XMXGZG: 'XMXGZG',
    XuGong: 'XuGong',
    YoungMan: 'Youngman',
    Zhongtong: 'Zhongtong',
    Zipstar: 'Zipstar',
    ZonDa: 'Zonda',
    Zoomlion: 'Zoomlion',
    Zotye: 'Zotye',
    Adiva: 'Adiva',
    AlexanderDennis: 'Alexander Dennis',
    AlfaRomeo: 'Alfa Romeo',
    Aprilia: 'Aprilia',
    AstonMartin: 'Aston Martin',
    Audi: 'Audi',
    Austin: 'Austin',
    Bajaj: 'Bajaj',
    Benelli: 'Benelli',
    Bentley: 'Bentley',
    Bmw: 'BMW',
    Brixton: 'Brixton',
    Bugati: 'Bugatti',
    Byd: 'Byd',
    CanAm: 'Can-Am',
    Chana: 'Chana',
    Chery: 'Chery',
    Chevrolet: 'Chevrolet',
    Chrysler: 'Chrysler',
    Citroen: 'Citroen',
    Crown: 'Crown',
    Daelim: 'Daelim',
    Daewoo: 'Daewoo',
    Daf: 'Daf',
    Daihatsu: 'Daihatsu',
    Demag: 'Demag',
    Derbi: 'Derbi',
    Dodge: 'Dodge',
    Dongfeng: 'Dongfeng',
    Ducati: 'Ducati',
    Dulevo: 'Dulevo',
    Ferrari: 'Ferrari',
    Fiat: 'Fiat',
    Ford: 'Ford',
    Foton: 'Foton',
    Geely: 'Geely',
    Gilera: 'Gilera',
    Grove: 'Grove',
    Hafei: 'Hafei',
    HarleyDavidson: 'Harley Davidson',
    Hdk: 'Hdk',
    Hillman: 'Hillman',
    Hino: 'Hino',
    Holden: 'Holden',
    Honda: 'Honda',
    Husqvarna: 'Husqvarna',
    Hyosung: 'Hyosung',
    Hyundai: 'Hyundai',
    Indian: 'Indian',
    Infiniti: 'Infiniti',
    Isuzu: 'Isuzu',
    Iveco: 'Iveco',
    Jaguar: 'Jaguar',
    Jeep: 'Jeep',
    Kasinski: 'Kasinski',
    Kawasaki: 'Kawasaki',
    Keeway: 'Keeway',
    Kia: 'Kia',
    Komatsu: 'Komatsu',
    Ktm: 'Ktm',
    Kymco: 'Kymco',
    Lamborghini: 'Lamborghini',
    LandRover: 'LandRover',
    Lexbuild: 'Lexbuild',
    Lexus: 'Lexus',
    Leyland: 'Leyland',
    Lifan: 'Lifan',
    Lml: 'Lml',
    Lotus: 'Lotus',
    Man: 'Man',
    Manitou: 'Manitou',
    Maserati: 'Maserati',
    Maxus: 'Maxus',
    Mazda: 'Mazda',
    McLaren: 'McLaren',
    Mercedes: 'Mercedes',
    Mg: 'MG',
    MgRover: 'MG Rover',
    Mini: 'Mini',
    Mitsubishi: 'Mitsubishi',
    Mitsuoka: 'Mitsuoka',
    Modenas: 'Modenas',
    Morris: 'Morris',
    Mvagusta: 'Mvagusta',
    Naza: 'Naza',
    Nissan: 'Nissan',
    Noble: 'Noble',
    Opel: 'Opel',
    Pagani: 'Pagani',
    Perodua: 'Perodua',
    Peugeot: 'Peugeot',
    Pgo: 'Pgo',
    Piaggio: 'Piaggio',
    Porsche: 'Porsche',
    Proton: 'Proton',
    Renault: 'Renault',
    Rieju: 'Rieju',
    RollsRoyce: 'Rolls Royce',
    Rover: 'Rover',
    RoyalEnfield: 'Royal Enfield',
    Saab: 'Saab',
    Scania: 'Scania',
    Seat: 'Seat',
    Shacman: 'Shacman',
    ShenyangBrillianceJinbei: 'Shenyang Brilliance Jinbei',
    Skoda: 'Skoda',
    Smart: 'Smart',
    Ssangyong: 'Ssangyong',
    Subaru: 'Subaru',
    Sunlong: 'Sunlong',
    Suzuki: 'Suzuki',
    Sym: 'Sym',
    Tadano: 'Tadano',
    Tennant: 'Tennant',
    Terex: 'Terex',
    Tgb: 'Tgb',
    Toyota: 'Toyota',
    Triumph: 'Triumph',
    Tvs: 'Tvs',
    UDTrucks: 'UDTrucks',
    Vespa: 'Vespa',
    Volkswagen: 'Volkswagen',
    Volvo: 'Volvo',
    Yamaha: 'Yamaha',
    Yutong: 'Yutong',
    Zongshen: 'Zongshen',
    Mutt: 'Mutt',
};
