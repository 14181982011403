import { Formatter } from '@shift/transform';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JoinsValuesFormatter: Formatter<any[], { children: string }> = {
    transform: (params) => {
        const values = params && params.length > 0 && params.flat();
        const fullValues = Array.isArray(values) ? values.join(' ') : '';
        return {
            children: fullValues,
        };
    },
};
