import { useEffect, useRef, useCallback } from 'react';
import { DocumentNode } from 'graphql';
import { useLazyQuery, LazyQueryHookOptions, QueryLazyOptions } from '@apollo/react-hooks';
import { LazyQueryResult, OperationVariables } from '@apollo/client';

// TODO move this
// Adapted from https://github.com/apollographql/react-apollo/issues/3499#issuecomment-537748212
export const useAsyncQuery = <TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: LazyQueryHookOptions<TData, TVariables>,
): [(options?: QueryLazyOptions<TVariables>) => Promise<LazyQueryResult<TData, TVariables>>, LazyQueryResult<TData, TVariables>] => {
    const [execute, result] = useLazyQuery<TData, TVariables>(query, options);

    const resolveRef = useRef<(value: LazyQueryResult<TData, TVariables>) => void>();

    const { called, loading } = result;
    useEffect(() => {
        if (called && !loading && resolveRef.current) {
            resolveRef.current(result);
            resolveRef.current = undefined;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, called]);

    const asyncExecute = useCallback((o?: QueryLazyOptions<TVariables>) => {
        execute(o);
        return new Promise<LazyQueryResult<TData, TVariables>>((resolve) => {
            resolveRef.current = resolve;
        });
    }, [execute]);

    return [asyncExecute, result];
};
