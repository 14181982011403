export type Claims = {
    sub: string;
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type UnauthenticatedState = {
    status: 'UNAUTHENTICATED';
    login: (username: string, password: string) => void;
    forgotPassword: (username: string) => void;
    resumeRecovery: (token: string) => void;
};

export type AuthenticatedState = {
    status: 'AUTHENTICATED';
    user: Claims;
    logout: () => void;
};

export type PasswordExpirationState = {
    status: 'PASSWORD_EXPIRATION';
    changePassword: (password: string) => void;
    skip?: () => void;
    cancel: () => void;
};

export enum FACTOR_TYPES {
    AUTH_OTP = 'AUTH_OTP',
    EMAIL = 'EMAIL',
}

export type FactorEnrollState = {
    status: 'FACTOR_ENROLL';
    factors: {
        [factorKey in FACTOR_TYPES]?: {
            required: boolean;
            enroll: () => void;
        };
    };
    cancel: () => void;
};

interface Factor {
    type: FACTOR_TYPES;
}

interface GoogleAuthFactorActivate extends Factor {
    sharedSecret?: string;
    qrCode?: {
        type: string;
        href: string;
    };
}

export type FactorActivate = GoogleAuthFactorActivate;

export type FactorActivateState = {
    status: 'FACTOR_ACTIVATE';
    activate: (params: unknown) => void;
    previous: () => void;
    cancel: () => void;
    resend?: () => void;
    factor: FactorActivate;
};

export type FactorRequiredState = {
    status: 'FACTOR_REQUIRED';
    factors: {
        [factorType in FACTOR_TYPES]?: {
            verify: () => void;
        };
    };
    cancel: () => void;
};

export type FactorChallengeState = {
    status: 'FACTOR_CHALLENGE';
    verify: (params: unknown) => void;
    previous: () => void;
    cancel: () => void;
    factorType: FACTOR_TYPES;
};

export type RecoveryChallengeState = {
    status: 'RECOVERY_CHALLENGE';
    resumeRecovery: (token: string) => void;
    cancel: () => void;
};

export type LockedOutState = {
    status: 'LOCKED_OUT';
};

export type State = UnauthenticatedState
    | AuthenticatedState
    | PasswordExpirationState
    | FactorActivateState
    | FactorChallengeState
    | FactorEnrollState
    | FactorRequiredState
    | RecoveryChallengeState
    | LockedOutState;

// mark sure all state have a unique status
type Status = State['status'];
