import { TextWidgetter } from './TextWidget';
import { NoopWidgetter } from './NoopWidget';
import { CardWidget } from './CardWidget';
import { SelectWidgetter } from './SelectWidget';
import { LinksWidgetter } from './LinksWidget';
import { DownloadWidgetter } from './DownloadWidget';
import { DocumentLinkWidgetter } from './DocumentLinkWidget';
import { ThumbnailWidgetter } from './ThumbnailWidget';
import { MultiWidgetterFactory } from './MultiWidget';
import { LinksBillableActionWidgetter } from './LinksBillableActionWidget';
import { LinksExplorationSearchWidgetter } from './LinksExplorationSearchWidgetter';
import { Filter } from './filters';
import { TrajectoryIconWidget } from './TrajectoryIconWidget';
import { PercentageWidget, PercentageWidgetExpandableRow } from './PercentageWidget';
import { MultiLineTextWidgetter, MultiLineWidgetter } from './MultiLineTextWidget';
import { MaxWidthTextWidgetter } from './MaxWidthTextWidget';
import { TextInlineWidgetter } from './TextInlineWidget';

export const Widgetters = {
    ...CardWidget,
    DownloadWidgetter,
    DocumentLinkWidgetter,
    LinksWidgetter,
    NoopWidgetter,
    SelectWidgetter,
    TextWidgetter,
    ThumbnailWidgetter,
    LinksBillableActionWidgetter,
    LinksExplorationSearchWidgetter,
    MultiWidgetterFactory,
    Filter,
    TrajectoryIconWidget,
    PercentageWidget,
    PercentageWidgetExpandableRow,
    MultiLineTextWidgetter,
    MultiLineWidgetter,
    MaxWidthTextWidgetter,
    TextInlineWidgetter,
};
