/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Path,
    Formatter,
    Widgetter,
} from '@shift/transform';

import { Claims } from 'src/libs/auth/Authenticator';
import { Filter, FilterProps } from 'src/libs/filters';

type TokenPredicate = (user: Claims) => boolean;

type ChrootersConfig = Path[];

type ConfigTypes = 'KeyValueCard' | 'SelectKeyValueCard' | 'Table' | 'MergedTable' | 'SingleValueCard' | 'Processed';

type TableAdditionalConfig = {
    filter?: (Filter & { widgetter: Widgetter<FilterProps, FilterProps, Filter> }) | null;
}

type BaseContentTransformConfig<T = unknown> = {
    i18NId: string;
    chrooters: ChrootersConfig;
    formatter: Formatter<any[], T>;
    widgetter: Widgetter<T>;
};

export type RawContentTransformConfig<T = unknown> =
    Pick<BaseContentTransformConfig<T>, 'chrooters'>
    & Partial<BaseContentTransformConfig<T>>
    & { permissions?: TokenPredicate | null };

type RawTableContentTransformConfig<T = unknown> =
    RawContentTransformConfig<T> & TableAdditionalConfig


type MergedTableHeaderConfig = ({
    i18n: string;
} & TableAdditionalConfig)[];
export interface RawConfig<ConfigType extends ConfigTypes, T extends RawContentTransformConfig = RawContentTransformConfig> {
    i18Namespace?: string;
    context: Path;
    transforms: T[];
    configType: ConfigType;
}
export interface MergedRawConfig<
    ConfigType extends ConfigTypes,
    T extends RawConfig<ConfigTypes, RawContentTransformConfig> = RawConfig<ConfigTypes, RawContentTransformConfig>> {
    i18Namespace: string;
    transforms: T[];
    configType: ConfigType;
}


export const isConfig = (config: any): config is Configs => typeof config === 'object' && typeof config?.configType !== 'undefined';

function isConfigFactory<C>(configType: ConfigTypes) {
    return (config: unknown): config is C => isConfig(config) && config.configType === configType;
}

export type SingleValueCardConfig = RawConfig<'SingleValueCard'>;

export const isSingleValueCardConfig = isConfigFactory<SingleValueCardConfig>('SingleValueCard');

export type KeyValueCardConfig = RawConfig<'KeyValueCard'>;

export const isKeyValueCardConfig = isConfigFactory<KeyValueCardConfig>('KeyValueCard');

export type SelectKeyValueCardConfig = RawConfig<'SelectKeyValueCard'> & { header: RawContentTransformConfig };

export const isSelectKeyValueCardConfig = isConfigFactory<SelectKeyValueCardConfig>('SelectKeyValueCard');

export type TableConfig = RawConfig<'Table', RawTableContentTransformConfig>;

export const isTableConfig = isConfigFactory<TableConfig>('Table');

export type MergedTableConfig = MergedRawConfig<'MergedTable', SingleValueCardConfig> & { header: MergedTableHeaderConfig };

export const isMergedTableConfig = isConfigFactory<MergedTableConfig>('MergedTable');

export type ProcessedConfig = {
    configType: 'Processed';
    context: string[];
    fields: string[][];
}

export const isProcessedConfig = isConfigFactory<ProcessedConfig>('Processed');

export type RawConfigs = SingleValueCardConfig | KeyValueCardConfig | SelectKeyValueCardConfig | TableConfig;

export type Configs = RawConfigs | MergedTableConfig | ProcessedConfig;

type BaseConfig<T> = {
    [key: string]: T;
}

export type GeneralConfig = BaseConfig<Configs>;

export type RawConfiguration = { [value: string]: GeneralConfig };

// TODO: Replace QueryConfig when each type of config result has a type
export type ProcesssedConfigType<TConfig> =
    TConfig extends SingleValueCardConfig ? SingleValueQueryConfig
    : TConfig extends KeyValueCardConfig ? KeyValueQueryConfig
    : TConfig extends SelectKeyValueCardConfig ? KeyValueQueryConfig
    : TConfig extends TableConfig ? TableQueryConfig
    : TConfig extends MergedTableConfig ? MergedTableQueryConfig
    : QueryConfig;

export type ProcessedSubConfiguration<TRawConfig> = {
    [K in keyof TRawConfig]: ProcesssedConfigType<TRawConfig[K]>;
};

export type ProcessedConfiguration<TRawConfig> = {
    [K in keyof TRawConfig]: ProcessedSubConfiguration<TRawConfig[K]>;
};


export type ContentTransformConfig<T = unknown> =
    Pick<BaseContentTransformConfig<T>, 'widgetter' | 'formatter'>
    & Partial<BaseContentTransformConfig<T>>;

export type TableHeaderContentTransformConfig<T = unknown> =
    ContentTransformConfig<T> & TableAdditionalConfig;

export type TransformConfig<T = unknown, CT = [ContentTransformConfig<T>]> = {
    context: Path;
    content: CT[];
}

export type TableTransformConfig<T = unknown> =
    TransformConfig<T, [TableHeaderContentTransformConfig<T>, ContentTransformConfig<T>]>;


export type CardTransformConfig<T = unknown> =
    TransformConfig<T, [ContentTransformConfig<T>, ContentTransformConfig<T>]>;

export interface QueryConfig<T = unknown, TC = TransformConfig<T>> {
    type: ConfigTypes;
    // these are used to build the query
    context: string[];
    fields: string[][];

    // these are used to build the components
    transformers: TC;
}

export const isQueryConfig = (config: any): config is QueryConfig => typeof config === 'object'
    && typeof config?.context !== 'undefined'
    && typeof config?.fields !== 'undefined'
    && typeof config?.transformers !== 'undefined'
    && typeof config?.transformers.content !== 'undefined'
    && typeof config?.transformers.content[0] !== 'undefined';


export type TableQueryConfig<T = unknown> = QueryConfig<T, TableTransformConfig<T>>

export const isTableQueryConfig = (config: any): config is TableQueryConfig => isQueryConfig(config)
    && config.type === 'Table'
    && typeof (config.transformers.content as any)[0][0]?.filter !== 'undefined'
    && typeof (config.transformers.content as any)[0][1] === 'object';


export type KeyValueQueryConfig<T = unknown> = QueryConfig<T, CardTransformConfig<T>> & { header: ContentTransformConfig<T> | null }

export const isKeyValueQueryConfig = (config: any): config is KeyValueQueryConfig => isQueryConfig(config)
    && (config.type === 'KeyValueCard' || config.type === 'SelectKeyValueCard')
    && typeof (config.transformers.content as any)[0][0] === 'object'
    && typeof (config.transformers.content as any)[0][1] === 'object';


export type SingleValueQueryConfig<T = unknown> = QueryConfig<T>

export const isSingleValueQueryConfig = (config: any): config is SingleValueQueryConfig => isQueryConfig(config)
    && config.type === 'SingleValueCard'
    && typeof (config.transformers.content as any)[0][0] === 'object'
    && typeof (config.transformers.content as any)[0][1] === 'undefined';


export type MergedTableQueryConfig<T = unknown> = {
    header: TableHeaderContentTransformConfig<T>[];
    rows: QueryConfig<T>[]; // single value Query config since we don't need header
}
export const isMergedTableQueryConfig = (config: any): config is MergedTableQueryConfig => typeof config.header === 'object'
    && config.type === 'MergedTableConfig'
    && Array.isArray(config.rows)
    && isSingleValueQueryConfig(config.rows[0]);
