import React, { useContext } from 'react';
import {
    Textarea, FileFormats, SelectInputOption, Icon,
    Grid, GridItem, StyledText, ThemeContext,
} from '@shift/design-system';
import { InfoCircleSvg } from '@shift/icons';

import { formTranslate } from 'src/utils/forms';
import { formSections } from 'src/config/form';
import {
    FormField, ConditionalFormField,
    FormSection, FilesUpload,
    YesNoRadio,
} from 'src/components/form';
import { ContextTranslate as CT } from 'src/utils/translation';
import { SubHeaderForm } from 'src/components/SubHeader/SubHeaderForm';
import {
    privateHireVehicle, maxTextAreaCharacterLength, isDraft, isMyInfoInitiated,
} from 'src/constants';
import { EnumValue, DocumentCategory } from 'src/types';

export const AttachmentsSection = () => {
    const { colors } = useContext(ThemeContext);
    const { name, title, fields } = formSections.attachmentsSection;
    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <StyledText weight="bold" color={colors.gray[60]}>
                        <Grid>
                            <GridItem style={{ marginRight: '0.5rem' }}><Icon width={1.5} height={1.5}><InfoCircleSvg /></Icon></GridItem>
                            <GridItem><CT k="personal-documents-warning" /></GridItem>
                        </Grid>
                    </StyledText>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.anyAccidentPhoto}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.anyAccidentVideo}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <SubHeaderForm
                        text={`${formTranslate(fields.sketchPlan.label)} *`}
                    />
                    <FormField
                        fieldInfo={fields.sketchPlan}
                        component={FilesUpload}
                        addText={formTranslate(fields.sketchPlan.label)}
                        displayLabel={false}
                        maxFileSize={50}
                        acceptedFormats={[FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF]}
                        documentCategory={DocumentCategory.SketchPlan}
                        isMulti
                    />
                </div>
            </div>
            <ConditionalFormField
                sourceField={fields.anyAccidentPhoto}
                targetFields={[fields.accidentOrVehiclePhotos]}
            >
                <div className="row">
                    <div className="col-xs-12 form-group">

                        <SubHeaderForm
                            text={`${formTranslate(fields.accidentOrVehiclePhotos.label)}*`}
                        />

                        <FormField
                            fieldInfo={fields.accidentOrVehiclePhotos}
                            component={FilesUpload}
                            addText={formTranslate(fields.accidentOrVehiclePhotos.label)}
                            isMulti
                            displayLabel={false}
                            maxFileSize={50}
                            acceptedFormats={[FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF]}
                            documentCategory={DocumentCategory.IncidentPhoto}
                        />
                    </div>
                </div>
            </ConditionalFormField>

            <ConditionalFormField
                sourceField={formSections.ownVehicleSection.fields.category}
                targetFields={[fields.privateHirePhoto]}
                condition={(enumValue: SelectInputOption) => (enumValue.value === privateHireVehicle)}
            >
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <SubHeaderForm
                            text={`${formTranslate('private-hire-photo')} *`}
                        />
                        <FormField
                            fieldInfo={fields.privateHirePhoto}
                            component={FilesUpload}
                            addText={formTranslate(fields.privateHirePhoto.label)}
                            displayLabel={false}
                            maxFileSize={50}
                            acceptedFormats={[FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF]}
                            documentCategory={DocumentCategory.PrivateHireCertificate}
                        />
                    </div>
                </div>
            </ConditionalFormField>
            <ConditionalFormField
                sourceField={formSections.accidentSection.fields.status}
                targetFields={[fields.addendumForm]}
                condition={(status: EnumValue) => (status && !isDraft(status.Name) && !isMyInfoInitiated(status.Name))}
            >
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <SubHeaderForm
                            text={`${formTranslate(fields.addendumForm.label)} *`}
                        />
                        <FormField
                            fieldInfo={fields.addendumForm}
                            component={FilesUpload}
                            addText={formTranslate(fields.addendumForm.label)}
                            displayLabel={false}
                            maxFileSize={50}
                            acceptedFormats={[FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF]}
                            documentCategory={DocumentCategory.AddendumForm}
                        />
                    </div>
                </div>
            </ConditionalFormField>
            <ConditionalFormField
                sourceField={fields.anyAccidentVideo}
                targetFields={[fields.accidentVideos, fields.canUploadVideo, fields.noVideoUploadReason]}
            >
                <div className="row">
                    <div className="col-xs-12 form-group">
                        <SubHeaderForm
                            text={`${formTranslate(fields.accidentVideos.label)} *`}
                        />
                        <div className="row">
                            <div className="col-xs-6 form-group">
                                <FormField
                                    fieldInfo={fields.canUploadVideo}
                                    component={YesNoRadio}
                                    className="radio-option"
                                />
                            </div>
                        </div>
                        <ConditionalFormField
                            sourceField={fields.canUploadVideo}
                            targetFields={[fields.accidentVideos]}
                        >
                            <FormField
                                fieldInfo={fields.accidentVideos}
                                component={FilesUpload}
                                isMulti
                                addText={formTranslate(fields.accidentVideos.label)}
                                displayLabel={false}
                                // eslint-disable-next-line no-underscore-dangle
                                acceptedFormats={[FileFormats.MP4, FileFormats.MKV, FileFormats.MOV, FileFormats.AVI, FileFormats._3GP]}
                                maxFileSize={50}
                                documentCategory={DocumentCategory.IncidentVideo}
                            />
                        </ConditionalFormField>
                        <ConditionalFormField
                            sourceField={fields.canUploadVideo}
                            targetFields={[fields.noVideoUploadReason]}
                            condition={(anyAccidentVideo: boolean) => (anyAccidentVideo === false)}
                        >
                            <FormField
                                fieldInfo={fields.noVideoUploadReason}
                                component={Textarea}
                                maxCharacterLength={maxTextAreaCharacterLength}
                            />
                        </ConditionalFormField>
                    </div>
                </div>
            </ConditionalFormField>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <SubHeaderForm
                        text={formTranslate(fields.personalDocuments.label)}
                    />
                    <FormField
                        fieldInfo={fields.personalDocuments}
                        component={FilesUpload}
                        addText={formTranslate(fields.personalDocuments.label)}
                        isMulti
                        displayLabel={false}
                        maxFileSize={50}
                        acceptedFormats={[
                            FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF,
                            FileFormats.PDF, FileFormats.DOC, FileFormats.DOCX, FileFormats.MP3,
                        ]}
                        documentCategory={DocumentCategory.UnClassified}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <SubHeaderForm
                        text={formTranslate(fields.otherDocuments.label)}
                    />
                    <FormField
                        fieldInfo={fields.otherDocuments}
                        component={FilesUpload}
                        addText={formTranslate(fields.otherDocuments.label)}
                        isMulti
                        displayLabel={false}
                        maxFileSize={50}
                        acceptedFormats={[
                            FileFormats.JPG, FileFormats.JPEG, FileFormats.PNG, FileFormats.TIF, FileFormats.TIFF,
                            FileFormats.PDF, FileFormats.DOC, FileFormats.DOCX, FileFormats.MP3,
                        ]}
                        documentCategory={DocumentCategory.UnClassified}
                    />
                </div>
            </div>
        </FormSection>
    );
};
