/**
 * Use GIA config for tests
 * This is used for tests of gears
 * Do not onfuse with Config library tests
 */
import { gearsConfig as baseGearsConfig } from '../GIA/gearsConfig-GIA';
import { GearsConfig } from '../../types';

export const gearsConfig: GearsConfig = {
    ...baseGearsConfig,
    tests: {
        kvCard: {
            configType: 'KeyValueCard',
            context: ['claim', 'persons'],
            transforms: [
                {
                    chrooters: [
                        ['id'],
                    ],
                },
                {
                    chrooters: [
                        ['name'],
                    ],
                },
                {
                    chrooters: [
                        ['recordOwner'],
                    ],
                },
            ],
        },
    },
};
