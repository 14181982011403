/* eslint-disable @typescript-eslint/no-explicit-any */
// import { capitalize } from 'src/utils/utils';
import gql from 'graphql-tag';
import merge from 'deepmerge';
import { get, set } from 'object-path';
import {
    AnyType, FormSectionInformation, SingleFieldInformation, ArrayFieldInformation, FieldInformation,
} from 'src/types';
import { setDataOnTarget, setValue, combineMerge } from 'src/utils/forms';

export const insertAccidentReportMutation = gql(`
mutation SubmitAccidentReport($accidentReport: AccidentReportInput!, $saveAsDraft: Boolean, $source: ShiftEnum_AccidentReportSourceEnum) {
    insertAccidentReport(accidentReport: $accidentReport, saveAsDraft: $saveAsDraft, source: $source) {
        id
    }
}`);

export const executeRecoveryStepMutation = gql(`
mutation ExecuteRecoveryStep($recoveryStepInput: RecoveryStepInputInput!) {
    executeRecoveryStep(recoveryStepInput: $recoveryStepInput) {
        recovery {
            id
        }
    }
}`);

export const assignOfficerInChargeMutation = gql(`
mutation AssignOfficerInCharge($workflowId: Long, $officerId: String) {
    assignOfficer(workflowId: $workflowId, officerId: $officerId)
}`);

export const uploadWorkflowDocumentMutation = gql(`
mutation UploadWorkflowDocument($workflowId: Long, $documentId: Long) {
    uploadWorkflowDocument(workflowId: $workflowId, documentId: $documentId)
}`);

export const sendTPReportRequest = gql(`
mutation TPReportRequest($tpReportRequest : TPReportRequestInput!) {
    tPReportRequest(request: $tpReportRequest){
        date
        firstPartyVRN
        thirdPartyVRN
        referenceCode
    }
}`);

const transformFieldData = (field: FieldInformation, accidentReport: AnyType): AnyType => {
    const value = get(accidentReport, field.path);
    if (value === undefined || value === null) {
        const hasAdditionalValues = field.formatter?.additionalSources ? Object.values(field.formatter?.additionalSources).some((additionalPath) => {
            const additionalData = get(accidentReport, additionalPath);
            return additionalData !== undefined && additionalData !== null;
        }) : false;

        if (!hasAdditionalValues) {
            return {};
        }
    }

    const arrayField = field as ArrayFieldInformation;
    if (arrayField?.item) {
        const arrayResult: AnyType[] = [];
        value.forEach((item: AnyType) => {
            let result: AnyType = {};
            Object.values(arrayField.item).forEach((itemField) => {
                result = merge(result, transformFieldData(itemField, item), { arrayMerge: combineMerge });
            });
            arrayResult.push(result);
        });
        return setValue({}, arrayField.target ?? arrayField.path, arrayResult);
    }
    const singleField = field as SingleFieldInformation;
    const additionalData: AnyType = {};
    if (singleField.formatter && singleField.formatter.additionalSources) {
        Object.entries(singleField.formatter.additionalSources).forEach(([key, val]) => {
            const additionalValue = get(accidentReport, val);
            set(additionalData, key, additionalValue);
        });
    }

    if (!singleField.formatter || singleField.formatter.transform) {
        const newValues = singleField.formatter?.transform ? singleField.formatter.transform({ value, additionalData }) : value;
        if (newValues === undefined) {
            return {};
        }

        if (singleField.target) {
            return setDataOnTarget(singleField.target, newValues);
        }
        return setValue({}, singleField.path, newValues);
    }
    return {};
};

export const transformForm = (sections: AnyType, accidentReport: AnyType): AnyType => {
    let result: AnyType = {};

    Object.values(sections).forEach((formSection) => {
        const section = formSection as FormSectionInformation;
        Object.values(section.fields).forEach((field) => {
            result = merge(result, transformFieldData(field, accidentReport), { arrayMerge: combineMerge });
        });
    });
    return result;
};

export const updateClaimStatusMutation = gql`
mutation UpdateClaimStatus($id: Long, $close: Boolean) {
    updateStatus(claimId: $id, close: $close) {
        id
        status
    }
}
`;

export const insertAccidentReportCommentMutation = gql`
mutation InsertAccidentReportComment($parentId: Long, $content: String) {
    insertAccidentReportComment(parentId: $parentId, content: $content) {
        id
        content
        createDateUtc
        author {
            id
            userName
        }
    }
}
`;

export const insertRecoveryCommentMutation = gql`
mutation InsertRecoveryComment($parentId: Long, $content: String, $commentType: String) {
    insertRecoveryComment(parentId: $parentId, content: $content, commentType: $commentType) {
        id
        content
        createDateUtc
        author {
            id
            userName
        }
    }
}
`;

export const billExplorationSearchMutation = gql`
mutation BillExplorationSearch ($vrn: String, $nric: String) {
    billExplorationSearch(vrn:$vrn, nric:$nric)
}
`;

export const billReportPurchaseMutation = gql`
mutation BillExplorationSearch ($id:Long) {
    billReportPurchase(reportId:$id)
}
`;

export const assignArcMutation = gql`
mutation AssignArc($referenceCode: String, $vehicleNo: String) {
    assignArc(referenceCode: $referenceCode, vehicleNo: $vehicleNo){
        reportId
        errorCode
        insurerCode
    }    
}
`;
