import { runtimeConfig } from './runtime-config';

// eslint-disable-next-line
const MYINFO_ATTRIBUTES = 'uinfin,name,sex,dob,email,mobileno,regadd,drivinglicence.qdl.classes,drivinglicence.qdl.validity,vehicles.vehicleno,vehicles.type,vehicles.make,vehicles.model,vehicles.enginecapacity,vehicles.chassisno,vehicles.firstregistrationdate,vehicles.propellant,vehicles.effectiveownership';

const myInfoFields = [
    'uinfin',
    'name',
    'sex',
    'race',
    'dob',
    'email',
    'mobileno',
    'regadd',
    'drivinglicence.qdl.classes',
    'drivinglicence.qdl.validity',
    'vehicles.vehicleno',
    'vehicles.type',
    'vehicles.make',
    'vehicles.model',
    'vehicles.propellant',
    'vehicles.enginecapacity',
    'vehicles.chassisno',
    'vehicles.firstregistrationdate',
    'vehicles.effectiveownership',
];

const {
    myInfoClientId,
    myInfoRedirectUrl,
    myInfoPurpose,
    myInfoEnvironment,
    myInfoAuthUrl,
    myInfoPersonUrl,

} = runtimeConfig();

export const MyInfo = {
    authUrl: myInfoAuthUrl,
    clientId: myInfoClientId,
    redirectUrl: myInfoRedirectUrl,
    attributes: (MYINFO_ATTRIBUTES as string)
        .split(',')
        .filter((attr) => myInfoFields.includes(attr))
        .join(','),
    purpose: myInfoPurpose,
    environment: myInfoEnvironment,
    personUrl: myInfoPersonUrl,
};
