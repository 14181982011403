import React, { useContext } from 'react';
import { StyledText, ThemeContext } from '@shift/design-system';
import { Widgetter } from '@shift/transform';

const LabelWidget = ({ children }: { children: string[] }) => {
    const { colors } = useContext(ThemeContext);
    return (
        <StyledText color={colors.text.primary}>
            {children}
        </StyledText>
    );
};
const CardLabelWidgetter: Widgetter<{ children: string[] }> = {
    transform: LabelWidget,
};
const ValueWidget = ({ children }: { children: string[] }) => {
    const { colors } = useContext(ThemeContext);
    return (
        <div style={{
            maxWidth: '200px',
        }}
        >
            <StyledText type="div" weight="bold" color={colors.text.primary} wrap="nowrap">
                {children}
            </StyledText>
        </div>
    );
};
export const CardValueWidgetter: Widgetter<{ children: string[] }> = {
    transform: ValueWidget,
};


export const CardWidget = {
    CardLabelWidgetter,
    CardValueWidgetter,
};
