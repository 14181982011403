import {
    ExternalVariants, DeepPartial, ModalTheme, NavbarTheme,
} from '@shift/design-system';
// eslint-disable-next-line import/no-unresolved
import {
    BaseBareTheme, TagTheme, TableTheme, CheckboxesTheme,
} from '@shift/design-system/dist/atoms';
import { opacify } from 'polished';

export const variants: DeepPartial<ExternalVariants> = ({
    checkboxes: {
        secondary: (theme: BaseBareTheme): DeepPartial<CheckboxesTheme> => ({
            default: {
                checkbox: {
                    labelColor: theme.colors.text.secondary,
                },
            },
        }),
    },
    modal: {
        gearsModal: (_theme: BaseBareTheme): DeepPartial<ModalTheme> => ({
            content: {
                maxHeight: '80vh',
            },
        }),
    },
    navbar: {
        subheader: (theme: BaseBareTheme): DeepPartial<NavbarTheme> => ({
            backgroundColor: theme.colors.brand.primary.darken8,
            tab: {
                padding: '0.2rem 2rem',
                fontSize: '1.1rem',
            },
        }),
    },
    table: {
        explorationHistories: (theme: BaseBareTheme): DeepPartial<TableTheme> => ({
            cell: {
                _expanded: () => ({
                    border: '0',
                    backgroundColor: opacify(0.2, theme.colors.background.primary),
                }),
            },

        }),
    },
    tag: {
        remaingTime: (theme: BaseBareTheme): DeepPartial<TagTheme> => ({
            default: {
                backgroundColor: theme.colors.brand.primary.base,
                borderRadius: '1rem',
                color: theme.colors.background?.white,
                fontSize: '0.9rem',
                padding: '0.25rem 1rem',
            },
        }),
    },
});
