import { unknownObjectEnum } from 'src/constants';
import { KeyValueCardConfig } from '../../../../../libs/config';
import { FormatterFactories, Formatters } from '../../../../formatters';
import { Widgetters } from '../../../../widgets';

const vehicle: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['vehicle'],
    i18Namespace: 'vehicle-exploration',
    transforms: [
        {
            chrooters: [
                ['vrn'],
            ],
            formatter: Formatters.LinksExplorationSearchFormatter('vehicle'),
            widgetter: Widgetters.LinksExplorationSearchWidgetter,

        },
        {
            chrooters: [
                ['type'],
            ],
            formatter: Formatters.MultipleFormatters([
                FormatterFactories.DefaultValueFormatterFactory(unknownObjectEnum),
                Formatters.EnumValuesFormatter,
            ]),
        },
        {
            i18NId: 'vehicle-make',
            chrooters: [
                ['makeNotInEnum'],
                ['make'],
                ['makeDescription'],
            ],
            formatter: Formatters.VehicleMakeFormatter,
        },
        {
            chrooters: [
                ['model'],
            ],
            formatter: Formatters.VehicleModelFormatter,
        },
        {
            i18NId: 'current-ncd',
            chrooters: [
                [
                    'owner',
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'coefficient',
                ],
                // Do not remove used to get the insurer code to get the email
                [
                    'owner',
                    'policy',
                    'specializations',
                    'mapping_Policy_Insurer',
                    'insurer',
                ],
            ],
            widgetter: Widgetters.PercentageWidget,
        },
        {
            i18NId: 'renewal-ncd',
            chrooters: [
                [
                    'owner',
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    {
                        name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'RenewalNcd' }] },
                    },
                    'coefficient',
                ],
                [
                    'owner',
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'coefficient'],
            ],
            formatter: Formatters.NcdValuesFormatter,
            widgetter: Widgetters.TrajectoryIconWidget,
        },
        {
            i18NId: 'ncd-protection',
            chrooters: [
                [
                    'owner',
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_NoClaimsDiscount',
                    { name: '', selector: { path: ['identifier'], values: [{ Type: 'NoClaimsDiscountType', Name: 'CurrentNcd' }] } },
                    'protection'],
            ],
            formatter: Formatters.BooleanValueFormatter,
        },
        {
            i18NId: 'last-update',
            chrooters: [
                [
                    'modifyDateUtc',
                ],
            ],
            formatter: Formatters.UtcDateFormatter,
        },
    ],
};

const vehicleNumberAccident: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['vehicle'],
    i18Namespace: 'statistic-exploration',
    transforms: [
        {
            i18NId: 'total',
            chrooters: [
                [
                    'adhocStatistics',
                    'accidents',
                    'total',
                ],
                [
                    'adhocStatistics',
                    'accidents',
                    'startDate',
                ],
                [
                    'adhocStatistics',
                    'accidents',
                    'endDate',
                ],
            ],
        },
    ],
};

const vehicleTotalClaimAmount: KeyValueCardConfig = {
    configType: 'KeyValueCard',
    context: ['vehicle'],
    i18Namespace: 'statistic-exploration',
    transforms: [
        {
            i18NId: 'total',
            chrooters: [
                [
                    'statistics',
                    'totalClaimedAmountsWhereTheVehicleWasInvolvedAsFirstPartyVehicle',
                ],
                [
                    'statistics',
                    'startDate',
                ],
                [
                    'statistics',
                    'endDate',
                ],
            ],
        },
    ],
};
export const vehicleInformation = {
    vehicle,
    vehicleTotalClaimAmount,
    vehicleNumberAccident,
};
