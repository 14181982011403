import {
    validateListIfCondition, isNoneEnum, isValidPhoneNumber, isValidNationalIdentifier, isValidAlphanumeric,
} from 'src/utils/forms';
import {
    VoidFormatter, EnumFormatter,
    PhoneNumberFormatter, UnknownNoEnumTypeFormatter, UpperCaseFormatter, OtherNoEnumTypeFormatter,
} from 'src/utils/forms/formatters';
import { SelectInputOption } from '@shift/design-system';
import { AnyType } from 'src/types';

export const thirdPartySection = {
    name: 'thirdParties',
    title: 'third-party-details',
    fields: {
        anyThirdParties: {
            path: 'thirdParties.anyThirdParties',
            target: 'generalInformation.accidentInformation.specializations.mapping_Claim_Motor_Checklist.anyOtherVehicleDamage',
            label: 'any-third-party',
            isRequired: true,
        },
        vehiclesOrProperties: {
            path: 'thirdParties.thirdPartyVehicles',
            target: 'thirdPartyVehicles',
            label: 'third-party-details',
            item: {
                id: {
                    path: 'vehiclePolicy.vehicle.id',
                    label: '',
                },
                vehicleNumber: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                    label: 'tp-vehicle-number',
                    validate: isValidAlphanumeric,
                    formatter: UpperCaseFormatter,
                },
                manufacturer: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.make',
                    target: {
                        type: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.make',
                        noType: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.makeNotInEnum',
                    },
                    label: 'vehicle-manufacturer',
                    formatter: OtherNoEnumTypeFormatter,
                },
                manufacturerText: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.makeDescription',
                    label: 'vehicle-manufacturer-description',
                },
                model: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.model',
                    label: 'vehicle-model',
                    formatter: {
                        additionalSources: {
                            modelText: 'vehiclePolicy.vehicle.modelText',
                        },
                        format: (value: number): SelectInputOption | undefined => (value ? {
                            value,
                            label: '',
                        } : undefined
                        ),
                        transform: ({ value, additionalData }:
                            { value: SelectInputOption; additionalData: { modelText: string } }) => (
                            value && !isNoneEnum(value.value) ? value.value : additionalData.modelText
                        ),
                    },
                },
                modelText: {
                    path: 'vehiclePolicy.vehicle.modelText',
                    target: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.model',
                    label: 'vehicle-model-description',
                    formatter: VoidFormatter,
                },
                color: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.color',
                    label: 'vehicle-color',
                    formatter: EnumFormatter,
                },
                category: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.type',
                    label: 'vehicle-category',
                    target: {
                        type: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.type',
                        noType: 'vehiclePolicy.vehicle.specializations.mapping_Object_Motor_Vehicle.typeNotInEnum',
                    },
                    formatter: UnknownNoEnumTypeFormatter,
                    isRequired: true,
                },
                insuranceCompany: {
                    path: 'vehiclePolicy.policy.specializations.mapping_Policy_Insurer.insurer',
                    label: 'tp-insurance-company',
                    formatter: EnumFormatter,
                },
                propertyDetails: {
                    path: 'vehiclePolicy.vehicle.specializations.mapping_Object_FreeText.description',
                    label: 'tp-property-details',
                },
                damageNature: {
                    path: 'vehiclePolicy.damage.specializations.mapping_Damage_FreeText.0.freeTextDescription',
                    label: 'tp-damage-nature',
                },
                driverName: {
                    path: 'driver.person.specializations.mapping_Person_Gears.name',
                    label: 'driver-name',
                },
                driverIdType: {
                    path: 'driverIdType',
                    target: 'driver.person.specializations.mapping_Person_NationalIdentifier.0.identifier',
                    label: 'driver-id-type',
                    formatter: EnumFormatter,
                },
                driverId: {
                    path: 'driverId',
                    target: 'driver.person.specializations.mapping_Person_NationalIdentifier.0.nationalIdentifier',
                    label: 'driver-id',
                    validate(value: string, formValues: AnyType) {
                        // the path of the current object is changed in the form when adding items
                        // Which means we don't know the index of the current element before runtime
                        // So we look for the 'driverIdType' relatively to the current field
                        const { path } = this;
                        const idx = path.lastIndexOf('.');
                        if (idx === -1) {
                            return undefined;
                        }

                        const reworkedPath = path.substring(0, idx)
                            .replace('[', '.')
                            .replace(']', '');
                        return isValidNationalIdentifier(`${reworkedPath}.driverIdType`)(value, formValues);
                    },
                },
                driverContactNumber: {
                    path: 'driver.primaryPhoneNumber',
                    target: {
                        number: 'driver.primaryPhoneNumber.number',
                        countryCode: 'driver.primaryPhoneNumber.countryCode',
                        phoneNumberType: 'driver.primaryPhoneNumber.phoneNumberType',
                    },
                    formatter: PhoneNumberFormatter,
                    label: 'tp-contact-number',
                    validate: isValidPhoneNumber,
                },
                driverAddress1: {
                    path: 'driver.address.fullAddress',
                    label: 'address-1',
                },
                driverAddress2: {
                    path: 'driver.address.complement',
                    label: 'address-2',
                },
                driverPostCode: {
                    path: 'driver.address.postCode',
                    label: 'post-code',
                },
                passengerCount: {
                    path: 'vehiclePolicy.numberOfPassengers',
                    label: 'tp-passenger-count',
                },
                passengers: {
                    path: 'vehiclePolicy.passengers',
                    label: 'passengers',
                    item: {
                        name: {
                            path: 'specializations.mapping_Person_Gears.name',
                            label: 'passenger-name',
                        },
                        gender: {
                            path: 'specializations.mapping_Person_Individual.gender',
                            label: 'passenger-gender',
                            formatter: EnumFormatter,
                        },
                    },
                },
            },
            validate: validateListIfCondition('thirdParties.anyThirdParties'),
        },
    },
};
