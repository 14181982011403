import React, { useContext } from 'react';
import { buildQuery, GqlQueryName, useQuery } from 'src/graphql';
import { useIdFromParams } from 'src/utils/hooks';
import { useAccidentReportsConfig } from 'src/config/configuration/GearsConfigProvider/GearsConfigProvider';
import { GridCenteredCircularLoader } from 'src/components/Loader/GridCenteredCircularLoader';
import { formatGQLErrors } from 'src/utils/forms';
import { MessageModalContext } from 'src/MessageModal';
import { AccidentReportSubmitted } from '../components/AccidentReportSubmitted';

export const AccidentReportSubmittedPage = () => {
    const accidentReportId = useIdFromParams();
    const reportSubmittedConfig = useAccidentReportsConfig('reportSubmitted');
    const { displayMessage } = useContext(MessageModalContext);
    const formQuery = buildQuery(reportSubmittedConfig.context, reportSubmittedConfig.fields);
    const { data, loading, error } = useQuery(
        formQuery, { variables: { id: accidentReportId }, displayName: GqlQueryName.getSubmittedAccidentReport },
    );

    if (loading) {
        return (
            <GridCenteredCircularLoader />
        );
    }
    if (error?.graphQLErrors?.length) {
        displayMessage(formatGQLErrors(error?.graphQLErrors.map((e) => e)));
    }

    return (
        <>
            <AccidentReportSubmitted accidentReport={data?.claim} />
        </>
    );
};
