import moment, { Moment } from 'moment-timezone';
import { AnyType, EnumValue } from 'src/types';
import {
    DateTimeDisplayFormat, recoveryInitiatingParty,
    DateUtcFormat, DateOffsetFormat, DefaultTimezone, DateFormat,
    InputDateOffsetFormat,
} from 'src/constants';
import { FileFormats } from '@shift/design-system';

type DateInput = string | Date | undefined;
export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.substring(1);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => { };

export const parseUtcDate = (value: string, format: string = DateUtcFormat) => moment.utc(value, format, true).tz(DefaultTimezone);
export const parseOffsetDate = (value: string, format: string = DateOffsetFormat) => moment(value, format, true).tz(DefaultTimezone);
// eslint-disable-next-line max-len
export const toShiftDateTimeOffsetFormat = (date: DateInput) => (date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateOffsetFormat) : undefined);
// eslint-disable-next-line max-len
export const toUtcDateTimeFormat = (date: string | undefined) => (date && moment(date).isValid() ? moment.utc(date, InputDateOffsetFormat, false) : undefined);
// eslint-disable-next-line max-len
export const toUtcDateFormat = (date: string | undefined) => (date && moment(date).isValid() ? moment(date).tz(DefaultTimezone).format(DateUtcFormat) : undefined);


export const displayDate = (date: Moment) => (date ? date.format(DateFormat) : null);
export const displayDateTime = (date: Moment) => (date ? `${date.format(DateTimeDisplayFormat)} (SGT)` : null);

export const stringCompare = (first: string, second: string) => {
    if (first !== second) {
        return first > second ? 1 : -1;
    }
    return 0;
};

type Nullable<T> = T | null | undefined;

export const enumAnyEquals = (...es: Nullable<EnumValue>[]) => (
    (other: Nullable<EnumValue>) => es.findIndex((e) => other?.Name === e?.Name && other?.Type === e?.Type) !== -1
);

export const getClaimByRecoveryRole = (claims: AnyType, role: number) => claims?.filter(
    (c: AnyType) => c.links.ui_WorkflowToClaim[0].roleEnumValueId === role)[0];

export const getAccidentReportByRecoverySide = (accidentReports: AnyType, role: number) => accidentReports?.filter(
    (c: AnyType) => {
        if (role === recoveryInitiatingParty && c.links?.mapping_RecoveryTarget?.length) {
            return c;
        }
        if (role !== recoveryInitiatingParty && c.links?.mapping_RecoveryClaim?.length
                && c.links.mapping_RecoveryClaim[0].roleEnumValueId === role) {
            return c;
        }
        return null;
    })[0];

export const toDateTimeWithoutTz = (momentDate?: Moment) => {
    if (!momentDate) {
        return undefined;
    }
    return new Date(momentDate.year(), momentDate.month(), momentDate.date(), momentDate.hours(), momentDate.minutes());
};

export const getFileExtension = (name: string): FileFormats => {
    const stringArray = name.split('.');
    const rawFormat = `.${stringArray[stringArray.length - 1].toLowerCase()}`;

    const formatKey = Object
        .keys(FileFormats)
        .filter((key) => FileFormats[key as keyof typeof FileFormats] === rawFormat)[0] as keyof typeof FileFormats;

    return FileFormats[formatKey];
};

const stringDefault = (val?: any, prefix?: string, suffix?: string): string => (
    val
        ? `${stringDefault(prefix)}${val}${stringDefault(suffix)}`
        : ''
);

export const concatenateUri = (
    scheme: string,
    path: string,
    userInfo?: string,
    host?: string,
    port?: number,
    query?: string,
    fragment?: string,
// eslint-disable-next-line max-len
) => `${scheme}://${stringDefault(userInfo, undefined, '@')}${stringDefault(host)}${stringDefault(port, ':')}${path}${stringDefault(query)}${stringDefault(fragment)}`;
