import React, {
    useEffect, useContext, useState, useCallback,
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { insertAccidentReportMutation, useMutation } from 'src/graphql';
import { mapMyInfoToReportState } from 'src/utils/mapping/mapMyInfoToReportState';
import { MessageModalContext } from 'src/MessageModal';
import { MyInfo } from 'src/config/myinfo';
import { IVehicleInfo } from 'src/types/myInfo';
import { mapReportStateToGears } from 'src/utils/mapping/mapReportStateToGears';
import { formatGQLErrors } from 'src/utils/forms';
import { useMyInfoState } from 'src/state/MyInfoStateProvider';
import { GridCenteredCircularLoader } from 'src/components/Loader/GridCenteredCircularLoader';
import { arcForm, arcPreForm } from 'src/config/routes';
import { VehicleInformation } from './vehicle-information';

enum MyinfoErrors {
    AccessDenied = 'access_denied'
}

export const CallbackPage = () => {
    const { search } = useLocation();
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [accidentReportId, setAccidentReportId] = useState(0);
    const {
        myInfoState,
        updateState,
        clearReportState,
    } = useMyInfoState();

    const query = new URLSearchParams(search);

    const myinfoAuthCode = query.get('code');
    const myinfoAuthState = query.get('state');
    const myinfoError = query.get('error');

    const requestData = {
        authCode: myinfoAuthCode,
        state: myinfoAuthState,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(requestData),
    };

    const [insertForm] = useMutation(insertAccidentReportMutation);
    const { displayMessage } = useContext(MessageModalContext);

    const insertReport = () => {
        setIsSubmitting(true);
        insertForm({
            variables: {
                accidentReport: mapReportStateToGears(myInfoState),
                saveAsDraft: true,
                source: 'AccidentReportSourceEnum.MyInfoInitiated',
            },
        }).then((resp) => {
            clearReportState();
            if (resp.errors?.length) {
                displayMessage(formatGQLErrors(resp.errors));
            } else {
                setAccidentReportId(
                            resp.data?.insertAccidentReport?.id,
                );
            }
        });
    };

    const fetchMyInfoData = useCallback(() => {
        /* global fetch */
        fetch(MyInfo.personUrl, requestOptions)
            .then((res) => res.json())
            .then((result) => {
                const mappedMyInfoState = mapMyInfoToReportState(result);
                updateState(mappedMyInfoState);
                setIsLoaded(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (myinfoError === MyinfoErrors.AccessDenied) {
            history.push(arcPreForm.path);
            // eslint-disable-next-line max-len
            displayMessage(['In order to use Myinfo data to prefill the form, give consent so the data can be retrieved or fill the report manually']);
            return;
        }
        fetchMyInfoData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLoaded && (myInfoState.ownedVehicles?.length === 0 || myInfoState.isVehicleOwner === false)) {
            insertReport();
            clearReportState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myInfoState.ownedVehicles, myInfoState.isVehicleOwner, isLoaded]);

    const accidentReportFormPath = arcForm.path.split('/')[1];

    if (accidentReportId > 0) {
        return (<Redirect to={`/${accidentReportFormPath}/${accidentReportId}`} />);
    }
    if (isLoaded && myInfoState?.ownedVehicles && myInfoState?.ownedVehicles?.length > 0) {
        return (
            <VehicleInformation
                ownedVehicles={myInfoState?.ownedVehicles}
                setVehicleInfo={(selectedVehicle: IVehicleInfo) => { updateState({ vehicleInfo: selectedVehicle }); }}
                onSubmit={insertReport}
                isSubmitting={isSubmitting}
            />
        );
    }
    return (
        <GridCenteredCircularLoader />
    );
};
