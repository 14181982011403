import React from 'react';

import {
    setupTelemetry, LoggerPlugin, APMPlugin, forLazyRoute as _forLazyRoute, GAPlugin,
} from '@shift/telemetry';


import { telemetryConfig } from 'src/config';
import { runtimeConfig } from 'src/config/runtime-config';

import { graphql } from './graphql';

import { ruleMap } from './TrackingRouterRuleMap';

const { analyticsCode } = runtimeConfig();

export const { TelemetryProvider } = setupTelemetry({
    projectId: 'gears',
    projectName: 'GEARS',
    apollo: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        client: graphql as any,
        autoLink: true,
    },
}, [
    GAPlugin({
        usage: ['Tracking'],
        gaKey: analyticsCode,
        ruleMap,
        strict: true,
    }),
    APMPlugin({
        usage: ['Monitoring'],
        agentConfig: telemetryConfig,
    }),
    LoggerPlugin({
        usage: ['Tracking', 'Monitoring'],
        env: process.env.NODE_ENV === 'development' ? 'DEV' : 'PROD',
        logOnProd: false,
        errorFn: {
            DEV: 'error',
            PROD: 'warn',
        },
        verbose: true,
    }),
]);

export const forLazyRoute = <P, >(Component: React.FC<P>) => _forLazyRoute(['Monitoring', 'Tracking'], Component);
