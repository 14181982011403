import { accidentSection } from './accident-section';
import { ownVehicleSection } from './own-vehicle-section';
import { ownVehiclePolicySection } from './own-vehicle-policy-section';
import { driverSection } from './driver-section';
import { thirdPartySection } from './third-party-section';
import { injuredSection } from './injured-section';
import { witnessSection } from './witness-section';
import { attachmentsSection } from './attachments-section';
import { additionalCommentSection } from './additional-comment-section';

export * from './accident-section';
export * from './own-vehicle-section';
export * from './own-vehicle-policy-section';
export * from './driver-section';
export * from './third-party-section';
export * from './injured-section';
export * from './witness-section';
export * from './attachments-section';
export * from './additional-comment-section';

export const formSections = {
    additionalCommentSection,
    accidentSection,
    ownVehicleSection,
    ownVehiclePolicySection,
    driverSection,
    thirdPartySection,
    injuredSection,
    witnessSection,
    attachmentsSection,
};
