import {
    EnumFormatter, BackendListFormatter, FileWithTypeFormatter, getOffsetDateFormatter, UpperCaseFormatter,
} from 'src/utils/forms/formatters';
import { isValidAlphanumeric, isValidFileWithType, isValidLossAmount } from 'src/utils/forms';

export const initiateRecovery = {
    caseInformationStep: {
        name: 'caseInformation',
        title: 'case-information',
        fields: {
            accidentReportId: {
                path: 'caseInformation.id',
                target: 'initiatingParty.accidentReport.id',
            },
            actionType: {
                path: 'caseInformation.actionType',
                target: 'nextAction.step.action',
            },
            recoveryType: {
                path: 'caseInformation.recoveryType',
                target: 'recoveryType',
                label: 'recovery-type',
                formatter: EnumFormatter,
                isRequired: true,
            },
            officer: {
                path: 'caseInformation.officerIdentity',
                target: 'initiatingParty.officerIdentity',
                label: 'officer-in-charge',
                formatter: BackendListFormatter,
                isRequired: true,
            },
            initiatingPartyInsurer: {
                path: 'caseInformation.ipInsurer',
                target: 'initiatingParty.claim.specializations.mapping_Claim_Insurer.insurer',
                label: 'insurer',
                formatter: EnumFormatter,
                isRequired: true,
            },
            initiatingPartyPolicyholder: {
                path: 'caseInformation.ipPolicyholder',
                target: 'initiatingParty.policyholder.specializations.mapping_Person_Gears.name',
                label: 'policyholder',
                isRequired: true,
            },
            initiatingPartyVehiclePlateNumber: {
                path: 'caseInformation.ipPlateNumber',
                target: 'initiatingParty.vehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                label: 'vehicle-plate-number',
                isRequired: true,
                validate: isValidAlphanumeric,
                formatter: UpperCaseFormatter,
            },
            initiatingPartyLossAmount: {
                path: 'caseInformation.ipLossCost',
                target: 'amount',
                label: 'loss-amount',
                isRequired: true,
                validate: isValidLossAmount,
            },
            initiatingPartyClaimPaymentDate: {
                path: 'caseInformation.claimPaymentDate',
                target: 'paymentDate',
                label: 'claim-payment-date',
                formatter: getOffsetDateFormatter(null),
                isRequired: true,
            },
            initiatingPartyClaimNumber: {
                path: 'caseInformation.ipClaimNumber',
                target: 'initiatingParty.claim.referenceCode',
                label: 'claim-number',
                isRequired: true,
            },
            receivingPartyInsurer: {
                path: 'caseInformation.rpInsurer',
                target: 'receivingParty.claim.specializations.mapping_Claim_Insurer.insurer',
                label: 'insurer',
                formatter: EnumFormatter,
                isRequired: true,
            },
            receivingPartyVehiclePlateNumber: {
                path: 'caseInformation.rpVehiclePlateNumber',
                target: 'receivingParty.vehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                label: 'vehicle-plate-number',
                isRequired: true,
                validate: isValidAlphanumeric,
                formatter: UpperCaseFormatter,
            },
            thirdPartyLossAmount: {
                path: 'caseInformation.tpCost',
                target: 'amount',
                label: 'loss-amount',
                isRequired: true,
                validate: isValidLossAmount,
            },
            thirdPartyClaimPaymentDate: {
                path: 'caseInformation.tpClaimPaymentDate',
                target: 'paymentDate',
                label: 'claim-payment-date',
                formatter: getOffsetDateFormatter(null),
                isRequired: true,
            },
            thirdPartyLegalCost: {
                path: 'caseInformation.tpLegalCost',
                target: 'legalCost',
                label: 'legal-cost',
                isRequired: true,
            },
            thirdPartyVehiclePlateNumber: {
                path: 'caseInformation.tpVehiclePlateNumber',
                target: 'initiatingParty.tpVehicle.specializations.mapping_Object_Motor_VehicleRegistration.0.number',
                label: 'vehicle-plate-number',
                isRequired: true,
                validate: isValidAlphanumeric,
                formatter: UpperCaseFormatter,
            },
        },
    },
    bolaScenarioStep: {
        name: 'bolaCase',
        title: 'bola-scenario',
        fields: {
            bolaScenario: {
                path: 'bolaCase.bolaScenario',
                target: 'nextAction.bolaScenario.id',
                label: 'bola-scenario',
                formatter: EnumFormatter,
                isRequired: true,
            },
            comment: {
                path: 'bolaCase.comment',
                target: 'comment.text.content',
                label: 'ip-version',
            },
            commentType: {
                path: 'bolaCase.commentType',
                target: 'comment.commentType',
                label: '',
            },
        },
    },
    importedDocumentsStep: {
        name: 'importedDocumentsStep',
        title: 'importedDocuments',
        fields: {
            attachments: {
                path: 'importedDocumentsStep.attachments',
                label: 'imported-documents',
                target: {
                    name: 'importedDocuments',
                    item: {
                        document: 'document',
                        category: 'category',
                    },
                },
                formatter: FileWithTypeFormatter,
                validate: isValidFileWithType,
            },
        },
    },
    attachmentsStep: {
        name: 'attachmentStep',
        title: 'attachments',
        fields: {
            attachments: {
                path: 'attachmentStep.attachments',
                label: 'new-attachments',
                target: {
                    name: 'nextAction.attachments',
                    item: {
                        document: 'document',
                        category: 'category',
                    },
                },
                formatter: FileWithTypeFormatter,
                validate: isValidFileWithType,
            },
        },
    },
};
