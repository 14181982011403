/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Widgetter } from '@shift/transform';

export interface MultiLineTextWidgetterProps {
    children: string;
}

const MultiLineTextWidget = ({ children }: MultiLineTextWidgetterProps) => {
    const text = children.split('\n');
    const isMultiLine = text.length > 1;

    return (
        <>
            {isMultiLine ? text.map((line, index) => <div key={index}>{line.trim()}</div>) : children}
        </>
    );
};
const MultiLineWidget = ({ children }: { children: any[] }) => (
    <>
        {Array.isArray(children) ? children.map((line, index) => <div key={index}>{line.trim()}</div>) : children}
    </>
);

export const MultiLineTextWidgetter: Widgetter<MultiLineTextWidgetterProps> = {
    transform: MultiLineTextWidget,
};
export const MultiLineWidgetter: Widgetter<{ children: any[] }> = {
    transform: MultiLineWidget,
};
