import { BackendListFormatter, CheckboxesToEnumFormatter } from '../../../utils/forms/formatters';

export const sendBackRecovery = {
    singleSection: {
        name: 'sendBack',
        title: 'send-back',
        fields: {
            workflowId: {
                path: 'workflowId',
            },
            actionType: {
                path: 'sendBack.actionType',
                target: 'nextAction.step.action',
            },
            // claimNumber is not needed for now
            // Let's keep it commented if it's not the case in the future
            // claimNumber: {
            //     path: 'sendBack.claimNumber',
            //     target: 'receivingParty.claim.referenceCode',
            //     label: 'claim-number',
            // },
            officer: {
                path: 'sendBack.receivingParty.officerIdentity',
                target: 'receivingParty.officerIdentity',
                label: 'officer-in-charge',
                formatter: BackendListFormatter,
            },
            sendBackReason: {
                path: 'sendBack.sendBackReason',
                target: 'nextAction.step.specializations.ui_WorkflowStep_Reason',
                label: 'send-back-reason',
                isRequired: true,
                formatter: CheckboxesToEnumFormatter,
            },
            comment: {
                path: 'sendBack.comment',
                target: 'comment.text.content',
                label: 'comment',
            },
            commentType: {
                path: 'sendBack.commentType',
                target: 'comment.commentType',
                label: '',
            },
        },
    },
};
