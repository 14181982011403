import { useEffect, useState } from 'react';

const getKeyboardFocusableElements = (element = document) => [...element.querySelectorAll(
    'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
)].filter((el) => !el.hasAttribute('disabled'));

export const useKeyboardFocusableToggle = (ref: any) => {
    const [keyboardFocusables, setKeyboardFocusables] = useState<Element[]>([]);

    useEffect(() => {
        const { current } = ref;
        setKeyboardFocusables(getKeyboardFocusableElements(current));
    }, [ref]);


    return (visible: boolean) => {
        keyboardFocusables.forEach((element) => {
            element.setAttribute('tabindex', visible ? '0' : '-1');
        });
    };
};
