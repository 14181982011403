import {
    createStore, compose, applyMiddleware, Store,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { RootReducer } from './reducers';

let reduxStore: Store;
if (process.env.NODE_ENV === 'production') {
    reduxStore = createStore((RootReducer), compose(applyMiddleware(thunkMiddleware)));
} else {
    const composeEnhancers = composeWithDevTools({});
    reduxStore = createStore(RootReducer, composeEnhancers(
        applyMiddleware(thunkMiddleware),
    ));
}

export const store = reduxStore;
