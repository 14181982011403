import React from 'react';

import { useSecurity } from './Security';
import { Claims } from './Authenticator';

export type SecureProps = {
    permissions?: (user: Claims) => boolean;
    fallback?: React.ReactElement;
};

export const Secure: React.FC<SecureProps> = ({ permissions, children, fallback }) => {
    const { user } = useSecurity();

    if (!user) {
        return null;
    }
    if (permissions && !permissions(user)) {
        return fallback || null;
    }

    return <>{children}</>;
};
