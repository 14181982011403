import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { colors, theme, variants } from 'src/constants';
import { GraphQLProvider } from 'src/graphql/GraphQLProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@shift/design-system';
import { store } from 'src/store/store';

import { SecurityProvider } from 'src/libs/auth';
import { FetchProvider } from 'src/libs/fetch/FetchProvider';

import { MessageModalProvider } from 'src/MessageModal';
import { FilterProvider } from 'src/libs/filters';

import { GraphQLQueryOptionsProvider } from 'src/config/query-options';
import { gearsConfig } from '../../config/configuration/clients/GIA';
import { GearsConfigProvider } from '../../config/configuration/GearsConfigProvider/GearsConfigProvider';

import { TelemetryProvider } from './Telemetry';
import { auth, accessToken } from './auth';
import { graphql } from './graphql';

export const Providers = ({ children }: { children: React.ReactNode }) => (
    <React.StrictMode>
        <ThemeProvider colors={colors} theme={theme} variants={variants}>
            <Router basename="/">
                <MessageModalProvider>
                    <SecurityProvider auth={auth}>
                        <GearsConfigProvider configuration={gearsConfig}>
                            <GraphQLProvider client={graphql}>
                                <FetchProvider token={accessToken}>
                                    <ReduxProvider store={store}>
                                        <FilterProvider>
                                            <GraphQLQueryOptionsProvider>
                                                <TelemetryProvider>
                                                    {children}
                                                </TelemetryProvider>
                                            </GraphQLQueryOptionsProvider>
                                        </FilterProvider>
                                    </ReduxProvider>
                                </FetchProvider>
                            </GraphQLProvider>
                        </GearsConfigProvider>
                    </SecurityProvider>
                </MessageModalProvider>
            </Router>
        </ThemeProvider>
    </React.StrictMode>
);
