/* eslint-disable @typescript-eslint/camelcase */
import {
    recoveryActions, recoverableAmountEnum, recoveryStates, bolaCaseCommentType, recoveryReceivingParty, recoveryInitiatingParty,
} from 'src/constants';

const ip = {
    createDateUtc: '01/01/2020T01:01:00',
    id: 1001,
    identity: {
        id: 1001,
        insurerEntity: {
            id: 1001,
            insurer: {
                id: 1001,
                insurerCode: {
                    Name: 'InsurerA',
                },
            },
        },
    },
};

const rp = {
    id: 1002,
    identity: {
        id: 1002,
        insurerEntity: {
            id: 1002,
            insurer: {
                id: 1002,
                insurerCode: {
                    Name: 'InsurerB',
                },
            },
        },
    },
};

export const initiateStep = {
    id: 1,
    action: recoveryActions.initiate,
    state: recoveryStates.pendingCounterActionByRp,
    effectiveDateUtc: '01/01/2020T01:01:00',
    creator: ip,
    accidentCase: [
        {
            caseEnumValueId: 1,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 1,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 1,
            content: 'Initiate comment',
            links: {
                id: 1,
                ui_WorkflowStepToComment: [
                    {
                        id: 1,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptStep = {
    id: 2,
    action: recoveryActions.accept,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '02/02/2020T02:02:00',
    creator: rp,
    specializations: {
        id: 2,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 2,
            content: 'Accept comment',
            links: {
                id: 2,
                ui_WorkflowStepToComment: [
                    {
                        id: 2,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptDefaultedStep = {
    id: 200,
    action: recoveryActions.default,
    state: recoveryStates.pendingPayment,
    accidentCase: [
        {
            caseEnumValueId: 1,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    effectiveDateUtc: '02/02/2020T02:02:00',
    creator: rp,
    specializations: {
        id: 200,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 200,
            content: 'Accept defaulted comment',
            links: {
                id: 200,
                ui_WorkflowStepToComment: [
                    {
                        id: 200,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const waiveStep = {
    id: 3,
    action: recoveryActions.waive,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '03/03/2020T03:03:00',
    creator: ip,
    specializations: {
        id: 3,
        ui_WorkflowStep_Reason: {
            id: 3,
            reasonEnumIdentifier: 'RecoveryWaiveReason',
            reasonEnumValueId: 1,
        },
    },
    comments: [
        {
            id: 3,
            content: 'Waive comment',
            links: {
                id: 3,
                ui_WorkflowStepToComment: [
                    {
                        id: 3,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const waiveAcceptStep = {
    id: 4,
    action: recoveryActions.waive,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '04/04/2020T04:04:00',
    creator: rp,
    specializations: {
        id: 4,
        ui_WorkflowStep_Reason: {
            id: 4,
            reasonEnumIdentifier: 'RecoveryWaiveReason',
            reasonEnumValueId: 3,
        },
    },
    comments: [
        {
            id: 4,
            content: 'Waive Accept comment',
            links: {
                id: 4,
                ui_WorkflowStepToComment: [
                    {
                        id: 4,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const sendPaymentStep = {
    id: 5,
    action: recoveryActions.sendPayment,
    state: recoveryStates.pendingPaymentAcknowledgeByIp,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:05:00',
    creator: rp,
    specializations: {
        id: 5,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 5,
            content: 'Send payment comment',
            links: {
                id: 5,
                ui_WorkflowStepToComment: [
                    {
                        id: 5,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acknowledgePaymentStep = {
    id: 6,
    action: recoveryActions.acknowledgePayment,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '06/06/2020T06:06:00',
    creator: ip,
    specializations: {
        id: 6,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 6,
            content: 'Acknowledge payment comment',
            links: {
                id: 6,
                ui_WorkflowStepToComment: [
                    {
                        id: 6,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const initiateDrmStep = {
    id: 9,
    action: recoveryActions.drmInitiateEscalation,
    state: recoveryStates.pendingDrmApproveInitiateEscalation,
    effectiveDateUtc: '09/09/2020T09:09:00',
    creator: ip,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 1,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 1,
            content: 'Initiate DRM comment',
            links: {
                id: 1,
                ui_WorkflowStepToComment: [
                    {
                        id: 1,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const cancelDrmIpStep = {
    id: 10,
    action: recoveryActions.drmCancel,
    state: recoveryStates.pendingSecondActionByIp,
    effectiveDateUtc: '10/10/2020T10:10:00',
    creator: ip,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 0,
                    yLiablity: 100,
                },
            },
        },
    ],
    specializations: {
        id: 10,
        ui_WorkflowStep_Reason: {
            id: 10,
            reasonEnumIdentifier: 'RecoveryWaiveReason',
            reasonEnumValueId: 3,
        },
    },
    comments: [
        {
            id: 10,
            content: 'Cancel DRM (by IP) comment',
            links: {
                id: 10,
                ui_WorkflowStepToComment: [
                    {
                        id: 10,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const cancelDrmRpStep = {
    id: 1001,
    action: recoveryActions.drmCancel,
    state: recoveryStates.pendingFinalActionByRp,
    effectiveDateUtc: '10/10/2020T10:11:00',
    creator: rp,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 0,
                    yLiablity: 100,
                },
            },
        },
    ],
    specializations: {
        id: 1001,
        ui_WorkflowStep_Reason: {
        },
    },
    comments: [
        {
            id: 1001,
            content: 'Cancel DRM (by RP) comment',
            links: {
                id: 1001,
                ui_WorkflowStepToComment: [
                    {
                        id: 1001,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const approveInitiateDrmStep = {
    id: 11,
    action: recoveryActions.drmApproveInitiateEscalation,
    state: recoveryStates.pendingDrmEscalationResponseByCounterParty,
    effectiveDateUtc: '11/11/2020T11:11:00',
    creator: ip,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 11,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 11,
            content: 'Approve initiate DRM comment',
            links: {
                id: 11,
                ui_WorkflowStepToComment: [
                    {
                        id: 11,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const counterOfferStep = {
    id: 7,
    action: recoveryActions.counterOffer,
    state: recoveryStates.pendingSecondActionByIp,
    effectiveDateUtc: '07/07/2020T07:07:00',
    creator: rp,
    accidentCase: [
        {
            caseEnumValueId: 2,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 50,
                    yLiablity: 50,
                },
            },
        },
    ],
    specializations: {
        id: 7,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 7,
            content: 'Counter offer comment',
            links: {
                id: 7,
                ui_WorkflowStepToComment: [
                    {
                        id: 7,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptCounterOfferStep = {
    id: 71,
    action: recoveryActions.accept,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '07/07/2020T07:08:00',
    creator: ip,
    specializations: {
        id: 71,
        ui_WorkflowStep_Reason: {
        },
    },
    comments: [
        {
            id: 71,
            content: 'Accept counter offer comment',
            links: {
                id: 71,
                ui_WorkflowStepToComment: [
                    {
                        id: 71,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptCounterOfferDefaultedStep = {
    id: 72,
    action: recoveryActions.default,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '07/07/2020T07:09:00',
    creator: ip,
    specializations: {
        id: 72,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 72,
            content: 'Accept counter-offer defaulted comment',
            links: {
                id: 72,
                ui_WorkflowStepToComment: [
                    {
                        id: 72,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const counterOfferNoLiabilityStep = {
    id: 73,
    action: recoveryActions.counterOffer,
    state: recoveryStates.pendingSecondActionByIp,
    effectiveDateUtc: '07/07/2020T07:10:00',
    creator: rp,
    accidentCase: [
        {
            caseEnumValueId: 2,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 73,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 73,
            content: 'Counter offer comment',
            links: {
                id: 73,
                ui_WorkflowStepToComment: [
                    {
                        id: 73,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptCounterOfferNoLiabilityStep = {
    id: 74,
    action: recoveryActions.accept,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '07/07/2020T07:11:00',
    creator: ip,
    specializations: {
        id: 74,
        ui_WorkflowStep_Reason: {
        },
    },
    comments: [
        {
            id: 74,
            content: 'Accept counter offer (no liability) comment',
            links: {
                id: 74,
                ui_WorkflowStepToComment: [
                    {
                        id: 74,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptCounterOfferNoLiabilityDefaultedStep = {
    id: 75,
    action: recoveryActions.default,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '07/07/2020T07:12:00',
    creator: ip,
    specializations: {
        id: 75,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 75,
            content: 'Accept counter-offer (no liability) defaulted comment',
            links: {
                id: 75,
                ui_WorkflowStepToComment: [
                    {
                        id: 75,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const finalOfferStep = {
    id: 8,
    action: recoveryActions.finalOffer,
    state: recoveryStates.pendingFinalActionByRp,
    effectiveDateUtc: '08/08/2020T08:08:00',
    creator: ip,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 1,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 1,
            content: 'Final offer comment',
            links: {
                id: 1,
                ui_WorkflowStepToComment: [
                    {
                        id: 1,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptFinalOfferStep = {
    id: 81,
    action: recoveryActions.accept,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '08/08/2020T08:09:00',
    creator: rp,
    specializations: {
        id: 81,
        ui_WorkflowStep_Reason: {
        },
    },
    comments: [
        {
            id: 81,
            content: 'Accept final offer comment',
            links: {
                id: 81,
                ui_WorkflowStepToComment: [
                    {
                        id: 81,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acceptFinalOfferDefaultedStep = {
    id: 82,
    action: recoveryActions.default,
    state: recoveryStates.pendingPayment,
    accidentCase: [
        {
            caseEnumValueId: 1,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    effectiveDateUtc: '08/08/2020T08:10:00',
    creator: ip,
    specializations: {
        id: 82,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 82,
            content: 'Accept final offer defaulted comment',
            links: {
                id: 82,
                ui_WorkflowStepToComment: [
                    {
                        id: 82,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmStep = {
    id: 12,
    action: recoveryActions.drmWithdraw,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:05:00',
    creator: ip,
    specializations: {
        id: 12,
        ui_WorkflowStep_Reason: {
            id: 12,
            reasonEnumIdentifier: 'RecoveryWithdrawReason',
            reasonEnumValueId: 4,
        },
    },
    comments: [
        {
            id: 12,
            content: 'Withdraw DRM comment',
            links: {
                id: 12,
                ui_WorkflowStepToComment: [
                    {
                        id: 12,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmAcceptStep = {
    id: 121,
    action: recoveryActions.drmWithdraw,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:06:00',
    creator: rp,
    specializations: {
        id: 121,
        ui_WorkflowStep_Reason: {
            id: 121,
            reasonEnumIdentifier: 'RecoveryWithdrawReason',
            reasonEnumValueId: 2,
        },
    },
    comments: [
        {
            id: 121,
            content: 'Withdraw DRM 2 comment',
            links: {
                id: 121,
                ui_WorkflowStepToComment: [
                    {
                        id: 121,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmStepWithAcknowledge = {
    id: 122,
    action: recoveryActions.drmWithdraw,
    state: recoveryStates.pendingDrmAcknowledgeWithdrawal,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:07:00',
    creator: ip,
    specializations: {
        id: 122,
        ui_WorkflowStep_Reason: {
            id: 122,
            reasonEnumIdentifier: 'RecoveryWithdrawReason',
            reasonEnumValueId: 3,
        },
    },
    comments: [
        {
            id: 122,
            content: 'Withdraw DRM comment',
            links: {
                id: 122,
                ui_WorkflowStepToComment: [
                    {
                        id: 122,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmAcknowledgedStep = {
    id: 123,
    action: recoveryActions.drmAcknowledgeWithdraw,
    state: recoveryStates.pendingPayment,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:08:00',
    creator: rp,
    specializations: {
        id: 123,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 123,
            content: 'Acknowledge Withdraw DRM comment',
            links: {
                id: 123,
                ui_WorkflowStepToComment: [
                    {
                        id: 123,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmRejectedStep = {
    id: 124,
    action: recoveryActions.drmRejectWithdraw,
    state: recoveryStates.pendingDrmGiaAdjudication,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:09:00',
    creator: rp,
    specializations: {
        id: 124,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 124,
            content: 'Reject Withdraw DRM comment',
            links: {
                id: 124,
                ui_WorkflowStepToComment: [
                    {
                        id: 124,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const withdrawDrmDefaultedStep = {
    id: 125,
    action: recoveryActions.default,
    state: recoveryStates.pendingDrmGiaAdjudication,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:10:00',
    creator: rp,
    specializations: {
        id: 125,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 125,
            content: 'Default Withdraw DRM comment',
            links: {
                id: 125,
                ui_WorkflowStepToComment: [
                    {
                        id: 125,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const respondDrmStep = {
    id: 13,
    action: recoveryActions.drmRespondEscalation,
    state: recoveryStates.pendingDrmApproveRespondEscalation,
    effectiveDateUtc: '01/01/2021T01:01:00',
    creator: rp,
    accidentCase: [
        {
            caseEnumValueId: 4,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 50,
                    yLiablity: 50,
                },
            },
        },
    ],
    specializations: {
        id: 13,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 13,
            content: 'Respond DRM comment',
            links: {
                id: 13,
                ui_WorkflowStepToComment: [
                    {
                        id: 13,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const approveRespondDrmStep = {
    id: 14,
    action: recoveryActions.drmApproveRespondEscalation,
    state: recoveryStates.pendingDrmGiaAdjudication,
    effectiveDateUtc: '02/02/2021T02:02:00',
    creator: rp,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    specializations: {
        id: 14,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 14,
            content: 'Approve respond DRM comment',
            links: {
                id: 14,
                ui_WorkflowStepToComment: [
                    {
                        id: 14,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const respondDrmDefaultedStep = {
    id: 141,
    action: recoveryActions.default,
    state: recoveryStates.pendingDrmGiaAdjudication,
    accidentCase: [],
    effectiveDateUtc: '05/05/2020T05:06:00',
    creator: ip,
    specializations: {
        id: 141,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 141,
            content: 'Withdraw DRM defaulted comment',
            links: {
                id: 141,
                ui_WorkflowStepToComment: [
                    {
                        id: 141,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const sendBackStep = {
    id: 16,
    action: recoveryActions.sendback,
    state: recoveryStates.sentback,
    accidentCase: [],
    effectiveDateUtc: '04/04/2021T04:04:00',
    creator: rp,
    specializations: {
        id: 16,
        ui_WorkflowStep_Reason: {
            reasonEnumIdentifier: 'RecoverySendbackReason',
            reasonEnumValueId: 1,
        },
    },
    comments: [
        {
            id: 16,
            content: 'Sendback comment',
            links: {
                id: 16,
                ui_WorkflowStepToComment: [
                    {
                        id: 16,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const sendBackNoReinitiateStep = {
    id: 161,
    action: recoveryActions.sendback,
    state: recoveryStates.closed,
    accidentCase: [],
    effectiveDateUtc: '04/04/2021T04:05:00',
    creator: rp,
    specializations: {
        id: 161,
        ui_WorkflowStep_Reason: {
            reasonEnumIdentifier: 'RecoverySendbackReason',
            reasonEnumValueId: 2,
        },
    },
    comments: [
        {
            id: 161,
            content: 'Sendback comment',
            links: {
                id: 161,
                ui_WorkflowStepToComment: [
                    {
                        id: 161,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const reinitiateStep = {
    id: 161,
    action: recoveryActions.reinitiate,
    state: recoveryStates.pendingCounterActionByRp,
    accidentCase: [
        {
            caseEnumValueId: 1,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    effectiveDateUtc: '04/04/2021T04:05:00',
    creator: ip,
    specializations: {
        id: 161,
        ui_WorkflowStep_Reason: {
        },
    },
    comments: [
        {
            id: 161,
            content: 'Reinitiate comment',
            links: {
                id: 161,
                ui_WorkflowStepToComment: [
                    {
                        id: 161,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const drmGiaAdjudicateIpStep = {
    id: 17,
    action: recoveryActions.drmGiaAdjudicate,
    state: recoveryStates.pendingPayment,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 0,
                    yLiablity: 100,
                },
            },
        },
    ],
    effectiveDateUtc: '05/05/2021T05:05:00',
    creator: null,
    specializations: {
        id: 17,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 17,
            content: 'GIA adjudication (IP) comment',
            links: {
                id: 17,
                ui_WorkflowStepToComment: [
                    {
                        id: 17,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const drmGiaAdjudicateRpStep = {
    id: 171,
    action: recoveryActions.drmGiaAdjudicate,
    state: recoveryStates.closed,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    effectiveDateUtc: '05/05/2021T05:06:00',
    creator: null,
    specializations: {
        id: 171,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 171,
            content: 'GIA adjudication (RP) comment',
            links: {
                id: 17,
                ui_WorkflowStepToComment: [
                    {
                        id: 17,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const drmGiaRejectStep = {
    id: 18,
    action: recoveryActions.drmGiaReject,
    state: recoveryStates.closed,
    accidentCase: [
        {
            caseEnumValueId: 3,
            specializations: {
                mapping_AccidentCase_LiabilityScenario_Gia: {
                    xLiablity: 100,
                    yLiablity: 0,
                },
            },
        },
    ],
    effectiveDateUtc: '06/06/2021T06:06:00',
    creator: null,
    specializations: {
        id: 18,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 18,
            content: 'GIA rejection comment',
            links: {
                id: 18,
                ui_WorkflowStepToComment: [
                    {
                        id: 18,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const sendPaymentDefaultedStep = {
    id: 19,
    action: recoveryActions.defaultPayment,
    state: recoveryStates.paymentDefaulted,
    accidentCase: [],
    effectiveDateUtc: '07/07/2022T07:07:00',
    creator: rp,
    specializations: {
        id: 5,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 5,
            content: 'Send payment defaulted comment',
            links: {
                id: 5,
                ui_WorkflowStepToComment: [
                    {
                        id: 5,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const acknowledgePaymentDefaultedStep = {
    id: 20,
    action: recoveryActions.defaultPayment,
    state: recoveryStates.paymentDefaulted,
    accidentCase: [],
    effectiveDateUtc: '08/08/2020T08:08:00',
    creator: ip,
    specializations: {
        id: 5,
        ui_WorkflowStep_Reason: {},
    },
    comments: [
        {
            id: 5,
            content: 'Acknowledge payment defaulted comment',
            links: {
                id: 5,
                ui_WorkflowStepToComment: [
                    {
                        id: 5,
                        roleEnumValueId: bolaCaseCommentType.id,
                    },
                ],
            },
        },
    ],
};

export const recoveryData = {
    id: 1,
    workflow: {
        id: 1,
        referenceCode: 'RCV7104',
        createDateUtc: '01/01/2020T01:01:00',
        party: {
            Name: 'InitiatingParty',
        },
        claims: [
            {
                id: 101,
                specializations: {
                    id: 101,
                    mapping_Claim_Insurer: {
                        id: ip.identity.insurerEntity.insurer.id,
                        insurer: {
                            Name: ip.identity.insurerEntity.insurer.insurerCode.Name,
                        },
                    },
                },
                links: {
                    id: 101,
                    ui_WorkflowToClaim: [
                        {
                            id: 101,
                            roleEnumValueId: recoveryInitiatingParty,
                        },
                    ],
                },
                objects: [
                    {
                        id: 101,
                        specializations: {
                            id: 101,
                            mapping_Object_Motor_VehicleRegistration: [
                                {
                                    id: 101,
                                    number: 'IP_CAR',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                id: 102,
                specializations: {
                    id: 102,
                    mapping_Claim_Insurer: {
                        id: rp.identity.insurerEntity.insurer.id,
                        insurer: {
                            Name: rp.identity.insurerEntity.insurer.insurerCode.Name,
                        },
                    },
                },
                links: {
                    id: 102,
                    ui_WorkflowToClaim: [
                        {
                            id: 102,
                            roleEnumValueId: recoveryReceivingParty,
                        },
                    ],
                },
                objects: [
                    {
                        id: 102,
                        specializations: {
                            mapping_Object_Motor_VehicleRegistration: [
                                {
                                    id: 102,
                                    number: 'RP_CAR',
                                },
                            ],
                        },
                    },
                ],
            },
        ],
        officer: {
            id: 1001,
            partySide: {
                Name: 'InitiatingParty',
            },
            identity: {
                id: 1001,
                insurerEntity: {
                    id: 1001,
                    person: {
                        id: 1001,
                        specializations: {
                            id: 1001,
                            mapping_Person_Gears: {
                                id: 1001,
                                name: 'Officer B',
                            },
                        },
                    },
                },
            },
        },
        documents: [{
            id: 1,
            documentName: 'left_back',
            specializations: {
                id: 1,
                document_Document_DestinationDiskStorageInformation: {
                    id: 1,
                    fileSize: 1234,
                },
            },
            links: {
                id: 1,
                ui_WorkflowToDocument: [
                    {
                        id: 1,
                        roleEnumValueId: 1,
                    },
                ],
            },
        },
        {
            id: 2,
            documentName: 'left_side',
            specializations: {
                id: 2,
                document_Document_DestinationDiskStorageInformation: {
                    id: 2,
                    fileSize: 4321,
                },
            },
            links: {
                id: 2,
                ui_WorkflowToDocument: [
                    {
                        id: 2,
                        roleEnumValueId: 1,
                    },
                ],
            },
        }],
    },
    payments: [
        {},
    ],
    accidentReports: [
        {
            id: 1,
            links: {
                id: 1,
                mapping_RecoveryTarget: [
                    {
                        id: 1,
                        entityRoleEnumValueId: 1,
                    },
                ],
            },
        },
    ],
    amounts: {
        id: 1,
        specializations: {
            id: 1,
            mapping_Recovery_Amount: [
                {
                    id: 1,
                    amount: 1500,
                    createDateUtc: '01/01/2020T02:03:00',
                    currency: {
                        Name: 'SGD',
                    },
                    identifier: {
                        Name: recoverableAmountEnum,
                    },
                },
            ],
        },
    },
};
