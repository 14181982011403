/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
    Grid, GridItem,
    SubmitButton,
} from '@shift/design-system';
import { FormSideBar, ChangeTrackerProvider } from 'src/components/form';
import { formSections } from 'src/config/form';
import { AnyType } from 'src/types';

import { SecondarySubmitButton } from 'src/components/button';
import { ContextTranslateProvider, ContextTranslate as CT } from 'src/utils/translation';
import { isDraft, isMyInfoInitiated } from 'src/constants';
import { onlyPreventDefault } from 'src/utils/forms';
import { noop } from 'src/utils';
import {
    AccidentSection,
    OwnVehicleSection,
    OwnVehiclePolicySection,
    OwnDriverSection,
    ThirdPartySection,
    InjuredSection,
    WitnessSection,
    AttachmentsSection,
    AdditionalCommentSection,
} from './sections';

interface FormProps {
    onSubmit: (accidenReport: AnyType, formErrors: AnyType, isDraft: boolean) => void;
    initialValue: AnyType;
    submitting: boolean;
    isDraft: boolean;
}

const isAddendum = (initialValue: AnyType) => (
    initialValue?.generalInformation?.accidentInformation?.status
    && (!isDraft(initialValue.generalInformation.accidentInformation.status.Name)
    && !isMyInfoInitiated(initialValue.generalInformation.accidentInformation.status.Name))
);

const isAdditionalCommentSectionVisible = (initialValue: AnyType) => initialValue?.generalInformation?.accidentInformation?.status
        && isDraft(initialValue.generalInformation.accidentInformation.status.Name)
        && initialValue?.generalInformation?.accidentInformation?.specializations?.mapping_Claim_FreeText?.eventDescription
        && initialValue.generalInformation.accidentInformation.specializations.mapping_Claim_FreeText.eventDescription.trim().length !== 0;

const AccidentReportForm = ({
    onSubmit, initialValue, submitting,
}: FormProps) => {
    const [submittingDraft, setSubmittingDraft] = useState(false);
    let visibleFormSections = {};
    if (isAdditionalCommentSectionVisible(initialValue)) {
        visibleFormSections = formSections;
    } else {
        visibleFormSections = Object.values(formSections).filter((it) => it.name !== 'additionalComment');
    }

    return (
        <Form
            onSubmit={noop}
            initialValues={initialValue}
            render={() => (
                <ContextTranslateProvider workspace="accident-report-form">
                    <Grid>
                        <GridItem
                            className={classNames('wrapper-sidebar-left')}
                        >
                            <FormSideBar
                                className={classNames('sidebar-left')}
                                sections={visibleFormSections}
                            />
                        </GridItem>
                        <GridItem className={classNames('form-container')}>
                            <div className="form-header"><CT k={initialValue?.id ? 'new-amendment' : 'accident-report'} /></div>
                            <form onSubmit={onlyPreventDefault} autoComplete="off">
                                <ChangeTrackerProvider enabled={isAddendum(initialValue)}>
                                    {isAdditionalCommentSectionVisible(initialValue) && <AdditionalCommentSection />}
                                    <AccidentSection initialValue={initialValue} />
                                    <OwnVehicleSection initialValue={initialValue} />
                                    <OwnVehiclePolicySection initialValue={initialValue} />
                                    <OwnDriverSection initialValue={initialValue} />
                                    <ThirdPartySection />
                                    <InjuredSection />
                                    <WitnessSection />
                                    <AttachmentsSection />
                                    <FormSpy subscription={{ submitting: true, values: true, errors: true }}>
                                        {({ values: submitValues, errors: submitErrors }) => (
                                            <Grid style={{ justifyContent: 'flex-end', marginBottom: '1rem' }}>
                                                {!isAddendum(initialValue) && (
                                                    <GridItem style={{ marginLeft: '1rem' }}>
                                                        <SecondarySubmitButton
                                                            submitting={submitting && submittingDraft}
                                                            disabled={submitting}
                                                            onClick={() => {
                                                                setSubmittingDraft(true);
                                                                onSubmit(submitValues, submitErrors, true);
                                                            }}
                                                        >
                                                            <CT k="save-draft" />
                                                        </SecondarySubmitButton>
                                                    </GridItem>
                                                )}
                                                <GridItem style={{ marginLeft: '1rem' }}>
                                                    <SubmitButton
                                                        submitting={submitting && !submittingDraft}
                                                        disabled={submitting}
                                                        onClick={() => {
                                                            setSubmittingDraft(false);
                                                            onSubmit(submitValues, submitErrors, false);
                                                        }}
                                                    >
                                                        <CT k="submit-report" />
                                                    </SubmitButton>
                                                </GridItem>
                                            </Grid>
                                        )}
                                    </FormSpy>
                                </ChangeTrackerProvider>
                            </form>
                        </GridItem>
                    </Grid>
                </ContextTranslateProvider>
            )}
            mutators={{
                ...arrayMutators,
            }}
            subscription={{}}
        />
    );
};

AccidentReportForm.defaultProps = {
    initialValue: {},
    error: null,
};

export { AccidentReportForm };
