import React, { useMemo } from 'react';
import { PhoneInput } from '@shift/design-system';
import { formSections } from 'src/config/form';
import { formTranslate, isValuePopulatedByMyinfo } from 'src/utils/forms';
import { useArcInsurersList } from 'src/utils/hooks';
import {
    FormField, FormSection, EnumSelectInput, ExpandableField,
} from 'src/components/form';
import { isMyInfoInitiated, sgCountryCode } from 'src/constants';
import { AnyType } from 'src/types';
import { GetPhoneItem } from '../array-items';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const OwnVehiclePolicySection = ({ initialValue }: AnyType) => {
    const insurerCodeList = useArcInsurersList();
    const { name, title, fields } = formSections.ownVehiclePolicySection;
    const PhoneItem = useMemo(() => GetPhoneItem(fields.altPhones.item), [fields]);
    const isMyInfoInitiatedStatus = isMyInfoInitiated(initialValue?.generalInformation?.accidentInformation?.status?.Name);

    return (
        <FormSection title={formTranslate(title)} name={name}>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.insuranceCompany}
                        component={EnumSelectInput}
                        options={insurerCodeList?.options}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.policyNumber}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.registeredOwnerIdType}
                        component={EnumSelectInput}
                        enumName="NationalIdentifierType"
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.registeredOwnerIdType.path, initialValue)}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.registeredOwnerId}
                        component={TextInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.registeredOwnerId.path, initialValue)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.registeredOwnerName}
                        component={TextInput}
                        disabled={isMyInfoInitiatedStatus && isValuePopulatedByMyinfo(fields.registeredOwnerName.path, initialValue)}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        tooltip={formTranslate('email-hint')}
                        fieldInfo={fields.email}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={fields.primaryMobilePhone}
                        component={PhoneInput}
                        defaultValue={sgCountryCode}
                    />
                </div>
            </div>
            <ExpandableField
                fieldInfo={fields.altPhones}
                addItemLabel="add-alt-phone-no"
                itemTitle="Alt Phone"
                Item={PhoneItem}
                emptyElement={{
                    phone: {
                        countryCode: 'SG',
                    },
                }}
                initialItemsCount={0}
                minCount={0}
            />
        </FormSection>
    );
};
