import {
    EnumFormatter, PhoneNumberFormatter, OtherNoEnumTypeFormatter, TrimWhitespacesFormatter,
} from 'src/utils/forms/formatters';
import {
    phoneNumberRequired, isValidPhoneNumber, isValidNationalIdentifier, isValidEmail,
} from 'src/utils/forms';

export const ownVehiclePolicySection = {
    name: 'ownVehiclePolicy',
    title: 'own-vehicle-policy',
    fields: {
        insuranceCompany: {
            path: 'ownVehiclePolicy.insuranceCompany',
            target: 'ownVehicle.vehiclePolicy.policy.specializations.mapping_Policy_Insurer.insurer',
            label: 'insurance-company',
            formatter: EnumFormatter,
            isRequired: true,
        },
        policyNumber: {
            path: 'ownVehiclePolicy.policyNumber',
            target: 'ownVehicle.vehiclePolicy.policy.referenceCode',
            label: 'policy-number',
            formatter: TrimWhitespacesFormatter,
        },
        registeredOwnerName: {
            path: 'ownVehiclePolicy.registeredOwnerName',
            target: 'ownVehicle.owner.person.specializations.mapping_Person_Gears.name',
            label: 'registered-owner-name',
            isRequired: true,
        },
        registeredOwnerIdType: {
            path: 'ownVehiclePolicy.registeredOwnerIdType',
            target: 'ownVehicle.owner.person.specializations.mapping_Person_NationalIdentifier.0.identifier',
            label: 'registered-owner-id-type',
            formatter: EnumFormatter,
            isRequired: true,
        },
        registeredOwnerId: {
            path: 'ownVehiclePolicy.registeredOwnerId',
            target: 'ownVehicle.owner.person.specializations.mapping_Person_NationalIdentifier.0.nationalIdentifier',
            label: 'registered-owner-id',
            formatter: TrimWhitespacesFormatter,
            isRequired: true,
            validate: isValidNationalIdentifier('ownVehiclePolicy.registeredOwnerIdType'),
        },
        email: {
            path: 'ownVehiclePolicy.email',
            target: 'ownVehicle.owner.email.address',
            label: 'email',
            isRequired: true,
            validate: isValidEmail,
        },
        primaryMobilePhone: {
            path: 'ownVehiclePolicy.primaryPhoneNumber',
            label: 'phone-number',
            target: {
                number: 'ownVehicle.owner.primaryPhoneNumber.number',
                countryCode: 'ownVehicle.owner.primaryPhoneNumber.countryCode',
                phoneNumberType: 'ownVehicle.owner.primaryPhoneNumber.phoneNumberType',
            },
            formatter: PhoneNumberFormatter,
            isRequired: true,
            requiredFunction: phoneNumberRequired,
            validate: isValidPhoneNumber,
        },
        altPhones: {
            path: 'ownVehiclePolicy.alternativePhoneNumbers',
            target: 'ownVehicle.owner.alternativePhoneNumbers',
            label: 'alt-phones',
            item: {
                type: {
                    path: 'type',
                    label: 'owner-alt-phone-type',
                    target: {
                        type: 'phoneNumberType',
                        noType: 'phoneNumberTypeNotInEnum',
                    },
                    formatter: OtherNoEnumTypeFormatter,
                    isRequired: true,
                },
                phone: {
                    path: 'phone',
                    label: 'owner-alt-phone',
                    target: {
                        number: 'number',
                        countryCode: 'countryCode',
                    },
                    formatter: PhoneNumberFormatter,
                    isRequired: true,
                    requiredFunction: phoneNumberRequired,
                    validate: isValidPhoneNumber,
                },
            },
        },
    },
};
