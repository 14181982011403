import { newOkta } from 'src/libs/auth/okta';
import { oktaConfig } from '../../config';

export const {
    clientId, issuer, redirectUri, scopes, pkce, // disableHttpsCheck,
} = oktaConfig;

export const auth = newOkta({
    clientId,
    issuer,
    redirectUri,
    scopes,
    pkce,
});

export const accessToken = async () => {
    const state = auth.state();
    if (state.status === 'AUTHENTICATED') {
        return (await state.token()).accessToken;
    }
    return null;
};
