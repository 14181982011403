import i18n from 'i18next';

export const Locale = {
    en: 'en',
    enSG: 'en-SG'
};

const languages = {
    primary: {
        'en': require('./Locales/en.json')
    },
    extended: {
        'en-SG': require('./Locales/en-SG.json')
    }
};

const languagesResources = {};
Object.entries(languages.primary).forEach(([code, resource]) => {
	languagesResources[code] = resource;
});

Object.entries(languages.extended).forEach(([code, resource]) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	languagesResources[code] = resource;
});

const fallbackMap = {};
Object.keys(languages.extended).forEach((extendedLanguage) => {
	const prefix = extendedLanguage.split('-')[0];
	if (Object.keys(languages.primary).includes(prefix)) {
		fallbackMap[extendedLanguage] = [prefix];
	}
});

const [defaultLanguage] = Object.keys(languages.primary);
fallbackMap.default = [defaultLanguage];

export const init = (locale) => {
    i18n
        .init({
            debug: process.env.NODE_ENV === 'development',
            resources: languagesResources,
            lng: locale,
            fallbackLng: fallbackMap,
            interpolation: {
                escapeValue: false,
            },
        });
};

export const translate = i18n.t.bind(i18n);

export const useTranslations = (workspace) => {
    return (key, options) => translate(`${workspace}:${key}`, options);
};

export const makeTranslationFunc = (workspace) => {
  return (key, options) => translate(`${workspace}:${key}`, options);
};


export const changeLanguage = i18n.changeLanguage.bind(i18n);
