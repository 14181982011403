/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { Widgetter } from '@shift/transform';
import {
    CircularLoader, FileCard, FileInfo, ThemeContext,
} from '@shift/design-system';
import { useFetch } from 'src/libs/fetch';
import { download, loadPreview } from 'src/utils/documents/download';
import { getFileExtension } from 'src/utils';

export const LoaderWrapper = styled.span`
    display: inline-block;
    margin: auto 0.5rem;
    vertical-align: top;
`;

export const ThumbnailWidget = ({ file }: { file: FileInfo }) => {
    const {
        src, size, thumbnailSrc, name,
    } = file;
    const theme = useContext(ThemeContext);
    const { imgPreview, download: dl } = useFetch();
    const [loading, setLoading] = useState(false);
    const [thumbnailData, setThumbnailData] = useState('');
    const downloadCallback = useCallback((url, fileName) => {
        if (!loading) download(dl, url ?? '', setLoading, fileName);
    }, [dl, setLoading, loading]);
    useEffect(() => {
        if (thumbnailSrc) {
            loadPreview(thumbnailSrc, imgPreview, setThumbnailData, setLoading, getFileExtension(name));
        }
    }, [src, size, imgPreview, thumbnailSrc, name]);

    return loading ? (
        <LoaderWrapper>
            <CircularLoader width="0.2rem" size="1.3rem" color={theme.colors.brand.primary.base} />
        </LoaderWrapper>
    ) : (
        <FileCard downloadCallback={downloadCallback} file={{ ...file, readOnly: true, thumbnailSrc: thumbnailData }} />
    );
};

export const ThumbnailWidgetter: Widgetter<FileInfo | string> = {
    transform: (file) => (
        typeof file === 'string' ? (
            <span>
                {file}
            </span>
        ) : <ThumbnailWidget file={{ readOnly: true, ...file }} />
    ),
};
