import { TextInput } from '@shift/design-system';
import React from 'react';
import {
    ArrayItemProps,
    DateInput,
    FormField,
} from 'src/components/form';
import { SingleFieldInformation } from 'src/types';

export interface DrivingLicenseBackendInfo {
    startDate: SingleFieldInformation;
    endDate: SingleFieldInformation;
    licenseTypeSourceName: SingleFieldInformation;
}

// eslint-disable-next-line max-len
export const GetDrivingLicenseItem = ({ startDate, licenseTypeSourceName }: DrivingLicenseBackendInfo) => ({ setItemPath, itemIndex }: ArrayItemProps) => (
    <>
        <div className="row">
            <div className="col-xs-6 form-group">
                <FormField
                    displayLabel={itemIndex === 0}
                    fieldInfo={setItemPath(licenseTypeSourceName)}
                    component={TextInput}
                    disabled
                />
            </div>
            <div className="col-xs-6 form-group">
                <FormField
                    displayLabel={itemIndex === 0}
                    fieldInfo={setItemPath(startDate)}
                    component={DateInput}
                    disabled
                />
            </div>
        </div>
    </>
);
