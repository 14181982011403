import { RouterRuleMap } from '@shift/telemetry';

export const ruleMap: RouterRuleMap = [
    {
        rule: /^\/accident-reports$/,
        url: '/accident-reports/',
        title: 'Accident Reports',
    },
    {
        rule: /^\/accident-reports\/details/,
        url: '/accident-reports/details/',
        title: 'Accident Report',
    },
    {
        rule: /^\/accident-report-form/,
        url: '/accident-reports/new/',
        title: 'New Accident Report',
    },
    {
        rule: /^\/accident-report-submitted/,
        url: '/accident-report-submitted/',
        title: 'Submitted Accident Report',
    },
    {
        rule: /^\/recoveries$/,
        url: '/recoveries/',
        title: 'Recoveries',
    },
    {
        rule: /^\/recoveries\/details/,
        url: '/recoveries/details/',
        title: 'Recovery',
    },
    {
        rule: /^\/exploration$/,
        url: '/exploration/person/',
        title: 'Search Person Exploration',
    },
    {
        rule: /^\/exploration\/person/,
        url: '/exploration/person/',
        title: 'Search Person Exploration',
    },
    {
        rule: /^\/exploration\/vehicle/,
        url: '/exploration/vehicle/',
        title: 'Search Vehicle Exploration',
    },
    {
        rule: /^\/ncd$/,
        url: '/exploration/ncd/',
        title: 'Search NCD Exploration',
    },
    {
        rule: /^\/insurer-enquiry$/,
        url: '/exploration/insurer/',
        title: 'Search Insurer Exploration',
    },
    {
        rule: /^\/account\/change-language$/,
        url: '/account/language/',
        title: 'Language Account',
    },
    {
        rule: /^\/account\/change-password$/,
        url: '/account/password/',
        title: 'Password Account',
    },
    {
        rule: /^\/folders$/,
        url: '/folders/',
        title: 'Folders',
    },
    {
        rule: /^\/folders\/details/,
        url: '/folders/details/',
        title: 'Folder',
    },
    {
        rule: /^\/third-party-reports$/,
        url: '/third-party-reports/',
        title: 'Search Third Party Reports',
    },
    {
        rule: /^\/arcs$/,
        url: '/arc/',
        title: 'ARC',
    },
];
