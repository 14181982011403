import React, { useMemo } from 'react';

import { SelectInputOption } from '@shift/design-system';
import { translate } from '@gears/translations';

import { ParameterType } from 'src/libs/filters';
import { SelectFilter, SelectFilterProps } from './SelectFilter';

export type BooleanFilterProps = Omit<SelectFilterProps, 'options' | 'parameterType'>;

export const BooleanFilter = ({
    operation, field, children, side,
}: BooleanFilterProps) => {
    const options: SelectInputOption[] = useMemo(() => [
        { label: translate('common:yes'), value: 'true' },
        { label: translate('common:no'), value: 'false' },
    ], []);

    return (
        <SelectFilter
            side={side}
            options={options}
            operation={operation}
            field={field}
            parameterType={ParameterType.Boolean}
        >
            {children}
        </SelectFilter>
    );
};
