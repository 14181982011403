import { useSecurity } from '../../libs/auth/Security';

export const useLocalStorage = () => {
    const { user } = useSecurity();
    const storage = window.localStorage;
    if (!storage) {
        throw new Error('Local storage not defined');
    }
    const getFullKey = (key: string) => `${user?.sub || ''}-${key}`;
    return {
        getItem: <T = string>(key: string): T | undefined => {
            const item = storage.getItem(getFullKey(key));
            if (!item) {
                return undefined;
            }
            try {
                const typedItem = JSON.parse(item) as T;
                return typedItem;
            } catch (err) {
                return undefined;
            }
        },
        setItem: <T = {}>(key: string, data: T) => storage.setItem(getFullKey(key), JSON.stringify(data)),
    };
};
