import React from 'react';
import { FormField, ArrayItemProps, EnumSelectInput } from 'src/components/form';
import { formSections } from 'src/config/form';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const ForeignVehicleItem = ({ setItemPath }: ArrayItemProps) => {
    const { item: itemInformation } = formSections.accidentSection.fields.foreignVehicles;

    const number = setItemPath(itemInformation.number);
    const category = setItemPath(itemInformation.category);
    return (
        <>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={number}
                        component={TextInput}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        component={EnumSelectInput}
                        fieldInfo={category}
                        enumName="VehicleType"
                    />
                </div>
            </div>
        </>
    );
};
