import { Formatter } from '@shift/transform';
import { EnumFormatters } from './EnumValuesFormatter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ClaimantEnumFormatter: Formatter<any[], { children: string }> = {
    transform([raw]) {
        const isOwner = raw.some((r) => r.specializations.mapping_ClaimPerson_Function.some(
            (rr: any) => rr.enumIdentifier === 'MotorClaimPersonFunction' && rr.enumValueId === 1,
        ));
        const isDriver = raw.some((r) => r.specializations.mapping_ClaimPerson_Function.some(
            (rr: any) => rr.enumIdentifier === 'MotorClaimPersonFunction' && rr.enumValueId === 3,
        ));
        let enumName = '';

        if (isDriver && isOwner) {
            enumName = 'Both';
        } else
        if (isDriver) {
            enumName = 'Driver';
        } else
        if (isOwner) {
            enumName = 'Owner';
        }

        const claimantEnum = [[{ Type: 'Claimant', Name: enumName }]];
        return EnumFormatters.EnumValuesFormatter.transform(claimantEnum);
    },
};
