import React from 'react';

export function configContextFactory<TConfig>(
    defaultConfig?: TConfig,
) {
    return React.createContext<TConfig | undefined>(defaultConfig);
}

export interface ConfigProviderProps<TRawConfig, TConfig> {
    configuration: TRawConfig;
    configurationContext: React.Context<TConfig | undefined>;
    children: React.ReactNode;
}

export function ConfigProvider<TConfig>({
    configuration,
    configurationContext,
    children,
}: ConfigProviderProps<TConfig, TConfig>) {
    return (
        <configurationContext.Provider value={configuration}>
            {children}
        </configurationContext.Provider>
    );
}

export type Key1<T> = keyof T;
export type Key2<T, K1 extends Key1<T>> = T[K1] extends undefined ? never : keyof T[K1];

export function configHookFactory<TConfig>(
    configContext: React.Context<TConfig | undefined>,
) {
    return function useConfig<
        K1 extends Key1<TConfig> = Key1<TConfig>,
        K2 extends Key2<TConfig, K1> = Key2<TConfig, K1>
    >(mainContext: K1, subContext: K2): TConfig[K1][K2] {
        const ctx = React.useContext(configContext);

        if (ctx) {
            if (!ctx[mainContext]) {
                throw new Error(`${mainContext} missing in configuration`);
            }

            if (!ctx[mainContext][subContext]) {
                throw new Error(
                    `${mainContext}.${subContext} missing in configuration`,
                );
            }
            return ctx[mainContext][subContext];
        }
        throw new Error('Configuration is not initialized');
    };
}
