// Only has the categories potentially used in gears
export enum DocumentCategory {
    UnClassified = 7,
    PoliceTranscript = 13,
    Handwritten = 11,
    SketchPlan = 27,
    PrivateHireCertificate = 28,
    AddendumForm = 29,
    IncidentVideo = 30,
    IncidentAudio = 31,
    IncidentPhoto = 32,
    RepairInvoice = 33,
    LetterOfRecoveryDemand = 34,
    LegalInvoice = 35,
    PaymentVoucher = 36,
}
