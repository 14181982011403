import { SingleValueCardConfig } from '../../../../../libs/config/QueryConfigTypes';


export const requestStatusFromInsurer: SingleValueCardConfig = {
    configType: 'SingleValueCard',
    context: ['ownedVehicle'],
    i18Namespace: 'person-exploration',
    transforms: [
        {
            chrooters: [
                ['person', 'name'],
            ],
        },
        {
            chrooters: [
                ['policy', 'referenceCode'],
            ],
        },
        {
            chrooters: [
                ['policy', 'effectiveDateOffset'],
            ],
        },
        {
            chrooters: [
                ['policy', 'expirationDateOffset'],
            ],
        },
        {
            chrooters: [
                [
                    'policyObject',
                    'specializations',
                    'mapping_PolicyObject_Motor_TerminationReason',
                    'policyObjectTerminationReason',
                ],
            ],
        },
        {
            chrooters: [
                ['person', 'identifier'],
            ],
        },
        {
            chrooters: [
                ['person', 'nationalIdentifier'],
            ],
        },
        {
            chrooters: [
                ['vehicle', 'vrn'],
            ],
        },
    ],
};
