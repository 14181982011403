import { Formatter } from '@shift/transform';
import { translate } from '@gears/translations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LiabilityFormatter: Formatter<any[], { children: string }> = {
    transform: (params) => {
        const value = params && params.length > 0 && params[0][0];
        return {
            children: value !== null && value !== undefined ? `${value}%` : translate('common:NA'),
        };
    },
};
