import { Formatter } from '@shift/transform';
import { translate } from '../../utils/forms';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExplorationReferenceFormatter: Formatter<any[], { children: string }> = {
    transform: ([[policyObjectTerminationReason]]) => {
        if (policyObjectTerminationReason?.Name === 'PaymentNotFulfilled') {
            return {
                children: translate('history-exploration:lapsed'),
            };
        }
        if (policyObjectTerminationReason?.Name === 'Cancelled') {
            return {
                children: translate('history-exploration:cancel'),
            };
        }
        // https://jira.shift-technology.com/browse/GEARS-1393
        // If PolicyObjectTerminationReason is null, "Active" should be the default
        // We consider a policy is "Active" unless the data says otherwise
        return { children: translate('history-exploration:active') };
    },
};
