import React from 'react';
import { translate } from '@gears/translations';
import { Page } from '../../Page';
import { AccidentReportFormPage as BaseAccidentReportFormPage } from './containers/AccidentReportForm';

export const AccidentReportFormPage = () => (
    <div className="arc-reporting-form-page">
        <Page title={translate('pages:accident-reports-form')}>
            <BaseAccidentReportFormPage />
        </Page>
    </div>
);
