import { changeLanguage, Locale } from '@gears/translations';
import { ThunkAction } from 'redux-thunk';
import { ApplicationActionTypes, SET_LOCALE } from './application-types';

export const setLocale = (locale: Locale): ThunkAction<void, {}, {}, ApplicationActionTypes> => (dispatch) => {
    changeLanguage((locale as string));
    dispatch({
        type: SET_LOCALE,
        locale,
    });
};
