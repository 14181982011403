import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import {
    Textarea,
    NumberInput, PhoneInput,
} from '@shift/design-system';

import { formSections } from 'src/config/form';
import {
    FormField, ArrayItemProps, YesNoRadio, EnumSelectInput,
} from 'src/components/form';
import { computeInjuredVehicleList } from 'src/utils/forms';
import { maxTextAreaCharacterLength, sgCountryCode } from 'src/constants';
import { TextInput } from '../../../../../components/TextInput/TextInput';

export const InjuredItem = ({ setItemPath }: ArrayItemProps) => {
    const { item: itemInformation } = formSections.injuredSection.fields.injuredPersons;

    const { input: tpVehicles } = useField(formSections.thirdPartySection.fields.vehiclesOrProperties.path, { subscription: { value: true } });
    const { input: vehicleSelection } = useField(setItemPath(itemInformation.injuredVehicle).path, { subscription: { value: true } });
    const onSelectionChange = vehicleSelection.onChange;
    const vehiclesList = useMemo(() => {
        const list = computeInjuredVehicleList(tpVehicles.value);
        const selectedItem = list.find((item) => item.value === vehicleSelection.value.value);

        if (vehicleSelection.value) {
            if (!selectedItem) onSelectionChange(undefined);
            else if (selectedItem.label !== vehicleSelection.value.label) onSelectionChange(selectedItem);
        }
        return list;
    }, [tpVehicles.value, vehicleSelection.value, onSelectionChange]);

    return (
        <>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.name)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.gender)}
                        component={EnumSelectInput}
                        enumName="Gender"
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.primaryMobilePhone)}
                        component={PhoneInput}
                        defaultValue={sgCountryCode}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.address1)}
                        component={TextInput}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.address2)}
                        component={TextInput}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.postCode)}
                        component={TextInput}
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.age)}
                        component={NumberInput}
                        min={0}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.injuriesDescription)}
                        component={Textarea}
                        maxCharacterLength={maxTextAreaCharacterLength}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.injuredVehicle)}
                        component={EnumSelectInput}
                        options={vehiclesList}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.hadSeatBeltOn)}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
                <div className="col-xs-6 form-group">
                    <FormField
                        fieldInfo={setItemPath(itemInformation.tookAmbulance)}
                        component={YesNoRadio}
                        className="radio-option"
                    />
                </div>
            </div>
        </>
    );
};
