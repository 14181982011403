export const REGULAR_ROW_TYPE = Symbol('regular_row_type');
export const ITALIC_ROW_TYPE = Symbol('italic_row_type');
export const MISSING_ROW_TYPE = Symbol('missing_row_type');
export const LOADING_ROW_TYPE = Symbol('loading_row_type');
export const NO_DATA_ROW_TYPE = Symbol('no_data_row_type');
export const EXPANDABLE_ROW_TYPE = Symbol('expandable_row_type');


// Exploration Histories
export const EXPANDABLE_CLAIM_ROW_TYPE = Symbol('expandable_claim_row_type');
export const REGULAR_CLAIM_ROW_TYPE = Symbol('regular_claim_row_type');
export const EXPANDABLE_AR_ROW_TYPE = Symbol('expandable_ar_row_type');
export const REGULAR_AR_ROW_TYPE = Symbol('regular_ar_row_type');
export const EXPANDABLE_POLICY_ROW_TYPE = Symbol('expandable_policy_row_type');
export const REGULAR_POLICY_ROW_TYPE = Symbol('regular_policy_row_type');
