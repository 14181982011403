import React, { useContext } from 'react';
import { Widgetter } from '@shift/transform';
import { ArrowDown, ArrowUp } from '@shift/icons';
import {
    StyledText, Icon, Grid, GridItem, ThemeContext,
} from '@shift/design-system';
import { translate } from '@gears/translations';

export interface TrajectoryIconWidgetProps {
    currentNcd: number;
    renewalNcd: number;
}

const ValueWidget = ({ currentNcd, renewalNcd }: TrajectoryIconWidgetProps) => {
    const { colors } = useContext(ThemeContext);
    const isEqual = currentNcd === renewalNcd;
    const isRenewalGreaterThanCurrent = currentNcd && renewalNcd && currentNcd < renewalNcd;
    const Arrow = isRenewalGreaterThanCurrent ? ArrowUp : ArrowDown;
    return (
        <Grid>
            <GridItem>
                {renewalNcd !== undefined ? (
                    <StyledText weight="bold" type="span">
                        {`${renewalNcd} %`}
                    </StyledText>
                )
                    : (
                        <StyledText type="div" weight="bold" color={colors?.text?.primary} wrap="nowrap">
                            {translate('ncd:not-available')}
                        </StyledText>

                    )}
            </GridItem>
            {!!currentNcd && !!renewalNcd && !isEqual && (
                <Icon
                    width={1}
                    height={1}
                    color={isRenewalGreaterThanCurrent ? 'green' : 'red'}
                >
                    <Arrow />
                </Icon>
            )}
            <GridItem />
        </Grid>

    );
};
export const TrajectoryIconWidget: Widgetter<TrajectoryIconWidgetProps> = {
    transform: ValueWidget,
};
